import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			hl7MessageId: props.data._id,
			errors: [],
		};
	}

	handleDownload = () => {
		this.downloadTextFile("hl7Message_" + this.props.data.time_transmitted + ".hl7", this.props.data.message_content);
	};

	handleShow = () => {
		this.setState({ show: true });
	}

	handleClose = () => {
		this.setState({ show: false });
	}

	renderTooltipDownload = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Download HL7 Message Content
		</Tooltip>
	);

	renderTooltipView = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			View HL7 Message Content
		</Tooltip>
	);

	downloadTextFile = (filename, text, type = "text/plain") => {
		// Create an invisible A element
		const a = document.createElement("a");
		a.style.display = "none";
		document.body.appendChild(a);

		// Set the HREF to a Blob representation of the data to be downloaded
		a.href = window.URL.createObjectURL(
			new Blob([text], { type })
		);

		// Use download attribute to set set desired file name
		a.setAttribute("download", filename);

		// Trigger the download by simulating click
		a.click();

		// Cleanup
		window.URL.revokeObjectURL(a.href);
		document.body.removeChild(a);
	}

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDownload}
				>
					<button onClick={this.handleDownload} className="edit-order-btn">
						<i className="fas fa-download"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipView}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-eye"></i>
					</button>
				</OverlayTrigger>
				<Modal
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>HL7 Message Content</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								this.props.data.message_content
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}
