import React, { Component } from "react";
import Breadcrumb from "./breadcrumb";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormLabel,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import "moment-timezone";

class SearchMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Breadcrumb />
        <div
          className="row mt-1 d-flex align-items-center"
          style={{ padding: "12px" }}
        >
          <div className="col-md-8">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <FormLabel
                      component="legend"
                      style={{ marginBottom: "10px", marginLeft: "10px" }}
                    >
                      Date Range
                    </FormLabel>
                  </div>
                </div>
                <div className="dateRangeBar mb-3">
                  <button
                    className={
                      "btn btn-info dateRangeBtn mx-1 mb-2" +
                      (this.props.date_range === "today" ? " active" : "")
                    }
                    onClick={() => this.props.updateDateRange("today")}
                  >
                    Today
                  </button>
                  <button
                    className={
                      "btn btn-info dateRangeBtn mx-1 mb-2" +
                      (this.props.date_range === "week" ? " active" : "")
                    }
                    onClick={() => this.props.updateDateRange("week")}
                  >
                    Week
                  </button>
                  <button
                    className={
                      "btn btn-info dateRangeBtn mx-1 mb-2" +
                      (this.props.date_range === "month" ? " active" : "")
                    }
                    onClick={() => this.props.updateDateRange("month")}
                  >
                    Month
                  </button>
                  <button
                    className={
                      "btn btn-info dateRangeBtn ml-1 mb-2" +
                      (this.props.date_range === "custom" ? " active" : "")
                    }
                  >
                    Custom
                  </button>
                </div>
                <div className="dateRangeBar dateRangeBarPickers">
                  <MobileDateTimePicker
                    value={
                      this.state.pickerOpened
                        ? this.state.from_date
                        : this.props.from_date
                    }
                    onChange={(dateTime) =>
                      this.setState({ from_date: dateTime })
                    }
                    onOpen={() =>
                      this.setState({
                        from_date: this.props.from_date,
                        pickerOpened: true,
                      })
                    }
                    onClose={() => this.setState({ pickerOpened: false })}
                    onAccept={(dateTime) =>
                      this.props.handleDateFiltersChange(
                        dateTime.$d,
                        "from_date"
                      )
                    }
                    label="From Date"
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        className="ml-1 mr-2 mt-2"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <MobileDateTimePicker
                    value={
                      this.state.pickerOpened
                        ? this.state.to_date
                        : this.props.to_date
                    }
                    onChange={(dateTime) =>
                      this.setState({ to_date: dateTime })
                    }
                    onOpen={() =>
                      this.setState({
                        to_date: this.props.to_date,
                        pickerOpened: true,
                      })
                    }
                    onClose={() => this.setState({ pickerOpened: false })}
                    onAccept={(dateTime) =>
                      this.props.handleDateFiltersChange(dateTime.$d, "to_date")
                    }
                    label="To Date"
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        className="mt-2"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-right">
            <TextField
              label="Current Grid Search"
              variant="outlined"
              id="reset-form"
              className="mt-2"
              InputLabelProps={{
                shrink: true,
              }}
              margin="none"
              onChange={this.props.onFilterTextChange}
            />
            <button
              className="btn btn-primary button-info-grid"
              onClick={() => this.props.clearFilter()}
            >
              <i className="fa fa-times" aria-hidden="true"></i> Clear Filter
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchMenu;
