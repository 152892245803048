import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ClinicPortalLoginContainer from "../components/clinicPortal/login";
import LIMSPortalLayout from "../layouts/LIMSPortalLayout";
import LIMSAccessioningPage from "../components/limsPortal/accessioning";
import LIMSImportResultsGrid from "../components/limsPortal/importResults";
import LIMSPlateTemplatesGrid from "../components/limsPortal/plateTemplates";
import LIMSExportPlateGrid from "../components/limsPortal/exportPlate";
import LIMSPlateArchiveGrid from "../components/limsPortal/plateArchive";

import ThemeProvider from "../theme/ThemeProvider";

class LIMSPortalRouter extends Component {
    render(props) {
        return <ThemeProvider>
            <LIMSPortalLayout>
                <Switch>
                    <Route path="/lims/" exact component={ClinicPortalLoginContainer} />
                    <Route path="/lims/accessioning" exact component={LIMSAccessioningPage} />
                    <Route path="/lims/importResults" exact component={LIMSImportResultsGrid} />
                    <Route path="/lims/plateTemplates" exact component={LIMSPlateTemplatesGrid} />
                    <Route path="/lims/exportPlate" exact component={LIMSExportPlateGrid} />
                    <Route path="/lims/plateArchive" exact component={LIMSPlateArchiveGrid} />
                </Switch>
            </LIMSPortalLayout>
        </ThemeProvider>
    }
}

export default LIMSPortalRouter;