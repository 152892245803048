import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import PdfRenderer from "../../requisitions/clinicRequisitionGrid/requisitionPdfRenderer"
import ActionComponent from "./ActionComponent";
import CancelReasonComponent from "./CancelReasonComponent";
import RequisitionsNewSearchbar from "./RequisitionsNewSearchbar";
import moment from "moment";
import {
	getRequisitionNewSamples,
} from "../../../../services/clinicPortalServices/orderSearchService";
import { getUserRole } from "../../../../services/common/util";
import RequisitionNewBreadcrumb from './requisitionNewBreadcrumb';
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicRequisitionNewGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user_role: getUserRole(),
			gridName: "Default",
			context: { componentParent: this },
			rowData: [],
			sampleIds: [],
			searchString: "",
			rowTmpData: [],
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			columnDefs: [
				{

					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					resizable: true,
					cellStyle: { textAlign: "center" },
					cellRenderer: "ActionComponent",

				},
				{
					headerName: 'Requisition Information',
					children: [
						{
							headerName: "Patient Name",
							minWidth: 200,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.patient_id) {
									return (
										params.data.patient_id.first_name +
										" " +
										params.data.patient_id.last_name
									);
								} else {
									return "";
								}
							},
							cellRenderer: 'patientBtnCellRenderer'
						},
						{
							headerName: "DOB",
							minWidth: 150,
							valueGetter: function addColumns(params) {
								return params.data.patient_id && params.data.patient_id.date_of_birth
									? moment(
										params.data.patient_id.date_of_birth,
										"YYYY-MM-DD"
									).format("MM/DD/YYYY")
									: "";
							},
							field: "patient_id.date_of_birth",
							columnGroupShow: 'open'
						},
						{
							headerName: "MRN",
							minWidth: 150,
							field: "patient_id.mrn",
							columnGroupShow: 'open'
						},
						{
							headerName: "Test",
							minWidth: 150,
							field: "test_id.name",
							columnGroupShow: 'open'
						},
						{
							headerName: "Specimen Type",
							minWidth: 150,
							field: "test_info.specimenType",
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Sample Type",
							minWidth: 150,
							field: "test_info.sampleType",
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Sample ID",
							minWidth: 180,
							field: "test_info.sample",
							resizable: true
						},
						{
							headerName: "Requisition PDF",
							minWidth: 150,
							field: "requisition_pdf_path",
							cellRenderer: "PdfRenderer",
							resizable: true
						},
						{
							headerName: "Facility",
							minWidth: 150,
							resizable: true,
							field: "facility_id.name",
						},
						{
							headerName: "Physician",
							minWidth: 150,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.provider) {
									return (
										params.data.provider.first_name +
										" " +
										params.data.provider.last_name
									);
								} else {
									return "";
								}
							},
						},
						{
							headerName: "Collected Date",
							field: "test_info.collected",
							minWidth: 200,
							resizable: true,
							valueGetter: function (params) {
								return params.data.test_info && params.data.test_info.collected
									? moment(
										params.data.test_info.collected,
										"YYYYMMDDHHmmss"
									).format("MM/DD/YYYY hh:mm A")
									: "";
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Collector Name",
							field: "test_info.collector_name",
							minWidth: 200,
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Diagnosis Code",
							minWidth: 150,
							resizable: true,
							field: "diagnosis_code",
							valueGetter: function addColumns(params) {
								if (params.data.diagnosis_code && params.data.diagnosis_code.length) {
									return params.data.diagnosis_code.join(', ');
								} else {
									return "";
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Passport",
							minWidth: 150,
							resizable: true,
							field: "patient_id.passport",
							columnGroupShow: 'open'
						},
						{
							headerName: "Is HL7?",
							resizable: true,
							field: "is_HL7_requisition",
							valueGetter: function addColumns(params) {
								return params.data.is_HL7_requisition ? "YES" : "";
							},
						},
						{
							headerName: "Is VIP Order?",
							resizable: true,
							field: "is_vip_order",
							valueGetter: function addColumns(params) {
								return params.data.is_vip_order ? "YES" : "NO";
							},
						},
						{
							headerName: "Is Cancelled?",
							resizable: true,
							field: "is_cancel",
							valueGetter: function addColumns(params) {
								return params.data.is_cancel ? "YES" : "NO";
							},
						},
						{
							headerName: "Reason for cancellation",
							resizable: true,
							field: "cancel_reason",
							cellStyle: { textAlign: "center" },
							cellRenderer: "CancelReasonComponent",
						},
					]
				},
			],
			components: {
				PdfRenderer: PdfRenderer,
				ActionComponent: ActionComponent,
				CancelReasonComponent: CancelReasonComponent
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowClassRules: {
				'cancelled-requisition': function (params) {
					return params.data.is_cancel;
				},
				'passed-24hours-requisition': function (params) {
					return !params.data.is_cancel && params.data.passed_24hours && !(params.data.sample_tracking && params.data.sample_tracking.filter(item => item.eventName === "ACCESSIONED").length);
				},
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			dateFilter: {
				fromDate: moment().subtract(1, "days").format("YYYY-MM-DD") + 'T00:00',
				toDate: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'custom'
			}
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	getSampleIds = () => {
		let sampleIdArray = [];
		this.state.rowTmpData.map((row) => {
			if ('sample' in row.test_info) {
				sampleIdArray.push(row.test_info);
			}
			return null;
		});
		this.setState({ sampleIds: sampleIdArray });
	}

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		getRequisitionNewSamples(this.state.dateFilter)
			.then((response) => {
				if (response.data.length > 0) {
					let requisitions = [];
					response.data.map(item => {
						let pushToArray = true;
						if (item.orderIds && item.orderIds.length) {
							item.orderIds.map(order => {
								if (order.is_assigned_to_plate) {
									pushToArray = false;
								}
								return null;
							})
						} else if (item.order_type === 'sub_bundle' && item.bundle_id && item.bundle_id.is_cancel) {
							pushToArray = false;
						}
						if (pushToArray) {
							requisitions.push(item);
						}
						return null;
					})
					this.setState({ rowTmpData: requisitions }, () => {
						this.getSampleIds();
						if (this.state.searchString) {
							this.showRequestNewSamples(this.state.searchString);
						}
					});

					this.gridApi.hideOverlay();
				} else {
					this.gridApi.showNoRowsOverlay();
				}
				this.gridApi.refreshCells();
			})

	};

	updateDateRange = (dateRange) => {
		let datefilter = this.state.dateFilter;
		if (dateRange !== datefilter.dateRange) {
			switch (dateRange) {
				case 'today':
					datefilter.toDate = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					datefilter.fromDate = moment().format("YYYY-MM-DD") + 'T00:00';
					datefilter.dateRange = dateRange;
					break;
				case 'week':
					datefilter.toDate = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					datefilter.fromDate = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					datefilter.dateRange = dateRange;
					break;
				case 'month':
					datefilter.toDate = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					datefilter.fromDate = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					datefilter.dateRange = dateRange;
					break;
				default:
					break;
			}
			this.setState({ dateFilter: datefilter });
			if (dateRange !== 'custom') {
				this.loadGridData();
			}
		}

	}

	setDateRange = (dateFilter) => {
		if (moment(dateFilter.toDate).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(dateFilter.fromDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				dateFilter.dateRange = 'today';
			} else if (moment(dateFilter.fromDate).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				dateFilter.dateRange = 'week';
			} else if (moment(dateFilter.fromDate).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				dateFilter.dateRange = 'month';
			} else {
				dateFilter.dateRange = 'custom';
			}
		} else {
			dateFilter.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		let date_filter = this.state.dateFilter;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(date_filter.toDate).isAfter(dateTime)) {
				date_filter.fromDate = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(date_filter.fromDate)) {
				date_filter.toDate = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setDateRange(date_filter);
			this.setState({ dateFilter: date_filter });
			this.loadGridData();
		}
	}

	onSearchTextChange = (e) => {
		this.setState({
			searchString: e.target.value
		})
		this.showRequestNewSamples(e.target.value);
	};

	findSample = (sampleId) => {
		this.setState({
			searchString: sampleId
		})
		this.showRequestNewSamples(sampleId);
	};

	showRequestNewSamples = (searchString) => {
		if (searchString) {
			this.setState({ rowData: this.state.rowTmpData });
			this.gridApi.setQuickFilter(searchString);
		} else {
			this.setState({ rowData: [] });
		}
	}


	render() {
		let columnDefs = /salesperson|salesadmin/.test(getUserRole().toLowerCase()) ? this.state.columnDefs[0].children.filter(x => /Patient Name|Sample ID|Facility|Physician|Collected Date|Collector Name/.test(x.headerName)) : this.state.columnDefs;
		columnDefs = /audit/.test(getUserRole().toLowerCase()) ? columnDefs.filter(x => x.headerName !== 'Actions') : columnDefs;
		return (
			<div className="clinic-contain">
				<RequisitionNewBreadcrumb />
				<RequisitionsNewSearchbar
					onSearchTextChange={this.onSearchTextChange}
					handleDateFiltersChange={this.handleDateFiltersChange}
					updateDateRange={this.updateDateRange}
					dateFilter={this.state.dateFilter}
					from_date={this.state.dateFilter.fromDate}
					to_date={this.state.dateFilter.toDate}
					date_range={this.state.dateFilter.dateRange}
					findSample={this.findSample}
					sampleIds={this.state.sampleIds}
					searchString={this.state.searchString}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 230px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
									rowClassRules={this.state.rowClassRules}
								/>
							</div>
						)}
					</ThemeContext.Consumer>

				</div>
			</div>
		);
	}
}

export default ClinicRequisitionNewGrid;