import React from "react";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon } from "react-share";

const SocialShare = () => {
    return (
        <div className="container" style={{ textAlign: "center", paddingBottom: "20px" }}>
            <FacebookShareButton url="https://benchlabit.com" style={{ padding: "5px" }}>
                <FacebookIcon size={32}>

                </FacebookIcon>
            </FacebookShareButton>
            <TwitterShareButton url="https://benchlabit.com" style={{ padding: "5px" }}>
                <TwitterIcon size={32}>

                </TwitterIcon>
            </TwitterShareButton>
            <LinkedinShareButton url="https://benchlabit.com" style={{ padding: "5px" }}>
                <LinkedinIcon size={32}>

                </LinkedinIcon>
            </LinkedinShareButton>
            <EmailShareButton url="https://benchlabit.com" style={{ padding: "5px" }}>
                <EmailIcon size={32}>

                </EmailIcon>
            </EmailShareButton>
        </div>
    );
}
export default SocialShare;

