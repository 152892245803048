import React, { Component } from "react";
import { searchOrdersUniversal } from "../../../services/clinicPortalServices/orderSearchService";
import { getPlateList } from "../../../services/limsPortalServices/plateService";
import {
    TextField,
    IconButton,
    InputAdornment,
    MenuItem,
    Popover
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import toastr from "toastr";
import { FadeLoader } from "react-spinners";
import { loaderPositionStyle } from "../../../services/common/optionsData";
import OrderViewPopup from "../../clinicPortal/orders/clinicOrderGrid/orderViewPopup";

class UniversalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            targetTemp: null,
            searchStr: "",
            searchResults: [],
            showResultDetail: false,
            resultDetail: {},
            showLoading: false,
            plateList: []
        };
    }

    componentDidMount() {
        getPlateList().then((res) => {
            if (res && res.data) {
                this.setState({ plateList: res.data });
            }
        });
    }

    handleChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        this.setState({ [key]: value });
    };

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.handleSearch(e);
        }
    };

    handleRemove = () => {
        this.setState({ searchStr: "" });
    };

    handleSearch = (event) => {
        if (this.state.searchStr.length > 2) {
            const targetTemp = event.currentTarget;
            this.setState({ searchResults: [], showLoading: true });
            searchOrdersUniversal({ searchStr: this.state.searchStr }).then(
                (response) => {
                    this.setState({ showLoading: false });
                    if (response.data?.length > 0) {
                        this.setState({
                            searchResults: response.data,
                            anchorEl: targetTemp
                        });
                    } else {
                        toastr.error("No data matched!");
                    }
                }
            );
        } else if (this.state.searchStr) {
            toastr.warning("Please provide at least 3 characters.");
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleShowResultDetail = () => {
        this.setState({ showResultDetail: true });
    };

    handleCloseResultDetail = () => {
        this.setState({ showResultDetail: false });
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;
        if (
            window.location.pathname &&
            window.location.pathname.includes("brooksLimsRecord")
        ) {
            return null;
        }
        return (
            <>
                <TextField
                    name="searchStr"
                    variant="outlined"
                    className="universal-search"
                    label="System Search"
                    value={this.state.searchStr}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {this.state.searchStr && (
                                    <IconButton
                                        onClick={this.handleRemove}
                                        edge="end"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    onClick={this.handleSearch}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    size="small"
                    margin="none"
                    sx={{ maxWidth: 220 }}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    style={{ height: "300px" }}
                >
                    {this.state.searchResults.length > 0 &&
                        this.state.searchResults.map((option) => (
                            <MenuItem
                                className="search-item-container"
                                key={option._id}
                                selected={option === "Pyxis"}
                                onClick={() =>
                                    this.setState({
                                        resultDetail: { _id: option._id },
                                        showResultDetail: true,
                                        anchorEl: null
                                    })
                                }
                            >
                                <div
                                    className={`search-item-dot ${
                                        option.results?.value
                                            ? "search-item-order"
                                            : "search-item-requisition"
                                    }`}
                                ></div>
                                <div style={{ marginLeft: "10px" }}>
                                    <div className="search-item-sample">
                                        {option.test_info?.sample}
                                    </div>
                                    <div className="search-item-lab">
                                        {option.lab_order_id}
                                    </div>
                                    <div className="search-item-patient">
                                        {option.patient_id.first_name +
                                            " " +
                                            option.patient_id.last_name}{" "}
                                        |{" "}
                                        {moment(
                                            option.patient_id.date_of_birth,
                                            "YYYY-MM-DD"
                                        ).format("MM/DD/YYYY")}
                                    </div>
                                </div>
                            </MenuItem>
                        ))}
                </Popover>
                {this.state.resultDetail &&
                    this.state.resultDetail._id &&
                    this.state.showResultDetail && (
                        <OrderViewPopup
                            show={this.state.showResultDetail}
                            handleClose={this.handleCloseResultDetail}
                            data={this.state.resultDetail}
                            plateList={this.state.plateList}
                            type="universal_search"
                        />
                    )}
                <FadeLoader
                    loading={this.state.showLoading}
                    height={12}
                    width={5}
                    radius={10}
                    margin={2}
                    css={loaderPositionStyle}
                    color={"var(--primary-background-active-color)"}
                />
            </>
        );
    }
}

export default UniversalSearch;
