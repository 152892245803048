import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import UserDetails from "./userDetails";
import { deleteUser, updateUser } from "../../../../services/clinicPortalServices/userService";
import { getUserDetails, getUserRole } from "../../../../services/common/util";
import Swal from "sweetalert2";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		let loggedInUserName = '';
		if (getUserDetails()) {
			loggedInUserName = JSON.parse(getUserDetails()).user_name;
		}
		this.state = {
			show: false,
			userId: props.data._id,
			errors: [],
			loggedInUserName: loggedInUserName
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this user!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				const userInfo = {
					_id: this.state.userId,
					first_name: this.props.data.first_name,
					last_name: this.props.data.last_name,
				};
				deleteUser(userInfo)
					.then((response) => {
						this.props.context.componentParent.loadUserGridData();
					})
			}
		});
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit User
		</Tooltip>
	);

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete User
		</Tooltip>
	);

	renderTooltipActive = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Activate User
		</Tooltip>
	)

	renderTooltipDeactive = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Deactivate User
		</Tooltip>
	)

	handleDeactivate = () => {
		let userInfo = {
			id: this.props.data._id,
			userName: this.props.data.user_name,
			role: this.props.data.role,
			firstName: this.props.data.first_name,
			lastName: this.props.data.last_name,
			password: this.props.data.password,
			email: this.props.data.email,
			mobile: this.props.data.mobile,
			facilityIds: this.props.data.facilityIds,
			companyIds: this.props.data.companyIds,
			require2FA: this.props.data.require2FA,
			is_active: false
		};
		updateUser(userInfo).then(res => {
			this.props.context.componentParent.loadUserGridData();
		})
	}

	handleActivate = () => {
		let userInfo = {
			id: this.props.data._id,
			userName: this.props.data.user_name,
			role: this.props.data.role,
			firstName: this.props.data.first_name,
			lastName: this.props.data.last_name,
			password: this.props.data.password,
			email: this.props.data.email,
			mobile: this.props.data.mobile,
			facilityIds: this.props.data.facilityIds,
			companyIds: this.props.data.companyIds,
			require2FA: this.props.data.require2FA,
			is_active: true,
			last_logged_in: moment().format("YYYYMMDDHHmmss")
		};
		updateUser(userInfo).then(res => {
			this.props.context.componentParent.loadUserGridData();
		})
	}

	render() {
		return (
			<div>
				{
					!(this.props.data.role === 'SuperAdmin' && /^admin/.test(getUserRole().toLowerCase()))
					&&
					<div>
						{
							!/salesadmin/.test(getUserRole().toLowerCase())
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipEdit}
							>
								<button onClick={this.handleShow} className="edit-order-btn">
									<i className="fas fa-pen"></i>
								</button>
							</OverlayTrigger>
						}
						{
							/salesadmin/.test(getUserRole().toLowerCase()) && this.props.data.role === 'SalesPerson'
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipEdit}
							>
								<button onClick={this.handleShow} className="edit-order-btn">
									<i className="fas fa-pen"></i>
								</button>
							</OverlayTrigger>
						}
						{
							!/salesadmin/.test(getUserRole().toLowerCase()) && this.props.data.is_active
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipDeactive}
							>
								<button onClick={this.handleDeactivate} className="edit-order-btn">
									<i className="fas fa-lock"></i>
								</button>
							</OverlayTrigger>
						}
						{
							!/salesadmin/.test(getUserRole().toLowerCase()) && !this.props.data.is_active
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipActive}
							>
								<button onClick={this.handleActivate} className="edit-order-btn">
									<i className="fas fa-lock-open"></i>
								</button>
							</OverlayTrigger>
						}
						{/* {
							['spmobileprods', 'creid'].includes(this.state.loggedInUserName)
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipDelete}
							>
								<button onClick={this.handleDelete} className="edit-order-btn">
									<i className="fas fa-trash"></i>
								</button>
							</OverlayTrigger>
						} */}
					</div>
				}
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update User Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<UserDetails
							userId={this.state.userId}
							context={this.props.context}
							handleClose={this.handleClose}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
