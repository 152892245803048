import React, { Component, memo } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import HistoryCellRenderer from "./historyCellRenderer";
import { getInvoiceData } from "../../../../services/clinicPortalServices/invoiceServices";
import InvoiceSearchMenu from "./invoiceSearchMenu";
import NoteCellRenderer from "./noteCellRenderer";
import {
    getUserRole,
    dateComparator,
    getUserDetails
} from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicInvoiceGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 180,
                    maxWidth: 180,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Company Name",
                    field: "company_id.name",
                    minWidth: 150,
                    resizable: true
                },
                {
                    headerName: "Invoice ID",
                    field: "invoice_id",
                    minWidth: 120,
                    resizable: true
                },
                {
                    headerName: "Initial Amount",
                    field: "init_amount",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function (params) {
                        return "$" + params.data.init_amount;
                    }
                },
                {
                    headerName: "Services",
                    field: "services",
                    minWidth: 250,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        let dom = [];
                        params.data.services.map((service, index) => {
                            dom.push(
                                <div key={index}>
                                    ${service.rate * service.quantity}(
                                    {service.service_name})
                                </div>
                            );
                            return null;
                        });
                        return dom;
                    })
                },
                {
                    headerName: "Payments",
                    field: "payments",
                    minWidth: 150,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        let dom = [];
                        params.data.payments.map((payment, index) => {
                            dom.push(
                                <div key={index}>
                                    ${payment.amount}(
                                    {moment(payment.appliedDate).format(
                                        "MM/DD/YYYY"
                                    )}
                                    )
                                </div>
                            );
                            return null;
                        });
                        return dom;
                    })
                },
                {
                    headerName: "Credits",
                    field: "credits",
                    minWidth: 150,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        let dom = [];
                        params.data.credits.map((credit, index) => {
                            dom.push(
                                <div key={index}>
                                    ${credit.amount}(
                                    {moment(credit.appliedDate).format(
                                        "MM/DD/YYYY"
                                    )}
                                    )
                                </div>
                            );
                            return null;
                        });
                        return dom;
                    })
                },
                {
                    headerName: "Notes",
                    field: "notes",
                    minWidth: 90,
                    resizable: true,
                    cellRenderer: "noteCellRenderer"
                },
                {
                    headerName: "History",
                    field: "histories",
                    minWidth: 100,
                    resizable: true,
                    cellRenderer: "historyCellRenderer"
                },
                {
                    headerName: "Invoice date",
                    field: "invoice_date",
                    minWidth: 120,
                    resizable: true,
                    valueGetter: function (params) {
                        if (params.data.invoice_date) {
                            return moment(
                                params.data.invoice_date,
                                "MM/DD/YYYY"
                            ).format("MM/DD/YYYY");
                        } else {
                            return "";
                        }
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Due date",
                    field: "due_date",
                    minWidth: 120,
                    resizable: true,
                    valueGetter: function (params) {
                        if (params.data.due_date) {
                            return moment(
                                params.data.due_date,
                                "MM/DD/YYYY"
                            ).format("MM/DD/YYYY");
                        } else {
                            return "";
                        }
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Outstanding money",
                    field: "amount",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function (params) {
                        return "$" + params.data.amount;
                    }
                },
                {
                    headerName: "Status",
                    field: "state",
                    minWidth: 140,
                    cellRenderer: memo((params) => {
                        if (params.data.state === "PAID") {
                            return (
                                <div style={{ color: "rgb(18, 222, 18)" }}>
                                    {params.data.state}
                                </div>
                            );
                        } else {
                            return (
                                <div style={{ color: "red" }}>
                                    {params.data.state}
                                </div>
                            );
                        }
                    })
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer,
                historyCellRenderer: HistoryCellRenderer,
                noteCellRenderer: NoteCellRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                wrapText: true
            },
            rowData: [],
            context: { componentParent: this }
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridData();
    };

    loadGridData = () => {
        getInvoiceData().then((response) => {
            let invoices = [];
            if (
                ["facilityuser", "facilityadmin"].includes(
                    getUserRole().toLowerCase()
                )
            ) {
                response.data.map((item) => {
                    let flag = false;
                    const userFacilityIds = JSON.parse(
                        window.localStorage.getItem("FACILITY_IDS")
                    );
                    item.company_id.facilityIds.map((a) => {
                        userFacilityIds.map((b) => {
                            if (a === b) {
                                flag = true;
                            }
                            return null;
                        });
                        return null;
                    });
                    if (flag) {
                        invoices.push(item);
                    }
                    return null;
                });
            } else {
                invoices = response.data;
            }
            this.setState({ rowData: invoices });
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    getRowHeight = (params) => {
        const maxCount = Math.max(
            params.data.payments.length,
            params.data.services.length,
            params.data.credits.length
        );
        return 35 * maxCount;
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Invoice_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Invoices"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.InvoiceGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.InvoiceGridExported
        };
        createAudit(auditData);
    };

    render() {
        return (
            <div className="clinic-contain">
                <InvoiceSearchMenu
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                    context={this.state.context}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine invoice-grid"
                                        : "ag-theme-alpine-dark invoice-grid"
                                }
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    components={this.state.components}
                                    context={this.state.context}
                                    getRowHeight={this.getRowHeight}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicInvoiceGrid;
