import React, { Component } from "react";
import { TextField } from "@mui/material";
import Breadcrumb from "./breadcrumb";
import AddPlateTemplate from "./addPlateTemplate";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<Breadcrumb />
				<div className="row" style={{ padding: "12px" }}>
					<div
						className="col-md-8"
					>
						<div className="row">
							<div className="col-md-6">
								<TextField
									label="Current Grid Search"
									variant="outlined"
									id="reset-form"
									InputLabelProps={{
										shrink: true,
									}}
									type="string"
									margin="dense"
									onChange={this.props.onFilterTextChange}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="col grid-buttons">
							<div>
								<AddPlateTemplate
									context={this.props.context}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchMenu;
