import React, { Component } from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import Breadcrumb from "./breadcrumb";

class SearchMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div>
                <Breadcrumb />
                <div className="row" style={{ padding: "12px" }}>
                    <div
                        className="col-md-8"
                        style={{ borderRight: "2px solid lightGray" }}
                    >
                        <div className="row">
                            <div className="col-md-5">
                                <TextField
                                    label="Current Grid Search"
                                    variant="outlined"
                                    id="reset-form"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type="string"
                                    margin="dense"
                                    onChange={this.props.onFilterTextChange}
                                />
                            </div>
                            {this.props.tempImportedData.length > 0 &&
                                this.props.activeStep > 1 && (
                                    <div className="col-md-7">
                                        <FormControl
                                            className="mr-3"
                                            component="fieldset"
                                        >
                                            <FormLabel
                                                style={{ marginBottom: "0px" }}
                                            >
                                                Result
                                            </FormLabel>
                                            <RadioGroup
                                                aria-label="result"
                                                name="result_value"
                                                value={this.props.result_value}
                                                onChange={
                                                    this.props
                                                        .handleFiltersChange
                                                }
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "start"
                                                }}
                                            >
                                                <FormControlLabel
                                                    key="all"
                                                    value="all"
                                                    control={<Radio />}
                                                    label="All"
                                                />
                                                <FormControlLabel
                                                    key="valid"
                                                    value="valid"
                                                    control={<Radio />}
                                                    label="Valid"
                                                />
                                                <FormControlLabel
                                                    key="invalid"
                                                    value="invalid"
                                                    control={<Radio />}
                                                    label="Invalid / Inconclusive"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl
                                            className="mr-3"
                                            component="fieldset"
                                        >
                                            <FormLabel
                                                style={{ marginBottom: "0px" }}
                                            >
                                                Import Count
                                            </FormLabel>
                                            <RadioGroup
                                                aria-label="result"
                                                name="import_count"
                                                value={this.props.import_count}
                                                onChange={
                                                    this.props
                                                        .handleFiltersChange
                                                }
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "start"
                                                }}
                                            >
                                                <FormControlLabel
                                                    key="all"
                                                    value="all"
                                                    control={<Radio />}
                                                    label="All"
                                                />
                                                <FormControlLabel
                                                    key="first"
                                                    value="first"
                                                    control={<Radio />}
                                                    label="First"
                                                />
                                                <FormControlLabel
                                                    key="second"
                                                    value="second"
                                                    control={<Radio />}
                                                    label="Second"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col grid-buttons">
                            <div>
                                <TextField
                                    style={{ width: "100px" }}
                                    label="Page Size"
                                    className="mt-3"
                                    variant="outlined"
                                    id="page-size"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type="number"
                                    size="small"
                                    margin="none"
                                    onChange={this.props.onPageSizeChanged}
                                />
                            </div>
                            <div>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="import_result_file"
                                    onChange={this.props.importResults}
                                    accept=".xlsx,.csv,/*"
                                />
                                <button
                                    className="btn btn-info button-info-grid"
                                    onClick={this.props.importFile}
                                >
                                    {this.props.importing && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                        ></i>
                                    )}
                                    {!this.props.importing && (
                                        <i
                                            className="fas fa-file-import"
                                            style={{ marginRight: "5px" }}
                                        ></i>
                                    )}
                                    Import Document
                                </button>
                                <button
                                    className="btn btn-primary button-info-grid"
                                    onClick={() => this.props.resetState()}
                                >
                                    {" "}
                                    <i className="fa fa-repeat"></i> Default
                                </button>
                                <button
                                    className="btn btn-primary button-info-grid"
                                    onClick={() => this.props.onBtnExport()}
                                >
                                    <i
                                        className="fa fa-file-excel-o"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchMenu;
