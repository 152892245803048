import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { ModalStyled } from '../../../../theme/customizedStyleComponents';

class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this._onDetected = this._onDetected.bind(this);
    this._toggle = this._toggle.bind(this);
  }

  handleError = (err) => {
    console.error(err);
  };

  render() {
    return (
      <div>
        <button className="btn btn-info button-info-grid" type="button" onClick={this._toggle}>
          Scan Barcode
        </button>

        <ModalStyled
          show={this.state.modal}
          onHide={this._toggle}
          backdrop="static"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>Scan Code 39 BarCode</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <div className="target-barcode" >
            </div>
            <BarcodeScannerComponent
              onError={this.handleError}
              onUpdate={(err, result) => {
                if (result) {
                  this._onDetected(result.text);
                } else {
                };
              }}
            />
          </Modal.Body>
        </ModalStyled>
      </div>
    );
  }

  _toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  _onDetected(result) {
    const format = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
    if (!format.test(result)) {
      this.setState({
        modal: false
      });
      const resultData = {
        target: {
          name: "sample",
          value: result
        }
      }
      this.props.handleChange(resultData);
    }
  }
}

export default BarcodeScanner;
