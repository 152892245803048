import { apiInterfaceForJson } from "../common/apiManager";

export const fetchPatientDataById = (patientId) => {
	return apiInterfaceForJson("/patient/v1/" + patientId, "GET");
};

export const fetchPatientWithQuery = (data) => {
	return apiInterfaceForJson("/patient/v1/search", "POST", data, window.apiCallController.signal);
};

export const fetchPatientDuplicatedData = (facilityId) => {
	return apiInterfaceForJson("/patient/v1/searchDuplicated", "POST", { facility_id: facilityId });
};

export const dismissPatientDuplicatedData = (params) => {
	return apiInterfaceForJson("/patient/v1/dismissDuplicated", "POST", params);
};

export const fetchPatientExpandableData = (patientId) => {
	return apiInterfaceForJson("/order/v1/search", "POST", { patient_id: patientId, order_type: { $ne: "sub_bundle" } });
};

export const fetchPatientInsuranceData = (patientId) => {
	return apiInterfaceForJson("/patientinsurance/v1/search", "POST", { patient: patientId });
};

export const checkPatientUsername = (username) => {
	return apiInterfaceForJson("/patient/v1/checkUsername", "POST", { username: username });
};
