import { apiInterfaceForJson } from "../common/apiManager";

export const fetchUnassignedPatientDetails = (patientId) => {
	return apiInterfaceForJson("/patient/v1/details", "POST", { patient_id: patientId });
};

export const updateUnassignedPatientDetails = (patientDetails) => {
	let patientInfo = {
		_id: patientDetails.patientId,
		first_name: patientDetails.firstName,
		last_name: patientDetails.lastName,
		email: patientDetails.email,
		mobile: patientDetails.phone,
		date_of_birth: patientDetails.dob,
		gender: patientDetails.sex,
		ethnicity: patientDetails.ethnicity,
		race: patientDetails.race,
		is_assigned: false,
		is_active: false,
		address: {
			address1: patientDetails.address1 ? patientDetails.address1 : "",
			address2: patientDetails.address2 ? patientDetails.address2 : "",
			city: patientDetails.city,
			state: patientDetails.state,
			zip: patientDetails.zip,
		},
		insurance: {
			_id: patientDetails.insuranceId,
			insured_first_name: patientDetails.insuredFirstName,
			insured_last_name: patientDetails.insuredLastName,
			insured_member_id: patientDetails.memberId,
			insured_group_number: patientDetails.groupNumber,
			insurance_provider:
				patientDetails.insuranceProv1.toLowerCase() === "other"
					? patientDetails.insuranceProv2
					: patientDetails.insuranceProv1,
			relation_to_insured: patientDetails.relation,
			is_active: true,
			patient: patientDetails.patientId,
			images: {
				insurance_front: patientDetails.insuranceFrontPageFileName,
				insurance_back: patientDetails.insuranceBackPageFileName,
			},
		},
		driver_license: patientDetails.driverLicense,
		driver_license_image: patientDetails.driverLicFileName
	};
	return apiInterfaceForJson("/patient/v1/insurance", "POST", patientInfo);
};
