import { apiInterfaceForJson } from "../common/apiManager";

export const getAllPlatesByDateRange = (query) => {
    return apiInterfaceForJson("/plate/v1/getAllByDateRange", "POST", query);
};

export const getPlatesForAccessioning = (params) => {
    return apiInterfaceForJson("/plate/v1/getPlatesForAccessioning", "POST", params);
}

export const getPlatesForExport = (query) => {
    return apiInterfaceForJson("/plate/v1/getPlatesForExport", "POST", query);
}

export const getPlateList = () => {
    return apiInterfaceForJson("/plate/v1/getList", "GET");
};

export const getPlateDataById = (plateId) => {
    return apiInterfaceForJson("/plate/v1/" + plateId, "GET");
};

export const createPlate = (plateDetails) => {
    return apiInterfaceForJson("/plate/v1/", "POST", plateDetails);
};

export const updatePlate = (plateDetails) => {
    return apiInterfaceForJson("/plate/v1/", "PUT", plateDetails);
};

export const plateAccession = (params) => {
    return apiInterfaceForJson("/plate/v1/accession", "POST", params);
}

export const generateResultDocument = (params) => {
    return apiInterfaceForJson("/plate/v1/generateResultDocument", "POST", params);
}