import React, { Component } from "react";
import { isAllowedReducedGridView } from "../../../../services/common/util";
import { FormControlLabel, Switch } from "@mui/material";
import { RequisitionGridContext } from ".";

class RequisitionBreadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-12">
                            <nav
                                aria-label="breadcrumb"
                                className="page-breadcrumb"
                            >
                                <ol className="breadcrumb">
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Orders
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Requisitions
                                    </li>
                                </ol>
                            </nav>
                            <div style={{ display: "flex" }}>
                                <h2 className="breadcrumb-title">
                                    Requisitions
                                </h2>
                                <FormControlLabel
                                    value="includeArchived"
                                    control={
                                        <Switch
                                            checked={this.props.isArchived}
                                            onChange={
                                                this.props
                                                    .onIsArchivedInformation
                                            }
                                        />
                                    }
                                    label={
                                        this.props.isArchived
                                            ? "Hide Archives"
                                            : "Show Archives"
                                    }
                                    labelPlacement="end"
                                    className="m-0 breadcrumb-control breadcrumb-control"
                                />
                                <FormControlLabel
                                    value="includeArchived"
                                    control={
                                        <Switch
                                            checked={this.props.isCancelled}
                                            onChange={
                                                this.props
                                                    .onIsCancelledInformation
                                            }
                                        />
                                    }
                                    label="Show Cancelled"
                                    labelPlacement="end"
                                    className="m-0 breadcrumb-control"
                                />
                                {isAllowedReducedGridView("requisition") && (
                                    <RequisitionGridContext.Consumer>
                                        {({
                                            isReducedGridView,
                                            onIsReducedGridView
                                        }) => (
                                            <FormControlLabel
                                                value="isReducedGridView"
                                                control={
                                                    <Switch
                                                        checked={
                                                            isReducedGridView
                                                        }
                                                        onChange={
                                                            onIsReducedGridView
                                                        }
                                                    />
                                                }
                                                label={
                                                    isReducedGridView
                                                        ? "Reduced Grid View"
                                                        : "Full Grid View"
                                                }
                                                labelPlacement="end"
                                                className="m-0 show-reduced-control breadcrumb-control"
                                            />
                                        )}
                                    </RequisitionGridContext.Consumer>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="row breadcrumb-btns">
                                <label className="breadcrumb-text">
                                    Import & DownLoads:
                                </label>
                                <ul className="export-ul">
                                    <li
                                        onClick={() => this.props.onBtnExport()}
                                    >
                                        Export
                                    </li>
                                    {/* {
									this.props.showGrubbsImport
									&&
									<li
										onClick={() => {
											document.getElementById("import_grubbs").click();
										}}
									>Grubbs Import</li>
								}
								{
									/^superadmin/.test(getUserRole().toLowerCase())
									&&
									<li
										onClick={() => {
											document.getElementById("import_requisitions").click();
										}}
									>Nova School Import</li>
								} */}
                                    <li
                                        onClick={() =>
                                            this.props.onBtnExportZipForRequisitions()
                                        }
                                    >
                                        Bulk Requisitions
                                    </li>
                                </ul>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="import_grubbs"
                                    onChange={this.props.importGrubbs}
                                    accept=".xlsx,/*"
                                />
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="import_requisitions"
                                    onChange={this.props.importNovaRequisitions}
                                    accept=".xlsx,/*"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequisitionBreadcrumb;
