import moment from "moment";
import { apiInterfaceForJson } from "../common/apiManager";

export const getUnCheckedOrder = (facilities) => {
	return apiInterfaceForJson("/order/v1/getUnCheckedOrder", "POST", facilities);
}

export const searchOrdersForSelfPayment = (filters) => {
	let from_date = moment(filters.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(filters.to_date).format("YYYYMMDDHHmmss");

	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date,
	};
	if (filters.status_value) {
		filterParams.status_value = filters.status_value;
	}
	if (filters.search_date) {
		filterParams.search_date = filters.search_date;
	}
	if (filters.testIds) {
		filterParams.testIds = filters.testIds;
	}
	return apiInterfaceForJson("/order/v1/filterSelfPayment", "POST", filterParams);
};

export const searchOrdersOrder = (filters) => {
	let from_date = moment(filters.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(filters.to_date).format("YYYYMMDDHHmmss");

	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date,
		resultValues: filters.resultValues && filters.resultValues.length ? filters.resultValues : []
	};
	if (filters.filter_by) {
		filterParams.filterBy = filters.filter_by;
	}
	if (filters.testIds) {
		filterParams.testIds = filters.testIds;
	}
	return apiInterfaceForJson("/order/v1/filterOrder", "POST", filterParams);
};

export const searchOrdersUniversal = (filters) => {
	const filterParams = {
		search_str: filters.searchStr,
	};

	return apiInterfaceForJson("/order/v1/filterUniversal", "POST", filterParams);
};


export const searchOrderUniversalById = (_id) => {
	return apiInterfaceForJson("/order/v1/filterUniversalById", "POST", { _id: _id });
};

export const searchOrdersReports = (filters) => {
	let from_date = moment(filters.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(filters.to_date).format("YYYYMMDDHHmmss");

	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date,
	};
	if (filters.filter_by) {
		filterParams.filterBy = filters.filter_by;
	}
	if (filters.testIds) {
		filterParams.testIds = filters.testIds;
	}
	return apiInterfaceForJson("/order/v1/filterReports", "POST", filterParams);
};

export const searchOrdersDashboard = (filters) => {
	let from_date = moment(filters.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(filters.to_date).format("YYYYMMDDHHmmss");

	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date,
		testIds: filters.testIds
	};
	return apiInterfaceForJson("/order/v1/filterDashboard", "POST", filterParams);
};

export const searchTopFacilities = (filters) => {
	let from_date = moment(filters.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(filters.to_date).format("YYYYMMDDHHmmss");

	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date,
	};
	return apiInterfaceForJson("/order/v1/filterTopFacilities", "POST", filterParams);
};


export const getRequisitions = (filters) => {
	let from_date = moment(filters.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(filters.to_date).format("YYYYMMDDHHmmss");
	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date,
		is_cancel: filters.is_cancel,
		testIds: filters.testIds
	};
	return apiInterfaceForJson("/order/v1/getRequisitions", "POST", filterParams);
};

export const getRequisitionNewSamples = (datefilter) => {
	return apiInterfaceForJson("/order/v1/getRequisitionNewSamples", "POST", datefilter);
};