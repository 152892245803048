import React, { Component } from "react";
import HomeBanner from "./homebanner";
import ReserveNow from "./reservenow";
import FormInfo from "./forminfo";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="main-wrapper">
          <HomeBanner />
          <br />
          <ReserveNow />
          <br />
          <FormInfo />
        </div>
      </div>
    );
  }
}
export default Home;
