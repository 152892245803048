import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import LabDetails from "./labDetails";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { deleteLab } from "../../../../services/clinicPortalServices/labService";

export default class EditBtnCellRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            labId: props.data._id
        };
    }

    handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this lab!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteLab({
                    _id: this.state.labId,
                    name: this.props.data.name
                }).then((res) => {
                    this.props.context.componentParent.loadGridData();
                });
            }
        });
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    renderTooltipEdit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit Lab
        </Tooltip>
    );

    renderTooltipDelete = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Delete Lab
        </Tooltip>
    );

    render() {
        return (
            <div>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipEdit}
                >
                    <button
                        onClick={this.handleShow}
                        className="edit-order-btn"
                    >
                        <i className="fas fa-pen"></i>
                    </button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipDelete}
                >
                    <button
                        onClick={this.handleDelete}
                        className="edit-order-btn"
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </OverlayTrigger>
                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Lab Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LabDetails
                            labId={this.state.labId}
                            context={this.props.context}
                            handleClose={this.handleClose}
                        />
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}
