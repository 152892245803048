import React, { Component } from "react";

class EnvelopeDot extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height={this.props.height} width={this.props.width} viewBox="0 0 576 512">
                <path d="M512 0c-35.35 0-64 28.65-64 64s28.65 64 64 64c35.35 0 64-28.65 64-64S547.3 0 512 0zM48.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l193.7-150.6C457.5 158.2 416 116.1 416 64H80C53.49 64 32 85.49 32 112C32 125 38.01 137.3 48.29 145.3zM288 352c-16.53 0-33.07-5.318-47.17-16.3L32 173.3V400C32 426.5 53.49 448 80 448h416c26.51 0 48-21.49 48-48V173.3l-208.8 162.5C321.1 346.7 304.5 352 288 352z"/>
            </svg>
        )
    }
}

export default EnvelopeDot