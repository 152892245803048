import { apiInterfaceForJson } from "../common/apiManager";

export const sendNotification = (info) => {
    return apiInterfaceForJson("/setting/v1/sendNotification", "POST", info);
};

export const changeSettingMaintenanceState = (info) => {
    return apiInterfaceForJson("/setting/v1/changeSettingMaintenanceState", "POST", info);
};

export const changeOrderResultReleaseNotificationSetting = (info) => {
    return apiInterfaceForJson("/setting/v1/changeOrderResultReleaseNotificationSetting", "POST", info);
}

export const getSettings = () => {
    return apiInterfaceForJson("/setting/v1/getSettings", "GET");
};