import React, { Component } from "react";

class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav
                                aria-label="breadcrumb"
                                className="page-breadcrumb"
                            >
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">LIMS</li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Accessioning Menu
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Accessioning
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Accessioning</h2>
                        </div>
                        <div className="col-md-6">
                            <div className="row d-flex justify-content-end">
                                <h5
                                    style={{
                                        margin: "0px",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        marginRight: "10px"
                                    }}
                                    onClick={
                                        this.props
                                            .generateResultDocumentForPlate
                                    }
                                >
                                    Generate Result Document
                                </h5>
                                <h5
                                    style={{
                                        margin: "0px",
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    }}
                                    onClick={
                                        this.props.showCreateRequisitionPopup
                                    }
                                >
                                    Create Requisition
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Breadcrumb;
