import { apiInterfaceForJson } from "../common/apiManager";

export const generateUniqueKey = () => {
    return apiInterfaceForJson("/order/v1/uniquekey", "POST");
};

export const generateBarcodeImage = (params) => {
    return apiInterfaceForJson(
        "/order/v1/generateBarcodeImage",
        "POST",
        params
    );
};

export const saveRequisitionChanges = (reqInfo) => {
    let updatedRequisition = {
        test_id: reqInfo.testId,
        provider: {
            first_name: reqInfo.providerFirstName,
            last_name: reqInfo.providerLastName,
            npi: reqInfo.providerNPI
        },
        test_info: {
            code: reqInfo.testInfoCode,
            description: reqInfo.testInfoDescription,
            specimenType: reqInfo.specimenType,
            sampleType: reqInfo.sampleType,
            sample: reqInfo.sample,
            collected: reqInfo.collectedDate,
            collector_name: reqInfo.collectorName,
            received: reqInfo.receivedDate,
            requisition: reqInfo.requisition,
            requisition_pdf_path: reqInfo.requistionPDFPath
        },
        patient_id: reqInfo.patientId,
        facility_id: reqInfo.facilityId,
        order_date: reqInfo.orderDate,
        facility_order_id: reqInfo.facilityOrderId,
        facility_source: reqInfo.facilitySource,
        diagnosis_code: reqInfo.diagnosisCode,
        is_HL7_requisition: false
    };

    if (reqInfo.requistionPDFPath) {
        updatedRequisition.requisition_pdf_path = reqInfo.requistionPDFPath;
    }
    if (reqInfo.patientInsuranceId) {
        updatedRequisition.patient_insurance_id = reqInfo.patientInsuranceId;
    }
    if (reqInfo.importNova) {
        updatedRequisition.importNova = reqInfo.importNova;
    }
    if (reqInfo.symptoms && reqInfo.symptoms.length) {
        updatedRequisition.symptoms = reqInfo.symptoms;
    }
    if (reqInfo.orderType) {
        updatedRequisition.order_type = reqInfo.orderType;
    }
    if (reqInfo.orderIds) {
        updatedRequisition.orderIds = reqInfo.orderIds;
    }
    if (reqInfo._id) {
        updatedRequisition._id = reqInfo._id;
    }
    if (reqInfo.bundle_id) {
        updatedRequisition.bundle_id = reqInfo.bundle_id;
    }
    return apiInterfaceForJson(
        "/order/v1/requisition",
        "POST",
        updatedRequisition
    );
};

export const createRequisitionPDF = (params) => {
    let updatedOrder = {
        patient_name: params.patientName,
        address1: params.address1,
        address2: params.address2,
        city: params.city,
        state: params.state,
        zip: params.zip,
        patient_phoneNumber: params.phoneNumber,
        email: params.email,
        date_of_birth: params.dateOfBirth,
        gender: params.gender,
        lab_order_id: params.lab_order_id,
        sample: params.sample,
        physician_name: params.physicianName,
        organization: params.facilityName,
        npi: params.providerNPI,
        facilityAddress1: params.facilityAddress1,
        facilityAddress2: params.facilityAddress2,
        facilityCity: params.facilityCity,
        facilityState: params.facilityState,
        facilityZip: params.facilityZip,
        facilityPhone: params.facilityPhone,
        specimenType: params.specimenType,
        sampleType: params.sampleType,
        collectedDate: params.collectedDate,
        collectedTime: params.collectedTime,
        signature_image: params.signature_image,
        requisition_pdf_path: params.requisition_pdf_path,
        diagnosisCode: params.diagnosisCode,
        symptoms: params.symptoms,
        insuranceProvider: params.insuranceProvider,
        insuranceMemberId: params.insuranceMemberId,
        insuranceGroupNumber: params.insuranceGroupNumber,
        insuranceRelationToInsured: params.insuranceRelationToInsured,
        insuranceHolderName: params.insuranceHolderName,
        insuranceDriverLicense: params.insuranceDriverLicense,
        insuranceDriverLicenseState: params.insuranceDriverLicenseState,
        testId: params.testId,
        mrn: params.mrn
    };
    return apiInterfaceForJson(
        "/order/v1/createRequisitionPDF",
        "POST",
        updatedOrder
    );
};

export const notifyGrubbsUploaded = () => {
    return apiInterfaceForJson("/order/v1/notifyGrubbsUploaded", "POST");
};

export const notifyBrowserGrubbsUploaded = () => {
    const body = {
        title: "Grubbs uploaded",
        text: "Grubbs has now uploaded their spreadsheet for the day.",
        target: "superadmin"
    };
    return apiInterfaceForJson("/notifications/v1/browser", "POST", body);
};

export const sendReportNewSample = (params) => {
    return apiInterfaceForJson("/order/v1/sendReportNewSample", "POST", params);
};

export const revertCancelRequisition = (params) => {
    return apiInterfaceForJson(
        "/order/v1/revertCancelRequisition",
        "POST",
        params
    );
};

export const getOrderOverview = () => {
    return apiInterfaceForJson("/order/v1/getOverview", "POST");
};
