import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CommonLayout from "../layouts/CommonLayout";
import ClinicPortalLoginContainer from "../components/clinicPortal/login";
import ClinicPortalTwoFactorAuth from "../components/clinicPortal/twoFactorAuth";
import ClinicPortalForgetPassword from "../components/clinicPortal/forgetPassword";
import ClinicPortalResetPassword from "../components/clinicPortal/resetPassword";
import ClinicPortalCreatePassword from "../components/clinicPortal/createPassword";
import PatientPortalLoginContainer from "../components/patientPortal/login";
import PatientPortalIssueContainer from "../components/patientPortal/issue";
import PatientPortalDashboard from "../components/patientPortal/dashboard";
import PatientSuccessPayment from "../components/patientPortal/successPayment";
// import Home from "../components/appointmentBooking/home";
import ClinicInvoiceView from "../components/clinicPortal/invoices/ClinicInvoiceView";
import TermsAndConditions from "../components/common/termsAndConditions";
import ClientOnboarding from "../components/clientOnboarding";
import ComingSoon from "../components/clinicPortal/comingSoon/index";
import PatientQrComponent from "../components/common/patientQrComponent";
import PatientOneTimeViewer from "../components/patientPortal/oneTimeViewer";
import VIPReserve from "../components/appointmentBooking/vipReserve";
import VIPReserveSuccessPayment from "../components/appointmentBooking/vipReserve/successPayment";
import InputSampleForVIP from "../components/appointmentBooking/vipReserve/inputSample";

class ClinicPortalRouter extends Component {
    render(props) {
        return <CommonLayout>
            <Switch>
                <Route path="/clinic" exact component={ClinicPortalLoginContainer} />

                <Route path="/clinic_security/:userId" exact component={ClinicPortalTwoFactorAuth} />
                <Route path="/clinic_forgetPassword" exact component={ClinicPortalForgetPassword} />
                <Route path="/clinic_resetPassword/:forgetPasswordKey/:userId" exact component={ClinicPortalResetPassword} />
                <Route path="/clinic_createPassword/:createPasswordKey/:userId" exact component={ClinicPortalCreatePassword} />

                <Route path="/lims" exact component={ClinicPortalLoginContainer} />

                <Route path="/invoiceView/:invoiceId" exact render={({ match }) => (
                    <ClinicInvoiceView invoiceId={match.params.invoiceId} />
                )} />

                <Route path="/patientportal" exact component={PatientPortalLoginContainer} />
                <Route path="/patientportal-issue" exact component={PatientPortalIssueContainer} />
                <Route path="/result-validation" exact component={PatientPortalLoginContainer} />
                <Route path="/patientportal/dashboard" exact component={PatientPortalDashboard} />
                <Route path="/patientportal/successPayment/:orderId" exact component={PatientSuccessPayment} />

                <Route path="/terms-and-conditions" exact component={TermsAndConditions} />

                <Route path="/client_onboard" exact component={ClientOnboarding} />
                <Route path="/comingSoon" exact component={ComingSoon} />


                <Route exact path="/patient/qr/:id" render={({ match }) => (
                    <PatientQrComponent patientId={match.params.id} />
                )} />

                <Route exact path="/patient/result/:id" render={({ match }) => (
                    <PatientOneTimeViewer resultId={match.params.id} />
                )} />

                <Route path="/vipReserve" exact component={VIPReserve} />
                <Route path="/vipReserve/successPayment/:vipPaymentId" exact component={VIPReserveSuccessPayment} />
                <Route path="/vipReserve/inputSample/:vipPaymentId" exact component={InputSampleForVIP} />

                <Route path="(/|/home)" exact component={ClinicPortalLoginContainer} />
                <Redirect to="/" />
            </Switch>
        </CommonLayout>
    }
}

export default ClinicPortalRouter;