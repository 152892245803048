import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import InsuranceDetails from "./insuranceDetails";
import { Checkbox } from "@mui/material";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class MasterBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			bulkChecked: false
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Insurance
		</Tooltip>
	);

	renderTooltipCheckBulk = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Check bulk edit
		</Tooltip>
	);

	handleBulkChecked = () => {
		this.props.context.componentParent.handleBulkChecked(this.props.data._id);
	}

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-address-card"></i>
					</button>
				</OverlayTrigger>
				{
					this.props.context.componentParent.state.isBulkEdit &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipCheckBulk}
					>
						<Checkbox checked={this.props.context.componentParent.state.bulkCheckedIds.includes(this.props.data._id)} onClick={() => this.handleBulkChecked()}></Checkbox>
					</OverlayTrigger>
				}


				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Insurance Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InsuranceDetails
							data={this.props.data}
							nodeId={this.props.node.id}
							context={this.props.context}
							handleClose={this.handleClose}
							type="updateFromInsurance"
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
