import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";

export default class ActionRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            orderId: props.data._id,
            orderSample: props.data.test_info ? props.data.test_info.sample : "",
            patientName: props.data.patient_id ? props.data.patient_id.first_name + " " + props.data.patient_id.last_name : "",
            patientBirthday: props.data.patient_id ? moment(props.data.patient_id.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY") : "",
            orderCollected: props.data.test_info ? moment(props.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "",
            collectorName: props.data.provider ? props.data.provider.first_name + " " + props.data.provider.last_name : "",
            facilityName: props.data.facility_id ? props.data.facility_id.name : "",
            errors: [],
        };
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {(this.props.data && this.props.data.is_vip_order) ? "Assign to VIP Plate Map" : "Assign to Regular Plate Map"}
        </Tooltip>
    );

    assignToPlate = (type) => {
        const orderInfo = {
            orderId: this.state.orderId,
            orderSample: this.state.orderSample,
            patientName: this.state.patientName,
            patientBirthday: this.state.patientBirthday,
            orderCollected: this.state.orderCollected,
            collectorName: this.state.collectorName,
            facilityName: this.state.facilityName
        }
        this.props.context.componentParent.assignToPlate(orderInfo, type);
    }

    render() {
        return (
            <div className="text-center assign-actions">
                {
                    this.props.data.is_vip_order === false
                    &&
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 100 }}
                        overlay={this.renderTooltip}
                    >
                        <button
                            className="btn btn-info"
                            onClick={() => this.assignToPlate('regular')}
                            disabled={this.props.context.componentParent.state.regularPlate ? false : true}
                        >
                            REG
                        </button>
                    </OverlayTrigger>
                }
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltip}
                >
                    <button
                        className="btn btn-primary ml-2"
                        onClick={() => this.assignToPlate('vip')}
                        disabled={this.props.context.componentParent.state.vipPlate ? false : true}
                    >
                        VIP
                    </button>
                </OverlayTrigger>
            </div>
        );
    }
}
