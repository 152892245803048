import { Light } from "./schemes/LightTheme";
import { Dark } from "./schemes/DarkTheme";

export function themeCreator(theme, primaryColor) {
  return themeMap[theme](primaryColor);
}

const themeMap = {
  Dark,
  Light,
};
