import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import EnvelopeDot from "../../../Icons/envelopeDot";
import EnvelopeOpen from "../../../Icons/envelopeOpen"
import { submitCommunication } from "../../../../services/clinicPortalServices/communicationService"
import { getUserID } from "../../../../services/common/util";
import toastr from 'toastr';
import ReplyModal from "./replyModal";

export default class ReplyBtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showReplyBox: false,
            comment: {
                replyToId: '',
                subject: this.props.data.subject,
                message: '',
            },
            isread: false,
        };
    }

    checkRead = () => {
        let isReadable = false;
        if (this.props.data) {
            if (this.props.data.fromUserId && this.props.data.fromUserId._id === getUserID()) {
                isReadable = false;
            } else if (this.props.data.isRead.includes(getUserID())) {
                isReadable = false;
            } else {
                isReadable = true;
            }
        }
        return isReadable;
    }

    openReplyBox = () => {
        this.setState({ showReplyBox: true });
    }

    hideReplyBox = () => {
        this.setState({ showReplyBox: false });
    }

    onCommunicateTextChange = (event) => {
        let comment = this.state.comment;
        if (event.target.name === "subject") {
            comment.subject = event.target.value;
        }
        if (event.target.name === "message") {
            comment.message = event.target.value;
        }
        this.setState({ comment: comment });
    }

    handleReplyCommunication = () => {
        let comment = this.state.comment;
        if (comment.subject !== '' && comment.message !== '') {
            comment.replyToId = this.props.data._id;
            submitCommunication(comment).then(res => {
                comment = {
                    replyToId: '',
                    subject: this.props.data.subject,
                    message: '',
                }
                this.setState({ showReplyBox: false, comment: comment });
                toastr.success('Communication is replied successfully')
                let rowdata = this.props.data;
                rowdata.replyCount = rowdata.replyCount + 1;
                this.props.node.setData(rowdata);
                this.props.api.redrawRows({ rowNodes: [this.props.node.detailNode] })
            })
        } else {
            toastr.error('Please fill all fields')
        }
    }

    renderTooltipReply = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Reply to Communication
        </Tooltip>
    );
    renderTooltipView = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Open Communication
        </Tooltip>
    );
    render() {

        return (
            <div>
                {
                    this.props.data.fromUserId && getUserID() !== this.props.data.fromUserId._id
                    &&
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 100 }}
                        overlay={this.renderTooltipReply}
                    >
                        <button onClick={this.openReplyBox} className="edit-order-btn">
                            <i className="fas fa-reply"></i>
                        </button>
                    </OverlayTrigger>
                }
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipView}
                >
                    <Link to={"/clinic/communication/" + this.props.data._id}>

                        <button className="edit-order-btn">
                            {
                                this.checkRead()
                                &&
                                <EnvelopeDot width={25} height={25} />
                            }
                            {
                                !this.checkRead()
                                &&
                                <EnvelopeOpen width={25} height={25} />
                            }
                        </button>
                    </Link>
                </OverlayTrigger>
                <ReplyModal
                    showReplyBox={this.state.showReplyBox}
                    comment={this.state.comment}
                    hideReplyBox={this.hideReplyBox}
                    handleReplyCommunication={this.handleReplyCommunication}
                    onCommunicateTextChange={(event) => { this.onCommunicateTextChange(event) }}
                />
            </div>
        )
    }
}
