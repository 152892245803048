import React, { Component } from "react";

class Bell extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height={this.props.height} width={this.props.width} viewBox="0 0 448 512">
                <path d="M223.1 512C259.4 512 288 483.4 288 448H160C160 483.4 188.6 512 223.1 512zM439.4 362.3c-19.25-20.75-55.5-52-55.5-154.3c0-77.75-54.38-139.9-127.9-155.1V32c0-17.62-14.38-32-32-32S192 14.38 192 32v20.88C118.5 68.13 64.13 130.3 64.13 208c0 102.3-36.25 133.5-55.5 154.3C2.625 368.8 0 376.5 0 384c.125 16.38 13 32 32.13 32h383.8c19.12 0 32-15.62 32.13-32C448 376.5 445.4 368.8 439.4 362.3z"/>
            </svg>
        )
    }
}

export default Bell;