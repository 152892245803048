import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { IconButton, Button } from "@mui/material";
import { ModalStyled } from "../../../theme/customizedStyleComponents";
import store from "../../../redux/store/index.js";
import actions from "../../../redux/actions/index.js";
import { bindActions } from "redux-zero/utils/";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { Connect } from "redux-zero/react";
import { updateUserBackgroundThemeColor } from "../../../services/clinicPortalServices/userService.js";

const boundActions = bindActions(actions, store);

class ColorPickerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      colors: [
        // "Slate",
        "Gray",
        "Zinc",
        "Neutral",
        "Stone",
        "Red",
        "Orange",
        "Amber",
        "Yellow",
        "Lime",
        "Green",
        "Emerald",
        "Teal",
        "Cyan",
        "Sky",
        "Blue",
        "Indigo",
        "Violet",
        "Purple",
        "Fuchsia",
        "Pink",
        "Rose",
      ],
    };
  }

  handleShow() {
    this.setState({
      showPopup: true,
    });
  }

  hidePopup() {
    this.setState({ showPopup: false });
  }

  setColor(color) {
    boundActions.setBackgroundThemeColor(color.toLowerCase());
    document.getElementsByTagName("body")[0].className = color.toLowerCase();
    const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS"));
    updateUserBackgroundThemeColor({
      _id: userDetails._id,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      user_name: userDetails.user_name,
      backgroundThemeColor: color.toLowerCase(),
    }).then((res) => {
      if (res && res.data && res.data.RESULT === "SUCCESS") {
        window.localStorage.setItem(
          "USER_DETAILS",
          JSON.stringify(res.data.user)
        );
      }
    });
  }

  render() {
    return (
      <Connect
        mapToProps={({ backgroundThemeColor }) => ({
          backgroundThemeColor,
        })}
      >
        {({ backgroundThemeColor }) => {
          return (
            <>
              <div>
                <IconButton
                  onClick={() => {
                    this.handleShow();
                  }}
                  sx={{
                    mr: 0,
                    height: "fit-content",
                  }}
                >
                  <ColorizeIcon
                    sx={{
                      color: "#272b41",
                    }}
                  />
                </IconButton>
              </div>
              <ModalStyled
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showPopup}
                onHide={() => this.hidePopup()}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Select a color</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    {this.state.colors.map((color) => {
                      return (
                        <div
                          className="col-4 d-flex align-items-center justify-content-center"
                          key={color}
                        >
                          <div
                            className={
                              "d-flex flex-column align-items-center justify-content-center p-2 px-4 m-1 cursorPointer " +
                              color.toLowerCase()
                            }
                            onClick={() => this.setColor(color)}
                            style={{
                              borderStyle: "solid",
                              borderWidth: "2px",
                              borderRadius: "10px",
                              borderColor:
                                backgroundThemeColor === color.toLowerCase()
                                  ? "var(--primary-background-active-color)"
                                  : "transparent",
                            }}
                          >
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                background:
                                  "var(--primary-background-active-color)",
                                borderRadius: "10px",
                              }}
                            ></div>
                            <div className="mt-1">{color}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.hidePopup()}>
                    Close
                  </Button>
                </Modal.Footer>
              </ModalStyled>
            </>
          );
        }}
      </Connect>
    );
  }
}

export default ColorPickerPopup;
