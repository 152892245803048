import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import {
    checkPatient,
    authenticateAndFetchUserDetails
} from "../../../services/patientPortalServices/loginService";
import {
    getPatientAuthToken,
    isValidEmail,
    setCookie
} from "../../../services/common/util";
import { fetchDashboardDetails } from "../../../services/patientPortalServices/dashboardService";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import moment from "moment";
import Swal from "sweetalert2";
import { createAudit } from "../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../services/common/constants";
import { ModalStyled } from "../../../theme/customizedStyleComponents";
import ResetPasswordModal from "../resetPasswordModal";
import { checkPatientUsername } from "../../../services/clinicPortalServices/patientSearchService";
import { BLUELOT_LOGO } from "../../common/imgLogoes";

class PatientPortalLoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPatientChooseModal: false,
            showPasswordSetModal: false,
            username: "",
            password: "",
            tab: "new",
            patientsData: [],
            setPatientId: "",
            usedPatient: null,
            dob: null,
            contactInfo: "",
            id: "",
            isAuthenticationfailed: "UNKNOWN",
            loading: false,
            showPasswordCharacters: false
        };
        if (getPatientAuthToken()) {
            this.props.history.push("/patientportal/dashboard");
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDateChange = (date) => {
        this.setState({ dob: date });
    };

    handleCheck = (e) => {
        e.preventDefault();
        let loginInfo = {};
        this.setState({ loading: true });
        if (isValidEmail(this.state.contactInfo)) {
            loginInfo = {
                email: this.state.contactInfo,
                date_of_birth: moment(this.state.dob).format("YYYY-MM-DD")
            };
        } else {
            loginInfo = {
                mobile: this.state.contactInfo,
                date_of_birth: moment(this.state.dob).format("YYYY-MM-DD")
            };
        }
        checkPatient(loginInfo).then((res) => {
            this.setState({
                isAuthenticationfailed: "NO",
                loading: false,
                showPasswordCharacters: false
            });

            if (res.data && res.data.patients.length > 0) {
                let patientsData = res.data.patients;
                let patientIds = [];
                patientsData.map((patient) => {
                    patient.fullName =
                        patient.first_name + " " + patient.last_name;
                    patient.ageDetails =
                        moment(patient.date_of_birth, "YYYY/MM/DD").format(
                            "Do MMM YYYY"
                        ) +
                        ", " +
                        moment().diff(
                            moment(patient.date_of_birth, "YYYY/MM/DD"),
                            "years"
                        ) +
                        " years";
                    patient.results = [];
                    patientIds.push(patient._id);
                    return null;
                });
                if (res.data.patients.length > 1) {
                    const patientInfo = { patient_id: { $in: patientIds } };
                    fetchDashboardDetails(patientInfo).then((data) => {
                        if (data.data != null && data.data.length > 0) {
                            data.data.map((item) => {
                                patientsData.map((patient) => {
                                    if (patient._id === item.patient_id._id) {
                                        if (item.createdAt) {
                                            item.dateTime = moment
                                                .tz(
                                                    item.createdAt,
                                                    "America/New_York"
                                                )
                                                .format("YYYYMMDDHHmmss");
                                        } else {
                                            item.dateTime = moment(
                                                item.test_info.collected,
                                                "YYYYMMDDHHmmss"
                                            ).format("YYYYMMDDHHmmss");
                                        }
                                        if (
                                            item.results &&
                                            item.results.pdf_path
                                        ) {
                                            patient.results.push(item);
                                        } else if (
                                            item.sample_tracking &&
                                            item.sample_tracking.length
                                        ) {
                                            patient.results.push(item);
                                        }
                                    }
                                    return null;
                                });
                                return null;
                            });
                            patientsData.map((patient) => {
                                patient.results.sort(
                                    (a, b) => b.dateTime - a.dateTime
                                );
                                if (patient.results[0]) {
                                    let status = "Created";
                                    if (
                                        patient.results[0].results &&
                                        (patient.results[0].results.value ||
                                            (patient.results[0].results
                                                .values &&
                                                patient.results[0].results
                                                    .values.length > 0))
                                    ) {
                                        status = "Completed";
                                    } else if (
                                        patient.results[0].sample_tracking &&
                                        patient.results[0].sample_tracking
                                            .length
                                    ) {
                                        if (
                                            patient.results[0].sample_tracking.filter(
                                                (item) =>
                                                    item.eventName.includes(
                                                        "IMPORT"
                                                    )
                                            ).length
                                        ) {
                                            status = "Imported";
                                        } else if (
                                            patient.results[0].sample_tracking.filter(
                                                (item) =>
                                                    item.eventName ===
                                                    "ACCESSIONED"
                                            ).length
                                        ) {
                                            status = "Accessioned";
                                        }
                                    }
                                    patient.dropdownText =
                                        patient.first_name +
                                        " " +
                                        patient.last_name +
                                        " ( Sample ID: " +
                                        patient.results[0].test_info.sample +
                                        ", Order Date: " +
                                        moment(
                                            patient.results[0].dateTime,
                                            "YYYYMMDDHHmmss"
                                        ).format("MMM DD YYYY") +
                                        ", Status: " +
                                        status +
                                        ")";
                                    patient.lastTestDate =
                                        patient.results[0].dateTime;
                                } else {
                                    patient.dropdownText =
                                        patient.first_name +
                                        " " +
                                        patient.last_name +
                                        " ( No Result Available )";
                                    patient.lastTestDate = "";
                                }
                                return null;
                            });
                            patientsData.sort(
                                (a, b) => b.lastTestDate - a.lastTestDate
                            );
                        }
                        this.setState({
                            patientsData: patientsData,
                            usedPatient: patientsData[0],
                            setPatientId: patientsData[0]._id,
                            showPatientChooseModal: true
                        });
                    });
                } else {
                    const patient = patientsData[0];
                    this.setState({
                        patientsData: patientsData,
                        usedPatient: patient,
                        setPatientId: patient._id
                    });
                    this.checkPasswordIsSet(patient);
                }
            } else {
                Swal.fire({
                    customClass: {
                        container:
                            window.localStorage.getItem("appTheme") ===
                                "Dark" &&
                            /clinic|lims/.test(
                                window.location.pathname.split("/")[1]
                            ) &&
                            "dark-swal"
                    },
                    title: "Having Trouble Logging In?",
                    text: `Seems our record is different from yours.\n
						You can use either your email or mobile number.\n
						Please try again with a different method or click below.`,
                    icon: "warning",
                    showCloseButton: true,
                    confirmButtonText: "Get Support To Access Your Results"
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push("/patientportal-issue");
                    }
                });
                this.setState({
                    isAuthenticationfailed: "YES",
                    loading: false
                });
            }
        });
    };

    handleChoosePatient = (e) => {
        this.setState({
            setPatientId: e.target.value
        });
    };

    handleSetPatient = () => {
        const patient = this.state.patientsData.find((patientItem) => {
            return patientItem._id === this.state.setPatientId;
        });
        this.setState({
            usedPatient: patient,
            showPatientChooseModal: false
        });
        this.checkPasswordIsSet(patient);
    };

    checkPasswordIsSet = (patient) => {
        if (patient.password && patient.username) {
            Swal.fire({
                customClass: {
                    container:
                        window.localStorage.getItem("appTheme") === "Dark" &&
                        /clinic|lims/.test(
                            window.location.pathname.split("/")[1]
                        ) &&
                        "dark-swal"
                },
                title: 'Existing User as "' + patient.username + '"',
                text:
                    `You already set user as "` +
                    patient.username +
                    `" with your account, please choose the existing user tab to login with your username and password.`,
                icon: "warning",
                showCloseButton: true
            });
            this.setState({ tab: "existing", username: patient.username });
        } else {
            this.setState({
                showPasswordSetModal: true,
                resetPasswordModalType: "NEW_PASSWORD"
            });
        }
    };

    handleLogin = (type, info = {}) => {
        let loginInfo = {};
        if (type === "SetPassword") {
            loginInfo.patient_id = this.state.usedPatient._id;
            loginInfo.type = type;
            loginInfo.code = info.verificationCode;
            loginInfo.password = info.setPassword;
            loginInfo.username = info.username;
        } else {
            type.preventDefault();
            loginInfo.password = this.state.password;
            loginInfo.username = this.state.username;
        }
        authenticateAndFetchUserDetails(loginInfo).then((res) => {
            if (res && res.data) {
                if (res.data === "WRONG_PASS") {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        title: "Wrong password",
                        text: `Password you input is not matching, please try again. If you forgot, you can reset password by clicking forgot password at the bottom of password input field`,
                        icon: "warning",
                        showCloseButton: true
                    });
                } else if (res.data === "NO_MATCHING") {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        title: "No matching user",
                        text: `We couldn't find user with the username you provided, please provide correctly, or if you have forgot username, try as a new user to get your username if you have.`,
                        icon: "warning",
                        showCloseButton: true
                    });
                } else {
                    const patient = res.data.patient;
                    if (type === "SetPassword") {
                        const auditData = {
                            identifier: auditEnums.IDENTIFIERS.PatientRecord,
                            event_type:
                                auditEnums.EVENTTYPES
                                    .PatientSetUsernamePassword,
                            user_id: patient._id,
                            user_name:
                                patient.first_name +
                                " " +
                                patient.last_name +
                                " (Patient, username: " +
                                patient.username +
                                ")",
                            record_id: patient._id,
                            update_string:
                                patient.first_name +
                                " " +
                                patient.last_name +
                                " (" +
                                patient.mrn +
                                ") " +
                                (this.state.resetPasswordModalType ===
                                "FORGOT_PASSWORD"
                                    ? "reset password by using forgot password option"
                                    : "set username and password")
                        };
                        createAudit(auditData);
                    }
                    window.localStorage.setItem(
                        "PATIENT-AUTH-TOKEN",
                        res.data.token
                    );
                    window.localStorage.setItem(
                        "MATCHED_PATIENTS_DATA",
                        JSON.stringify(patient)
                    );
                    setCookie();
                    const auditData = {
                        identifier: auditEnums.IDENTIFIERS.PatientRecord,
                        event_type: auditEnums.EVENTTYPES.PatientLogIn,
                        user_id: patient._id,
                        user_name:
                            patient.first_name +
                            " " +
                            patient.last_name +
                            " (Patient, username: " +
                            patient.username +
                            ")",
                        record_id: patient._id,
                        update_string:
                            patient.first_name +
                            " " +
                            patient.last_name +
                            " (" +
                            patient.mrn +
                            ") logged in to the patient portal"
                    };
                    createAudit(auditData).then((res) => {
                        this.props.history.push("/patientportal/dashboard");
                    });
                }
            }
        });
    };

    forgotPassword = () => {
        if (this.state.username) {
            checkPatientUsername(this.state.username).then((res) => {
                if (res && res.data) {
                    this.setState({
                        usedPatient: res.data,
                        showPasswordSetModal: true,
                        resetPasswordModalType: "FORGOT_PASSWORD"
                    });
                } else {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        title: "Please provide correct username",
                        text: "Please provide correct username, if you forgot your username, try as new user, then you can get your correct username if you have.",
                        icon: "warning",
                        showCloseButton: true
                    });
                }
            });
        } else {
            Swal.fire({
                customClass: {
                    container:
                        window.localStorage.getItem("appTheme") === "Dark" &&
                        /clinic|lims/.test(
                            window.location.pathname.split("/")[1]
                        ) &&
                        "dark-swal"
                },
                title: "Please provide username",
                icon: "warning",
                showCloseButton: true
            });
        }
    };

    render() {
        return (
            <div className="content login-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content patient-signup">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-12 col-lg-6 my-4 d-flex justify-content-center">
                                        <img src={BLUELOT_LOGO} alt="login" />
                                    </div>
                                    <div className="col-md-12 col-lg-6 login-right tabs">
                                        <Tabs
                                            activeKey={this.state.tab}
                                            onSelect={(k) =>
                                                this.setState({ tab: k })
                                            }
                                        >
                                            <Tab
                                                eventKey="new"
                                                title="New User"
                                            >
                                                <form
                                                    onSubmit={this.handleCheck}
                                                >
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Email or Mobile #{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="contactInfo"
                                                            value={
                                                                this.state
                                                                    .contactInfo
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control"
                                                            required
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "13px"
                                                            }}
                                                        >
                                                            Email:
                                                            username@example.com{" "}
                                                            <br /> Mobile #:
                                                            2223334444
                                                        </span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Patient Date Of
                                                            Birth{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <MobileDatePicker
                                                            value={
                                                                this.state.dob
                                                            }
                                                            onChange={(date) =>
                                                                this.handleDateChange(
                                                                    date.$d
                                                                )
                                                            }
                                                            maxDate={new Date()}
                                                            renderInput={(
                                                                props
                                                            ) => (
                                                                <TextField
                                                                    {...props}
                                                                    InputProps={{
                                                                        endAdornment:
                                                                            (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end">
                                                                                        <EventIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                    }}
                                                                />
                                                            )}
                                                            required
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "13px"
                                                            }}
                                                        >
                                                            Date Of Birth:
                                                            MM/DD/YYYY
                                                            (05/25/1984)
                                                        </span>
                                                    </div>

                                                    <button
                                                        className="btn btn-primary btn-block btn-lg login-btn"
                                                        type="submit"
                                                        disabled={
                                                            this.state.loading
                                                        }
                                                    >
                                                        {this.state.loading && (
                                                            <i
                                                                className="fa fa-refresh fa-spin"
                                                                style={{
                                                                    marginRight:
                                                                        "5px"
                                                                }}
                                                            ></i>
                                                        )}
                                                        {this.state.loading && (
                                                            <span>
                                                                Checking...
                                                                Please wait
                                                            </span>
                                                        )}
                                                        {!this.state
                                                            .loading && (
                                                            <span>Next</span>
                                                        )}
                                                    </button>
                                                </form>
                                            </Tab>
                                            <Tab
                                                eventKey="existing"
                                                title="Existing User"
                                            >
                                                <form
                                                    onSubmit={this.handleLogin}
                                                    className="row"
                                                >
                                                    <div className="col-12 form-group">
                                                        <label className="font-weight-bold">
                                                            Username{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            value={
                                                                this.state
                                                                    .username
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-12 form-group mb-2">
                                                        <label className="font-weight-bold">
                                                            Password{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type={
                                                                this.state
                                                                    .showPasswordCharacters
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            name="password"
                                                            required
                                                            value={
                                                                this.state
                                                                    .password
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        {!this.state
                                                            .showPasswordCharacters && (
                                                            <div
                                                                className="password-eye-icon cursorPointer"
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            showPasswordCharacters: true
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-eye"></i>
                                                            </div>
                                                        )}
                                                        {this.state
                                                            .showPasswordCharacters && (
                                                            <div
                                                                className="password-eye-icon cursorPointer"
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            showPasswordCharacters: false
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-eye-slash"></i>
                                                            </div>
                                                        )}
                                                        <div className="mt-3 d-flex justify-content-end">
                                                            <label
                                                                className="control-label cursorPointer"
                                                                onClick={
                                                                    this
                                                                        .forgotPassword
                                                                }
                                                            >
                                                                Forgot Password?
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <button
                                                            className="btn btn-primary btn-block btn-lg login-btn"
                                                            type="submit"
                                                            disabled={
                                                                this.state
                                                                    .loading
                                                            }
                                                        >
                                                            {this.state
                                                                .loading && (
                                                                <i
                                                                    className="fa fa-refresh fa-spin"
                                                                    style={{
                                                                        marginRight:
                                                                            "5px"
                                                                    }}
                                                                ></i>
                                                            )}
                                                            {this.state
                                                                .loading && (
                                                                <span>
                                                                    Checking...
                                                                    Please wait
                                                                </span>
                                                            )}
                                                            {!this.state
                                                                .loading && (
                                                                <span>
                                                                    Login
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </form>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showPatientChooseModal}
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title>Choose One Record Below</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row" style={{ padding: "10px" }}>
                            <div
                                className="col-12"
                                style={{ marginBottom: "15px" }}
                            >
                                Verify with the dropdown which record you are
                                wanting to view. The test date is in the
                                dropdown so you can easily choose the most
                                recent.
                            </div>
                            <div className="col-12">
                                <select
                                    required
                                    type="text"
                                    name="patientUser"
                                    value={this.state.setPatientId}
                                    onChange={this.handleChoosePatient}
                                    className="form-control"
                                >
                                    {this.state.patientsData &&
                                        this.state.patientsData.map(
                                            (patientItem) => {
                                                return (
                                                    <option
                                                        key={patientItem._id}
                                                        value={patientItem._id}
                                                    >
                                                        {
                                                            patientItem.dropdownText
                                                        }
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() =>
                                this.setState({ showPatientChooseModal: false })
                            }
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleSetPatient}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
                <ResetPasswordModal
                    handleSubmit={this.handleLogin}
                    show={this.state.showPasswordSetModal}
                    handleClose={() =>
                        this.setState({ showPasswordSetModal: false })
                    }
                    usedPatient={this.state.usedPatient}
                    type={this.state.resetPasswordModalType}
                />
            </div>
        );
    }
}

export default PatientPortalLoginContainer;
