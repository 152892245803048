import React, { Component, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import 'moment-timezone';
import { getPlatesForExport, updatePlate } from "../../../services/limsPortalServices/plateService";
import { getRowColumnIndexForQuantPlate, getPlateCellCount } from "../../../services/common/util";
import { TextField, MenuItem, Checkbox, IconButton, InputAdornment } from "@mui/material";
import Breadcrumb from "./breadcrumb";
import QuantPlateMap from "../accessioning/quantPlateMap";
import Barcode from "react-barcode";
import Swal from "sweetalert2";
import { quantPlateColumns, quantPlateRows, targetNameArray, reporterArray } from "../../../services/common/optionsData";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import EventIcon from "@mui/icons-material/Event";
import { ThemeContext } from "../../../theme/ThemeProvider";
import store from "../../../redux/store";
import actions from "../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

class LIMSExportPlateGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			gridName: "Default",
			pageSize: "",
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 150,
					field: "batch_id",
					resizable: true
				},
				{
					headerName: "Position",
					minWidth: 150,
					resizable: true,
					valueGetter: params => {
						const { row, column } = getRowColumnIndexForQuantPlate(params.data.index);
						return quantPlateRows[row] + quantPlateColumns[column]
					}
				},
				{
					headerName: "BarCode",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						let barcodeValue;
						if (params.data.is_control_spot) {
							barcodeValue = params.data.control_spot_type + params.data.dateTime;
						} else if (params.data && params.data.orderId && params.data.orderId.test_info) {
							barcodeValue = params.data.orderId.test_info.sample;
						}
						return <div className="text-center">
							<Barcode
								value={barcodeValue}
								format="CODE39"
								width={1}
								height={25}
								marginTop={0}
							/>
						</div>
					}),
					valueGetter: params => {
						if (params.data.is_control_spot) {
							return params.data.control_spot_type + params.data.dateTime;
						} else if (params.data && params.data.orderId && params.data.orderId.test_info) {
							return params.data.orderId.test_info.sample;
						}
					}
				},
				{
					headerName: "Requisition",
					minWidth: 150,
					field: "orderId.lab_order_id",
					resizable: true
				},
				{
					headerName: "Specimen Type",
					minWidth: 150,
					resizable: true,
					valueGetter: params => {
						if (params.data.is_control_spot) {
							return params.data.control_spot_type;
						} else if (params.data && params.data.orderId && params.data.orderId.test_info) {
							return params.data.orderId.test_info.specimenType + ", " + params.data.orderId.test_info.sampleType;
						}
					}
				},
				{
					headerName: "Volume",
					minWidth: 150,
					resizable: true
				},
				{
					headerName: "Unit",
					minWidth: 150,
					resizable: true
				},
			],
			components: {
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			allPlates: [],
			plateType: null,
			plate: null,
			plateFilter: null,
			showSidebar: true,
			from_date: moment().subtract(5, "days").format("YYYY-MM-DD") + 'T00:00',
			to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
			pickerOpened: false,
			temp_from_date: moment().subtract(5, "days").format("YYYY-MM-DD") + 'T00:00',
			temp_to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
		};
	}

	componentDidMount() {
		this.getPlates();
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	getPlates = () => {
		boundActions.startBlockUILoading();
		const query = {
			'createdAt': {
				$gte: new Date(this.state.from_date).toISOString(),
				$lte: new Date(this.state.to_date).toISOString()
			}
		}
		getPlatesForExport(query).then(res => {
			boundActions.endBlockUILoading();
			if (res.data && res.data.length) {
				res.data.sort((a, b) => moment(b.createdAt).format("YYYYMMDDHHmmss") - moment(a.createdAt).format("YYYYMMDDHHmmss"));
				res.data.map(item => {
					if (item.is_imported) {
						item.status = "Imported At " + moment(item.importedTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
					} else if (item.is_rna_extractioned) {
						item.status = "RNA Extraction " + moment(item.rnaExtractionTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
					} else if (item.is_exported) {
						item.status = "Exported At " + moment(item.exportedTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
					} else {
						let cellsArray = this.convertArray(item.cells);
						cellsArray.sort((a, b) => b.dateTime - a.dateTime);
						item.status = "Last Accessioned " + moment(cellsArray[0].dateTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
					}
					return null;
				})
				this.setState({
					allPlates: res.data,
					plate: res.data[0],
					plateType: res.data[0].type,
					rowData: this.convertArray(res.data[0].cells)
				});
			} else {
				this.setState({ allPlates: [] });
			}
		})
	}

	selectPlate = (plateData) => {
		this.setState({
			plate: plateData,
			plateType: plateData.type,
			rowData: this.convertArray(plateData.cells)
		});
	}

	convertArray = (cells) => {
		let cellsArray = [];
		for (let [key, value] of Object.entries(cells)) {
			if (value) {
				value.index = key;
				const { row, column } = getRowColumnIndexForQuantPlate(key);
				value.wellIndex = (row - 1) * (quantPlateColumns.length - 1) + column;
				cellsArray.push(value);
			}
		}
		return cellsArray;
	}

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	onPlateFilterChange = (e) => {
		this.setState({ plateFilter: e.target.value });
	}

	onBtnExport = () => {
		if (!this.state.plate.is_exported) {
			this.export();
		} else {
			Swal.fire({
				title: "Already Resulted",
				text: "The plate you are exported was already resulted, there should be no reason for you to export it again.",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "Download Anyway",
				cancelButtonText: "Cancel",
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
					cancelButton: 'order-1',
					confirmButton: 'order-2'
				}
			}).then(result => {
				if (result.isConfirmed) {
					this.export();
				}
			})
		}
	};

	export = () => {
		let cellsArray = this.convertArray(this.state.plate.cells);
		let orderIdsArray = [];
		let orderCellsArray = cellsArray.filter(item => item.orderId && item.orderId._id);
		let duplicatedOrderIdsArray = []
		cellsArray.map(cellItem => {
			if (cellItem.orderId && cellItem.orderId._id && !orderIdsArray.includes(cellItem.orderId._id)) {
				orderIdsArray.push(cellItem.orderId._id);
			} else if (cellItem.orderId && cellItem.orderId._id && !duplicatedOrderIdsArray.includes(cellItem.orderId._id)) {
				duplicatedOrderIdsArray.push(cellItem.orderId._id)
			}
			return null;
		})
		if (orderCellsArray.length === orderIdsArray.length) {
			let text = "[Sample Setup]\nWell\tWell Position\tSample Name\tTarget Name\tTask\tReporter\tQuencher\n";
			cellsArray.sort((a, b) => a.wellIndex - b.wellIndex);
			cellsArray.map(cell => {
				const { row, column } = getRowColumnIndexForQuantPlate(cell.index);
				let wellPosition = quantPlateRows[row] + quantPlateColumns[column];
				let sampleName = cell.is_control_spot ? cell.control_spot_type.toUpperCase() : cell.orderId.test_info.sample;
				let task = cell.is_control_spot ? (['NC', 'NTC'].includes(cell.control_spot_type) ? 'NTC' : 'STANDARD') : 'UNKNOWN';
				targetNameArray.map((targetName, index) => {
					text += cell.wellIndex + "\t" + wellPosition + "\t" + sampleName + "\t" + targetName + "\t" + task + "\t" + reporterArray[index] + "\tNone\n";
					return null;
				})
				return null;
			})
			this.downloadTextFile(this.state.plate.name + ".txt", text);
			const updateData = {
				_id: this.state.plate._id,
				is_exported: true,
				export_action: true,
				exportedTime: moment().format("YYYYMMDDHHmmss")
			}
			updatePlate(updateData).then(res => {
			})
		} else {
			let duplicatedCellIndexTexts = [];
			duplicatedOrderIdsArray.map(duplicatedId => {
				let wellPositions = [];
				let sampleId = "";
				orderCellsArray.map(cell => {
					if (cell.orderId._id === duplicatedId) {
						const { row, column } = getRowColumnIndexForQuantPlate(cell.index);
						let wellPosition = quantPlateRows[row] + quantPlateColumns[column];
						wellPositions.push(wellPosition);
						sampleId = cell.orderId.test_info.sample;
					}
					return null;
				})
				duplicatedCellIndexTexts.push(wellPositions.join("/") + ": " + sampleId);
				return null;
			})

			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: "Duplicates Found",
				html: "Please address the samples below on the accessioning page and remove the duplicated samples, so only one remains in the wells mentioned below:<br/>" + duplicatedCellIndexTexts.join("<br/>"),
				icon: "warning",
				confirmButtonText: "Ok"
			})
		}
	}

	onPageSizeChanged = () => {
		let value = document.getElementById("page-size").value;
		this.gridApi.paginationSetPageSize(Number(value));
	};

	resetState = () => {
		this.gridColumnApi.resetColumnState();
	};

	downloadTextFile = (filename, text, type = "text/plain") => {
		// Create an invisible A element
		const a = document.createElement("a");
		a.style.display = "none";
		document.body.appendChild(a);

		// Set the HREF to a Blob representation of the data to be downloaded
		a.href = window.URL.createObjectURL(
			new Blob([text], { type })
		);

		// Use download attribute to set set desired file name
		a.setAttribute("download", filename);

		// Trigger the download by simulating click
		a.click();

		// Cleanup
		window.URL.revokeObjectURL(a.href);
		document.body.removeChild(a);
	}

	toggleSidebar = () => {
		this.setState({ showSidebar: !this.state.showSidebar })
	}

	handleDateFiltersChange = (time, type) => {
		this.setState({ [type]: time }, () => {
			this.getPlates();
		});
	}

	render() {
		let plates = this.state.allPlates;
		if (this.state.plateFilter) {
			plates = plates.filter(item => item.name.toLowerCase().includes(this.state.plateFilter.toLowerCase()));
		}
		let browserWidth = document.body.offsetWidth;
		return (
			<div>
				<Breadcrumb />
				<div className="lims-container" style={{ padding: "15px" }}>
					<div className="d-flex" style={{ minHeight: "100vh" }}>
						{
							this.state.showSidebar
							&&
							<div className="card mb-0" style={{ maxHeight: "110vh", width: "350px", marginRight: "15px", zIndex: "1" }}>
								<div className="d-flex justify-content-center align-items-center sidebar-icon" style={{ right: "-15px", top: "25px" }} onClick={this.toggleSidebar}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="card-header">
									<ThemeContext.Consumer>
										{({ themeName }) => (
											<>
												<div>
													<MobileDateTimePicker
														value={this.state.pickerOpened ? this.state.temp_from_date : this.state.from_date}
														onChange={(dateTime) => this.setState({ temp_from_date: dateTime })}
														onOpen={() => this.setState({ temp_from_date: this.state.from_date, pickerOpened: true })}
														onClose={() => this.setState({ pickerOpened: false })}
														onAccept={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'from_date')}
														label="From Date"
														renderInput={props => <TextField
															{...props}
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton edge="end">
																			<EventIcon />
																		</IconButton>
																	</InputAdornment>
																)
															}}
														/>}
													/>
												</div>
												<div className="mt-2">
													<MobileDateTimePicker
														value={this.state.pickerOpened ? this.state.temp_to_date : this.state.to_date}
														onChange={(dateTime) => this.setState({ temp_to_date: dateTime })}
														onOpen={() => this.setState({ temp_to_date: this.state.to_date, pickerOpened: true })}
														onClose={() => this.setState({ pickerOpened: false })}
														onAccept={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'to_date')}
														label="To Date"
														renderInput={props => <TextField
															{...props}
															className="mt-2"
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton edge="end">
																			<EventIcon />
																		</IconButton>
																	</InputAdornment>
																)
															}}
														/>}
													/>
												</div>
											</>
										)}
									</ThemeContext.Consumer>
									<div className="mt-3 d-flex align-items-center justify-content-between">
										<div>
											<h4>Plates</h4>
										</div>
										<div>
											<TextField
												label="Plate Search"
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												type="string"
												onChange={this.onPlateFilterChange}
											/>
										</div>
									</div>
								</div>
								<div className="card-body pl-0 pr-0" style={{ overflowY: "auto" }}>
									{
										plates && plates.length > 0 && plates.map((plate, index) => {
											return <MenuItem onClick={() => this.selectPlate(plate)} className="pl-0 pr-0 d-flex" key={index}>
												<Checkbox checked={this.state.plate && this.state.plate._id === plate._id ? true : false} />
												<div className="plate-status-box">
													<abbr style={{ fontSize: "16px", fontWeight: "700" }}>{plate.name} ({getPlateCellCount(plate.cells)})</abbr>
													<br />
													<abbr style={{ fontSize: "14px" }}>{plate.status}</abbr>
												</div>
											</MenuItem>
										})
									}
									{
										!store.getState().blockUILoading && plates && plates.length === 0
										&&
										<h5 className="p-4">There is no exported plate for selected date range.</h5>
									}
								</div>
							</div>
						}
						{
							((browserWidth > 500) || (browserWidth < 500 && !this.state.showSidebar))
							&&
							<div style={{ position: "relative", width: this.state.showSidebar ? "calc(100% - 365px)" : "100%" }}>
								{
									!this.state.showSidebar
									&&
									<div className="d-flex justify-content-center align-items-center sidebar-icon" style={{ left: "-5px", top: "15px" }} onClick={this.toggleSidebar}>
										<i className="fa fa-bars"></i>
									</div>
								}
								{
									this.state.plate &&
									<div>

										<div className="row">
											<div className="col-md-6 plate-section">
												<div className={this.state.plateType + "-plate"}>
													<QuantPlateMap
														type={this.state.plateType}
														plateData={this.state.plate}
													/>
												</div>
											</div>
											{
												this.state.plate
												&&
												<div className="col-md-6">
													<div className="row d-flex align-items-center mt-2">
														<div className="col-sm-8">
															<Barcode
																value={this.state.plate.name}
																format="CODE39"
																width={1}
																marginLeft={0}
																paddingLeft={0}
																displayValue={false}
																marginTop={0}
															/>
															<h3 className="ml-2">{this.state.plate.name}</h3>
														</div>
														<div className="col-sm-4">
															<button
																className="btn btn-primary"
																style={{ width: "120px", height: "52px" }}
																onClick={() => this.onBtnExport()}
																disabled={this.state.plate ? false : true}
															>
																<i className="fa fa-file" aria-hidden="true"></i> Export
															</button>
														</div>
													</div>
													<div className="row d-flex align-items-center mt-2">
														<div className="col-5">
															<h4>Plate Type</h4>
														</div>
														<div className="col-7">
															<h4>{this.state.plateType === 'regular' ? "Regular Plate" : "VIP Plate"}</h4>
														</div>
													</div>
													<div className="row d-flex align-items-center mt-2">
														<div className="col-5">
															<h4>Empty / Filled</h4>
														</div>
														<div className="col-7">
															<h4>{((quantPlateRows.length - 1) * (quantPlateColumns.length - 1) - getPlateCellCount(this.state.plate.cells)) + " / " + getPlateCellCount(this.state.plate.cells)}</h4>
														</div>
													</div>
													<div className="row d-flex align-items-center mt-2">
														<div className="col-5">
															<h4>Creation Date</h4>
														</div>
														<div className="col-7">
															<h4>{moment.tz(this.state.plate.createdAt, "America/New_York").format("MM/DD/YYYY hh:mm A")}</h4>
														</div>
													</div>
													<div className="row d-flex align-items-center mt-2">
														<div className="col-5">
															<h4>Created By</h4>
														</div>
														<div className="col-7">
															<h4>{this.state.plate.createdBy}</h4>
														</div>
													</div>
													{
														this.state.plate.exportedTime
														&&
														<div className="row d-flex align-items-center mt-2">
															<div className="col-5">
																<h4>Exported At</h4>
															</div>
															<div className="col-7">
																<h4>{this.state.plate.exportedTime ? moment(this.state.plate.exportedTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : ""}</h4>
															</div>
														</div>
													}
													{
														this.state.plate.rnaExtractionTime
														&&
														<div className="row d-flex align-items-center mt-2">
															<div className="col-5">
																<h4>RNA Extraction</h4>
															</div>
															<div className="col-7">
																<h4>{this.state.plate.rnaExtractionTime ? moment(this.state.plate.rnaExtractionTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : ""}</h4>
															</div>
														</div>
													}
													{
														this.state.plate.revertedTime
														&&
														<div className="row d-flex align-items-center mt-2">
															<div className="col-5">
																<h4>Reverted At</h4>
															</div>
															<div className="col-7">
																<h4>{this.state.plate.revertedTime ? moment(this.state.plate.revertedTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : ""}</h4>
															</div>
														</div>
													}
													{
														this.state.plate.importedTime
														&&
														<div className="row d-flex align-items-center mt-2">
															<div className="col-5">
																<h4>Imported At</h4>
															</div>
															<div className="col-7">
																<h4>{this.state.plate.importedTime ? moment(this.state.plate.importedTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : ""}</h4>
															</div>
														</div>
													}
												</div>
											}
										</div>
										<div className="row form-group">
											<div className="col-12 d-flex justify-content-end">
												<div style={{ width: "320px" }}>
													<TextField
														label="Current Grid Search"
														variant="outlined"
														id="reset-form"
														InputLabelProps={{
															shrink: true,
														}}
														type="string"
														margin="dense"
														onChange={this.onFilterTextChange}
													/>
												</div>
											</div>
										</div>
										<ThemeContext.Consumer>
											{({ themeName }) => (
												<div
													id="myGrid"
													style={{
														height: "620px",
														width: "100%",
													}}
													className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
												>
													<AgGridReact
														columnDefs={this.state.columnDefs}
														defaultColDef={this.state.defaultColDef}
														masterDetail={true}
														onGridReady={this.onGridReady}
														rowData={this.state.rowData}
														components={this.state.components}
														pagination={true}
														paginationPageSize={20}
														paginationNumberFormatter={this.state.paginationNumberFormatter}
														excelStyles={this.state.excelStyles}
														context={this.state.context}
													/>
												</div>
											)}
										</ThemeContext.Consumer>
									</div>
								}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default LIMSExportPlateGrid;
