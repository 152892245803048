import React, { Component } from "react";
import { FormControlLabel, Switch } from "@mui/material";

class InsuranceBreadCrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-4 col-12">
                            <nav
                                aria-label="breadcrumb"
                                className="page-breadcrumb"
                            >
                                <ol className="breadcrumb">
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Patient Menu
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Insurance
                                    </li>
                                </ol>
                            </nav>
                            <div style={{ display: "flex" }}>
                                <h2 className="breadcrumb-title">Insurance</h2>
                                <FormControlLabel
                                    value="includeArchived"
                                    control={
                                        <Switch
                                            checked={this.props.isArchived}
                                            onChange={
                                                this.props
                                                    .onIsArchivedInformation
                                            }
                                        />
                                    }
                                    label={
                                        this.props.isArchived
                                            ? "Hide Archives"
                                            : "Show Archives"
                                    }
                                    labelPlacement="end"
                                    className="m-0 show-archives-control breadcrumb-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-8 col-12">
                            <div className="row breadcrumb-btns">
                                <label className="breadcrumb-text">
                                    Bulk Edit & Review:
                                </label>
                                <ul className="export-ul">
                                    <li
                                        onClick={() =>
                                            this.props.onBtnBulkEdit()
                                        }
                                    >
                                        {this.props.isBulkEdit
                                            ? "Disable"
                                            : "Enable"}{" "}
                                        Bulk Edit
                                    </li>
                                    {this.props.showReviewButton && (
                                        <li
                                            onClick={() =>
                                                this.props.onBtnReviewSelection()
                                            }
                                        >
                                            Review Selection
                                        </li>
                                    )}
                                </ul>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="import_grubbs"
                                    onChange={this.props.importGrubbs}
                                    accept=".xlsx,/*"
                                />
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="import_requisitions"
                                    onChange={this.props.importNovaRequisitions}
                                    accept=".xlsx,/*"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InsuranceBreadCrumb;
