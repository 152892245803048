import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

import { dailyReports } from "../../../../services/clinicPortalServices/reportService";
import {
    getViewSettings,
    saveViewDefault
} from "../../../../services/clinicPortalServices/userViewSettings";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import ReportsSearchMenu from "./ReportsSearchMenu";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicDailyReportsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_role: getUserRole(),
            searchFilters: {
                facility_id: [],
                from_date:
                    moment().subtract(0, "days").format("YYYY-MM-DD") +
                    "T00:00",
                to_date:
                    moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                dateRange: "today",
                reportType: "all"
            },
            gridName: "DailyReport",
            pageSize: "",
            columnDefs: [
                {
                    headerName: "Facility Name",
                    minWidth: 350,
                    field: "name",
                    resizable: true,
                    sort: "asc"
                },
                {
                    headerName: "Total Samples",
                    minWidth: 200,
                    field: "count",
                    resizable: true
                },

                {
                    headerName: "Total SARS-CoV-2 Negative",
                    minWidth: 200,
                    field: "negativeCount",
                    resizable: true
                },
                {
                    headerName: "Total SARS-CoV-2 Positive",
                    minWidth: 200,
                    field: "positiveCount",
                    resizable: true
                },
                {
                    headerName: "Total SARS-CoV-2 Inconclusive / Invalid",
                    minWidth: 200,
                    field: "inconclusiveCount",
                    resizable: true
                },
                {
                    headerName: "Total CMP Tests",
                    minWidth: 200,
                    field: "CMPCount",
                    resizable: true
                }
            ],
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            excelStyles: [
                {
                    id: "header",
                    interior: {
                        color: "#aaaaaa",
                        pattern: "Solid"
                    }
                },
                {
                    id: "body",
                    interior: {
                        color: "#dddddd",
                        pattern: "Solid"
                    }
                }
            ],
            facilities: []
        };
    }

    componentDidMount() {
        this.loadGridData();
    }

    updateDateRange = (dateRange) => {
        let filters = this.state.searchFilters;
        if (filters.dateRange !== dateRange) {
            filters.dateRange = dateRange;
            switch (dateRange) {
                case "today":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().format("YYYY-MM-DD") + "T00:00";
                    break;
                case "week":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                case "month":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                default:
                    break;
            }
            this.setState({ searchFilters: filters });
            if (dateRange !== "custom") {
                this.loadGridData();
            }
        }
    };

    setDateRange = (searchFilters) => {
        if (
            moment(searchFilters.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "today";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("week").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "week";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("month").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "month";
            } else {
                searchFilters.dateRange = "custom";
            }
        } else {
            searchFilters.dateRange = "custom";
        }
    };

    handleDateFiltersChange = (dateTime, type) => {
        let filters = this.state.searchFilters;
        let filterIsValid = true;
        if (type === "from_date") {
            if (moment(filters.to_date).isAfter(dateTime)) {
                filters.from_date = dateTime;
            } else {
                filterIsValid = false;
            }
        } else {
            if (moment(dateTime).isAfter(filters.from_date)) {
                filters.to_date = dateTime;
            } else {
                filterIsValid = false;
            }
        }

        if (filterIsValid) {
            this.setDateRange(filters);
            this.setState({ searchFilters: filters });
            this.loadGridData();
        }
    };

    handleFiltersChange = (e) => {
        const filters = this.state.searchFilters;

        switch (e.target.name) {
            case "type": {
                filters.reportType = e.target.value;
                break;
            }
            default:
                break;
        }

        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridSchema();
    };

    loadGridData = () => {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }
        dailyReports(this.state.searchFilters).then((response) => {
            this.setState({ rowData: response.data });
            if (!response.data.length) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    onBtnExport = () => {
        let totalCount = 0;
        let totalPositiveCount = 0;
        let totalNegativeCount = 0;
        let totalInconclusiveCount = 0;
        let totalCMPCount = 0;
        this.state.rowData.map((item) => {
            totalCount += item.count;
            totalPositiveCount += item.positiveCount;
            totalNegativeCount += item.negativeCount;
            totalCMPCount += item.CMPCount;
            return null;
        });
        let dateRange =
            moment(this.state.searchFilters.from_date).format(
                "MM/DD/YYYY hh:mm a"
            ) +
            " ~ " +
            moment(this.state.searchFilters.to_date).format(
                "MM/DD/YYYY hh:mm a"
            );
        this.gridApi.exportDataAsExcel({
            prependContent: [
                { cells: [] },
                {
                    cells: [
                        {
                            data: {
                                value: dateRange,
                                type: "String"
                            }
                        }
                    ]
                },
                { cells: [] }
            ],
            appendContent: [
                { cells: [] },
                {
                    cells: [
                        {
                            data: {
                                value: "Total",
                                type: "String"
                            }
                        },
                        {
                            data: {
                                value: totalCount,
                                type: "Number"
                            }
                        },
                        {
                            data: {
                                value: totalNegativeCount,
                                type: "Number"
                            }
                        },
                        {
                            data: {
                                value: totalPositiveCount,
                                type: "Number"
                            }
                        },
                        {
                            data: {
                                value: totalInconclusiveCount,
                                type: "Number"
                            }
                        },
                        {
                            data: {
                                value: totalCMPCount,
                                type: "Number"
                            }
                        }
                    ]
                }
            ],
            fileName: "end_of_day_report.xlsx",
            sheetName: "End of Day Report"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.EndOfDayReportGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.EndOfDayReportGridExported
        };
        createAudit(auditData);
    };

    onPageSizeChanged = () => {
        let value = document.getElementById("page-size").value;
        this.gridApi.paginationSetPageSize(Number(value));
    };

    loadGridSchema = () => {
        let userId = window.localStorage.getItem("USER_ID");
        let params = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.END_OF_DAY_REPORTS
        };
        getViewSettings(params).then((response) => {
            const columnState =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                }).columns;
            if (columnState) {
                this.gridColumnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true
                });
            } else {
                this.gridColumnApi.resetColumnState();
            }

            const pageSize =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                }).page_size;
            document.getElementById("page-size").value =
                pageSize && pageSize > 0 ? pageSize : 20;
            this.onPageSizeChanged();
        });
    };

    saveState = () => {
        let userId = window.localStorage.getItem("USER_ID");
        const columnState = this.gridColumnApi.getColumnState();
        let pageSize = document.getElementById("page-size").value;
        let saveObject = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.END_OF_DAY_REPORTS,
            grid_views: [
                {
                    name: this.state.gridName,
                    page_size: pageSize,
                    columns: columnState
                }
            ]
        };
        saveViewDefault(saveObject).then(() => {
            toastr.success("Saved successfully.");
        });
    };

    resetState = () => {
        this.gridColumnApi.resetColumnState();
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";

        const filters = this.state.searchFilters;

        filters.reportType = "all";

        filters.from_date =
            moment().subtract(0, "days").format("YYYY-MM-DD") + "T00:00";
        filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";

        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    render() {
        return (
            <div className="clinic-contain">
                <ReportsSearchMenu
                    handleFiltersChange={this.handleFiltersChange}
                    handleDateFiltersChange={this.handleDateFiltersChange}
                    updateDateRange={this.updateDateRange}
                    facilities={this.state.facilities}
                    from_date={this.state.searchFilters.from_date}
                    to_date={this.state.searchFilters.to_date}
                    date_range={this.state.searchFilters.dateRange}
                    onFilterTextChange={this.onFilterTextChange}
                    reportType={this.state.searchFilters.reportType}
                    clearFilter={this.clearFilter}
                    onPageSizeChanged={this.onPageSizeChanged}
                    saveState={this.saveState}
                    resetState={this.resetState}
                    onBtnExport={this.onBtnExport}
                    user_role={this.state.user_role}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 350px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    components={this.state.components}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationNumberFormatter={
                                        this.state.paginationNumberFormatter
                                    }
                                    excelStyles={this.state.excelStyles}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicDailyReportsGrid;
