import { serviceConstants, auditEnums } from "../common/constants";
import { getPatientAuthToken } from "../common/util";
import { apiInterfaceForJson } from "../common/apiManager";
import { createAudit } from "../clinicPortalServices/auditService";

export const authenticateAndFetchUserDetails = (loginInfo) => {
	return apiInterfaceForJson("/patient/v1/authenticate", "POST", loginInfo);
};

export const sendVerificationCode = (info) => {
	return apiInterfaceForJson("/patient/v1/sendVerificationCode", "POST", info);
}

export const verifyCode = (info) => {
	return apiInterfaceForJson("/patient/v1/verifyCode", "POST", info);
}

export const checkPatient = (info) => {
	return apiInterfaceForJson("/patient/v1/check", "POST", info);
}

export const getDetailsFacility = (patientInfo) => {
	return apiInterfaceForJson("/patient/v1/details-facility", "POST", patientInfo);
};

export const submitError = (patientInfo) => {
	return apiInterfaceForJson("/patient/v1/submit-error", "POST", patientInfo);
};

export const logoutPatient = () => {
	if (window.localStorage.getItem('MATCHED_PATIENTS_DATA')) {
		const patient = JSON.parse(window.localStorage.getItem('MATCHED_PATIENTS_DATA'));
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.PatientRecord,
			event_type: auditEnums.EVENTTYPES.PatientLogOut,
			user_id: patient._id,
			user_name: patient.first_name + " " + patient.last_name + " (Patient)",
			record_id: patient._id,
			update_string: patient.first_name + " " + patient.last_name + " logged out from the patient portal"
		}
		createAudit(auditData).then(res => {
			logout();
		})
	} else {
		logout();
	}
}

export const goPatientLogin = () => {
	window.localStorage.clear();
	window.location.href = "/";
}

export const logout = () => {
	const token = getPatientAuthToken();
	return fetch(`${serviceConstants.API_HOST_NAME}/patient/v1/logout`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
	}).then((response) => {
		if (response.ok) {
			window.localStorage.clear();
			window.location.href = "/patientportal";
			return response.json();
		} else {
			throw response;
		}
	}).catch(function (error) {
		window.localStorage.clear();
		window.location.href = "/patientportal";
	});
};
