import React, { Component } from 'react';
import AuditBreadcrumb from './auditBreadcrumb';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { FormLabel, TextField, IconButton, InputAdornment } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";

class AuditSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from_date: this.props.from_date,
			to_date: this.props.to_date,
			pickerOpened: false
		}
	};

	render() {
		return (
			<div>
				<AuditBreadcrumb
					onBtnExport={this.props.onBtnExport}
				/>
				<div className="row mt-3" style={{ padding: "12px" }}>
					<div className='col-md-6' style={{ marginTop: "-15px" }}>
						<div className='row pl-2'>
							<div className='col-12'>
								<FormLabel style={{ marginBottom: "10px" }}>
									Date Range
								</FormLabel>
							</div>
							<div className='dateRangeBar mb-3'>
								<button
									className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'today' ? " active" : "")}
									onClick={() => this.props.updateDateRange('today')}
								>
									Today
								</button>
								<button
									className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'week' ? " active" : "")}
									onClick={() => this.props.updateDateRange('week')}
								>
									Week
								</button>
								<button
									className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'month' ? " active" : "")}
									onClick={() => this.props.updateDateRange('month')}
								>
									Month
								</button>
								<button
									className={"btn btn-info dateRangeBtn ml-1 mb-2" + (this.props.date_range === 'custom' ? " active" : "")}
								>
									Custom
								</button>
							</div>
							<div className='dateRangeBar dateRangeBarPickers'>
								<MobileDateTimePicker
									value={this.state.pickerOpened ? this.state.from_date : this.props.from_date}
									onChange={(dateTime) => this.setState({ from_date: dateTime })}
									onOpen={() => this.setState({ from_date: this.props.from_date, pickerOpened: true })}
									onClose={() => this.setState({ pickerOpened: false })}
									onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'from_date')}
									label="From Date"
									renderInput={props => <TextField
										{...props}
										className="ml-1 mr-2 mt-2"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>}
								/>
								<MobileDateTimePicker
									value={this.state.pickerOpened ? this.state.to_date : this.props.to_date}
									onChange={(dateTime) => this.setState({ to_date: dateTime })}
									onOpen={() => this.setState({ to_date: this.props.to_date, pickerOpened: true })}
									onClose={() => this.setState({ pickerOpened: false })}
									onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'to_date')}
									label="To Date"
									renderInput={props => <TextField
										{...props}
										className="mt-2"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-6 text-right">
						<TextField
							label="Current Grid Search"
							variant="outlined"
							id="reset-form"
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ maxWidth: 300 }}
							type="string"
							margin="dense"
							onChange={this.props.onFilterTextChange}
						/>
						<div>
							<button
								className="btn btn-primary mt-3"
								onClick={() => this.props.clearFilter()}
							>
								<i className="fa fa-times" aria-hidden="true"></i> Clear Filter
							</button>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default AuditSearchMenu;