import { serviceConstants, patientImagePath } from "../common/constants";
import { getUserAuthToken } from "../common/util";
import { apiInterfaceForJson } from "../common/apiManager";

export const updatePatientData = (editParams) => {
	let updatedPatient = {
		_id: editParams._id,
		first_name:
			editParams && editParams.firstName ? editParams.firstName : "",
		last_name: editParams && editParams.lastName ? editParams.lastName : "",
		email: editParams && editParams.email ? editParams.email : "",
		gender: editParams && editParams.gender ? editParams.gender : "U",
		mobile: editParams && editParams.mobile ? editParams.mobile : "",
		date_of_birth: editParams && editParams.dob ? editParams.dob : "",
		address: {
			address1: editParams && editParams.address1 ? editParams.address1 : "",
			address2: editParams && editParams.address2 ? editParams.address2 : "",
			city: editParams && editParams.city ? editParams.city : "",
			state: editParams && editParams.state ? editParams.state : "",
			zip: editParams && editParams.zip ? editParams.zip : "",
			country: editParams && editParams.country ? editParams.country : ""
		}
	}
	if (editParams.hasOwnProperty('passport')) {
		updatedPatient.passport = editParams.passport;
	}
	if (editParams.hasOwnProperty('custom_info')) {
		updatedPatient.custom_info = editParams.custom_info;
	}
	if (editParams.hasOwnProperty('internal_note')) {
		updatedPatient.internal_note = editParams.internal_note;
	}
	if (editParams.hasOwnProperty('is_pregnant')) {
		updatedPatient.is_pregnant = editParams.is_pregnant;
	}
	if (editParams.hasOwnProperty('get_sample_tracking_notification')) {
		updatedPatient.get_sample_tracking_notification = editParams.get_sample_tracking_notification;
	}
	if (editParams.hasOwnProperty('ethnicity')) {
		updatedPatient.ethnicity = editParams.ethnicity;
	}
	if (editParams.hasOwnProperty('race')) {
		updatedPatient.race = editParams.race;
	}
	if (editParams.hasOwnProperty('driverLicense')) {
		updatedPatient.driver_license = editParams.driverLicense;
	}
	if (editParams.hasOwnProperty('driverLicenseState')) {
		updatedPatient.driver_license_state = editParams.driverLicenseState;
	}
	if (editParams.hasOwnProperty('facility_id')) {
		updatedPatient.facility_id = editParams.facility_id;
	}
	if (editParams.hasOwnProperty('is_incorrect')) {
		updatedPatient.is_incorrect = editParams.is_incorrect;
	}
	if (editParams.hasOwnProperty('incorrect_history')) {
		updatedPatient.incorrect_history = editParams.incorrect_history;
	}
	if (editParams.hasOwnProperty('incorrect_note')) {
		updatedPatient.incorrect_note = editParams.incorrect_note;
	}
	return apiInterfaceForJson("/patient/v1/", "POST", updatedPatient);
};

export const getPatientPhotoUrls = (imageName) => {
	const imagePath = serviceConstants.API_HOST_NAME + patientImagePath;
	const imageSuffix = ".jpeg?" + new Date().getTime() + '&token=' + getUserAuthToken();
	const urlLarge = imagePath + imageName.substring(0, imageName.lastIndexOf(".")) + "-large" + imageSuffix;
	const urlSmall = imagePath + imageName.substring(0, imageName.lastIndexOf(".")) + "-small" + imageSuffix;
	return { urlLarge, urlSmall };
}

export const getPatientPhotoUrlOrigin = (imageName) => {
	return serviceConstants.HOST_NAME + patientImagePath + imageName;
}

export const easyUpdatePatient = (patientDetails) => {
	return apiInterfaceForJson("/patient/v1/", "PUT", patientDetails);
}

export const mergePatientData = (mergeDetails) => {
	return apiInterfaceForJson("/patient/v1/merge", "POST", mergeDetails);
}

export const triggerADTProcessForPatient = (data) => {
	return apiInterfaceForJson("/patient/v1/triggerADTProcess", "POST", data);
}

export const updatePDFForPatient = (data) => {
	return apiInterfaceForJson("/patient/v1/updatePDFForPatient", "POST", data);
}

export const handleBirthdayUpdatedNotificationToLab = (data) => {
	return apiInterfaceForJson('/patient/v1/handleBirthdayUpdatedNotificationToLab', "POST", data);
}