import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { serviceConstants, auditEnums } from "../../../../services/common/constants";
import { getPatientAuthToken } from "../../../../services/common/util";
import { Viewer } from '@react-pdf-viewer/core';
import MessageQuestions from "../../../Icons/messageQuestions";
import { patientCommunication } from "../../../../services/patientPortalServices/dashboardService";
import Swal from "sweetalert2";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import ResultData from ".";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class ResultCellBtnRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            pdfPath: this.props.data.results && this.props.data.results.pdf_path ? `${serviceConstants.API_HOST_NAME}${this.props.data.results.pdf_path}` : "",
            showCommunication: false,
            comment: {
                email: this.props.patientData.email,
                orderId: this.props.data.lab_order_id,
                mobile: this.props.patientData.mobile,
                name: this.props.patientData.first_name + ' ' + this.props.patientData.last_name,
                subject: "Benchlab, LLC. - Patient Support Request",
                message: '',
            },
            commentError: {
                emailError: false,
                messageErr: false,
            },
            showSubBundlePopup: false,
            subTests: []
        }
    }

    showResult = () => {
        this.setState({ show: true });

        const patient = this.props.patientData;
        const order = this.props.data;
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.OrderRecord,
            event_type: auditEnums.EVENTTYPES.OrderResultViewed,
            user_id: patient._id,
            user_name: patient.first_name + " " + patient.last_name + " (Patient)",
            record_id: order._id,
            update_string: "Order " + order.lab_order_id + " result is viewed by patient " + patient.first_name + " " + patient.last_name + " (" + patient.mrn + ")"
        }
        createAudit(auditData)
    }

    downloadResult = () => {
        const patient = this.props.patientData;
        const order = this.props.data;
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.OrderRecord,
            event_type: auditEnums.EVENTTYPES.OrderResultDownloaded,
            user_id: patient._id,
            user_name: patient.first_name + " " + patient.last_name + " (Patient)",
            record_id: order._id,
            update_string: "Order " + order.lab_order_id + " result pdf is downloaded by patient " + patient.first_name + " " + patient.last_name + " (" + patient.mrn + ")"
        }
        createAudit(auditData)
    }

    hideResult = () => {
        this.setState({ show: false });
    }

    openNewCommunication = () => {
        this.setState({
            showCommunication: true
        })
    }

    hideCommunication = () => {
        this.setState({
            showCommunication: false,
        })
    }

    onCommunicationTextChange = (e) => {
        let comment = this.state.comment;
        if (e.target.name === 'message') {
            comment.message = e.target.value;
        }
        if (e.target.name === 'email') {
            comment.email = e.target.value;
        }
        this.setState({ comment: comment });
    }

    onSupportRequest = () => {
        if (this.state.comment.email === "" || this.state.comment.message === "") {
            let error = this.state.commentError;
            if (this.state.comment.email === "") {
                error.emailError = true;
            }
            if (this.state.comment.message === "") {
                error.messageErr = true;
            }
            this.setState({ commentError: error });
            return;
        }

        let comment = this.state.comment;
        comment.subject = "Benchlab, LLC. - Patient Support Request";
        comment.name = this.props.patientData.first_name + ' ' + this.props.patientData.last_name;
        comment.mobile = this.props.patientData.mobile;
        comment.orderId = this.props.data.lab_order_id;

        patientCommunication(comment).then(res => {
            this.hideCommunication();
            Swal.fire({
                customClass: {
                    container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
                },
                title: 'We have received your support request',
                html: '<p>A support representative will be reviewing your request and will send you a personal response. (usually within 24 hours).</p><p>Our laboratory customer service hours are from 8am to 8pm, 7 days a week. We will reply to you within those hours.</p><p>Thank you for your patience.</p><p>Sincerely,</p><p>The Benchlab, LLC. Team</p>',
                icon: 'success'
            })
        });
        this.setState({ comment: comment });
    }

    hideSubBundlePopup = () => {
        this.setState({ showSubBundlePopup: false })
    }

    render() {
        let patient_test_result_management_info = this.props.facilityData ? this.props.facilityData.patient_test_result_management_info : null;
        let showResultButton = false;
        let showSubBundlePopupButton = false;
        let subTests = [];
        if (this.state.pdfPath) {
            showResultButton = true;
            if (this.props.data.order_type !== "sub_bundle" && patient_test_result_management_info && patient_test_result_management_info[this.props.data.test_id._id]) {
                showResultButton = false;
                if (Array.isArray(patient_test_result_management_info[this.props.data.test_id._id]) && patient_test_result_management_info[this.props.data.test_id._id].length && this.props.data.order_type === 'bundle') {
                    showSubBundlePopupButton = true;
                    patient_test_result_management_info[this.props.data.test_id._id].map(testId => {
                        this.props.data.orderIds.map(order => {
                            if (order.test_id._id === testId) {
                                subTests.push(order);
                            }
                            return null;
                        })
                        return null;
                    })
                }
            }
        }
        return (
            <div className="resultAction_bar">
                {
                    showResultButton
                    &&
                    <a
                        style={{ textDecoration: "underline" }}
                        href={this.state.pdfPath + '?' + new Date().getTime() + '&token=' + getPatientAuthToken()}
                        target="_blank"
                        rel="noreferrer"
                        onClick={this.downloadResult}
                    >
                        <button
                            style={{ border: "none", margin: "3px", backgroundColor: "#43dbf347", color: "#0036cf", borderRadius: '5px', padding: "0 10px", lineHeight: "30px" }}
                        >
                            <i className="fas fa-download" style={{ marginRight: "10px" }}></i>
                            Download
                        </button>
                    </a>
                }
                {
                    showSubBundlePopupButton
                    &&
                    <div
                        onClick={() => { this.setState({ showSubBundlePopup: true }) }}
                    >
                        <button
                            style={{ border: "none", margin: "3px", backgroundColor: "#78e3ad47", color: "#2fb305", borderRadius: '5px', padding: "0 10px", lineHeight: "30px" }}
                        >
                            <i className="fas fa-list" style={{ marginRight: "10px" }}></i>
                            Show Tests
                        </button>
                    </div>
                }
                {
                    this.props.isNotMobile && showResultButton
                    &&
                    <button
                        onClick={this.showResult}
                        style={{ border: "none", margin: "3px", backgroundColor: "#78e3ad47", color: "#2fb305", borderRadius: '5px', padding: "0 10px", lineHeight: "30px" }}
                    >
                        <i className="fas fa-eye" style={{ marginRight: "10px" }}></i>
                        View
                    </button>
                }
                {
                    !showResultButton
                    &&
                    <button
                        onClick={this.openNewCommunication}
                        style={{ border: "none", margin: "3px", backgroundColor: "#78e3ad47", color: "#2fb305", borderRadius: '5px', padding: "0 10px", lineHeight: "30px" }}
                    >
                        <MessageQuestions width={30} height={20} color="rgb(47 179 107)" />
                    </button>
                }
                {
                    showSubBundlePopupButton
                    &&
                    <ModalStyled
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.showSubBundlePopup}
                        onHide={this.hideSubBundlePopup}
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Tests Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ResultData
                                result={subTests}
                                patientData={this.props.patientData}
                                facilityData={this.props.facilityData}
                            />
                        </Modal.Body>
                    </ModalStyled>
                }
                <ModalStyled
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.hideResult}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Result PDF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Viewer fileUrl={this.state.pdfPath + `?${this.props.oneTimeToken ? 'isOneTime=1&&isPdfView=1&&' : ''}isAPI=1&&` + new Date().getTime() + '&token=' + (this.props.oneTimeToken ? this.props.oneTimeToken : getPatientAuthToken())} />
                    </Modal.Body>
                </ModalStyled>
                <ModalStyled
                    size="lx"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCommunication}
                    onHide={this.hideCommunication}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Facility Contact Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label>Facility Name</label>
                                <input className="form-control" value={this.props.facilityData && this.props.facilityData.name} disabled />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Facility Phone Number</label>
                                <input className="form-control" value={this.props.facilityData && this.props.facilityData.phone_no} disabled />
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Facility Email</label>
                                <input className="form-control" value={this.props.facilityData && this.props.facilityData.contact_email} disabled />
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="primary" onClick={this.onSupportRequest}>
                            Send
                        </Button>
                    </Modal.Footer> */}
                </ModalStyled>
            </div>
        )
    }
}
