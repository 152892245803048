import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import {
    fetchNotificationTypes,
    updateNotificationType
} from "../../../../services/clinicPortalServices/notificationTypeService";
import SearchMenu from "./searchMenu";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import DescriptionCellRenderer from "../clinicNotificationTypeGrid/descriptionCellRenderer";
import SubjectCellRenderer from "../clinicNotificationTypeGrid/subjectCellRenderer";
import CheckboxRenderer from "./checkboxRenderer.jsx";
import ContentCellRenderer from "../clinicNotificationTypeGrid/contentCellRenderer";
import Swal from "sweetalert2";
import { auditEnums } from "../../../../services/common/constants";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import moment from "moment";

class FacilityNotificationGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            errors: [],
            columnDefs: [
                {
                    headerName: "Active",
                    minWidth: 100,
                    field: "checked",
                    cellRenderer: "checkboxRenderer"
                },
                {
                    headerName: "Title",
                    minWidth: 150,
                    resizable: true,
                    field: "title"
                },
                {
                    headerName: "Key",
                    minWidth: 150,
                    resizable: true,
                    field: "key"
                },
                {
                    headerName: "Description",
                    minWidth: 150,
                    resizable: true,
                    field: "description",
                    cellRenderer: "descriptionCellRenderer"
                },
                {
                    headerName: "Email Content",
                    minWidth: 150,
                    resizable: true,
                    field: "content",
                    cellRenderer: "contentCellRenderer"
                },
                {
                    headerName: "Send Email",
                    minWidth: 100,
                    resizable: true,
                    valueGetter: (params) => {
                        if (params.data.send_email) {
                            return "YES";
                        } else {
                            return "NO";
                        }
                    }
                },
                {
                    headerName: "Send SMS",
                    minWidth: 100,
                    resizable: true,
                    valueGetter: (params) => {
                        if (params.data.send_sms) {
                            return "YES";
                        } else {
                            return "NO";
                        }
                    }
                },
                {
                    headerName: "Send Browser Notification",
                    minWidth: 120,
                    resizable: true,
                    valueGetter: (params) => {
                        if (params.data.send_browser_notification) {
                            return "YES";
                        } else {
                            return "NO";
                        }
                    }
                },
                {
                    headerName: "Send Push Notification",
                    minWidth: 120,
                    resizable: true,
                    valueGetter: (params) => {
                        if (params.data.send_push_notification) {
                            return "YES";
                        } else {
                            return "NO";
                        }
                    }
                },
                {
                    headerName: "Subject",
                    minWidth: 150,
                    resizable: true,
                    field: "subject",
                    cellRenderer: "subjectCellRenderer"
                }
            ],
            context: { componentParent: this },
            components: {
                descriptionCellRenderer: DescriptionCellRenderer,
                subjectCellRenderer: SubjectCellRenderer,
                contentCellRenderer: ContentCellRenderer,
                checkboxRenderer: CheckboxRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            facilityId: "",
            facilityIds: [],
            facilities: []
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadFacilities();
    };

    loadGridData = () => {
        this.gridApi.showLoadingOverlay();
        fetchNotificationTypes().then((res) => {
            if (res && res.data && res.data.length) {
                res.data.map((item) => {
                    if (
                        item.disabled_facilities &&
                        item.disabled_facilities.length &&
                        item.disabled_facilities.includes(this.state.facilityId)
                    ) {
                        item.checked = false;
                    } else {
                        item.checked = true;
                    }
                    return null;
                });
                this.setState({ rowData: res.data });
                this.gridApi.refreshCells();
                this.gridApi.hideOverlay();
            } else {
                this.setState({ rowData: [] });
                this.gridApi.showNoRowsOverlay();
            }
        });
    };

    loadFacilities = () => {
        let facilityIds = [];
        let facilityId = "";

        getCompanyWithFacility(true).then((response) => {
            let facilities = response.data.filter(
                (item) => item.type === "facility"
            );
            facilities.map((fac) => {
                facilityIds.push(fac._id);
                return null;
            });
            facilityId = facilityIds[0];

            this.setState({ facilities, facilityIds, facilityId });
            this.loadGridData();
        });
    };

    checkItem = (notificationTypeId, checked) => {
        Swal.fire({
            title: "Are you sure?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                let updateData;
                let rowData = this.state.rowData;
                rowData.map((row) => {
                    if (row._id === notificationTypeId) {
                        if (!checked) {
                            row.disabled_facilities.push(this.state.facilityId);
                        } else {
                            row.disabled_facilities =
                                row.disabled_facilities.filter(
                                    (item) => item !== this.state.facilityId
                                );
                        }
                        updateData = row;
                    }
                    return null;
                });
                updateNotificationType(updateData).then((res) => {
                    this.loadGridData();
                });
            }
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.loadGridData();
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Facility_Notification_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Facility Notifications"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type:
                auditEnums.EVENTTYPES
                    .FacilityNotificationManagementGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string:
                auditEnums.EVENTTYPES.FacilityNotificationManagementGridExported
        };
        createAudit(auditData);
    };

    render() {
        let columnDefs = /facilityadmin|^admin/.test(
            getUserRole().toLowerCase()
        )
            ? this.state.columnDefs.filter(
                  (x) =>
                      !["Actions", "Email Template ID"].includes(x.headerName)
              )
            : this.state.columnDefs;
        return (
            <div className="clinic-contain">
                <SearchMenu
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                    facilities={this.state.facilities}
                    facilityId={this.state.facilityId}
                    facilityIds={this.state.facilityIds}
                    context={this.state.context}
                    handleChange={this.handleChange}
                />

                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    context={this.state.context}
                                    components={this.state.components}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default FacilityNotificationGrid;
