import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { getRowColumnIndexForQuantPlate } from "../../../../services/common/util";
import { quantPlateRows, quantPlateColumns } from "../../../../services/common/optionsData";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class SampleTrackingCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false
		}
	}

	handleShow = () => {
		if (this.props.data.sample_tracking && this.props.data.sample_tracking.length) {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					<span>
						{
							this.props.value
						}
					</span>
				</button>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Sample Tracking Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								this.props.data.sample_tracking && this.props.data.sample_tracking.length > 0
								&&
								this.props.data.sample_tracking.map((item, index) => {
									let plateName = "";
									let wellPosition = "";
									let eventName = item.eventName;
									if (item.plateId) {
										this.props.context.componentParent.state.plateList.map(plate => {
											if (plate._id === item.plateId) {
												plateName = plate.name;
											}
											return null;
										})
										if (item.cellIndex && !plateName.includes("SOL")) {
											const { row, column } = getRowColumnIndexForQuantPlate(item.cellIndex);
											wellPosition = quantPlateRows[row] + quantPlateColumns[column];
										} else if (item.hasOwnProperty('cellIndex')) {
											wellPosition = Number(item.cellIndex) + 1;
										}
									}
									if (eventName === "IMPORT - FOR RETEST") {
										if (["Positive SARS-CoV-2", "SARS-CoV-2 POSITIVE"].includes(item.result)) {
											eventName += " (Positive)"
										} else {
											plateName = " (Inconclusive / Invalid)";
										}
									}
									return <div className="row form-row history-row d-flex align-items-center" key={index}>
										<div className="col-12 col-md-5 history-date">
											{moment(item.time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}
										</div>
										<div className="col-12 col-md-7 history-description">
											<div className="row">
												<div className="col-12">
													{eventName}
												</div>
												{
													plateName
													&&
													<div className="col-12" style={{ fontSize: "15px" }}>
														{plateName} {wellPosition ? ("(" + wellPosition + ")") : ""}
													</div>
												}
											</div>
										</div>
									</div>
								})
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
