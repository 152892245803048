import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Popover, Button, ListGroup, OverlayTrigger } from 'react-bootstrap';

// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgrey' : 'lightgrey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    width: '100%'
});

export default class AllViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: getItems(10),
            gridViews: this.props.gridViews
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.gridViews !== this.props.gridViews)
            this.setState({ gridViews: this.props.gridViews });
    }
    onDragEnd(result) {
        let { gridViews } = this.state;
        if (!result.destination) {
            return;
        }
        gridViews = reorder(gridViews, result.source.index, result.destination.index);
        this.setState({ gridViews });
        this.props.updateViews(gridViews);
    }
    popoverEditView = selectedViewId => {
        return (
            <Popover id="popover-positioned-bottom" title="Popover bottom">
                <ListGroup>
                    <ListGroup.Item
                        action
                        onClick={() => this.props.openRenameView(selectedViewId)}
                    >
                        Rename
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={() => this.props.openDelteView(selectedViewId)}>
                        Delete
                    </ListGroup.Item>
                </ListGroup>
            </Popover>
        );
    };
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        let { gridViews } = this.state;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {gridViews.map((item, index) => (
                                <Draggable key={item._id} draggableId={item._id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className="view-item"
                                        >
                                            {item.name}
                                            {item.name !== 'Default' &&
                                                <OverlayTrigger
                                                    trigger="focus"
                                                    placement="bottom"
                                                    overlay={this.popoverEditView(item._id)}
                                                >
                                                    <Button
                                                        variant="link"
                                                        className="p-0 text float-right view-option"
                                                    >
                                                        Options
                                                        <i className="fa fa-pen pl-2"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            }
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
