
import { styled } from '@mui/material';
import { Modal } from 'react-bootstrap';

export const ModalStyled = styled(Modal)(
    ({ theme }) => theme.palette.mode === 'dark' && `
        .modal-content, .modal-title, span, input, select, option, label, textarea, .MuiInputBase-input, .MuiInputLabel-outlined, .MuiInputLabel-outlined.Mui-focused, .MuiFormLabel-root.Mui-focused, .MuiPaper-root, .MuiPaper-root .MuiTypography-root, .MuiFormLabel-root, i, .dropzone, .dropzone-browse-files, .inline-errormsg i, .form-control:focus, h3, h4, h5, .form-group, .btn:hover, a, .facility-name {
            color: ${theme.palette.common.white};
        }
        .subject-input, .MuiInputBase-root .MuiInputBase-input, .draggable-route-container .text-info, .crop-your-image label {
            color: ${theme.palette.common.white} !important;
        }
        .modal-content, input, select, option, textarea, .dropzone, .MuiFormControl-root, .insurance-information-container, .MuiInputBase-root, .insurance-provider-input, .notifyType_imageBar {
            background-color:${theme.palette.common.blackBackgroundColor};
        }
        .form-control:focus, .MuiPaper-root, .draggable-route-container{
            background-color: ${theme.palette.common.blackBackgroundColor} !important;
        }
        .rdw-editor-wrapper {
            color: #272b41;
            span, input {
                color: inherit;
            }
            .rdw-image-mandatory-sign {
                color: red;
            }
            input {
                background-color: inherit;
            }
            a {
                color: #2e3842;
            }
            li {
                color: #272b41;
            }   
        }
        .MuiFormLabel-root {
            z-index: 1;
        }
        .checkbox-inactive .MuiButtonBase-root {
            background-color: transparent !important;   
        }
        .dropzone, .MuiOutlinedInput-root, .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, hr, .modal-button-col {
            border-color: ${theme.palette.common.white} !important;
        }
        .close {
            opacity: 1;
        }
        .MuiSvgIcon-root {
            fill: white;
        }
        .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom-color: ${theme.palette.common.white};
        }
        .form-control:disabled, .form-control[readonly] {
            background-color: #494e50 !important;
        }
        .signature-image img {
            filter: invert(1);
        }
        label.Mui-disabled {
            color: #babfc7;
        }
        .MuiInputBase-input.Mui-disabled, .MuiSelect-select.Mui-disabled {
            -webkit-text-fill-color: #babfc7;
        }
        .input-box {
            border: 1px solid white;
        }
    `
);
