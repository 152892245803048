import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import NotificationTypeDetails from "./notificationTypeDetails";

export default class AddNotificationType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            errors: []
        };
    }

    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    render() {
        return (
            <div>
                <button
                    className="btn btn-primary button-info-grid"
                    onClick={() => this.handleShow()}
                >
                    <i className="fas fa-user-plus"></i> Add Notification Type
                </button>
                <ModalStyled
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Notification Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NotificationTypeDetails
                            context={this.props.context}
                            handleClose={this.handleClose}
                        />
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}
