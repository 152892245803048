import { apiInterfaceForJson } from "../common/apiManager";

export const fetchUsers = () => {
    return apiInterfaceForJson("/user/v1/", "GET");
};

export const searchUsers = (query) => {
    return apiInterfaceForJson("/user/v1/search", "POST", query);
}

export const getUserDataById = (userId) => {
    return apiInterfaceForJson("/user/v1/" + userId, "GET");
};

export const createUser = (userDetails) => {
    let userInfo = {
        'user_name': userDetails.userName,
        'first_name': userDetails.firstName,
        'last_name': userDetails.lastName,
        'email': userDetails.email,
        'mobile': userDetails.mobile,
        'password': userDetails.password,
        'role': userDetails.role,
        'facilityIds': userDetails.facilityIds,
        'is_active': true
    };
    return apiInterfaceForJson("/user/v1/", "POST", { user: userInfo, link: window.location.origin });
};

export const updateUser = (userDetails) => {
    let updateUserInfo = {
        '_id': userDetails.id,
        'user_name': userDetails.userName,
        'first_name': userDetails.firstName,
        'last_name': userDetails.lastName,
        'email': userDetails.email,
        'mobile': userDetails.mobile,
        'role': userDetails.role,
        'facilityIds': userDetails.facilityIds,
        'companyIds': userDetails.companyIds,
        'device_id': userDetails.deviceId,
        'require2FA': userDetails.require2FA,
    };
    if (userDetails.hasOwnProperty('is_active')) {
        updateUserInfo.is_active = userDetails.is_active;
    }
    if (userDetails.hasOwnProperty('last_logged_in')) {
        updateUserInfo.last_logged_in = userDetails.last_logged_in;
    }
    return apiInterfaceForJson("/user/v1/update", "POST", updateUserInfo);
};

export const updateUserBackgroundThemeColor = (userDetails) => {
    return apiInterfaceForJson("/user/v1/update", "POST", userDetails);
};

export const updateDevice = (deviceDetails) => {
    let userDeviceInfo = {
        '_id': deviceDetails.id,
        'device_id': deviceDetails.deviceId,
    };
    return apiInterfaceForJson("/user/v1/updateDevice", "POST", userDeviceInfo);
};

export const deleteUser = (userDetails) => {
    return apiInterfaceForJson("/user/v1/delete", "POST", userDetails);
};
