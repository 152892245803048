import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Checkbox, Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { getAllTestsForManagementPage } from "../../../../services/clinicPortalServices/testService";
import { getAllMaintenanceEvents } from "../../../../services/clinicPortalServices/maintenanceEventService";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import moment from "moment";
import { removeDuplicates, getRepeatInfoString } from "../../../../services/common/util";
import { updateLabInventory, createLabInventory, getLabInventoryDataById } from "../../../../services/clinicPortalServices/labInventoryService";

export default class LabInventoryDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			labInventoryId: this.props && this.props.labInventoryId ? this.props.labInventoryId : "",
			name: "",
			location: "",
			current_amount: "",
			measurement: "",
			minimum_threshold: "",
			automatic_depelte_amount: "",
			inventory_type: "manual_entry",
			automatic_deplete_type: "Allocated to Sample",
			associated_tests: [],
			associated_maintenances: [],
			vendors: [],
			repeat_interval: 1,
			repeat_unit: "week",
			repeat_week_info: [],
			specific_dates: [moment().format("D")],
			inventory_count_day_repeat_info: {},
			inventory_count_day_repeat_info_string: "",
			errors: [],
		};
	}

	componentDidMount() {
		if (this.state.labInventoryId) {
			this.loadDetails();
		}
		getAllTestsForManagementPage().then(res => {
			this.setState({ tests: res.data })
		})
		getAllMaintenanceEvents().then(res => {
			this.setState({ maintenanceEvents: res.data.filter(item => !item.is_deleted) })
		})
		const measurements = this.props.context.componentParent.state.rowData.map(item => {
			return item.measurement;
		})
		this.setState({ measurements: measurements })
	}

	loadDetails = () => {
		getLabInventoryDataById(this.state.labInventoryId).then(res => {
			const labInventory = res.data;
			this.setState({
				name: labInventory.name,
				location: labInventory.location,
				current_amount: labInventory.current_amount,
				measurement: labInventory.measurement,
				minimum_threshold: labInventory.minimum_threshold,
				automatic_depelte_amount: labInventory.automatic_depelte_amount,
				inventory_type: labInventory.inventory_type,
				automatic_deplete_type: labInventory.automatic_deplete_type,
				associated_tests: labInventory.associated_tests.map(item => item._id),
				associated_maintenances: labInventory.associated_maintenances.map(item => item._id),
				inventory_count_day_repeat_info: labInventory.inventory_count_day_repeat_info,
				repeat_interval: labInventory.inventory_count_day_repeat_info.repeat_interval,
				repeat_unit: labInventory.inventory_count_day_repeat_info.repeat_unit,
				repeat_week_info: labInventory.inventory_count_day_repeat_info.repeat_week_info,
				specific_dates: labInventory.inventory_count_day_repeat_info.specific_dates,
				vendors: labInventory.vendors,
			}, () => {
				this.setState({ inventory_count_day_repeat_info_string: getRepeatInfoString(this.state) })
			})
		})
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};


	handleVendorItemChange = (type, value, index) => {
		let vendors = this.state.vendors;
		vendors.map((item, i) => {
			if (i === index) {
				item[type] = value;
			}
			return null;
		})
		this.setState({ vendors: vendors });
	}

	saveChanges = () => {
		let errors = [];
		if (!this.state.name) {
			errors.push("name");
		}
		if (!this.state.location) {
			errors.push("location");
		}
		if (!this.state.current_amount) {
			errors.push("current_amount");
		}
		if (!this.state.measurement) {
			errors.push("measurement");
		}
		if (!this.state.minimum_threshold) {
			errors.push("minimum_threshold");
		}
		if (this.state.inventory_type === "automatic_entry") {
			if (this.state.automatic_deplete_type === "Allocated to Sample" && !this.state.associated_tests.length) {
				errors.push("associated_tests");
			}
			if (this.state.automatic_deplete_type === "Allocated to Maintenance Task" && !this.state.associated_maintenances.length) {
				errors.push("associated_maintenances");
			}
			if (!this.state.automatic_depelte_amount) {
				errors.push("automatic_depelte_amount");
			}
		} else {
			if (!this.state.inventory_count_day_repeat_info_string) {
				errors.push("inventory_count_day_repeat_info");
			}
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let labInventoryInfo = {
			name: this.state.name,
			location: this.state.location,
			current_amount: this.state.current_amount,
			measurement: this.state.measurement,
			minimum_threshold: this.state.minimum_threshold,
			automatic_depelte_amount: this.state.automatic_depelte_amount,
			inventory_type: this.state.inventory_type,
			automatic_deplete_type: this.state.automatic_deplete_type,
			associated_tests: this.state.automatic_deplete_type === "Allocated to Sample" ? this.state.associated_tests : [],
			associated_maintenances: this.state.automatic_deplete_type === "Allocated to Maintenance Task" ? this.state.associated_maintenances : [],
			inventory_count_day_repeat_info: this.state.inventory_count_day_repeat_info,
			vendors: this.state.vendors
		};

		if (this.state.labInventoryId) {
			labInventoryInfo._id = this.state.labInventoryId;
			updateLabInventory(labInventoryInfo).then(res => {
				this.props.handleClose();
				this.props.context.componentParent.loadGridData();
			})
		} else {
			createLabInventory(labInventoryInfo).then(res => {
				this.props.handleClose();
				this.props.context.componentParent.loadGridData();
			})
		}
	};


	handleCustomRecurrenceModal = (flag) => {
		this.setState({
			showCustomModal: flag
		});
		if (!this.state.inventory_count_day_repeat_info_string) {
			this.setState({
				repeat_interval: 1,
				repeat_unit: "week",
				repeat_week_info: [moment(this.state.start_time).weekday().toString()]
			})
		}
	}

	updateWeekInfo = (weekdayNumber) => {
		let repeat_week_info = this.state.repeat_week_info;
		if (repeat_week_info.includes(weekdayNumber)) {
			repeat_week_info = repeat_week_info.filter(item => item !== weekdayNumber);
		} else {
			repeat_week_info.push(weekdayNumber);
			repeat_week_info.sort((a, b) => Number(a) - Number(b));
		}
		this.setState({ repeat_week_info: repeat_week_info });
	}

	updateRepeatInfo = () => {
		if (this.state.repeat_unit === "specific_dates") {
			let specific_dates = this.state.specific_dates;
			specific_dates = removeDuplicates(specific_dates);
			specific_dates.sort((a, b) => a - b);
			if (!specific_dates.length) {
				return;
			}
			this.setState({ specific_dates: specific_dates }, () => {
				let inventory_count_day_repeat_info_string = getRepeatInfoString(this.state);
				this.setState({
					showCustomModal: false,
					inventory_count_day_repeat_info: {
						repeat_interval: this.state.repeat_interval,
						repeat_unit: this.state.repeat_unit,
						repeat_week_info: this.state.repeat_week_info,
						specific_dates: this.state.specific_dates
					},
					inventory_count_day_repeat_info_string: inventory_count_day_repeat_info_string
				})
			})
		} else {
			let inventory_count_day_repeat_info_string = getRepeatInfoString(this.state);
			this.setState({
				showCustomModal: false,
				inventory_count_day_repeat_info: {
					repeat_interval: this.state.repeat_interval,
					repeat_unit: this.state.repeat_unit,
					repeat_week_info: this.state.repeat_week_info,
					specific_dates: this.state.specific_dates
				},
				inventory_count_day_repeat_info_string: inventory_count_day_repeat_info_string
			})
		}
	}

	render() {
		return (
			<div>
				<form>
					<div className="row form-row">
						<div className="col-12 col-md-6">
							<div className="form-group">
								<TextField
									label="Name"
									name="name"
									value={this.state.name}
									onChange={this.handleChange}
								/>
								<div
									className={this.hasError("name") ? "inline-errormsg" : "hidden"}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<TextField
									label="Location"
									name="location"
									value={this.state.location}
									onChange={this.handleChange}
								/>
								<div
									className={this.hasError("location") ? "inline-errormsg" : "hidden"}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<TextField
									label="Current Amount"
									name="current_amount"
									value={this.state.current_amount}
									onChange={this.handleChange}
									disabled={this.state.labInventoryId ? true : false}
								/>
								<div
									className={this.hasError("current_amount") ? "inline-errormsg" : "hidden"}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<Autocomplete
									options={this.state.measurements && this.state.measurements.length ? this.state.measurements : []}
									value={this.state.measurement}
									onChange={(event, newValue) => {
										this.setState({
											measurement: newValue
										})
									}}
									freeSolo
									autoSelect
									getOptionLabel={(option) => option ? option : ""}
									renderInput={(params) => <TextField {...params} label="Measurement" variant="outlined" />}
								/>
								<div
									className={this.hasError("measurement") ? "inline-errormsg" : "hidden"}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<TextField
									label="Minimum Threshold"
									name="minimum_threshold"
									value={this.state.minimum_threshold}
									onChange={this.handleChange}
								/>
								<div
									className={this.hasError("minimum_threshold") ? "inline-errormsg" : "hidden"}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group d-flex">
								<div style={{ cursor: "pointer" }} onClick={(e) => {
									this.setState({ inventory_type: this.state.inventory_type === "manual_entry" ? "automatic_entry" : "manual_entry" })
								}}>
									<Checkbox checked={this.state.inventory_type === "automatic_entry" ? true : false} />Does this inventory item deplete automatically?
								</div>
							</div>
						</div>
						{
							this.state.inventory_type === "automatic_entry"
							&&
							<div className="col-12">
								<div className="row form-row">
									<div className={"col-12" + (this.state.automatic_deplete_type === "Allocated to Kit Creation By Lab" ? " col-md-6" : "")}>
										<div className="form-group">
											<Autocomplete
												options={["Allocated to Sample", "Allocated to Maintenance Task", "Allocated to Kit Creation By Lab"]}
												value={this.state.automatic_deplete_type}
												onChange={(event, newValue) => {
													this.setState({
														automatic_deplete_type: newValue
													})
												}}
												getOptionLabel={(option) => option ? option : ""}
												renderInput={(params) => <TextField {...params} label="Automatic Deplete Type" variant="outlined" />}
											/>
										</div>
									</div>
									{
										this.state.automatic_deplete_type === "Allocated to Sample" && this.state.tests && this.state.tests.length > 0
										&&
										<div className="col-12 col-md-6">
											<div className="form-group">
												<FormControl
													variant="outlined"
													style={{ width: "100%" }}
												>
													<InputLabel id="facility-label">Associated Tests</InputLabel>
													<Select
														value={this.state.associated_tests}
														onChange={(e) => this.handleChange(e)}
														label="Associated Tests"
														name="associated_tests"
														multiple
														renderValue={(selected) => {
															if (selected.length === this.state.tests.length) {
																return 'All Tests';
															} else {
																let displayStrings = [];
																selected.map(selectedId => {
																	displayStrings.push(this.state.tests.find(item => item._id === selectedId).name);
																	return null;
																})
																return displayStrings.join(', ');
															}
														}}
													>
														{
															this.state.tests.map((test, index) => {
																return (
																	<MenuItem
																		key={index}
																		value={test._id}
																	>
																		<Checkbox checked={this.state.associated_tests.includes(test._id) ? true : false} />
																		{test.name}
																	</MenuItem>
																);
															})
														}
													</Select>
												</FormControl>
												<div
													className={this.hasError("associated_tests") ? "inline-errormsg" : "hidden"}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
									}
									{
										this.state.automatic_deplete_type === "Allocated to Maintenance Task" && this.state.maintenanceEvents && this.state.maintenanceEvents.length > 0
										&&
										<div className="col-12 col-md-6">
											<div className="form-group">
												<FormControl
													variant="outlined"
													style={{ width: "100%" }}
												>
													<InputLabel id="facility-label">Associated Maintenances</InputLabel>
													<Select
														value={this.state.associated_maintenances}
														onChange={(e) => this.handleChange(e)}
														label="Associated Maintenances"
														name="associated_maintenances"
														multiple
														renderValue={(selected) => {
															let displayStrings = [];
															selected.map(selectedId => {
																let event = this.state.maintenanceEvents.find(item => item._id === selectedId);
																displayStrings.push(event.item_name + " - " + event.serial_number);
																return null;
															})
															return displayStrings.join(', ');
														}}
													>
														{
															this.state.maintenanceEvents.map((event, index) => {
																return (
																	<MenuItem
																		key={index}
																		value={event._id}
																	>
																		<Checkbox checked={this.state.associated_maintenances.includes(event._id) ? true : false} />
																		{event.item_name + " - " + event.serial_number}
																	</MenuItem>
																);
															})
														}
													</Select>
												</FormControl>
												<div
													className={this.hasError("associated_maintenances") ? "inline-errormsg" : "hidden"}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
									}
									<div className="col-12 col-md-6">
										<div className="form-group">
											<div style={{ display: "flex", alignItems: "center" }}>
												<TextField
													type="number"
													label={this.state.automatic_deplete_type === "Allocated to Kit Creation By Lab" ? "Automatic Deplete Amount Per 1 Kit" : "Automatic Deplete Amount"}
													name="automatic_depelte_amount"
													value={this.state.automatic_depelte_amount}
													onChange={this.handleChange}
													style={{ width: "70%" }}
												/>
												<label className="ml-2">{this.state.measurement}</label>
											</div>
											<div
												className={this.hasError("automatic_depelte_amount") ? "inline-errormsg" : "hidden"}
											>
												<i className="fa fa-exclamation-circle" aria-hidden="true">
													&nbsp;This field is required.
												</i>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						{
							this.state.inventory_type !== "automatic_entry"
							&&
							<div className="col-12">
								<div className="row form-row">
									<div className="col-12">
										<div className="form-group">
											<div className="btn btn-info" onClick={() => {
												this.handleCustomRecurrenceModal(true)
											}}>
												{this.state.inventory_count_day_repeat_info_string ? this.state.inventory_count_day_repeat_info_string : "Inventory Check Reminder Date Setting"}
											</div>
											<div
												className={this.hasError("inventory_count_day_repeat_info") ? "inline-errormsg" : "hidden"}
											>
												<i className="fa fa-exclamation-circle" aria-hidden="true">
													&nbsp;This field is required.
												</i>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="col-12">
							<label>Vendors <i className="fa fa-plus-circle btn" onClick={() => {
								let vendors = this.state.vendors;
								vendors.push({
									vendor_name: "",
									vendor_contact: "",
									vendor_phone: "",
									vendor_email: "",
									vendor_address: ""
								});
								this.setState({ vendors: vendors });
							}}></i></label>
							{
								this.state.vendors && this.state.vendors.length > 0
								&&
								this.state.vendors.map((vendor, index) => {
									return <div className="row input-box form-row" key={index}>
										<div className="col-12">
											<label style={{ margin: "10px 0px" }}>Vendor {index + 1} <i className="fa fa-times-circle btn" onClick={() => {
												let vendors = this.state.vendors;
												vendors.splice(index, 1);
												this.setState({ vendors: vendors });
											}}></i></label>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<TextField
													label="Name"
													value={vendor.vendor_name}
													onChange={(e) => this.handleVendorItemChange("vendor_name", e.target.value, index)}
												/>
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<TextField
													label="Contact"
													value={vendor.vendor_contact}
													onChange={(e) => this.handleVendorItemChange("vendor_contact", e.target.value, index)}
												/>
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<TextField
													label="Phone"
													value={vendor.vendor_phone}
													onChange={(e) => this.handleVendorItemChange("vendor_phone", e.target.value, index)}
												/>
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<TextField
													label="Email"
													value={vendor.vendor_email}
													onChange={(e) => this.handleVendorItemChange("vendor_email", e.target.value, index)}
												/>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<TextField
													label="Address"
													value={vendor.vendor_address}
													onChange={(e) => this.handleVendorItemChange("vendor_address", e.target.value, index)}
												/>
											</div>
										</div>
									</div>
								})
							}
						</div>

						<ModalStyled
							size="md"
							aria-labelledby="contained-modal-title-vcenter"
							centered
							show={this.state.showCustomModal}
							onHide={() => this.handleCustomRecurrenceModal(false)}
							backdrop="static"
						>
							<Modal.Header closeButton>
								<Modal.Title>Inventory Check Reminder Date Setting</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="row">
									<div className="form-group col-sm-12 d-flex align-items-center">
										<div style={{ whiteSpace: "nowrap" }}>Repeat every</div>
										<select className="form-control ml-2" name="repeat_unit" value={this.state.repeat_unit} onChange={this.handleChange} >
											<option value="every_day">day{Number(this.state.repeat_interval) > 1 ? "s" : ""}</option>
											<option value="week">week{Number(this.state.repeat_interval) > 1 ? "s" : ""}</option>
											<option value="specific_dates">month{Number(this.state.repeat_interval) > 1 ? "s" : ""} in following specific dates</option>
											<option value="end_of_month">end of month</option>
										</select>
									</div>
									{
										this.state.repeat_unit === "week"
										&&
										<div className="form-group col-sm-12">
											<label>
												Repeat on
											</label>
											<div className="d-flex align-items-center">
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("0")} >
													<Checkbox checked={this.state.repeat_week_info.includes("0") ? true : false} /> Su
												</div>
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("1")}>
													<Checkbox checked={this.state.repeat_week_info.includes("1") ? true : false} className="ml-2" /> Mo
												</div>
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("2")}>
													<Checkbox checked={this.state.repeat_week_info.includes("2") ? true : false} className="ml-2" /> Tu
												</div>
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("3")}>
													<Checkbox checked={this.state.repeat_week_info.includes("3") ? true : false} className="ml-2" /> We
												</div>
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("4")}>
													<Checkbox checked={this.state.repeat_week_info.includes("4") ? true : false} className="ml-2" /> Th
												</div>
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("5")}>
													<Checkbox checked={this.state.repeat_week_info.includes("5") ? true : false} className="ml-2" /> Fr
												</div>
												<div className="cursorPointer" onClick={() => this.updateWeekInfo("6")}>
													<Checkbox checked={this.state.repeat_week_info.includes("6") ? true : false} className="ml-2" /> Sa
												</div>
											</div>
										</div>
									}
									{
										this.state.repeat_unit === "specific_dates"
										&&
										<div className="form-group col-sm-12">
											<div>
												<label>Specific Dates&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => { let specific_dates = this.state.specific_dates; specific_dates.push("1"); this.setState({ specific_dates: specific_dates }) }}></i></label>
											</div>
											<div className="row">
												{
													this.state.specific_dates.map((item, index) => {
														let optionsDom = [];
														for (let i = 1; i < 29; i++) {
															optionsDom.push(<option key={i} value={i}>{i}</option>)
														}
														optionsDom.push(<option key="end_of_month" value="end of month">End Of Month</option>)
														return <div key={index} className="col-sm-6 form-group d-flex align-items-center justify-content-between">
															<select
																onChange={(e) => {
																	let specific_dates = this.state.specific_dates;
																	specific_dates = specific_dates.map((date, i) => {
																		if (i === index) {
																			return e.target.value
																		}
																		return date;
																	});
																	this.setState({ specific_dates: specific_dates })
																}}
																className="form-control"
																value={item}
																style={{ width: "calc(100% - 30px)" }}
															>
																{optionsDom}
															</select>
															<div>
																<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => {
																	let specific_dates = this.state.specific_dates;
																	specific_dates.splice(index, 1);
																	this.setState({ specific_dates: specific_dates })
																}}></i>
															</div>
														</div>
													})
												}
											</div>
										</div>
									}
								</div>
							</Modal.Body>
							<Modal.Footer>
								<div>
									<div className="btn btn-secondary" onClick={() => this.handleCustomRecurrenceModal(false)}>Cancel</div>
									<div className="btn btn-primary ml-2" onClick={() => this.updateRepeatInfo()}>Done</div>
								</div>
							</Modal.Footer>
						</ModalStyled>
					</div>
					<div className="row">
						<div
							className="col-12"
							style={{
								paddingTop: "10px",
								borderTop: "1px solid rgba(0,0,0,.2",
							}}
						>
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.saveChanges}
							>
								Save Changes
							</Button>
							<Button
								style={{ float: "right" }}
								variant="secondary"
								onClick={this.props.handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				</form >
			</div >
		);
	}
}
