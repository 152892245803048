import React, { Component } from "react";

class MessageQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill={this.props.color} height={this.props.height} width={this.props.width} viewBox="100 0 448 448">
                <path d="M576 128H448v128c0 52.87-43.13 95.99-96 95.99l-96 .0013v31.98c0 35.25 28.75 63.1 63.1 63.1l125.8-.0073l82.75 62.12C534.9 514.8 544 510.2 544 502.2v-54.24h32c35.25 0 64-28.75 64-63.1V191.1C640 156.7 611.3 128 576 128zM416 256V63.1C416 28.75 387.3 0 352 0H64C28.75 0 0 28.75 0 63.1v192C0 291.2 28.75 320 64 320l32 .0106v54.25c0 7.998 9.125 12.62 15.5 7.875l82.75-62.12L352 319.9C387.3 320 416 291.2 416 256zM208.5 268.5C194.7 268.5 184 257.8 184 244c0-13.77 10.71-24.47 24.47-24.47c13.77 0 24.47 10.71 24.47 24.47C232.9 257.8 222.2 268.5 208.5 268.5zM261.1 158.9l-35.18 21.41v1.529c0 9.941-8.412 18.35-18.35 18.35S190.1 191.8 190.1 181.9V169.6c0-6.117 3.059-12.23 9.176-16.06l43.59-26c5.354-3.059 8.412-8.412 8.412-14.53c0-9.178-7.648-16.82-16.82-16.82H194.7c-9.178 0-16.82 7.646-16.82 16.82c0 9.941-8.412 18.35-18.35 18.35c-9.941 0-18.35-8.412-18.35-18.35c0-29.82 23.71-53.53 53.53-53.53h39.76C264.3 59.53 288 83.23 288 113.1C288 131.4 278.1 149 261.1 158.9z" />
            </svg>
        )
    }
}

export default MessageQuestions