import React, { Component } from "react";
import { fetchPatientQrResponse } from "../../../services/patientSignupServices/patientSignupFormService";
import { fetchUnassignedPatientDetails } from "../../../services/clinicPortalServices/unassignedPatientService";

class PatientQrComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: this.props.patientId,
      dataStream: "",
      patientName: "",
    };
  }

  componentDidMount() {
    fetchPatientQrResponse(this.props.patientId).then((response) => {
      this.setState({ dataStream: response.data });
    });
  }

  fetchPatientDetails = () => {
    fetchUnassignedPatientDetails(this.state.patientId)
      .then((data) => {
        let patientDetails = data.data[0];
        let patientFullName = patientDetails.first_name + " " + patientDetails.last_name;
        this.setState({ patientName: patientFullName });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="img-data-stream">
          <img src={this.state.dataStream} alt="QR Code" />
        </div>
        <div>
          <div className="img-text-data-stream">
            <label className="label-patient-details">
              Patient Id: {this.state.patientId}{" "}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientQrComponent;
