import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Fragment } from 'react';
import {
	Box,
	ListItemText,
	Divider,
	List,
	Card,
	Button,
	Typography,
	ListItem,
} from '@mui/material';
import moment from "moment";
import { getOrderOverview } from "../../../services/clinicPortalServices/requisitionService.js";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../theme/customizedStyleComponents";
import store from "../../../redux/store/index.js";
import actions from "../../../redux/actions/index.js";
import { bindActions } from "redux-zero/utils/";

const boundActions = bindActions(actions, store);

class OrderOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showOrderOverview: false,
			orderOverview: []
		}
	};

	handleClickOrderOverview() {
		boundActions.startBlockUILoading();
		getOrderOverview().then(response => {
			boundActions.endBlockUILoading();
			if (response?.status === 200) {
				this.setState({
					orderOverview: response.data,
					showOrderOverview: true
				});
			} else {
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					text: "Sorry, we can't get overview for now.",
					icon: 'error',
					confirmButtonText: 'Ok'
				});
			}
		})
	}

	hideOrderOverview() {
		this.setState({ showOrderOverview: false });
	}

	render() {
		return (
			<>
				{
					/superadmin|^admin|labtech/.test(this.props.userRole.toLowerCase()) ?
						<div>
							<button
								className="btn btn-primary button-info-grid mr-1 mt-0"
								onClick={() => this.handleClickOrderOverview()}
								style={{ fontSize: "1vmax" }}
							>
								Order Overview
							</button>
						</div>
						:
						<div></div>
				}
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showOrderOverview}
					onHide={() => this.hideOrderOverview()}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title><strong>Today: {moment().format('MMM D YYYY')}</strong></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							this.state.orderOverview.map((orderType, i) => {
								return <Card key={i} sx={{ marginTop: '10px' }}>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										sx={{
											background: 'rgba(34, 51, 84, 0.02)'
										}}
										p={2}
									>
										<Box sx={{ fontSize: '17px' }}>
											<strong>{orderType.name}</strong>
										</Box>
									</Box>
									<List disablePadding>
										{
											orderType.items.map((item, index) => (
												<Fragment key={index}>
													<Divider />
													<ListItem
														sx={{
															justifyContent: 'space-between',
															display: { xs: 'flex', sm: 'flex' },
															py: 2,
															px: 2.5
														}}
													>
														<ListItemText
															sx={{
																flexGrow: 0,
																maxWidth: '56%',
																flexBasis: '56%'
															}}
															disableTypography
															primary={
																<Typography color="text.primary">
																	<strong>{item.name}</strong>
																</Typography>
															}
														/>
														<Box pl={0.5} display="flex" flexGrow={1} alignItems="center">
															<strong>{item.value}</strong>
														</Box>
													</ListItem>
												</Fragment>
											))
										}
									</List>
								</Card>
							})
						}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => this.hideOrderOverview()}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</>
		);
	}
}

export default OrderOverview;
