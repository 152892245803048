import React, { Component } from "react";
import Dropzone from "react-dropzone";
import ImageEditor from "./imageEditor";
import Swal from "sweetalert2";

class FileBrowse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      showFile: false,
      fileOpened: null,
      filesSelected: []
    };
  }

  fileDeleteBtn = () => {
    this.setState({ filesSelected: [], showFile: false });
    this.props.fileDelete();
  };

  onDrop = (files) => {
    if (files.length === 0) {
      Swal.fire({
        customClass: {
          container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
        },
        title: "Unsupported File Format",
        text: "This file is not supported. Please try with Jpeg or Png.",
        icon: "warning",
        confirmButtonText: 'Ok',
      })
    } else {
      this.setState({
        files,
        fileOpened: files[0]
      });
    }
  };

  handleCloseImageEditor = () => {
    this.setState({
      fileOpened: null
    });
  }

  uploadCropped = (croppedImage) => {
    this.setState({ showFile: true, filesSelected: [croppedImage] });
    this.props.handleFileChange(croppedImage);
    this.handleCloseImageEditor();
  }

  uploadOriginal = () => {
    const { fileOpened } = this.state;
    this.setState({ showFile: true, filesSelected: [fileOpened] });
    this.props.handleFileChange(fileOpened);
    this.handleCloseImageEditor();
  }



  render() {
    const { fileOpened, filesSelected } = this.state;
    const files = filesSelected.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <>
        <Dropzone
          onDrop={this.onDrop}
          accept={{ 'image/jpeg': ['.jpeg', '.bmp', '.png', '.webp', '.gif'] }}
          multiple={false}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <section className="container" style={{ padding: "0px" }}>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />

                <div className="dropzone-icon">
                  <i className="fas fa-cloud-upload-alt"></i>
                </div>
                <h4 className="dropzone-browse-files">Browse Files</h4>
                <p className="dropzone-browse-files">
                  {!isDragActive &&
                    "Drag and drop file here, or click to select files. It accepts only one jpeg/png file"}
                  {isDragReject && "File type not accepted, sorry!"}
                </p>
              </div>
              <aside>
                {this.state.showFile && this.props.file &&
                  <ul className="dropzone-files row">
                    {files}{" "}
                    <button onClick={this.fileDeleteBtn} className="dropzone-btn">
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </ul>}
              </aside>
            </section>
          )}
        </Dropzone>
        <ImageEditor
          handleClose={this.handleCloseImageEditor}
          fileOpened={fileOpened}
          uploadCropped={this.uploadCropped}
          uploadOriginal={this.uploadOriginal}
        />
      </>

    );
  }
}

export default FileBrowse;
