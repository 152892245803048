import { serviceConstants, templateIds } from "../common/constants";
import { apiInterfaceForJson } from "../common/apiManager";

export const searchInvoicesDashboard = (params) => {
    return apiInterfaceForJson("/invoice/v1/searchDashboard", "POST", { params: params });
};

export const getInvoiceData = () => {
    return apiInterfaceForJson("/invoice/v1", "GET");
};

export const getInvoiceById = (invoiceId) => {
    return apiInterfaceForJson("/invoice/v1/" + invoiceId, "GET");
};

export const updateInvoice = (invoiceDetails) => {
    return apiInterfaceForJson("/invoice/v1/", "PUT", invoiceDetails);
};

export const remindCompany = (email, invoiceId) => {
    let bodyTemplate = {
        subject: "Your invoice is ready",
        emailTxt: "Hi there, your invoice is ready to view on Benchlab, LLC.. Please review your invoice and contact Lola at creid@benchlabit.com to arrange payment.<br/>This link shows your invoice details.<br/>" + serviceConstants.HOST_NAME + "/invoiceView/" + invoiceId + "<br/><br/>Thanks, Benchlab, LLC."
    }
    const emailDataTemplate = {
        toEmail: email,
        subject: "Your invoice is ready",
        body: bodyTemplate,
        templateId: templateIds.htmlEmailWithLogo
    }

    return apiInterfaceForJson("/notifications/v1/emailTemplate", "POST", emailDataTemplate);
}

export const deleteInvoice = (invoiceId) => {
    let invoiceInfo = {
        '_id': invoiceId
    };
    return apiInterfaceForJson("/invoice/v1/delete", "POST", invoiceInfo);
};