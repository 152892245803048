import React, { Component } from "react";
import PropTypes from "prop-types";
import { Viewer } from '@react-pdf-viewer/core';
import { getPatientAuthToken } from "../../../../services/common/util";

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

class PdfViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				{
					this.props.pdfPath !== ""
						?
						(
							<div>
								<div className="pdfMobileView">
									<a
										style={{ textDecoration: "underline" }}
										href={this.props.pdfPath + `?${this.props.oneTimeToken ? 'isOneTime=1&&isDownload=1&&' : ''}` + new Date().getTime() + '&token=' + (this.props.oneTimeToken ? this.props.oneTimeToken : getPatientAuthToken())}
										target="_blank"
										rel="noreferrer"
									>
										Download COVID report
									</a>
									<i className="fa fa-download" aria-hidden="true"></i>
								</div>
								<div
									className="pdfWebView"
								>
									{
										this.props.oneTimeToken &&
										<div className='text-center'>
											<label>This is a one time link so you can view this result.
												<br />Please download it for your records, by clicking the download link below.
												<br />You will not be able to access it again once leave this page.</label>
											<br />
											<a
												style={{ textDecoration: "underline" }}
												href={this.props.pdfPath + `?${this.props.oneTimeToken ? 'isOneTime=1&&isDownload=1&&' : ''}` + new Date().getTime() + '&token=' + (this.props.oneTimeToken ? this.props.oneTimeToken : getPatientAuthToken())}
												target="_blank"
												rel="noreferrer"
											>
												Download COVID report
											</a>
											<i className="fa fa-download" aria-hidden="true"></i>
										</div>
									}
									{
										!this.props.oneTimeToken &&
										<>
											<label>If you are unable to see your results on the page,</label>
											<br />
											<label>Please download your result below instead</label>
											<br />
											<a
												style={{ textDecoration: "underline" }}
												href={this.props.pdfPath + `?${this.props.oneTimeToken ? 'isOneTime=1&&isDownload=1&&' : ''}` + new Date().getTime() + '&token=' + (this.props.oneTimeToken ? this.props.oneTimeToken : getPatientAuthToken())}
												target="_blank"
												rel="noreferrer"
											>
												Download COVID report
											</a>
											<i className="fa fa-download" aria-hidden="true"></i>
										</>
									}
									<br />
									<br />
									<div style={{ overflow: "auto" }}>
										<Viewer fileUrl={this.props.pdfPath + `?${this.props.oneTimeToken ? 'isOneTime=1&&isPdfView=1&&' : ''}isAPI=1&&` + new Date().getTime() + '&token=' + (this.props.oneTimeToken ? this.props.oneTimeToken : getPatientAuthToken())} />
									</div>
								</div>
							</div>
						)
						:
						(
							<div>
								<label>No results available</label>
							</div>
						)
				}
			</div>
		);
	}
}

PdfViewer.propTypes = {
	pdfPath: PropTypes.string,
};

export default PdfViewer;
