import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import BarcodeScanner from "../barcodeScanner";
import { fetchPhysiciansUnlocked } from "../../../../services/clinicPortalServices/physicianServices";
import {
    saveRequisitionChanges,
    generateUniqueKey,
    createRequisitionPDF
} from "../../../../services/clinicPortalServices/requisitionService";
import Barcode from "react-barcode";
import {
    FormControl,
    TextField,
    Checkbox,
    MenuItem,
    InputLabel,
    Select,
    FormLabel,
    IconButton,
    InputAdornment,
    Autocomplete
} from "@mui/material";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import {
    getUserRole,
    getUserAuthToken,
    getUserDetails
} from "../../../../services/common/util";
import { serviceConstants } from "../../../../services/common/constants";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import Swal from "sweetalert2";
import BlockUi from "@availity/block-ui";
import { FadeLoader } from "react-spinners";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import {
    specimenTypes,
    sampleTypes,
    loaderPositionStyle
} from "../../../../services/common/optionsData";

export default class CreateRequisitionViewFromPatient extends Component {
    constructor(props) {
        super(props);

        let collectorName = "";
        if (getUserDetails()) {
            const userInfo = JSON.parse(getUserDetails());
            collectorName = userInfo.first_name + " " + userInfo.last_name;
        }

        let patientDetails =
            this.props && this.props.patientDetails
                ? this.props.patientDetails
                : "";
        this.state = {
            show: this.props.show,
            patientDetails: patientDetails,
            patientName: patientDetails
                ? patientDetails.firstName + " " + patientDetails.lastName
                : "",
            mrn: patientDetails.mrn ? patientDetails.mrn : "",
            dob:
                patientDetails && patientDetails.dob
                    ? moment(patientDetails.dob, "YYYY-MM-DD").format(
                          "MM/DD/YYYY"
                      )
                    : "",
            gender: patientDetails ? patientDetails.sex : "U",
            facilities: [],
            providers: [],
            selectedProviderId: "",
            facilitySource: "",
            facilityAddress: {},
            facilityPhone: "",
            facilityDetails: {},
            specimenType: "",
            sampleType: "",
            diagnosisCode: [],
            symptoms: [],
            sample: "",
            collectedDate: moment().format("YYYY-MM-DDTHH:mm"),
            collectorName: collectorName,
            uniqueKey: "",
            requisition: "",
            testInfoCode: "",
            testInfoDescription: "",
            comments: "",
            pdfPath: "",
            resultDate: "",
            released: "",
            releasedBy: "",
            patientId: patientDetails ? patientDetails.patientId : "",
            facilityId: "",
            orderDate: moment(new Date(), "MM/DD/YYYY hh:mm A").format(
                "YYYYMMDDHHmmss"
            ),
            facilityOrderId: "",
            providerFirstName: "",
            providerLastName: "",
            providerNPI: "",
            providerSignatureImage: "",
            resultCode: "",
            resultCodeType: "",
            resultDesc: "",
            errors: [],
            showGeneratedBarcode: false,
            checkedSign: false,
            saving: false,
            showPrintModal: false,
            allTests: []
        };
        getAllTests({ type: "all" }).then((res) => {
            this.setState({ allTests: res.data });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setComponentState(nextProps);
    }

    toggleCheckedSign = () => {
        if (!this.state.checkedSign) {
            const errors = this.state.errors.filter(
                (error) => error !== "checkedSign"
            );
            this.setState({ errors: errors });
        } else {
            let errors = this.state.errors;
            errors.push("checkedSign");
            this.setState({ errors: errors });
        }
        this.setState({ checkedSign: !this.state.checkedSign });
    };

    setComponentState = (props) => {
        const patientDetails = props.patientDetails;
        let collectorName = "";
        if (getUserDetails()) {
            const userInfo = JSON.parse(getUserDetails());
            collectorName = userInfo.first_name + " " + userInfo.last_name;
        }
        this.setState({
            show: props.show,
            patientDetails: patientDetails,
            patientName: patientDetails
                ? patientDetails.firstName + " " + patientDetails.lastName
                : "",
            mrn: patientDetails.mrn ? patientDetails.mrn : "",
            dob:
                patientDetails && patientDetails.dob
                    ? moment(patientDetails.dob, "YYYY-MM-DD").format(
                          "MM/DD/YYYY"
                      )
                    : "",
            gender: patientDetails ? patientDetails.sex : "U",
            facilities: [],
            providers: [],
            selectedProviderId: "",
            facilitySource: "",
            facilityAddress: {},
            facilityPhone: "",
            facilityDetails: {},
            specimenType: "",
            sampleType: "",
            diagnosisCode: [],
            symptoms: [],
            sample: "",
            collectedDate: moment().format("YYYY-MM-DDTHH:mm"),
            collectorName: collectorName,
            uniqueKey: "",
            requisition: "",
            testInfoCode: "",
            testInfoDescription: "",
            comments: "",
            pdfPath: "",
            resultDate: "",
            released: "",
            releasedBy: "",
            patientId: patientDetails ? patientDetails.patientId : "",
            facilityId: "",
            orderDate: moment(new Date(), "MM/DD/YYYY hh:mm A").format(
                "YYYYMMDDHHmmss"
            ),
            facilityOrderId: "",
            providerFirstName: "",
            providerLastName: "",
            providerNPI: "",
            providerSignatureImage: "",
            resultCode: "",
            resultCodeType: "",
            resultDesc: "",
            errors: [],
            checkedSign: false,
            testId: "",
            testDetails: {}
        });
    };

    handleShow = () => {
        this.setState({ show: true }, () => {
            let elements = document.getElementsByClassName(
                "removeTabIndexModal"
            );
            if (elements.length) {
                for (let i = 0; i < elements.length; i++) {
                    let dialog = elements[i];
                    dialog.parentNode.removeAttribute("tabindex");
                }
            }
        });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    handleChange = (e) => {
        if (e.target && e.target !== undefined) {
            this.setState({ [e.target.name]: e.target.value });
            if (e.target.name === "selectedProviderId") {
                let index = this.state.providers.findIndex(
                    (i) => i._id === e.target.value
                );
                if (index > -1) {
                    this.setState({
                        providerFirstName:
                            this.state.providers[index].first_name,
                        providerLastName: this.state.providers[index].last_name,
                        providerNPI: this.state.providers[index].npi,
                        providerSignatureImage:
                            this.state.providers[index].signature_image
                    });
                }
            }
            if (e.target.name === "facilityId") {
                const facility = this.state.facilities.filter(
                    (fac) => fac._id === e.target.value
                )[0];
                this.setState({
                    facilitySource: facility.name,
                    facilityAddress: facility.address,
                    facilityPhone: facility.phone_no,
                    facilityDetails: facility,
                    tests:
                        facility.testIds && facility.testIds.length
                            ? facility.testIds
                            : []
                });
                if (this.state.testId) {
                    let testDetails = facility.testIds.find(
                        (item) => item._id === this.state.testId
                    );
                    this.setState({ testDetails: testDetails }, () => {
                        this.setTestInfo();
                    });
                }
                this.getPhysicians(e.target.value);
            }
            if (e.target.name === "symptoms") {
                const value = e.target.value;
                let symptoms = this.state.symptoms;
                if (value === "None") {
                    symptoms = ["None"];
                } else {
                    if (
                        symptoms.findIndex((element) => element === value) ===
                            -1 &&
                        e.target.checked
                    ) {
                        symptoms.push(value);
                    } else if (
                        symptoms.findIndex((element) => element === value) !==
                            -1 &&
                        !e.target.checked
                    ) {
                        symptoms.splice(
                            symptoms.findIndex((element) => element === value),
                            1
                        );
                    }
                    symptoms = symptoms.filter((symptom) => symptom !== "None");
                }
                this.setState({ symptoms: symptoms });
            }
            if (e.target.name === "testId") {
                this.setState(
                    {
                        testDetails:
                            this.state.facilityDetails.testIds &&
                            this.state.facilityDetails.testIds.length
                                ? this.state.facilityDetails.testIds.find(
                                      (item) => item._id === e.target.value
                                  )
                                : {}
                    },
                    () => {
                        this.setTestInfo();
                    }
                );
            }
            if (e.target.name === "specimenType") {
                this.setState(
                    {
                        sampleType: "",
                        testDetails: {}
                    },
                    () => {
                        this.setTestInfo();
                    }
                );
            }
            if (e.target.name === "sampleType") {
                this.setState(
                    {
                        testDetails: {}
                    },
                    () => {
                        this.setTestInfo();
                    }
                );
            }
        }
    };

    handleDateChange = (dateTime, type) => {
        if (type === "collectedDate") {
            this.setState({ collectedDate: dateTime });
        }
    };

    loadDataForModal = () => {
        fetchFacilitiesForOrders().then((res) => {
            let facilityId, facilitySource;
            if (
                getUserRole().toLowerCase() === "superadmin" ||
                getUserRole().toLowerCase() === "admin"
            ) {
                if (this.props.patientDetails.facilityId) {
                    facilityId = this.props.patientDetails.facilityId;
                    facilitySource = this.props.patientDetails.facilityName;
                } else {
                    facilityId = res.data[0]._id;
                    facilitySource = res.data[0].name;
                }
            } else {
                if (this.props.patientDetails.facilityId) {
                    let flag = false;
                    res.data.map((item) => {
                        if (item._id === this.props.patientDetails.facilityId) {
                            flag = true;
                        }
                        return null;
                    });
                    if (flag) {
                        facilityId = this.props.patientDetails.facilityId;
                        facilitySource = this.props.patientDetails.facilityName;
                    } else {
                        facilityId = res.data[0]._id;
                        facilitySource = res.data[0].name;
                    }
                } else {
                    facilityId = res.data[0]._id;
                    facilitySource = res.data[0].name;
                }
            }
            this.setState({
                facilities: res.data,
                facilityId: facilityId,
                facilitySource: facilitySource
            });
            this.getPhysicians(facilityId);

            if (res.data && res.data.length) {
                res.data.map((item) => {
                    if (item._id === facilityId) {
                        this.setState({
                            facilityAddress: item.address,
                            facilityPhone: item.phone_no,
                            facilityDetails: item
                        });
                    }
                    return null;
                });
            }
        });
    };

    setTestInfo = () => {
        this.setState({
            testId:
                this.state.testDetails && this.state.testDetails._id
                    ? this.state.testDetails._id
                    : "",
            testInfoDescription:
                this.state.testDetails && this.state.testDetails.description
                    ? this.state.testDetails.description
                    : "",
            testInfoCode:
                this.state.testDetails && this.state.testDetails.code
                    ? this.state.testDetails.code
                    : "",
            diagnosisCode: [],
            symptoms: []
        });
    };

    getPhysicians = (facilityId) => {
        fetchPhysiciansUnlocked(facilityId).then((response) => {
            this.setState({
                providers: response.data
            });
            if (response.data.length) {
                this.setState({
                    selectedProviderId: response.data[0]._id,
                    providerFirstName: response.data[0].first_name,
                    providerLastName: response.data[0].last_name,
                    providerNPI: response.data[0].npi,
                    providerSignatureImage: response.data[0].signature_image
                });
            } else {
                this.setState({
                    selectedProviderId: "",
                    providerFirstName: "",
                    providerLastName: "",
                    providerNPI: "",
                    providerSignatureImage: ""
                });
            }
        });
    };

    autoGenerateKey = () => {
        generateUniqueKey().then((response) => {
            this.setState({
                uniqueKey: response.data,
                sample: response.data,
                showGeneratedBarcode: true
            });
        });
    };

    handleRequisitionChanges = async () => {
        let errors = [];

        if (
            !(
                this.state.dob &&
                moment(this.state.dob, "MM/DD/YYYY").format("YYYYMMDD") <
                    moment().format("YYYYMMDD")
            )
        ) {
            errors.push("dob");
        }

        if (!this.state.mrn) {
            errors.push("mrn");
        }

        if (!this.state.selectedProviderId) {
            errors.push("selectedProviderId");
        }

        if (!this.state.facilityId) {
            errors.push("facilityId");
        }

        if (!this.state.sampleType) {
            errors.push("sampleType");
        }

        if (!this.state.testId) {
            errors.push("testId");
        }

        if (!this.state.diagnosisCode.length) {
            errors.push("diagnosisCode");
        }

        if (!this.state.collectorName) {
            errors.push("collectorName");
        }

        if (
            !this.state.collectedDate ||
            (this.state.collectedDate &&
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") ===
                    "Invalid date")
        ) {
            errors.push("collectedDate");
        }

        if (!this.state.sample) {
            errors.push("sample");
        }
        if (this.state.sample && this.state.sample.length < 6) {
            errors.push("sampleLength");
        }
        if (this.state.symptoms && !this.state.symptoms.length) {
            errors.push("symptoms");
        }

        if (!this.state.checkedSign) {
            errors.push("checkedSign");
        }
        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        this.setState({ saving: true, loading: true });
        if (this.state.testDetails.isPanel) {
            let orderIds = [];
            for (let i = 0; i < this.state.testDetails.testIds.length; i++) {
                let testId = this.state.testDetails.testIds[i];
                let suffix = "";
                let testDetails;
                this.state.allTests.map((test) => {
                    if (test._id === testId) {
                        suffix = test.name
                            ? test.name.substring(0, 1).toUpperCase()
                            : "";
                        if (suffix === "I") {
                            suffix = "F";
                        }
                        testDetails = test;
                    }
                    return null;
                });
                let sample = this.state.sample + suffix;
                const reqInfo = this.getParamsForRequisition(
                    sample,
                    testDetails,
                    "sub_bundle"
                );
                let createSubBundlesPromise = new Promise((resolve, reject) => {
                    saveRequisitionChanges(reqInfo)
                        .then((response) => {
                            const createPDFData = this.getParamsForPDF(
                                sample,
                                testId
                            );
                            createPDFData.lab_order_id =
                                response.data.lab_order_id;
                            let orderId = response.data._id;
                            createRequisitionPDF(createPDFData)
                                .then((response) => {
                                    resolve(orderId);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
                let orderId = await createSubBundlesPromise;
                orderIds.push(orderId);
            }
            //create bundle
            let testDetails = {
                _id: this.state.testId,
                code: this.state.testInfoCode,
                description: this.state.testInfoDescription
            };
            let reqInfo = this.getParamsForRequisition(
                this.state.sample,
                testDetails,
                "bundle"
            );
            reqInfo.orderIds = orderIds;
            saveRequisitionChanges(reqInfo).then((response) => {
                const createPDFData = this.getParamsForPDF(
                    this.state.sample,
                    this.state.testId
                );
                createPDFData.lab_order_id = response.data.lab_order_id;
                createRequisitionPDF(createPDFData).then((response) => {
                    this.setState({
                        saving: false,
                        loading: false,
                        showPrintModal: true
                    });
                });
            });
        } else {
            let testDetails = {
                _id: this.state.testId,
                code: this.state.testInfoCode,
                description: this.state.testInfoDescription
            };
            const reqInfo = this.getParamsForRequisition(
                this.state.sample,
                testDetails,
                "single"
            );
            saveRequisitionChanges(reqInfo).then((changedReqDetails) => {
                let createPDFData = this.getParamsForPDF(
                    this.state.sample,
                    this.state.testId
                );
                createPDFData.lab_order_id =
                    changedReqDetails.data.lab_order_id;
                createRequisitionPDF(createPDFData).then((response) => {
                    this.setState({
                        saving: false,
                        loading: false,
                        showPrintModal: true
                    });
                });
            });
        }
    };

    printRequisitionPDF = () => {
        window.open(
            serviceConstants.API_HOST_NAME +
                "/files/requisitionPDF/" +
                this.state.sample +
                "-" +
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") +
                ".pdf?" +
                new Date().getTime() +
                "&token=" +
                getUserAuthToken()
        );
    };

    hidePrintModal = () => {
        Swal.fire({
            title: "Did you Print?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ showPrintModal: false });
                this.props.context.componentParent.loadGridData();
                this.props.hideCreateRequisitionHandler();
            }
        });
    };

    getParamsForRequisition = (sample, testDetails, orderType) => {
        return {
            providerFirstName: this.state.providerFirstName,
            providerLastName: this.state.providerLastName,
            providerNPI: this.state.providerNPI,
            testInfoCode: testDetails.code,
            testInfoDescription: testDetails.description,
            specimenType: this.state.specimenType,
            sampleType: this.state.sampleType,
            diagnosisCode: this.state.diagnosisCode,
            sample: sample,
            collectedDate: this.state.collectedDate
                ? moment(this.state.collectedDate).format("YYYYMMDDHHmmss")
                : "",
            collectorName: this.state.collectorName,
            requisition: this.state.requisition,
            resultCode: this.state.resultCode,
            resultCodeType: this.state.resultCodeType,
            resultDesc: this.state.resultDesc,
            comments: this.state.comments,
            pdfPath: this.state.pdfPath,
            resultDate: this.state.resultDate,
            released: this.state.released,
            releasedBy: this.state.releasedBy,
            patientId: this.state.patientId,
            facilityId: this.state.facilityId,
            orderDate: this.state.orderDate,
            facilityOrderId: sample,
            facilitySource: this.state.facilitySource,
            mrn: this.state.mrn,
            requistionPDFPath:
                sample +
                "-" +
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") +
                ".pdf",
            patientInsuranceId:
                this.state.patientDetails &&
                this.state.patientDetails.insuranceId
                    ? this.state.patientDetails.insuranceId
                    : "",
            symptoms: this.state.symptoms,
            testId: testDetails._id,
            orderType: orderType
        };
    };

    getParamsForPDF = (sample, testId) => {
        return {
            patientName:
                this.state.patientDetails.firstName +
                " " +
                this.state.patientDetails.lastName,
            address1: this.state.patientDetails.address1
                ? this.state.patientDetails.address1
                : "",
            address2: this.state.patientDetails.address2
                ? this.state.patientDetails.address2
                : "",
            city: this.state.patientDetails.city,
            state: this.state.patientDetails.state,
            zip: this.state.patientDetails.zip,
            phoneNumber: this.state.patientDetails.phone
                ? this.state.patientDetails.phone
                : "",
            email: this.state.patientDetails.email
                ? this.state.patientDetails.email
                : "",
            dateOfBirth: moment(this.state.patientDetails.dob).format(
                "MM/DD/YYYY"
            ),
            gender: this.state.gender ? this.state.gender : "U",
            sample: sample,
            physicianName:
                this.state.providerFirstName +
                " " +
                this.state.providerLastName,
            facilityName: this.state.facilitySource,
            providerNPI: this.state.providerNPI,
            signature_image: this.state.providerSignatureImage,
            facilityAddress1:
                this.state.facilityAddress &&
                this.state.facilityAddress.address1
                    ? this.state.facilityAddress.address1
                    : "",
            facilityAddress2:
                this.state.facilityAddress &&
                this.state.facilityAddress.address2
                    ? this.state.facilityAddress.address2
                    : "",
            facilityCity:
                this.state.facilityAddress && this.state.facilityAddress.city
                    ? this.state.facilityAddress.city
                    : "",
            facilityState:
                this.state.facilityAddress && this.state.facilityAddress.state
                    ? this.state.facilityAddress.state
                    : "",
            facilityZip:
                this.state.facilityAddress && this.state.facilityAddress.zip
                    ? this.state.facilityAddress.zip
                    : "",
            facilityPhone: this.state.facilityPhone,
            specimenType: this.state.specimenType,
            sampleType: this.state.sampleType,
            collectedDate: moment(this.state.collectedDate).format(
                "MM/DD/YYYY"
            ),
            collectedTime: moment(this.state.collectedDate).format("hh:mm A"),
            requisition_pdf_path:
                sample +
                "-" +
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") +
                ".pdf",
            diagnosisCode: this.state.diagnosisCode,
            symptoms:
                this.state.symptoms && this.state.symptoms.length
                    ? this.state.symptoms
                    : [],
            insuranceProvider:
                this.state.patientDetails.insuranceProv1.toLowerCase() ===
                "other"
                    ? this.state.patientDetails.insuranceProv2
                    : this.state.patientDetails.insuranceProv1,
            insuranceMemberId: this.state.patientDetails.memberId
                ? this.state.patientDetails.memberId
                : "",
            insuranceGroupNumber: this.state.patientDetails.groupNumber
                ? this.state.patientDetails.groupNumber
                : "",
            insuranceRelationToInsured: this.state.patientDetails.relation
                ? this.state.patientDetails.relation
                : "",
            insuranceHolderName:
                this.state.patientDetails.insuredFirstName +
                " " +
                this.state.patientDetails.insuredLastName,
            insuranceDriverLicense: this.state.patientDetails.driverLicense
                ? this.state.patientDetails.driverLicense
                : "",
            insuranceDriverLicenseState: this.state.patientDetails
                .driverLicenseState
                ? this.state.patientDetails.driverLicenseState
                : "",
            testId: testId,
            mrn: this.state.mrn
        };
    };

    render() {
        return (
            <div>
                <ModalStyled
                    onEnter={this.loadDataForModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.props.hideCreateRequisitionHandler}
                    backdrop="static"
                    dialogClassName="removeTabIndexModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Create Requisition Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BlockUi
                            tag="div"
                            blocking={this.state.loading}
                            loader={
                                <FadeLoader
                                    height={12}
                                    width={5}
                                    radius={10}
                                    margin={2}
                                    css={loaderPositionStyle}
                                    color={
                                        "var(--primary-background-active-color)"
                                    }
                                />
                            }
                        >
                            <div className="row form-row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="Patient Name"
                                            name="patientName"
                                            value={this.state.patientName}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="MRN"
                                            name="mrn"
                                            value={this.state.mrn}
                                            readOnly
                                        />
                                        <div
                                            className={
                                                this.hasError("mrn")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="Date Of Birth"
                                            name="dob"
                                            value={this.state.dob}
                                            readOnly
                                        />
                                        <div
                                            className={
                                                this.hasError("dob")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;Date of birth is wrong,
                                                please edit it on patient edit
                                                popup.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="Gender"
                                            name="gender"
                                            value={this.state.gender}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Facility *"
                                            name="facilityId"
                                            value={this.state.facilityId}
                                            onChange={this.handleChange}
                                        >
                                            {this.state.facilities &&
                                                this.state.facilities.map(
                                                    (fac) => {
                                                        return (
                                                            <MenuItem
                                                                value={fac._id}
                                                                key={fac._id}
                                                            >
                                                                {fac.name}{" "}
                                                                {fac.archived ===
                                                                "archived"
                                                                    ? " ( Archived ) "
                                                                    : ""}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError("facilityId")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Physician *"
                                            name="selectedProviderId"
                                            value={
                                                this.state.selectedProviderId
                                            }
                                            onChange={this.handleChange}
                                        >
                                            {this.state.providers &&
                                                this.state.providers.map(
                                                    (prov) => {
                                                        return (
                                                            <MenuItem
                                                                value={prov._id}
                                                                key={prov._id}
                                                            >
                                                                {prov.first_name +
                                                                    " " +
                                                                    prov.last_name}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError(
                                                    "selectedProviderId"
                                                )
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Specimen Type *"
                                            name="specimenType"
                                            value={this.state.specimenType}
                                            onChange={this.handleChange}
                                        >
                                            {specimenTypes.map(
                                                (specimenType) => {
                                                    return (
                                                        <MenuItem
                                                            value={specimenType}
                                                            key={specimenType}
                                                        >
                                                            {specimenType}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError("specimenType")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Sample Type *"
                                            name="sampleType"
                                            value={this.state.sampleType}
                                            onChange={this.handleChange}
                                        >
                                            {sampleTypes[
                                                this.state.specimenType
                                            ] &&
                                                sampleTypes[
                                                    this.state.specimenType
                                                ].length > 0 &&
                                                sampleTypes[
                                                    this.state.specimenType
                                                ].map((sampleType) => {
                                                    return (
                                                        <MenuItem
                                                            value={sampleType}
                                                            key={sampleType}
                                                        >
                                                            {sampleType}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError("sampleType")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Test *"
                                            name="testId"
                                            value={this.state.testId}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value="" key="">
                                                Please select test
                                            </MenuItem>
                                            {this.state.facilityDetails &&
                                                this.state.facilityDetails
                                                    .testIds &&
                                                this.state.facilityDetails
                                                    .testIds.length > 0 &&
                                                this.state.facilityDetails.testIds.map(
                                                    (test) => {
                                                        if (
                                                            test.sampleType ===
                                                            this.state
                                                                .sampleType
                                                        ) {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        test._id
                                                                    }
                                                                    key={
                                                                        test._id
                                                                    }
                                                                >
                                                                    {test.name}
                                                                </MenuItem>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                )}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError("testId")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TextField
                                            label="Test Description"
                                            name="testInfoDescription"
                                            value={
                                                this.state.testInfoDescription
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TextField
                                            label="Sample *"
                                            name="sample"
                                            value={this.state.sample}
                                            onChange={this.handleChange}
                                        />
                                        <ModalStyled
                                            show={
                                                this.state.showGeneratedBarcode
                                            }
                                            onHide={() =>
                                                this.setState({
                                                    showGeneratedBarcode: false
                                                })
                                            }
                                        >
                                            <Modal.Header closeButton="true">
                                                <Modal.Title>
                                                    Auto Generated BarCode
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="text-center">
                                                    {this.state.patientName}
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex"
                                                    }}
                                                >
                                                    <Barcode
                                                        value={this.state.sample.toString()}
                                                        format="CODE39"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end"
                                                    }}
                                                >
                                                    <button
                                                        className="btn btn-secondary button-info-grid"
                                                        type="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                showGeneratedBarcode: false
                                                            });
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </Modal.Body>
                                        </ModalStyled>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <BarcodeScanner
                                                handleChange={this.handleChange}
                                            />
                                            <button
                                                className="btn btn-primary button-info-grid"
                                                type="button"
                                                onClick={this.autoGenerateKey}
                                            >
                                                Auto Generate
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                this.hasError("sample")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                this.hasError("sampleLength")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;Sample ID must be at least
                                                6 characters.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <MobileDateTimePicker
                                                    value={
                                                        this.state.collectedDate
                                                    }
                                                    onChange={(dateTime) =>
                                                        this.handleDateChange(
                                                            dateTime.$d,
                                                            "collectedDate"
                                                        )
                                                    }
                                                    renderInput={(props) => (
                                                        <TextField
                                                            {...props}
                                                            label="Collected Date *"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                            <EventIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "collectedDate"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;This field is
                                                        incorrect.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <Autocomplete
                                                    id="box-demo"
                                                    options={[]}
                                                    value={
                                                        this.state.collectorName
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        this.setState({
                                                            collectorName:
                                                                newValue
                                                        });
                                                    }}
                                                    freeSolo
                                                    getOptionLabel={(option) =>
                                                        option ? option : ""
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Name of Collector *"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "collectorName"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;This field is
                                                        required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <FormControl
                                                    variant="outlined"
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "5px"
                                                    }}
                                                >
                                                    <InputLabel id="diagnosisCode-label">
                                                        Diagnosis Code For Visit
                                                    </InputLabel>
                                                    <Select
                                                        labelId="diagnosisCode-label"
                                                        id="diagnosisCode-select"
                                                        value={
                                                            this.state
                                                                .diagnosisCode
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        label="Diagnosis Code For Visit"
                                                        className={
                                                            this.hasError(
                                                                "diagnosisCode"
                                                            )
                                                                ? "select is-invalid"
                                                                : ""
                                                        }
                                                        name="diagnosisCode"
                                                        multiple
                                                        renderValue={(
                                                            selected
                                                        ) => {
                                                            return selected.join(
                                                                ", "
                                                            );
                                                        }}
                                                    >
                                                        {this.state
                                                            .testDetails &&
                                                            this.state
                                                                .testDetails
                                                                .name &&
                                                            this.state.testDetails.diagnosis_codes.map(
                                                                (code) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                code.code
                                                                            }
                                                                            value={
                                                                                code.code +
                                                                                " " +
                                                                                code.text
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                checked={
                                                                                    this.state.diagnosisCode.includes(
                                                                                        code.code +
                                                                                            " " +
                                                                                            code.text
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                            />
                                                                            {code.code +
                                                                                " " +
                                                                                code.text}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                    </Select>
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "diagnosisCode"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <FormControl>
                                            <FormLabel>Symptoms *</FormLabel>
                                            {this.state.testDetails &&
                                                this.state.testDetails
                                                    .symptoms &&
                                                this.state.testDetails.symptoms.map(
                                                    (item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .symptoms &&
                                                                        this.state.symptoms.findIndex(
                                                                            (
                                                                                elem
                                                                            ) =>
                                                                                elem ===
                                                                                item
                                                                        ) !== -1
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    value={item}
                                                                    name="symptoms"
                                                                />
                                                                <span>
                                                                    {item}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            <div
                                                className={
                                                    this.hasError("symptoms")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;This field is
                                                    required.
                                                </i>
                                            </div>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <MenuItem onClick={this.toggleCheckedSign}>
                                        <Checkbox
                                            checked={this.state.checkedSign}
                                        />
                                        <div
                                            style={{
                                                whiteSpace: "break-spaces"
                                            }}
                                        >
                                            I authorize this requisition and the
                                            use of my electronic signature
                                        </div>
                                    </MenuItem>
                                    <div
                                        className={
                                            this.hasError("checkedSign")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                        style={{
                                            width: "auto",
                                            fontSize: "15px"
                                        }}
                                    >
                                        <i
                                            className="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        >
                                            &nbsp;&nbsp;You must check the
                                            authorize box before creating/saving
                                            a requisition
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.props.hideCreateRequisitionHandler}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleRequisitionChanges}
                        >
                            {this.state.saving && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                ></i>
                            )}
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
                <ModalStyled
                    show={this.state.showPrintModal}
                    onHide={() => this.hidePrintModal()}
                >
                    <Modal.Header closeButton="true">
                        <Modal.Title>Please Print Necessary Items</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="text-center mb-3">
                            Thank you, the requisition was created successfully
                            and has been sent to our lab.
                        </h3>
                        <div className="row">
                            <div className="text-center col-12 mb-3">
                                <button
                                    className="btn btn-info"
                                    type="button"
                                    style={{ width: "250px" }}
                                    onClick={() => {
                                        window.open(
                                            serviceConstants.API_HOST_NAME +
                                                "/files/barcodeImagePDF/" +
                                                this.state.sample +
                                                ".pdf?" +
                                                new Date().getTime() +
                                                "&token=" +
                                                getUserAuthToken()
                                        );
                                    }}
                                >
                                    <i
                                        className="fa fa-barcode"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    Print Barcode
                                </button>
                            </div>
                            <div className="text-center col-12 mb-3">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    style={{ width: "250px" }}
                                    onClick={() => this.printRequisitionPDF()}
                                >
                                    <i
                                        className="fa fa-file-pdf"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    Print Requisition PDF
                                </button>
                            </div>
                            <div className="text-center col-12">
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    style={{ width: "250px" }}
                                    onClick={() => this.hidePrintModal()}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}
