import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { getOneTimeResult, updateResultLink } from "../../../services/clinicPortalServices/orderEditService";
import Swal from "sweetalert2";
import PdfViewer from "../dashboard/pdfViewer/index";
import { serviceConstants } from "../../../services/common/constants";
import { isValidEmail } from "../../../services/common/util";
import moment from 'moment';
import '@react-pdf-viewer/core/lib/styles/index.css';

class PatientOneTimeViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usedPatient: null,
			key: 1,
			result: null,
			pdfPath: "",
			selectedDateId: "",
			value: "",
			token: '',
			showForm: false,
			showResult: false,
			firstName: '',
			lastName: '',
			email: '',
			errors: []
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleSelect(key) {
		this.setState({ key });
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { firstName, lastName, email } = this.state;

		let errors = [];

		if (!firstName) {
			errors.push("firstName");
		}

		if (!lastName) {
			errors.push("lastName");
		}

		if (!email) {
			errors.push("email");
		}
		if (email && !isValidEmail(email)) {
			errors.push("wrongEmail");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}
		const params = {
			_id: this.props.resultId,
			is_viewed: true,
			viewed_date: moment().format('YYYYMMDDHHmmss'),
			$push: {
				visited: {
					first_name: firstName,
					last_name: lastName,
					email: email,
					visited_date: moment().format('YYYYMMDDHHmmss')
				}
			}
		}

		updateResultLink(params)
			.then((response) => {
				const data = response.data;
				if (data.success)
					this.setState({ showResult: true, showForm: false });
			})
	};

	componentDidMount() {
		getOneTimeResult(this.props.resultId)
			.then((response) => {
				if (response && response.data && !response.data.is_viewed) {
					this.setState({ token: response.token });
					this.initData(response.data);
				}
				else {
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
						},
						title: 'The result is not available!',
						text: `You can't get the result on this page. Please contact Benchlab, LLC..`,
						icon: 'error',
						confirmButtonText: 'Ok'
					}).then((result) => {
						this.props.history.push("/");
					});
				}
			})
	}

	initData = (resultInfo) => {
		let orderDetail = resultInfo.order_id;
		let orderResults = [];
		if (orderDetail.results && orderDetail.results.pdf_path) {
			orderResults.push(orderDetail);
		}
		const pdfPathTemp = `${serviceConstants.API_HOST_NAME}${orderResults[0].results.pdf_path}`;
		this.setState({
			result: orderResults,
			selectedDateId: orderResults[0]._id,
			pdfPath: pdfPathTemp,
			usedPatient: resultInfo.patient_id,
			showForm: true,
		});
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	render() {
		return (
			<div className="container-fluid" style={{
				minHeight: "calc(100vh - 220px)",
				padding: "15px",
			}}>
				{
					this.state.showForm
					&&
					<div className="row form-row" style={{ maxWidth: '800px', margin: 'auto' }}>
						<div className="col-12 col-md-12 py-4 text-center">
							<h5>You have come to this page for your result due to communication you had with Benchlab, LLC.. <br />Please enter your first, last and email address so we can have on record your consent</h5>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>First Name</label>
								<input
									type="text"
									name="firstName"
									value={this.state.firstName}
									onChange={this.handleChange}
									required
									className={this.hasError("firstName") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("firstName") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Last Name</label>
								<input
									type="text"
									name="lastName"
									value={this.state.lastName}
									onChange={this.handleChange}
									required
									className={this.hasError("lastName") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("lastName") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12">
							<div className="form-group">
								<label>Email</label>
								<input
									type="text"
									name="email"
									value={this.state.email}
									onChange={this.handleChange}
									required
									className={this.hasError("email") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("email") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
								<div className={this.hasError("wrongEmail") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;Email is invalid.
									</i>
								</div>
							</div>
						</div>
						<Button variant="info" onClick={this.handleSubmit}>
							Submit
						</Button>
					</div>
				}

				{
					this.state.showResult
					&&
					<div className="row justify-content-center">
						<div className="col-md-7 col-lg-8 col-xl-9">
							<div className="card schedule-widget mb-0">
								<div className="schedule-header">
									<div className="schedule-nav">
										{
											this.state.result != null
												?
												(
													<div>
														<h4 className="text-info text-center" style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}>Results</h4>
														<PdfViewer
															pdfPath={this.state.pdfPath}
															value={this.state.value}
															oneTimeToken={this.state.token}
														/>
													</div>
												) : (
													<label> {this.state.loading ? "Loading Results..." : "No results available"} </label>
												)
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default PatientOneTimeViewer;
