import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import PatientDetails from './patientDetails';
import InsuranceInfoPopup from "./insuranceInfoPopup";
import { fetchPatientInsuranceData, fetchPatientDataById } from "../../../../services/clinicPortalServices/patientSearchService";
import { easyUpdatePatient } from "../../../../services/clinicPortalServices/patientEditService";
import { easyUpdateInsurance } from "../../../../services/clinicPortalServices/patientInsuranceService";
import { getUserRole } from "../../../../services/common/util";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import PatientViewPopup from "./patientViewPopup";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";
import { templateIds } from "../../../../services/common/constants";
import { sendEmailTemplate } from "../../../../services/common/service";
import IncorrectInfoPopup from "./incorrectInfoPopup";
import moment from "moment";
import _ from 'lodash';

const boundActions = bindActions(actions, store);

export default class MasterBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showInsurancePopup: false,
			showIncorrectActionPopup: false,
			incorrectItems: [],
			originalIncorrectItems: [],
			errors: []
		};
	}

	handleShow = () => {
		this.setState({ show: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	};

	handleCreateRequisition = () => {
		this.props.context.componentParent.onQrCodeScanHandler(this.props.data._id);
	}

	handleShowInsurancePopup = () => {
		this.setState({ showInsurancePopup: true });
	}

	handleClose = () => {
		this.setState({ show: false, showIncorrectActionPopup: false, incorrectItems: [], errors: [] });
	};

	handleIncorrectAction = () => {
		boundActions.startBlockUILoading();
		this.setState({ showIncorrectActionPopup: true });
		fetchPatientInsuranceData(this.props.data._id)
			.then((response) => {
				boundActions.endBlockUILoading();
				let incorrectItems = [{
					type: "Patient",
					data: this.props.data,
					is_incorrect: this.props.data.is_incorrect ? this.props.data.is_incorrect : false,
					incorrect_note: this.props.data.incorrect_note ? this.props.data.incorrect_note : "",
					incorrect_history: this.props.data.incorrect_history && this.props.data.incorrect_history ? this.props.data.incorrect_history : []
				}];
				if (response.data && response.data.length) {
					response.data.map(insurance => {
						incorrectItems.push({
							type: "Insurance",
							data: insurance,
							is_incorrect: insurance.is_incorrect ? insurance.is_incorrect : false,
							incorrect_note: insurance.incorrect_note ? insurance.incorrect_note : "",
							incorrect_history: insurance.incorrect_history && insurance.incorrect_history.length ? insurance.incorrect_history : []
						})
						return null;
					})
				}
				this.setState({ incorrectItems: incorrectItems, originalIncorrectItems: _.cloneDeep(incorrectItems) });
			})
	}

	handleChange = (e, index) => {
		let incorrectItems = this.state.incorrectItems;
		incorrectItems.map((item, i) => {
			if (i === index) {
				if (e.target.name === 'is_incorrect') {
					item.is_incorrect = !item.is_incorrect;
				} else {
					item[e.target.name] = e.target.value;
				}
			}
			return null;
		})
		this.setState({ incorrectItems: incorrectItems });
	}

	handleIncorrectItems = async () => {
		let errors = [];
		this.state.incorrectItems.map((incorrectItem, index) => {
			if (incorrectItem.is_incorrect && !incorrectItem.incorrect_note) {
				errors.push(index);
			}
			return null;
		})
		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			for (let i = 0; i < this.state.incorrectItems.length; i++) {
				let incorrectItem = this.state.incorrectItems[i];
				let handleItemPromise = new Promise((resolve, reject) => {
					const incorrectHistory = incorrectItem.incorrect_history;
					if (this.state.originalIncorrectItems.find(item => item.data._id === incorrectItem.data._id).is_incorrect !== incorrectItem.is_incorrect) {
						if (incorrectItem.is_incorrect) {
							incorrectHistory.push({
								status: 'INCORRECT',
								note: incorrectItem.incorrect_note,
								fixed_by: '',
								time: moment().format("YYYYMMDDHHmmss")
							})
						} else {
							incorrectHistory.push({
								status: 'CORRECT',
								note: incorrectItem.incorrect_note,
								fixed_by: 'FACILITY',
								time: moment().format("YYYYMMDDHHmmss")
							})
						}
					}
					if (incorrectItem.type === "Patient") {
						const patientDetails = {
							_id: incorrectItem.data._id,
							is_incorrect: incorrectItem.is_incorrect,
							incorrect_note: incorrectItem.is_incorrect ? incorrectItem.incorrect_note : "",
							incorrect_history: incorrectHistory
						}
						easyUpdatePatient(patientDetails).then(res => {
							resolve();
						})
					} else {
						const insuranceDetails = {
							_id: incorrectItem.data._id,
							patient: incorrectItem.data.patient,
							is_incorrect: incorrectItem.is_incorrect,
							incorrect_note: incorrectItem.is_incorrect ? incorrectItem.incorrect_note : "",
							incorrect_history: incorrectHistory
						}
						easyUpdateInsurance(insuranceDetails).then(res => {
							resolve();
						})
					}
				})
				await handleItemPromise;
			}

			if (this.props.data.email && this.state.incorrectItems.find(item => item.is_incorrect)) {
				const bodyTemplate = {
					subject: "Important Message from Benchlab, LLC. - Update Required for Your Patient or Insurance Information",
					emailTxt: "Dear " + this.props.data.first_name + ",<br/><br/>We are writing to inform you that there appears to be an inconsistency with the patient or insurance information provided by you or your healthcare provider. This discrepancy is affecting the processing of insurance claims related to your recent healthcare services for a test that our laboratory has provided you a result for.<br/><br/>To ensure your claims are processed efficiently and to avoid any potential delays in coverage, we kindly request that you log in to your patient portal at your earliest convenience to review and update your information. Accurate and up-to-date information is crucial for the smooth handling of your insurance billing.<br/><br/>To update your information, please follow these steps:<br/><br/>Visit our patient portal: https://benchlabit.com/patientportal/<br/>Log in using your credentials.<br/>You will see a pop up describing the issue.<br/>Review and update any incorrect or outdated information.<br/>Save the changes.<br/><br/>Alternatively you can contact your provider and request they update the information in our system for you.<br/><br/>Your prompt attention to this matter is greatly appreciated. Ensuring the accuracy of your information helps us to provide you with timely and efficient healthcare services.<br/><br/>Thank you for your cooperation and understanding,<br/>The Benchlab, LLC. Team"
				}
				const emailDataTemplate = {
					toEmail: this.props.data.email,
					subject: "Important Message from Benchlab, LLC. - Update Required for Your Patient or Insurance Information",
					body: bodyTemplate,
					templateId: templateIds.htmlEmailWithLogo
				}
				sendEmailTemplate(emailDataTemplate);
			}

			this.handleClose();
			fetchPatientDataById(this.props.data._id).then(res => {
				if (res && res.data && res.data[0]) {
					this.props.context.componentParent.updateGridRow(this.props.node.id, res.data[0]);
				}
			})
		}
	}

	handlePatientInfoShow = () => {
		this.setState({ showPatientViewPopup: true })
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Patient
		</Tooltip>
	);

	renderTooltipEditInsurance = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Insurance Info
		</Tooltip>
	);

	renderTooltipCreateRequisition = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Create Requisition
		</Tooltip>
	);

	renderTooltipIncorrectAction = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{getUserRole() && ["SuperAdmin", "Admin"].includes(getUserRole()) ? "Mark as Incorrect" : "Incorrect Info"}
		</Tooltip>
	)

	renderTooltipPatientInfo = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Full Info
		</Tooltip>
	)

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button
						onClick={this.handleShow}
						style={{ border: "none", backgroundColor: "transparent" }}
					>
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEditInsurance}
				>
					<button
						onClick={this.handleShowInsurancePopup}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-address-card"></i>
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipCreateRequisition}
				>
					<button
						onClick={this.handleCreateRequisition}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-file-medical"></i>
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipIncorrectAction}
				>
					<button
						onClick={this.handleIncorrectAction}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-exclamation-triangle"></i>
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipPatientInfo}
				>
					<button
						onClick={this.handlePatientInfoShow}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-eye"></i>
					</button>
				</OverlayTrigger>

				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Patient Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.props.data}
							nodeId={this.props.node.id}
							handleClose={this.handleClose}
							context={this.props.context}
						/>
					</Modal.Body>
				</ModalStyled>
				<InsuranceInfoPopup
					data={this.props.data}
					nodeId={this.props.node.id}
					context={this.props.context}
					show={this.state.showInsurancePopup}
					handleClose={() => { this.setState({ showInsurancePopup: false }) }}
				/>
				<IncorrectInfoPopup
					show={this.state.showIncorrectActionPopup}
					onHide={this.handleClose}
					incorrectItems={this.state.incorrectItems}
					handleChange={this.handleChange}
					handleIncorrectItems={this.handleIncorrectItems}
					errors={this.state.errors}
					type="patientPage"
				/>
				<PatientViewPopup
					show={this.state.showPatientViewPopup}
					handleClose={() => this.setState({ showPatientViewPopup: false })}
					data={this.props.data}
					context={this.props.context}
				/>
			</div >
		);
	}
}
