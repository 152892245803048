import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import NotificationTypeDetails from "./notificationTypeDetails";

export default class EditBtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            notificationTypeId: props.data._id,
            errors: []
        };
    }

    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    renderTooltipEdit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    );

    render() {
        return (
            <div>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipEdit}
                >
                    <button
                        onClick={this.handleShow}
                        className="edit-order-btn"
                    >
                        <i className="fas fa-pen"></i>
                    </button>
                </OverlayTrigger>
                <ModalStyled
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Notification Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NotificationTypeDetails
                            notificationTypeId={this.state.notificationTypeId}
                            context={this.props.context}
                            handleClose={this.handleClose}
                        />
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}
