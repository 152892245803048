import React, { Component } from "react";
import { Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import FacilityDetails from "./facilityDetails";
import { archiveFacility, unArchiveFacility, deleteFacility } from "../../../../services/clinicPortalServices/facilityServices";
import { getUserRole } from "../../../../services/common/util";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			facilityId: props.data.facility._id,
		};
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Facility
		</Tooltip>
	);

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Facility
		</Tooltip>
	);

	handleShow = () => {
		this.setState({ show: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	};

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this facility!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				const facilityInfo = {
					_id: this.state.facilityId,
					name: this.props.data.facility.name
				};
				deleteFacility(facilityInfo)
					.then((response) => {
						this.props.context.componentParent.loadGridData();
					});
			}
		});
	}

	renderTooltipArchive = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Archive Facility
		</Tooltip>
	)

	archiveFacility = () => {
		Swal.fire({
			title: 'Archive Facility',
			text: 'You are about to archive ' + this.props.data.facility.name,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				archiveFacility(this.props.data.facility._id).then(facilityRes => {
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	}

	renderTooltipUnArchive = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Release Archived Facility
		</Tooltip>
	)

	unArchiveFacility = () => {
		unArchiveFacility(this.props.data.facility._id).then(facilityRes => {
			this.props.context.componentParent.loadGridData();
		})
	}

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div style={{ textAlign: "left" }}>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>
				{/* {
					!(/customerserviceuser|^admin/.test(getUserRole().toLowerCase())) &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipDelete}
					>
						<button onClick={this.handleDelete} className="edit-order-btn">
							<i className="fas fa-trash"></i>
						</button>
					</OverlayTrigger>
				} */}
				{
					!(/customerserviceuser|^admin/.test(getUserRole().toLowerCase()))
					&&
					<>
						{
							this.props.data.facility.archived === "archived"
							&&
							<>
								<OverlayTrigger
									placement="top"
									delay={{ show: 50, hide: 100 }}
									overlay={this.renderTooltipUnArchive}
								>
									<button onClick={this.unArchiveFacility} className="edit-order-btn">
										<i className="fas fa-lock-open"></i>
									</button>
								</OverlayTrigger>
							</>
						}
						{
							this.props.data.facility.archived !== "archived"
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipArchive}
							>
								<button onClick={this.archiveFacility} className="edit-order-btn">
									<i className="fas fa-lock"></i>
								</button>
							</OverlayTrigger>
						}
					</>
				}
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Facility Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FacilityDetails
							facilityId={this.state.facilityId}
							handleClose={this.handleClose}
							context={this.props.context}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
