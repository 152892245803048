import { apiInterfaceForJson } from "../common/apiManager";

export const saveOrderEditData = (editParams) => {
    let updatedOrder = {
        test_info: {
            sample: editParams.sample,
            collected: editParams.collectedDate,
            received: editParams.receivedDate,
            code: editParams.code,
            description: editParams.description,
            specimenType: editParams.specimenType,
            sampleType: editParams.sampleType,
            collector_name: editParams.collectorName
        },
        _id: editParams.orderId,
        facility_order_id: editParams.sample
    };
    if (editParams.results) {
        updatedOrder.results = editParams.results;
        updatedOrder.results.released = editParams.released;
        if (editParams.result) {
            updatedOrder.results.value = editParams.result;
        }
    }
    if (editParams.updateRequisition) {
        updatedOrder.facility_id = editParams.facility_id;
        updatedOrder.facility_source = editParams.facility_source;
        updatedOrder.provider = {
            first_name: editParams.providerFirstName,
            last_name: editParams.providerLastName,
            npi: editParams.providerNPI
        };
    }
    if (editParams.requisition_pdf_path) {
        updatedOrder.requisition_pdf_path = editParams.requisition_pdf_path;
    }
    if (editParams.diagnosis_code) {
        updatedOrder.diagnosis_code = editParams.diagnosis_code;
    }
    if (editParams.patient_id) {
        updatedOrder.patient_id = editParams.patient_id;
    }
    if (editParams.symptoms && editParams.symptoms.length) {
        updatedOrder.symptoms = editParams.symptoms;
    }
    if (editParams.is_notify) {
        updatedOrder.is_notify = editParams.is_notify;
    }
    return apiInterfaceForJson("/order/v1/", "POST", updatedOrder);
};

export const updateResultPDF = (params) => {
    let updatedOrder = {
        orderId: params.orderId,
        patient_name: params.patientName,
        date_of_birth: params.dob,
        mrn: params.mrn,
        email: params.email,
        mobile: params.mobile,
        requisition: params.requisition,
        sample: params.sample,
        collected: params.collectedDate,
        received: params.receivedDate,
        provider_name: params.provider,
        facility_source: params.facility_source,
        value: params.result,
        values: params.resultValues,
        pdf_path: params.pdfPath,
        released_date: params.released,
        released_by: params.releasedBy,
        test_type: params.testType,
        passport: params.passport,
        custom_info: params.custom_info,
        isEnvironmental: params.isEnvironmental,
        facility_email: params.facility_email,
        facility_id: params.facility_id,
        notify: params.notify,
        notify_from: "MRN",
        testId: params.test_id
    };
    return apiInterfaceForJson("/order/v1/update/", "POST", updatedOrder);
};

export const fetchOrderFaxData = (orderId) => {
    return apiInterfaceForJson("/order/v1/fax/", "POST", { order_id: orderId });
};

export const getOrderDataById = (orderId) => {
    return apiInterfaceForJson("/order/v1/" + orderId, "GET");
};

export const updateOrderManualAction = (params) => {
    return apiInterfaceForJson(
        "/order/v1/updateOrderManualAction",
        "POST",
        params
    );
};

export const bulkDownloadZipForPDF = (params) => {
    return apiInterfaceForJson(
        "/order/v1/bulkDownloadZipForPDF",
        "POST",
        params
    );
};

export const orderEasyUpdate = (params) => {
    return apiInterfaceForJson("/order/v1/easyUpdate", "POST", params);
};

export const createResultLink = (params) => {
    return apiInterfaceForJson(
        "/resultlink/v1/createResultLink",
        "POST",
        params
    );
};

export const orderUpdateNotifyState = (params) => {
    return apiInterfaceForJson(
        "/order/v1/updateOrderNotifyState",
        "POST",
        params
    );
};

export const updateResultLink = (params) => {
    return apiInterfaceForJson(
        "/resultlink/v1/updateResultLink",
        "POST",
        params
    );
};

export const getOneTimeResult = (result_id) => {
    return apiInterfaceForJson("/resultlink/v1/getResultLink", "POST", {
        _id: result_id
    });
};

export const searchResultLinks = (params) => {
    return apiInterfaceForJson("/resultlink/v1/search", "POST", params);
};

export const searchResultLinksDashboard = (params) => {
    return apiInterfaceForJson(
        "/resultlink/v1/searchDashboard",
        "POST",
        params
    );
};
