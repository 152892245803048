import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { AgGridReact } from "ag-grid-react";
import { ThemeContext } from "../../../../theme/ThemeProvider";

export default class AnalyteResultRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result:
                props.data &&
                props.data.results &&
                props.data.results.values &&
                props.data.results.values.length > 0
                    ? "View All Analytes"
                    : "",
            showPopup: false,
            rowData:
                props.data &&
                props.data.results &&
                props.data.results.values &&
                props.data.results.values.length > 0
                    ? props.data.results.values
                    : [],
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                resizable: true
            },
            columnDefs: [
                {
                    headerName: "Analyte",
                    minWidth: 80,
                    field: "analyteName"
                },
                {
                    headerName: "Result",
                    minWidth: 100,
                    field: "analyteResult"
                },
                {
                    headerName: "Units",
                    minWidth: 100,
                    field: "analyteUnits"
                },
                {
                    headerName: "Reference Range",
                    minWidth: 100,
                    field: "analyteReferenceRange"
                },
                {
                    headerName: "Flag",
                    minWidth: 100,
                    field: "analyteFlag"
                }
            ],
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            }
        };
    }

    handleClose = () => {
        this.setState({ showPopup: false });
    };

    render() {
        return (
            <div>
                <div
                    onClick={() => this.setState({ showPopup: true })}
                    className="cursorPointer"
                >
                    {this.state.result}
                </div>
                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showPopup}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Analyte Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row form-row">
                            <ThemeContext.Consumer>
                                {({ themeName }) => (
                                    <div
                                        id="currentListGrid"
                                        style={{
                                            height: "400px",
                                            width: "100%",
                                            marginBottom: "20px"
                                        }}
                                        className={
                                            themeName === "Light"
                                                ? "ag-theme-alpine col-12"
                                                : "ag-theme-alpine-dark col-12"
                                        }
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={
                                                this.state.defaultColDef
                                            }
                                            masterDetail={true}
                                            rowData={this.state.rowData}
                                            pagination={true}
                                            paginationNumberFormatter={
                                                this.state
                                                    .paginationNumberFormatter
                                            }
                                        />
                                    </div>
                                )}
                            </ThemeContext.Consumer>
                            <div
                                className="col-12"
                                style={{
                                    paddingTop: "12px",
                                    borderTop: "1px solid rgba(0,0,0,0.2)"
                                }}
                            >
                                <Button
                                    variant="secondary"
                                    style={{ float: "right" }}
                                    onClick={this.handleClose}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}
