import React, { Component } from "react";
import { Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";

export default class CheckboxRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            resultId: this.props.data._id
        };
    }

    checkedHandler = (event) => {
        let checked = event.target.checked;
        this.props.context.componentParent.checkItem(this.state.resultId, checked)
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Remove Result Item
        </Tooltip>
    );

    removeResult = () => {
        Swal.fire({
            title: 'Are you sure?',
            html: `You are gonna remove this result item,<br/>Sample ID: ` + this.props.data.sample,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: {
                container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
                cancelButton: 'order-1',
                confirmButton: 'order-2'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.context.componentParent.removeItem(this.state.resultId);
            }
        })
    }


    render() {
        return (
            <div className="text-center assign-actions">
                <Checkbox checked={this.props.data.checked} onClick={this.checkedHandler} ></Checkbox>
                {/* <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltip}
                >
                    <button
                        className="btn btn-primary ml-2"
                        onClick={() => this.removeResult()}
                    >
                        Remove
                    </button>
                </OverlayTrigger> */}
            </div>
        );
    }
}
