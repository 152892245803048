import React, { Component } from "react";
import Breadcrumb from "./breadcrumb";
import { Button } from "react-bootstrap";
import { sendNotification, changeSettingMaintenanceState, changeOrderResultReleaseNotificationSetting, getSettings } from "../../../services/clinicPortalServices/settingService";
import { Switch, TextField } from '@mui/material';
import toastr from 'toastr';

class ClinicSuperAdminSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: "We'll be right back.",
			text: "After 10 minutes, We will update Benchlab, LLC. to add some more amazing features. So you will not be able to use the website for a while. We will be back very soon.",
			errors: [],
			maintenanceState: false,
			is_enabled_order_result_release_notification: false
		};
	}

	componentDidMount() {
		getSettings().then(response => {
			if (response && response.data) {
				this.setState({ maintenanceState: response.data.state, is_enabled_order_result_release_notification: response.data.is_enabled_order_result_release_notification });
			}
		})
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSwitchChange = (e) => {
		if (e.target && e.target.name === "maintenanceState") {
			let info = {
				type: "state",
				state: e.target.checked
			}
			changeSettingMaintenanceState(info)
				.then((response) => {
					if (response.status === 200) {
						toastr.success(response.message);
					}
				})
		} else if (e.target && e.target.name === "is_enabled_order_result_release_notification") {
			let info = {
				is_enabled_order_result_release_notification: e.target.checked
			}
			changeOrderResultReleaseNotificationSetting(info).then(res => {
				if (res.status === 200) {
					toastr.success('Successfully changed.');
				}
			})
		}
		this.setState({ [e.target.name]: e.target.checked });
	};

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let { title, text } = this.state;

		let errors = [];

		if (!title) {
			errors.push("title");
		}

		if (!text) {
			errors.push("text");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let info = {
			type: "notification",
			title: title,
			text: text,
		}
		sendNotification(info)
			.then((response) => {
				if (response.status === 200)
					toastr.success(response.message);
			})
	}

	render() {
		return (
			<div className="clinic-contain">
				<Breadcrumb />
				<div className="px-5 py-3">
					<div className="row">
						<div className="col-12 col-md-5 d-flex flex-column mt-3">
							<label>Website Under Maintenance</label>
							<Switch
								onChange={this.handleSwitchChange}
								checked={this.state.maintenanceState}
								name="maintenanceState"
							/>
							<label className="mt-3">Red sends patient result notices / Grey shuts it off</label>
							<Switch
								onChange={this.handleSwitchChange}
								checked={this.state.is_enabled_order_result_release_notification}
								name="is_enabled_order_result_release_notification"
							/>
						</div>
						<div className="col-12 col-md-7 mt-3">
							<div className="row form-row">
								<div className="col-12">
									<label className="mb-3">Notification Management</label>
								</div>
								<div className="col-6">
									<div className="form-group">
										<TextField
											variant="outlined"
											value={this.state.title}
											onChange={this.handleChange}
											label="Notification Title"
											name="title"
										/>
										<div className={this.hasError("title") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
								<div className="col-12 mt-3">
									<div className="form-group">
										<TextField
											variant="outlined"
											value={this.state.text}
											onChange={this.handleChange}
											label="Notification Text"
											name="text"
											multiline
											rows={5}
										/>
										<div className={this.hasError("text") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
									</div>
								</div>
								<div className="col-12 mt-2">
									<Button
										className="btn-submit"
										type="submit"
										onClick={this.handleSubmit}
										id="btnSubmit"
									>
										Send Notification
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ClinicSuperAdminSettings;
