import React, { Component } from "react";
import { Route } from "react-router-dom";
import ClinicSidebar from "../components/common/clinicSidebar";
import ClinicPortalHeader from "../components/common/header";
import { getUserRole } from "../services/common/util";
import UniversalSearch from "../components/common/header/universalSearch";
import OrderOverview from "../components/common/header/orderOverview";
import { isUserLoggedIn } from "../services/common/util";
import { ThemeContext } from "../theme/ThemeProvider.jsx";

class ClinicPortalLayout extends Component {
    constructor(props) {
        super(props);
        if (!(isUserLoggedIn() && getUserRole())) {
            window.location.href = "/";
        }
    }

    render(props) {
        return (
            <ThemeContext.Consumer>
                {({ themeName }) => (
                    <div className={"root-div " + themeName}>
                        <Route
                            render={(props) => (
                                <ClinicPortalHeader {...props} />
                            )}
                        />
                        <div className="clinic-wrap">
                            {isUserLoggedIn() && getUserRole() && (
                                <Route
                                    render={(props) => (
                                        <ClinicSidebar {...props} />
                                    )}
                                />
                            )}
                            {getUserRole() &&
                                /^superadmin|^admin|^labtech|^customerserviceuser/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <div className="universal-mobile">
                                        <UniversalSearch />
                                        {getUserRole() &&
                                            /^superadmin|^admin|^labtech/.test(
                                                getUserRole().toLowerCase()
                                            ) && (
                                                <OrderOverview
                                                    userRole={getUserRole()}
                                                />
                                            )}
                                    </div>
                                )}
                            {getUserRole() && this.props.children}
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default ClinicPortalLayout;
