import React, { Component } from "react";
import { TextField, FormControlLabel, Switch } from "@mui/material";

class BreadCrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-5 col-12">
                            <nav
                                aria-label="breadcrumb"
                                className="page-breadcrumb"
                            >
                                <ol className="breadcrumb">
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Billing
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Self/Pay Payments
                                    </li>
                                </ol>
                            </nav>
                            <div style={{ display: "flex" }}>
                                <h2 className="breadcrumb-title">
                                    Self/Pay Payments
                                </h2>
                                <FormControlLabel
                                    value="includeArchived"
                                    control={
                                        <Switch
                                            checked={this.props.isArchived}
                                            onChange={
                                                this.props
                                                    .onIsArchivedInformation
                                            }
                                        />
                                    }
                                    label={
                                        this.props.isArchived
                                            ? "Hide Archives"
                                            : "Show Archives"
                                    }
                                    labelPlacement="end"
                                    className="m-0 show-archives-control breadcrumb-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-7 col-12">
                            <div className="row breadcrumb-btns">
                                <ul className="export-ul d-flex justify-content-end">
                                    <li>
                                        <TextField
                                            label="Page Size"
                                            className="breadcrumb-pagesize"
                                            id="page-size"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type="number"
                                            size="small"
                                            margin="none"
                                            onChange={
                                                this.props.onPageSizeChanged
                                            }
                                        />
                                    </li>
                                    <li>
                                        <button
                                            className="btn"
                                            style={{
                                                paddingRight: "0px",
                                                paddingLeft: "0px"
                                            }}
                                            onClick={() =>
                                                this.props.saveState()
                                            }
                                        >
                                            <i className="far fa-save"></i> Save
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="btn"
                                            style={{
                                                paddingRight: "0px",
                                                paddingLeft: "0px"
                                            }}
                                            onClick={() =>
                                                this.props.resetState()
                                            }
                                        >
                                            {" "}
                                            <i className="fa fa-repeat"></i>{" "}
                                            Default
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="btn"
                                            style={{
                                                paddingRight: "0px",
                                                paddingLeft: "0px"
                                            }}
                                            onClick={() =>
                                                this.props.onBtnExport()
                                            }
                                        >
                                            <i
                                                className="fa fa-file-excel-o"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            Export
                                        </button>
                                    </li>
                                    <li>
                                        <div
                                            className="d-flex"
                                            style={{ flexDirection: "column" }}
                                        >
                                            <button
                                                className="btn"
                                                style={{
                                                    paddingRight: "0px",
                                                    paddingLeft: "0px"
                                                }}
                                                onClick={() => {
                                                    document
                                                        .getElementById(
                                                            "import_document"
                                                        )
                                                        .click();
                                                }}
                                            >
                                                <i
                                                    className="fas fa-file-import"
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                ></i>
                                                Import Document
                                            </button>
                                            <button
                                                className="btn"
                                                style={{
                                                    paddingRight: "0px",
                                                    paddingLeft: "0px"
                                                }}
                                                onClick={() => {
                                                    window.open(
                                                        "https://benchlabit.com/public_files/self_payment_documents/sample_template_spreadsheet.xlsx"
                                                    );
                                                }}
                                            >
                                                <i
                                                    className="fas fa-download"
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                ></i>
                                                Download Example Template
                                            </button>
                                        </div>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="import_document"
                                            onChange={this.props.importDocument}
                                            accept=".xlsx,/*"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BreadCrumb;
