import React, { Component } from 'react'
import moment from "moment";
import PayCellButtonRenderer from "./payCellButtonRenderer";

class InsuranceData extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className='insurance-data' style={{ overflowY: "auto" }}>
                <table
                    id='insurance-table'
                    role="table"
                    style={{ width: "100%" }}
                >
                    <thead>
                        <tr role="row">
                            <th role="columnheader">Test Name</th>
                            <th role="columnheader">Collection Date</th>
                            <th role="columnheader">Sample ID</th>
                            <th role="columnheader">Patient Name</th>
                            <th role="columnheader">Facility Name</th>
                            <th role="columnheader">Physician Name</th>
                            <th role="columnheader">Chart Number</th>
                            <th role="columnheader">Total Amount</th>
                            <th role="columnheader">Adjustment Amount</th>
                            <th role="columnheader">Paid</th>
                            <th role="columnheader">Balance Due</th>
                            <th role="columnheader">Due By</th>
                            <th role="columnheader">Pay Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.insurance.map((insuranceItem, index) => {
                                return (
                                    <tr role="row" key={index}>
                                        <td role="cell">{insuranceItem.test_id.name}</td>
                                        <td role="cell">{moment(insuranceItem.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY")}</td>
                                        <td role="cell">{insuranceItem.test_info.sample}</td>
                                        <td role="cell">{insuranceItem.patient_id.first_name} {insuranceItem.patient_id.last_name}</td>
                                        <td role="cell">{insuranceItem.facility_source}</td>
                                        <td role="cell">{insuranceItem.provider.first_name} {insuranceItem.provider.last_name}</td>
                                        <td role="cell">{insuranceItem.payment.chart_number}</td>
                                        <td role="cell">${insuranceItem.payment.total_amount}</td>
                                        <td role="cell">${insuranceItem.payment.adjustment_amount}</td>
                                        <td role="cell">${insuranceItem.payment.paid_amount}</td>
                                        <td role="cell">${insuranceItem.payment.due_amount}</td>
                                        <td role="cell">{insuranceItem.payment.due_date}</td>
                                        <td role="cell">
                                            <PayCellButtonRenderer
                                                data={insuranceItem}
                                                patientData={this.props.patientData}
                                                value={insuranceItem.payment.due_amount}
                                                stripe={this.props.stripe}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default InsuranceData;
