import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import NotificationLogCellRenderer from "../../orders/clinicOrderGrid/notificationLogCellRenderer";

import { failedNotificationReports } from "../../../../services/clinicPortalServices/reportService";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import ReportsSearchMenu from "./ReportsSearchMenu";
import {
    getViewSettings,
    saveViewDefault
} from "../../../../services/clinicPortalServices/userViewSettings";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from "lodash";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicFailedNotificationReportsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isArchived: false,
            user_role: getUserRole(),
            searchFilters: {
                facility_id: [],
                from_date:
                    moment().subtract(0, "days").format("YYYY-MM-DD") +
                    "T00:00",
                to_date:
                    moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                dateRange: "today",
                stateReportsType: "all"
            },
            gridName: "FailedNotificationReport",
            pageSize: "",
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 200,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Requisition",
                    minWidth: 150,
                    field: "lab_order_id"
                },
                {
                    headerName: "Sample ID",
                    minWidth: 150,
                    field: "test_info.sample"
                },
                {
                    headerName: "Patient",
                    minWidth: 200,
                    resizable: true,
                    valueGetter: function addColumns(params) {
                        if (params.data.patient_id) {
                            return (
                                params.data.patient_id.first_name +
                                " " +
                                params.data.patient_id.last_name
                            );
                        } else {
                            return "";
                        }
                    }
                },
                {
                    headerName: "Facility",
                    minWidth: 150,
                    resizable: true,
                    field: "facility_id.name"
                },
                {
                    headerName: "Physician",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function addColumns(params) {
                        if (params.data.provider) {
                            return (
                                params.data.provider.first_name +
                                " " +
                                params.data.provider.last_name
                            );
                        } else {
                            return "";
                        }
                    }
                },
                {
                    headerName: "Patient Double Failure",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function addColumns(params) {
                        if (params.data.notification_state !== "NO_ACTION") {
                            if (
                                params.data.sms_delivery_status !==
                                    "delivered" &&
                                params.data.email_delivery_status !==
                                    "delivered"
                            ) {
                                return "YES";
                            } else {
                                return "NO";
                            }
                        } else {
                            return "Not Escalated";
                        }
                    }
                },
                {
                    headerName: "Notification Log",
                    minWidth: 180,
                    field: "notification_state",
                    cellRenderer: "notificationLogCellRenderer",
                    resizable: true
                }
            ],
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            components: {
                editBtnCellRenderer: EditBtnCellRenderer,
                notificationLogCellRenderer: NotificationLogCellRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            context: { componentParent: this },
            excelStyles: [
                {
                    id: "header",
                    interior: {
                        color: "#aaaaaa",
                        pattern: "Solid"
                    }
                },
                {
                    id: "body",
                    interior: {
                        color: "#dddddd",
                        pattern: "Solid"
                    }
                }
            ],
            facilities: []
        };
        this.loadFacilities(this.state.isArchived);
    }

    loadFacilities = (isArchived) => {
        let filters = this.state.searchFilters;
        let facilities = this.state.facilities;

        let isAllFacilities = false;
        if (
            filters.facility_id.length > 0 &&
            filters.facility_id.length === facilities.length &&
            facilities.length > 0
        ) {
            isAllFacilities = true;
        }

        getCompanyWithFacility(isArchived).then((response) => {
            let facilityIds = [];

            facilities = response.data;

            if (facilities.length > 0) {
                facilities.map((fac) => {
                    facilityIds.push(fac._id);
                    return null;
                });
                this.facilityIds = facilityIds;
                const facilityIdStorage =
                    window.localStorage.getItem("FACILITY_ID");
                if (isAllFacilities) {
                    filters.facility_id = facilityIds;
                } else if (
                    facilityIdStorage &&
                    facilityIdStorage !== undefined &&
                    Array.isArray(JSON.parse(facilityIdStorage)) &&
                    JSON.parse(facilityIdStorage).length > 0
                ) {
                    filters.facility_id = JSON.parse(facilityIdStorage);
                } else if (facilityIds.length >= 1) {
                    filters.facility_id = facilityIds;
                } else {
                    filters.facility_id = facilities[0]._id;
                }
            } else {
                filters.facility_id = [];
            }
            this.setState({ searchFilters: filters, facilities }, () => {
                this.loadGridData();
            });
        });
    };

    onIsArchivedInformation = (event) => {
        this.setState({ isArchived: event.target.checked });
        this.loadFacilities(event.target.checked);
    };

    updateDateRange = (dateRange) => {
        let filters = this.state.searchFilters;
        if (filters.dateRange !== dateRange) {
            filters.dateRange = dateRange;
            switch (dateRange) {
                case "today":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().format("YYYY-MM-DD") + "T00:00";
                    break;
                case "week":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                case "month":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                default:
                    break;
            }
            this.setState({ searchFilters: filters });
            if (dateRange !== "custom") {
                this.loadGridData();
            }
        }
    };

    setDateRange = (searchFilters) => {
        if (
            moment(searchFilters.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "today";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("week").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "week";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("month").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "month";
            } else {
                searchFilters.dateRange = "custom";
            }
        } else {
            searchFilters.dateRange = "custom";
        }
    };

    handleDateFiltersChange = (dateTime, type) => {
        let filters = this.state.searchFilters;
        let filterIsValid = true;

        if (type === "from_date") {
            if (moment(filters.to_date).isAfter(dateTime)) {
                filters.from_date = dateTime;
            } else {
                filterIsValid = false;
            }
        } else {
            if (moment(dateTime).isAfter(filters.from_date)) {
                filters.to_date = dateTime;
            } else {
                filterIsValid = false;
            }
        }

        if (filterIsValid) {
            this.setDateRange(filters);
            this.setState({ searchFilters: filters });
            this.loadGridData();
        }
    };

    handleFiltersChange = (e) => {
        const filters = this.state.searchFilters;

        switch (e.target.name) {
            case "type": {
                filters.stateReportsType = e.target.value;
                break;
            }
            case "facility_id": {
                let { facilities } = this.state;
                let facilityIdChanged = [],
                    isAdded = false,
                    facilitiesIn = [],
                    facilityIdsTemp = [];
                let newFacilityIds = e.target.value;
                if (e.target.value.includes("all")) {
                    if (
                        filters.facility_id.length === this.facilityIds.length
                    ) {
                        filters.facility_id = [];
                    } else {
                        filters.facility_id = _.cloneDeep(this.facilityIds);
                    }
                } else {
                    if (filters.facility_id.length > newFacilityIds.length) {
                        facilityIdChanged = filters.facility_id.find(
                            (val) => !newFacilityIds.includes(val)
                        );
                    } else {
                        facilityIdChanged = newFacilityIds.find(
                            (val) => !filters.facility_id.includes(val)
                        );
                        isAdded = true;
                    }
                    let facilityChanged = facilities.find(
                        (facility) => facility._id === facilityIdChanged
                    );
                    facilityIdsTemp = _.cloneDeep(filters.facility_id);
                    // If company is changed
                    if (facilityChanged.type === "company") {
                        let companyChanged = facilityChanged;
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyChanged._id) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        let index = facilityIdsTemp.indexOf(companyChanged._id);
                        if (facilitiesIn.length > 0) {
                            // If company is selected
                            if (isAdded) {
                                // Add company Id
                                facilityIdsTemp.push(companyChanged._id);
                                // Add facility Ids
                                facilitiesIn.forEach((facId) => {
                                    if (!facilityIdsTemp.includes(facId)) {
                                        facilityIdsTemp.push(facId);
                                    }
                                });
                            } else {
                                // If company is unselected
                                // Remove company Id
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                                facilitiesIn.forEach((facId) => {
                                    let index = facilityIdsTemp.indexOf(facId);
                                    if (index > -1) {
                                        facilityIdsTemp.splice(index, 1);
                                    }
                                });
                            }
                        } else {
                            isAdded
                                ? facilityIdsTemp.push(companyChanged._id)
                                : facilityIdsTemp.splice(index, 1);
                        }
                    } else {
                        // If facility is changed
                        let companyId = facilityChanged.parent_id;
                        let facilitiesIn = [];
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyId) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        // If facility Id is selected
                        if (isAdded) {
                            // Add facility Id
                            facilityIdsTemp.push(facilityChanged._id);
                            if (!filters.facility_id.includes(companyId)) {
                                facilityIdsTemp.push(companyId);
                            }
                        } else {
                            // If facility Id is unselected
                            // Remove facility Id
                            let index = facilityIdsTemp.indexOf(
                                facilityChanged._id
                            );
                            if (index > -1) {
                                facilityIdsTemp.splice(index, 1);
                            }
                            // Remove company Id
                            let facilitiesInFilters = [];
                            facilitiesIn.forEach((fac) => {
                                if (facilityIdsTemp.includes(fac)) {
                                    facilitiesInFilters.push(fac);
                                }
                            });
                            if (facilitiesInFilters.length === 0) {
                                index = facilityIdsTemp.indexOf(companyId);
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                            }
                        }
                    }
                    filters.facility_id = _.cloneDeep(facilityIdsTemp);
                    window.localStorage.setItem(
                        "FACILITY_ID",
                        JSON.stringify(filters.facility_id)
                    );
                }
                break;
            }
            default:
                break;
        }

        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridSchema();
    };

    checkRealFacilities = (searchFilters) => {
        let realFacilities = [];
        searchFilters.facility_id.map((facilityId) => {
            if (
                this.state.facilities.find(
                    (facility) => facility._id === facilityId
                )
            ) {
                realFacilities.push(facilityId);
            }
            return null;
        });
        return realFacilities;
    };

    loadGridData = () => {
        let searchFilters = this.state.searchFilters;
        searchFilters.facility_id = this.checkRealFacilities(searchFilters);
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }
        failedNotificationReports(searchFilters).then((response) => {
            let rowData = response.data.filter((item) => {
                if (
                    !["DELIVERED_TO_PATIENT", "SENT_TO_FACILITY"].includes(
                        item.notification_state
                    )
                ) {
                    return true;
                }
                return false;
            });
            this.setState({ rowData: rowData });
            if (!rowData.length) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Failed_Notification_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Failed Notifications"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.FailedSMSEmailReportGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string:
                auditEnums.EVENTTYPES.FailedSMSEmailReportGridExported
        };
        createAudit(auditData);
    };

    onPageSizeChanged = () => {
        let value = document.getElementById("page-size").value;
        this.gridApi.paginationSetPageSize(Number(value));
    };

    loadGridSchema = () => {
        let userId = window.localStorage.getItem("USER_ID");
        let params = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.FAILED_SMS_EMAIL_REPORTS
        };
        getViewSettings(params).then((response) => {
            const columnState =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                }).columns;
            if (columnState) {
                this.gridColumnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true
                });
            } else {
                this.gridColumnApi.resetColumnState();
            }

            const pageSize =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                }).page_size;
            document.getElementById("page-size").value =
                pageSize && pageSize > 0 ? pageSize : 20;
            this.onPageSizeChanged();
        });
    };

    saveState = () => {
        let userId = window.localStorage.getItem("USER_ID");
        const columnState = this.gridColumnApi.getColumnState();
        let pageSize = document.getElementById("page-size").value;
        let saveObject = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.FAILED_SMS_EMAIL_REPORTS,
            grid_views: [
                {
                    name: this.state.gridName,
                    page_size: pageSize,
                    columns: columnState
                }
            ]
        };
        saveViewDefault(saveObject).then(() => {
            toastr.success("Saved successfully.");
        });
    };

    resetState = () => {
        this.gridColumnApi.resetColumnState();
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";

        let filters = this.state.searchFilters;

        filters.stateReportsType = "all";

        filters.from_date =
            moment().subtract(0, "days").format("YYYY-MM-DD") + "T00:00";
        filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
        if (
            this.state.user_role &&
            (this.state.user_role.toLowerCase().trim() === "superadmin" ||
                this.state.user_role.toLowerCase().trim() === "admin")
        ) {
            filters.facility_id = this.facilityIds;
        } else {
            if (this.state.facilities.length > 1) {
                filters.facility_id = this.facilityIds;
            } else {
                filters.facility_id = this.state.facilities[0]._id;
            }
        }
        window.localStorage.removeItem("FACILITY_ID");
        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    render() {
        let columnDefs = /audit/.test(getUserRole().toLowerCase())
            ? this.state.columnDefs.filter((x) => x.headerName !== "Actions")
            : this.state.columnDefs;
        return (
            <div className="clinic-contain">
                <ReportsSearchMenu
                    onIsArchivedInformation={(event) => {
                        this.onIsArchivedInformation(event);
                    }}
                    isArchived={this.state.isArchived}
                    facilityId={this.state.searchFilters.facility_id}
                    handleFiltersChange={this.handleFiltersChange}
                    handleDateFiltersChange={this.handleDateFiltersChange}
                    updateDateRange={this.updateDateRange}
                    facilities={this.state.facilities}
                    from_date={this.state.searchFilters.from_date}
                    to_date={this.state.searchFilters.to_date}
                    date_range={this.state.searchFilters.dateRange}
                    onFilterTextChange={this.onFilterTextChange}
                    stateReportsType={this.state.searchFilters.stateReportsType}
                    clearFilter={this.clearFilter}
                    onPageSizeChanged={this.onPageSizeChanged}
                    saveState={this.saveState}
                    resetState={this.resetState}
                    onBtnExport={this.onBtnExport}
                    user_role={this.state.user_role}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 320px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    components={this.state.components}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationNumberFormatter={
                                        this.state.paginationNumberFormatter
                                    }
                                    excelStyles={this.state.excelStyles}
                                    context={this.state.context}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicFailedNotificationReportsGrid;
