import React, { Component } from "react";
import { Modal, Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import { serviceConstants } from "../../../../services/common/constants";
import { getCompanyData } from "../../../../services/clinicPortalServices/companyServices";
import { getInvoiceById, updateInvoice, deleteInvoice } from "../../../../services/clinicPortalServices/invoiceServices";
// import { remindCompany } from "../../../../services/clinicPortalServices/invoiceServices";
import { historyDescriptions } from "../../../../services/common/optionsData";
import moment from "moment";
import toastr from "toastr";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { TextField, InputAdornment, IconButton } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { getUserRole } from "../../../../services/common/util";
import Swal from 'sweetalert2';
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			invoiceId: props.data._id,
			companyId: "",
			invoice_id: "",
			init_amount: "",
			amount: "",
			companies: [],
			services: [],
			payments: [],
			totalPayment: "",
			credits: [],
			totalCredit: "",
			notes: [],
			histories: [],
			invoice_date: "",
			due_date: moment(props.data.due_date).format('MM/DD/YYYY'),
			errors: [],
			originInvoiceData: {}
		};
	}

	loadDataForModal = () => {
		getCompanyData().then(response => {
			this.setState({ companies: response.data });
		}).catch(error => {
			console.log(error);
		})
	}

	addArray = (type) => {
		if (type === 'payment') {
			let payments = this.state.payments;
			payments.push({
				appliedDate: moment().format("MM/DD/YYYY"),
				amount: "",
				paymentMethod: "",
			})
			this.setState({ payments: payments });
			this.addHistory("payment");
		} else if (type === 'credit') {
			let credits = this.state.credits;
			credits.push({
				appliedDate: moment().format("MM/DD/YYYY"),
				amount: "",
			})
			this.setState({ credits: credits });
			this.addHistory("credit");
		} else if (type === 'note') {
			let notes = this.state.notes;
			notes.push({
				note: "",
				appliedDate: moment().format("MM/DD/YYYY"),
			})
			this.setState({ notes: notes });
			this.addHistory("note");
		}
	}

	removeArray = (type, index) => {
		if (type === 'payment') {
			let payments = this.state.payments;
			payments.splice(index, 1);
			this.setState({ payments: payments });
			this.calculateAmount();
			this.removeHistory("payment");
		} else if (type === 'credit') {
			let credits = this.state.credits;
			credits.splice(index, 1);
			this.setState({ credits: credits });
			this.calculateAmount();
			this.removeHistory("credit");
		} else if (type === 'note') {
			let notes = this.state.notes;
			notes.splice(index, 1);
			this.setState({ notes: notes });
			this.removeHistory("note");
		}
	}

	addHistory = (type) => {
		let histories = this.state.histories;
		histories.push({
			description: historyDescriptions[type],
			date: moment().format("MM/DD/YYYY")
		})
		this.setState({ histories: histories });
	}

	removeHistory = (type) => {
		let histories = this.state.histories;
		let index;
		for (let i = histories.length; i > 0; i--) {
			if (histories[i - 1].description === historyDescriptions[type]) {
				index = i - 1;
			}
		}
		histories.splice(index, 1);
		this.setState({ histories: histories });
	}

	handleDateFiltersChange = (dateTime, type, index) => {
		if (type === "due_date") {
			this.setState({ due_date: dateTime });
		} else if (type === "payment") {
			let payments = this.state.payments;
			payments.map((payment, i) => {
				if (i === index) {
					payment.appliedDate = dateTime;
				}
				return null;
			})
			this.setState({ payments: payments });
		} else if (type === "credit") {
			let credits = this.state.credits;
			credits.map((credit, i) => {
				if (i === index) {
					credit.appliedDate = dateTime;
				}
				return null;
			})
			this.setState({ credits: credits });
		} else if (type === "note") {
			let notes = this.state.notes;
			notes.map((note, i) => {
				if (i === index) {
					note.appliedDate = dateTime;
				}
				return null;
			})
			this.setState({ notes: notes });
		}
	}

	handleChange = (e, type, index, isNumber = false) => {
		const name = e.target.name;
		let value = e.target.value;
		let prevValue;
		if (isNumber && value) {
			value = parseInt(value);
			switch (type) {
				case "payment":
					prevValue = this.state.payments[index].amount;
					break;
				case "credit":
					prevValue = this.state.credits[index].amount;
					break;
				default: break;
			}
		}
		if (!value || (!isNumber || (isNumber && value <= (this.state.amount + prevValue)))) {
			if (type === "payment") {
				let payments = this.state.payments;
				payments.map((payment, i) => {
					if (index === i) {
						payment[name] = value;
					}
					return null;
				})
				this.setState({ payments: payments });
			} else if (type === "credit") {
				let credits = this.state.credits;
				credits.map((credit, i) => {
					if (index === i) {
						credit[name] = value;
					}
					return null;
				})
				this.setState({ credits: credits });
			} else if (type === "note") {
				let notes = this.state.notes;
				notes.map((notes, i) => {
					if (index === i) {
						notes[name] = value;
					}
					return null;
				})
				this.setState({ notes: notes });
			}
			if (isNumber) {
				this.calculateAmount();
			}
		} else {
			toastr.warning("You input amount bigger than outstanding amount, please input correct number");
		}
	}

	calculateAmount = () => {
		let totalPayment = 0, totalCredit = 0;
		this.state.payments.map(payment => {
			totalPayment += payment.amount;
			return null;
		});
		this.state.credits.map(credit => {
			totalCredit += credit.amount;
			return null;
		});
		const amount = this.state.init_amount - totalPayment - totalCredit;
		if (amount >= 0) {
			this.setState({
				totalPayment: totalPayment,
				totalCredit: totalCredit,
				amount: amount
			})
			if (amount === 0) {
				this.setState({ state: "PAID" });
			}
		}
	}

	handleShow = () => {
		window.open(serviceConstants.HOST_NAME + "/invoiceView/" + this.props.data._id);
	};

	handleEdit = () => {
		this.setState({ show: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
		getInvoiceById(this.state.invoiceId).then(response => {
			const invoiceData = response.data[0];
			this.setState({
				companyId: invoiceData.company_id._id,
				companyName: invoiceData.company_id.name,
				invoice_id: invoiceData.invoice_id,
				init_amount: invoiceData.init_amount,
				amount: invoiceData.amount,
				companies: [],
				services: invoiceData.services.length ? [...invoiceData.services] : [],
				payments: invoiceData.payments.length ? [...invoiceData.payments] : [],
				credits: invoiceData.credits.length ? [...invoiceData.credits] : [],
				notes: invoiceData.notes.length ? [...invoiceData.notes] : [],
				histories: invoiceData.histories.length ? [...invoiceData.histories] : [],
				invoice_date: moment(invoiceData.invoice_date).format("MM/DD/YYYY"),
				due_date: moment(invoiceData.due_date).format("MM/DD/YYYY"),
				state: invoiceData.state
			});
			let totalPayment = 0, totalCredit = 0;
			invoiceData.payments.map(payment => {
				totalPayment += parseInt(payment.amount);
				return null;
			})
			invoiceData.credits.map(credit => {
				totalCredit += parseInt(credit.amount);
				return null;
			})
			this.setState({
				totalPayment: totalPayment,
				totalCredit: totalCredit,
				originInvoiceData: invoiceData
			})
		})
	}

	handleClose = () => {
		this.setState({ show: false });
	};

	handleSave = () => {
		if (document.getElementsByClassName("inline-errormsg").length) {
			toastr.warning("Please input all fields correctly");
		} else {
			const payments = this.state.payments.map(payment => {
				if (!payment.appliedDate.includes('-')) {
					payment.appliedDate = moment(payment.appliedDate, "MM/DD/YYYY").format("MM/DD/YYYY");
				}
				return payment;
			})
			const credits = this.state.credits.map(credit => {
				if (!credit.appliedDate.includes('-')) {
					credit.appliedDate = moment(credit.appliedDate, "MM/DD/YYYY").format("MM/DD/YYYY");
				}
				return credit;
			})
			const notes = this.state.notes.map(note => {
				if (!note.appliedDate.includes('-')) {
					note.appliedDate = moment(note.appliedDate, "MM/DD/YYYY").format("MM/DD/YYYY");
				}
				return note;
			})
			let histories = this.state.histories;
			const due_date = moment(this.state.due_date, "MM/DD/YYYY").format("MM/DD/YYYY");
			if (due_date !== this.state.originInvoiceData.due_date) {
				histories.push({
					description: "Due date is updated",
					date: moment().format("MM/DD/YYYY")
				})
			}
			if (this.state.state === 'PAID' && this.state.originInvoiceData.state === "OUTSTANDING") {
				histories.push({
					description: "Invoice payment is completed",
					date: moment().format("MM/DD/YYYY")
				})
			}
			const updatedParams = {
				_id: this.state.invoiceId,
				company_id: this.state.companyId,
				due_date: due_date,
				payments: payments,
				credits: credits,
				notes: notes,
				histories: histories,
				amount: this.state.amount,
				state: this.state.state
			}
			updateInvoice(updatedParams).then(response => {
				this.props.context.componentParent.loadGridData();
				this.handleClose();
			}).catch(error => {
				console.log(error);
			})
		}
	}

	handleRemind = () => {
		toastr.warning('It is disabled now :)');
		// if (this.props.data.company_id.contact_email) {
		// 	remindCompany(this.props.data.company_id.contact_email, this.state.invoiceId).then(response => {
		// 		toastr.success('Remind notice is sent successfully!');
		// 	}).catch(error => {
		// 		console.log(error);
		// 	})
		// } else {
		// 	toastr.warning('Company contact email is not ready');
		// }
	}

	handleDownload = () => {
		window.open(serviceConstants.HOST_NAME + "/public_files/invoices/" + this.props.data.fileName);
	}

	handleRemove = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this invoice item!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				deleteInvoice(this.state.invoiceId).then(response => {
					this.props.context.componentParent.loadGridData();
				}).catch(error => {
					console.log(error);
				})
			}
		})
	}

	renderTooltipView = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			View Invoice
		</Tooltip>
	);

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Invoice
		</Tooltip>
	);

	renderTooltipRemind = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Remind Company
		</Tooltip>
	);

	renderTooltipDownload = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Download Orders
		</Tooltip>
	);

	renderTooltipRemove = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Remove Invoice
		</Tooltip>
	);

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipView}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-eye"></i>
					</button>
				</OverlayTrigger>
				{
					['superadmin', 'admin', 'accountant'].includes(getUserRole().toLowerCase())
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipEdit}
					>
						<button onClick={this.handleEdit} className="edit-order-btn">
							<i className="fas fa-pen"></i>
						</button>
					</OverlayTrigger>
				}
				{
					['superadmin', 'admin', 'accountant'].includes(getUserRole().toLowerCase())
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipRemind}
					>
						<button onClick={this.handleRemind} className="edit-order-btn">
							<i className="fas fa-bell" style={{ color: "red" }}></i>
						</button>
					</OverlayTrigger>
				}
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDownload}
				>
					<button onClick={this.handleDownload} className="edit-order-btn">
						<i className="fas fa-download"></i>
					</button>
				</OverlayTrigger>
				{
					(getUserRole().toLowerCase() === 'superadmin' || getUserRole().toLowerCase() === 'admin')
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipRemove}
					>
						<button onClick={this.handleRemove} className="edit-order-btn">
							<i className="fas fa-trash"></i>
						</button>
					</OverlayTrigger>
				}
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					onEnter={this.loadDataForModal}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Invoice Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row form-row">
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Company</label>
									<select
										name="companyId"
										value={this.state.companyId}
										disabled
										className="form-control select"
									>
										<option key="0" value="null"></option>
										{this.state.companies.length && this.state.companies.map((company) => {
											return <option key={company._id} value={company._id}>{company.name}</option>;
										})}
									</select>
								</div>
							</div>
							<div className="col-12 col-md-3">
								<div className="form-group">
									<label>Invoice ID</label>
									<input
										type="text"
										name="invoice_id"
										value={this.state.invoice_id}
										disabled
										className="form-control select"
									/>
								</div>
							</div>
							<div className="col-12 col-md-3">
								<div className="form-group">
									<label>Initial Amount</label>
									<input
										type="text"
										name="init_amount"
										value={"$" + this.state.init_amount}
										disabled
										className="form-control select"
									/>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="form-group">
									<label>Invoice Date</label>
									<input
										type="text"
										name="init_amount"
										value={this.state.invoice_date}
										disabled
										className="form-control select"
									/>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="form-group">
									<label>Due Date</label>
									<MobileDatePicker
										value={this.state.due_date}
										onChange={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'due_date')}
										renderInput={props => <TextField
											{...props}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton edge="end">
															<EventIcon />
														</IconButton>
													</InputAdornment>
												)
											}}
											margine="none"
										/>}
									/>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="form-group">
									<label>State</label>
									<input
										type="text"
										value={this.state.amount === 0 ? "Paid" : "Outstanding with $" + this.state.amount}
										disabled
										className="form-control select"
									/>
								</div>
							</div>
							<div className="col-12">
								<label>
									Services
								</label>
							</div>
							<div className="col-12">
								{
									this.state.services.length > 0
									&&
									this.state.services.map((service, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-md-4">
													<div className="form-group">
														<input
															type="text"
															value={service.service_name}
															disabled
															className="form-control select"
														/>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="form-group">
														<input
															type="text"
															value={moment(service.service_start_date).format("MM/DD/YYYY") + ' ~ ' + moment(service.service_end_date).format("MM/DD/YYYY")}
															disabled
															className="form-control select"
														/>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="form-group">
														<input
															type="text"
															value={"$" + service.rate + " × " + service.quantity + " = $" + service.rate * service.quantity}
															disabled
															className="form-control select"
														/>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className="col-12">
								<label>
									Payments (${this.state.totalPayment})
									&nbsp;&nbsp;
									<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => this.addArray('payment')}></i>
								</label>
							</div>
							<div className="col-12">
								{
									this.state.payments.length > 0
									&&
									this.state.payments.map((payment, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-md-3">
													<div className="form-group">
														<input
															type="number"
															min="1"
															step="1"
															value={payment.amount}
															placeholder="Amount"
															name="amount"
															onChange={(e) => this.handleChange(e, 'payment', index, true)}
															className="form-control select"
														/>
														<div
															className={!payment.amount ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
														<div
															className={payment.amount && parseInt(payment.amount) <= 0 ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is not valid.
															</i>
														</div>
													</div>
												</div>
												<div className="col-12 col-md-5">
													<div className="form-group">
														<input
															type="text"
															placeholder="Payment Method"
															value={payment.paymentMethod}
															name="paymentMethod"
															onChange={(e) => this.handleChange(e, 'payment', index)}
															className="form-control select"
														/>
														<div
															className={!payment.paymentMethod ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
													</div>
												</div>
												<div className="col-10 col-md-3">
													<div className="form-group">
														<MobileDatePicker
															value={payment.appliedDate}
															onChange={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'payment', index)}
															label="Applied Date"
															renderInput={props => <TextField
																{...props}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton edge="end">
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																margine="none"
															/>}
														/>
													</div>
												</div>
												<div className="col-2 col-md-1" style={{ marginTop: "-20px", display: "flex", alignItems: "center" }}>
													<div>
														<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => this.removeArray('payment', index)}></i>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className="col-12">
								<label>
									Credits (${this.state.totalCredit})
									&nbsp;&nbsp;
									<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => this.addArray('credit')}></i>
								</label>
							</div>
							<div className="col-12">
								{
									this.state.credits.length > 0
									&&
									this.state.credits.map((credit, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-md-8">
													<div className="form-group">
														<input
															type="number"
															min="1"
															step="1"
															placeholder="Amount"
															value={credit.amount}
															name="amount"
															onChange={(e) => this.handleChange(e, 'credit', index, true)}
															className="form-control select"
														/>
														<div
															className={!credit.amount ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
														<div
															className={credit.amount && parseInt(credit.amount) <= 0 ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is not valid.
															</i>
														</div>
													</div>
												</div>
												<div className="col-10 col-md-3">
													<div className="form-group">
														<MobileDatePicker
															value={credit.appliedDate}
															onChange={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'credit', index)}
															label="Applied Date"
															renderInput={props => <TextField
																{...props}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton edge="end">
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																margine="none"
															/>}
														/>
													</div>
												</div>
												<div className="col-2 col-md-1" style={{ marginTop: "-20px", display: "flex", alignItems: "center" }}>
													<div>
														<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => this.removeArray('credit', index)}></i>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className="col-12">
								<label>
									Notes
									&nbsp;&nbsp;
									<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => this.addArray('note')}></i>
								</label>
							</div>
							<div className="col-12">
								{
									this.state.notes.length > 0
									&&
									this.state.notes.map((note, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-md-8">
													<div className="form-group">
														<input
															type="text"
															placeholder="Note"
															value={note.note}
															name="note"
															onChange={(e) => this.handleChange(e, 'note', index)}
															className="form-control select"
														/>
														<div
															className={!note.note ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
													</div>
												</div>
												<div className="col-10 col-md-3">
													<div className="form-group">
														<MobileDatePicker
															value={note.appliedDate}
															onChange={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'note', index)}
															label="Applied Date"
															renderInput={props => <TextField
																{...props}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton edge="end">
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																margine="none"
															/>}
														/>
													</div>
												</div>
												<div className="col-2 col-md-1" style={{ marginTop: "-20px", display: "flex", alignItems: "center" }}>
													<div>
														<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => this.removeArray('note', index)}></i>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={this.handleSave}>
							Save Changes
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
