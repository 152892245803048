import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { states } from "../../services/common/optionsData";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { isValidPhoneNumber } from "../../services/common/util";
import SignatureCanvas from "react-signature-canvas";

class PhysicianInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
        this.sigPads = [];
        this.props.physicians.map(item => {
            this.sigPads.push({});
            return null;
        })
    }

    componentDidMount() {
        this.props.physicians.map((physician, index) => {
            if (physician.signature_image) {
                this.sigPads[index].fromDataURL(physician.signature_image, { ratio: 1 });
            }
            return null;
        })
    }

    hasError = (index, key) => {
        if (this.state.errors[index]) {
            return this.state.errors[index].indexOf(key) !== -1;
        }
        return false;
    }

    clearSignaturePad = (index) => {
        this.sigPads[index].clear();
        this.props.handleArrayItemChange({ target: { name: "signature_image", value: "" } }, "physician", index);
    }

    continue = () => {
        let errors = this.props.physicians.map(physician => {
            return [];
        });

        this.props.physicians.map((physician, index) => {
            if (!physician.name) {
                errors[index].push('name');
            }
            if (!physician.npi) {
                errors[index].push('npi');
            }
            if (!physician.facilityNames.length) {
                errors[index].push('facilityNames');
            }
            if (!physician.mobile) {
                errors[index].push('mobile');
            }
            if (physician.mobile && !isValidPhoneNumber(physician.mobile)) {
                errors[index].push('wrongMobile');
            }
            if (!physician.address1) {
                errors[index].push('address1');
            }
            if (!physician.city) {
                errors[index].push('city');
            }
            if (!physician.state) {
                errors[index].push('state');
            }
            if (!physician.zip) {
                errors[index].push('zip');
            }
            if (!physician.signature_image) {
                errors[index].push('signature_image');
            }
            return null;
        })

        let errorHappened = false;
        errors.map(error => {
            if (error.length) {
                errorHappened = true;
            }
            return null;
        })
        if (errorHappened) {
            this.setState({ errors: errors });
        } else {
            this.setState({ errors: [] });
            this.props.nextStep();
        }
    }

    render() {
        return (
            <div className="row form-row">
                <div className="col-12">
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <h3>Physician Information <i className="fa fa-plus-circle btn" onClick={() => this.props.addArrayItem("physician")}></i></h3>
                            </div>
                        </div>
                        {
                            this.props.physicians.map((physician, index) => {
                                return <div className="col-12" key={index}>
                                    <div className="row form-row input-box">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <h4>Physician {index + 1} <i className="fa fa-times-circle btn" onClick={() => this.props.removeArrayItem("physician", index)}></i></h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Physician Name <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={physician.name}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "name") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Physician NPI <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="npi"
                                                    value={physician.npi}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "npi") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Facility <abbr className="text-danger">*</abbr></label>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{ width: "100%", marginTop: "5px" }}
                                                >
                                                    <InputLabel id="facility-label">Select Facility</InputLabel>
                                                    {
                                                        this.props.facilities
                                                        &&
                                                        this.props.facilities.length
                                                        &&
                                                        <Select
                                                            labelId="facility-label"
                                                            id="facility-select"
                                                            value={physician.facilityNames}
                                                            onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                            label="Select Facility"
                                                            name="facilityNames"
                                                            multiple
                                                            renderValue={(selected) => {
                                                                if (selected.length === this.props.facilities.length) {
                                                                    return 'All Facilities';
                                                                } else {
                                                                    return selected.join(', ');
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem className={this.props.facilities.length === physician.facilityNames.length ? "Mui-selected" : ""} key="all" value="all">
                                                                <Checkbox checked={this.props.facilities.length === physician.facilityNames.length ? true : false} />
                                                                All Facilities
                                                            </MenuItem>
                                                            {
                                                                this.props.facilities.map((fac, indexForFac) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={indexForFac}
                                                                            value={fac.name}
                                                                        >
                                                                            <Checkbox checked={physician.facilityNames.includes(fac.name) ? true : false} />
                                                                            {fac.name}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    }
                                                </FormControl>
                                                <div className={this.hasError(index, "facilityNames") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Physician Mobile # <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    style={{ marginTop: "8px" }}
                                                    type="text"
                                                    name="mobile"
                                                    value={physician.mobile}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "mobile") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongMobile") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Mobile is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <h5>Physician Address</h5>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Address1 <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="address1"
                                                    value={physician.address1}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "address1") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Address2</label>
                                                <input
                                                    type="text"
                                                    name="address2"
                                                    value={physician.address2}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>City <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={physician.city}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "city") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>State <abbr className="text-danger">*</abbr></label>
                                                <select
                                                    className="form-control select"
                                                    name="state"
                                                    value={physician.state}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                >
                                                    {
                                                        states.map((state, indexForState) => {
                                                            return (
                                                                <option key={indexForState} value={state.value}>
                                                                    {state.state}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className={this.hasError(index, "state") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Zip Code <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="zip"
                                                    value={physician.zip}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "physician", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "zip") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>
                                                    <strong>Physician Photo Signature <abbr className="text-danger">*</abbr></strong> ( Please draw )
                                                </label>
                                                <SignatureCanvas
                                                    penColor='black'
                                                    canvasProps={{ className: 'sigCanvas' }}
                                                    ref={(ref) => { this.sigPads[index] = ref }}
                                                    onEnd={(e) => this.props.handleArrayItemChange({ target: { name: "signature_image", value: this.sigPads[index].toDataURL("image/png") } }, "physician", index)}
                                                />
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <div className="btn" onClick={() => this.clearSignaturePad(index)} style={{ textDecoration: "underline" }}>Clear</div>
                                                </div>
                                                <div className={this.hasError(index, "signature_image") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Please provide signature.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="row next-button">
                        <Button
                            className="btn-pagebreak-previous"
                            type="submit"
                            onClick={this.props.previousStep}
                            id="btnBack"
                        >
                            Back
                        </Button>
                        <Button
                            className="btn-pagebreak-next"
                            type="submit"
                            onClick={this.continue}
                            id="btnNext"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PhysicianInfo;
