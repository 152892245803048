import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material"
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class ReplyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ModalStyled
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.showReplyBox}
                onHide={this.props.hideReplyBox}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reply to Communication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <TextField
                            label="Subject"
                            variant="outlined"
                            className="form-control subject-input"
                            id="subject"
                            name="subject"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="string"
                            margin="dense"
                            disabled
                            value={this.props.comment.subject}
                            onChange={this.props.onCommunicateTextChange}
                        />
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                        <TextField
                            label="Message"
                            variant="outlined"
                            className="form-control "
                            id="message"
                            name="message"
                            multiline
                            rows={4}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ height: "100px" }}
                            type="string"
                            margin="dense"
                            value={this.props.comment.message}
                            onChange={this.props.onCommunicateTextChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleReplyCommunication}>
                        Reply
                    </Button>
                </Modal.Footer>
            </ModalStyled>
        )
    }
}

export default ReplyModal

