import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { IconButton } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CropImgMaxWidth } from "../../services/common/constants";
import { ModalStyled } from "../../theme/customizedStyleComponents";

export default class ImageEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileOpened: null,
            imgSrc: null,
        };
        this.cropper = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { fileOpened } = this.props;
        if (fileOpened && fileOpened !== prevProps.fileOpened) {
            this.setState({ imageSrc: URL.createObjectURL(fileOpened) });
        }
    }

    handleRotate(deg) {
        this.cropper.current.cropper.rotate(deg);
    }

    handleClose = () => {
        this.setState({ imageSrc: null });
        this.props.handleClose();
    };

    uploadCropped = (isFull) => {
        const { width } = this.cropper.current.cropper
        .getData();
        if (isFull) {
            const { naturalWidth, naturalHeight } = this.cropper.current.cropper
        .getCanvasData();
            this.cropper.current.cropper.setAspectRatio(naturalWidth/naturalHeight);
        }
        const croppedImgData = this.cropper.current.cropper
            .getCroppedCanvas({
                width: Math.min(CropImgMaxWidth, width)
            })
            .toDataURL();
        let arr = croppedImgData.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], this.props.fileOpened.name, { type: mime });
        this.props.uploadCropped(croppedImage);
    }

    render() {
        const { imageSrc } = this.state;
        return (
            <ModalStyled
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={(this.props.fileOpened && imageSrc) ? true : false}
                onHide={this.handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="image-popup-title">
                        Edit Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Cropper
                        autoCropArea={1}
                        style={{ height: "400px", width: "100%" }}
                        ref={this.cropper}
                        src={imageSrc}
                        initialAspectRatio={16 / 9}
                    />
                    <div className="rotate-btn-group">
                        <div className="rotate-btn">
                            <IconButton color="primary" aria-label="rotate left" component="label" onClick={() => this.handleRotate(90)}>
                                <RotateRightIcon fontSize="large" />
                            </IconButton>
                            <span className="rotate-deg-label">90</span>
                        </div>
                        <div className="rotate-btn">
                            <IconButton color="primary" aria-label="rotate left" component="label" onClick={() => this.handleRotate(-90)}>
                                <RotateLeftIcon fontSize="large" />
                            </IconButton>
                            <span className="rotate-deg-label">-90</span>
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.uploadCropped(false)} className="upload-btn">
                        Upload cropped image
                    </Button>
                    <Button variant="secondary" onClick={() => this.uploadCropped(true)} className="upload-btn">
                        Upload full image
                    </Button>
                </Modal.Footer>
            </ModalStyled>
        );
    }
}