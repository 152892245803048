import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";
import PlateTemplateDetails from "./plateTemplateDetails";
import _ from "lodash";
import { deletePlateTemplate, setDefault } from "../../../services/limsPortalServices/plateTemplateService";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			plateTemplateId: props.data._id,
			plateTemplateData: _.cloneDeep(props.data),
			errors: [],
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this plate template!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				const data = {
					id: this.state.plateTemplateId
				}
				deletePlateTemplate(data).then(res => {
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	};

	handleDefault = () => {
		setDefault(this.state.plateTemplateId).then(res => {
			this.props.context.componentParent.loadGridData();
		})
	}

	handleClose = () => {
		this.setState({ show: false });
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Plate Template
		</Tooltip>
	);

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Plate Template
		</Tooltip>
	);
	
	renderTooltipDefault = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Set as Default
		</Tooltip>
	);

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDelete}
				>
					<button onClick={this.handleDelete} className="edit-order-btn">
						<i className="fas fa-trash"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDefault}
				>
					<button onClick={this.handleDefault} className="edit-order-btn">
						<i className="fas fa-thumbtack"></i>
					</button>
				</OverlayTrigger>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Plate Template</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PlateTemplateDetails
							plateTemplateId={this.state.plateTemplateId}
							context={this.props.context}
							handleClose={this.handleClose}
							plateTemplateData={_.cloneDeep(this.state.plateTemplateData)}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
