import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { updatePatientData, triggerADTProcessForPatient, updatePDFForPatient, handleBirthdayUpdatedNotificationToLab } from "../../../../services/clinicPortalServices/patientEditService";
import { updateResultPDF } from "../../../../services/clinicPortalServices/orderEditService";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { identity, ethnicity, race, states } from "../../../../services/common/optionsData";
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Checkbox, MenuItem, InputAdornment, IconButton, TextField } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import DriverLicensePopup from "./driverLicensePopup";
import toastr from "toastr";
import Swal from "sweetalert2";
import {
	fetchPatientDataById
} from "../../../../services/clinicPortalServices/patientSearchService";
import { getUserRole } from "../../../../services/common/util";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";

export default class PatientDetails extends Component {
	constructor(props) {
		super(props);
		let patientDetails = props && props.data ? props.data : "";
		this.state = {
			show: false,
			birthdayError: false,
			firstName: "",
			lastName: "",
			dob: "",
			gender: "U",
			mrn: "",
			email: "",
			mobile: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
			passport: "",
			is_pregnant: false,
			get_sample_tracking_notification: false,
			race: "",
			ethnicity: "",
			showDriverLicensePopup: false,
			disableEdit: this.props.type === 'insuranceGrid' ? true : false,
			driverLicense: "",
			driverLicenseState: "",
			driverLicenseImage: "",
			custom_info: "",
			internal_note: "",
			facilityId: "",
			facilities: []
		};
		fetchPatientDataById(patientDetails._id)
			.then((data) => {
				this.setState({
					firstName: data.data[0].first_name ? data.data[0].first_name : "",
					lastName: data.data[0].last_name ? data.data[0].last_name : "",
					dob: data.data[0].date_of_birth ? moment(data.data[0].date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY") : "",
					gender: data.data[0].gender ? data.data[0].gender : "U",
					mrn: data.data[0].mrn ? data.data[0].mrn : "",
					email: data.data[0].email ? data.data[0].email : "",
					mobile: data.data[0].mobile ? data.data[0].mobile : "",
					address1: data.data[0].address && data.data[0].address.address1 ? data.data[0].address.address1 : "",
					address2: data.data[0].address && data.data[0].address.address2 ? data.data[0].address.address2 : "",
					city: data.data[0].address && data.data[0].address.city ? data.data[0].address.city : "",
					state: data.data[0].address && data.data[0].address.state ? data.data[0].address.state : "",
					zip: data.data[0].address && data.data[0].address.zip ? data.data[0].address.zip : "",
					country: data.data[0].address && data.data[0].address.country ? data.data[0].address.country : "",
					passport: data.data[0].passport ? data.data[0].passport : "",
					custom_info: data.data[0].custom_info ? data.data[0].custom_info : "",
					internal_note: data.data[0].internal_note ? data.data[0].internal_note : "",
					is_pregnant: data.data[0].is_pregnant ? data.data[0].is_pregnant : false,
					get_sample_tracking_notification: data.data[0].get_sample_tracking_notification ? data.data[0].get_sample_tracking_notification : false,
					race: data.data[0].race ? data.data[0].race : "",
					ethnicity: data.data[0].ethnicity ? data.data[0].ethnicity : "",
					driverLicense: data.data[0].driver_license,
					driverLicenseState: data.data[0].driver_license_state,
					driverLicenseImage: data.data[0].driver_license_image,
					facilityId: data.data[0].facility_id._id
				});
			});

		if (["SuperAdmin", "Admin"].includes(getUserRole())) {
			fetchFacilitiesForOrders().then(res => {
				this.setState({ facilities: res.data })
			});
		}
	}

	handleCreateRequisition = () => {
		this.props.context.componentParent.onQrCodeScanHandler(this.props.data._id);
	}

	togglePregnancy = () => {
		this.setState({ is_pregnant: !this.state.is_pregnant });
	}

	toggleSampleTrackingNotification = () => {
		this.setState({ get_sample_tracking_notification: !this.state.get_sample_tracking_notification })
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleDateChange = (date) => {
		this.setState({ dob: date });
		if (moment(date).format("YYYY-MM-DD") !== 'Invalid date') {
			this.setState({ birthdayError: false });
		} else {
			this.setState({ birthdayError: true });
		}
	}

	handlePatientEditChanges = (e) => {
		e.preventDefault();
		if (moment(this.state.dob).format("YYYY-MM-DD") !== 'Invalid date') {
			if (this.state.firstName && this.state.lastName && (this.state.email || this.state.mobile)) {
				let editParams = {
					_id: this.props.data._id,
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					dob: moment(this.state.dob).format("YYYY-MM-DD"),
					gender: this.state.gender,
					mrn: this.state.mrn,
					email: this.state.email,
					mobile: this.state.mobile,
					address1: this.state.address1,
					address2: this.state.address2,
					city: this.state.city,
					state: this.state.state,
					zip: this.state.zip,
					country: this.state.country,
					passport: this.state.passport,
					custom_info: this.state.custom_info,
					internal_note: this.state.internal_note,
					is_pregnant: this.state.is_pregnant ? this.state.is_pregnant : false,
					get_sample_tracking_notification: this.state.get_sample_tracking_notification ? this.state.get_sample_tracking_notification : false,
					race: this.state.race ? this.state.race : "",
					ethnicity: this.state.ethnicity ? this.state.ethnicity : "",
					facility_id: this.state.facilityId
				};
				let originBirthday = this.props.data.date_of_birth;
				let newBirthday = moment(this.state.dob).format("YYYY-MM-DD");
				if ((!this.props.type || this.props.type === 'patientPortal') && this.props.data.is_incorrect) {
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
							cancelButton: 'order-1',
							denyButton: 'order-2 btn_info',
							confirmButton: 'order-3 btn_primary',
						},
						title: 'This record was marked as incorrect.',
						html: 'Did you correct the information?',
						icon: 'question',
						width: 800,
						showDenyButton: true,
						showCancelButton: true,
						confirmButtonText: "Yes, it's fixed",
						confirmButtonColor: '#dc3741',
						denyButtonText: 'No, save without fixing',
						denyButtonColor: '#7066e0',
						cancelButtonText: "No, I'll do it now",
						allowOutsideClick: false
					}).then((result) => {
						if (result.isConfirmed) {
							editParams.is_incorrect = false;
							editParams.incorrect_history = this.props.data.incorrect_history && this.props.data.incorrect_history.length ? this.props.data.incorrect_history : [];
							editParams.incorrect_history.push({
								status: 'CORRECT',
								note: this.props.data.incorrect_note,
								fixed_by: this.props.type === 'patientPortal' ? 'PATIENT' : 'FACILITY',
								time: moment().format("YYYYMMDDHHmmss")
							})
							editParams.incorrect_note = '';
							updatePatientData(editParams)
								.then((userDetails) => {
									triggerADTProcessForPatient({ patientId: editParams._id }).then(res => {
									})
									this.handlePDFfiles(originBirthday, newBirthday);
								});
						} else if (result.isDenied) {
							updatePatientData(editParams)
								.then((userDetails) => {
									this.handlePDFfiles(originBirthday, newBirthday);
								});
						} else {
							return;
						}
					});
				} else {
					updatePatientData(editParams)
						.then((userDetails) => {
							this.handlePDFfiles(originBirthday, newBirthday);
						});
				}
			} else {
				toastr.warning("Please provide first name and last name, also one of email or mobile to save.");
			}
		} else {
			this.setState({ birthdayError: true });
		}
	};

	handlePDFfiles = (originBirthday, newBirthday) => {
		if (this.props.type) {
			if (this.props.type === 'orderGrid') {
				if (this.props.order.results && this.props.order.results.pdf_path) {
					let testName = this.props.order.test_id.name;
					Swal.fire({
						title: !testName.toLowerCase().includes("sars-cov-2") ? 'Notify Facility?' : 'Notify Patient?',
						html: 'You updated patient on the order grid, which updates this order record, so PDF matched patient information.  Do you want to notify the patient?',
						icon: 'question',
						width: 800,
						showCancelButton: true,
						confirmButtonText: !testName.toLowerCase().includes("sars-cov-2") ? 'Update Result PDF and Notify Facility?' : 'Update Result PDF and Notify Patient',
						confirmButtonColor: '#dc3741',
						cancelButtonText: 'Update Result PDF Only',
						allowOutsideClick: false,
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
							cancelButton: 'order-1',
							confirmButton: 'order-2',
						}
					}).then((result) => {
						this.handleClose();
						this.props.context.componentParent.loadGridData();
						this.regeneratePDFFiles();
						if (result.isConfirmed) {
							this.regenerateResultPDF(true);
						}
					});
				} else {
					this.handleClose();
					this.props.context.componentParent.loadGridData();
					this.regeneratePDFFiles();
				}
			} else {
				this.handleClose();
				if (this.props.context && this.props.context.componentParent) {
					this.props.context.componentParent.loadGridData();
				} else if (this.props.type === "patientPortal") {
					this.props.refreshData();
				}
				this.regeneratePDFFiles();
				if (this.props.type === 'patient_duplicates_grid') {
					this.props.handleCloseDuplicatesPopup();
				}
			}
		} else {
			this.handleClose();
			this.regeneratePDFFiles();
			fetchPatientDataById(this.props.data._id).then(res => {
				if (res && res.data && res.data[0]) {
					this.props.context.componentParent.updateGridRow(this.props.nodeId, res.data[0]);
				}
			})
		}
		if (originBirthday !== newBirthday) {
			handleBirthdayUpdatedNotificationToLab({ patientId: this.props.data._id }).then(res => {
				console.log(res);
			});
		}
	}

	regenerateResultPDF = (isNotify) => {
		const pdfParams = {
			orderId: this.props.order.orderId,
			description: this.props.order.description,
			testType: this.props.order.test_info.specimenType + ", " + this.props.order.test_info.sampleType,
			diagnosis_code: this.props.order.diagnosis_code,
			sample: this.props.order.test_info.sample,
			result: this.props.order.results.value,
			resultValues: this.props.order.results.values,
			collectedDate: this.props.order.test_info.collected ? moment(this.props.order.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "",
			receivedDate: this.props.order.test_info.received ? moment(this.props.order.test_info.received, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "",
			requisition: this.props.order.lab_order_id,
			patientName: this.state.firstName + " " + this.state.lastName,
			email: this.state.email,
			mobile: this.state.mobile,
			facility_source: this.props.order.facility_source,
			mrn: this.state.mrn,
			dob: moment(this.state.dob, "MM/DD/YYYY").format("YYYY-MM-DD"),
			pdfPath: this.props.order.results.pdf_path,
			released: moment(this.props.order.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A"),
			releasedBy: this.props.order.results.releasedBy,
			results: this.props.order.results,
			passport: this.state.passport,
			custom_info: this.state.custom_info,
			internal_note: this.state.internal_note,
			isEnvironmental: this.props.order.facility_id.environmental_monitoring_enabled,
			facility_email: this.props.order.facility_id.contact_email,
			notify: isNotify.toString(),
			facility_id: this.props.order.facility_id._id,
			provider: this.props.order.provider.first_name + ' ' + this.props.order.provider.last_name,
			test_id: this.props.order.test_id._id
		};
		updateResultPDF(pdfParams)
			.then((data) => {
				if (isNotify) {
					toastr.success("We regenerated the PDF and notify to the Patient");
				} else {
					toastr.success("We regenerated the PDF");
				}
			});
	}

	regeneratePDFFiles = () => {
		updatePDFForPatient({ patientId: this.props.data._id }).then(res => {
		});
	}

	handleClose = () => {
		this.props.handleClose();
	};

	showDriverLicensePopup = () => {
		this.setState({ showDriverLicensePopup: true })
	}

	render() {
		return (
			<div>
				<form disabled>
					<div className="row form-row">
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label style={{ fontWeight: "100" }}>First Name <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={this.state.firstName}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Last Name <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="lastName"
									value={this.state.lastName}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label style={{ marginBottom: "0px" }}>Date of Birth <abbr className="text-danger">*</abbr></label>
								<MobileDatePicker
									value={this.state.dob}
									onChange={date => this.handleDateChange(date.$d)}
									maxDate={new Date()}
									disabled={this.state.disableEdit}
									renderInput={props => <TextField
										{...props}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>}
								/>
							</div>
							<div
								className={
									this.state.birthdayError ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is incorrect.
								</i>
							</div>
						</div>
						<div className={"col-12 " + (["SuperAdmin", "Admin"].includes(getUserRole()) ? "col-md-2" : "col-md-4")}>
							<div className="form-group">
								<label>Gender <abbr className="text-danger">*</abbr></label>
								<select
									className="form-control select"
									name="gender"
									value={this.state.gender}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								>
									{identity.map((identity, index) => {
										return (
											<option key={index} value={identity.value}>
												{identity.gender}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className={"col-12 " + (["SuperAdmin", "Admin"].includes(getUserRole()) ? "col-md-2" : "col-md-4")}>
							<div className="form-group">
								<label>MRN <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="mrn"
									disabled
									value={this.state.mrn}
									onChange={this.handleChange}
								/>
							</div>
						</div>

						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Email ID <abbr className="text-danger">*</abbr></label>
								<input
									type="email"
									className="form-control"
									name="email"
									value={this.state.email}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Mobile <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="mobile"
									value={this.state.mobile}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						{
							["SuperAdmin", "Admin"].includes(getUserRole())
							&&
							<div className="col-12 col-md-4">
								<div className="form-group">
									<label>
										Associated Facility <abbr className="text-danger">*</abbr>
									</label>
									<select
										required
										type="text"
										name="facilityId"
										value={this.state.facilityId}
										onChange={this.handleChange}
										className="form-control"
									>
										{
											this.state.facilities
											&&
											this.state.facilities.map((fac) => {
												return (
													<option value={fac._id} key={fac._id}>
														{fac.name} {fac.archived === "archived" ? " ( Archived ) " : ""}
													</option>
												);
											})
										}
									</select>
								</div>
							</div>
						}
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Address Line 1 <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="address1"
									value={this.state.address1}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Address Line 2</label>
								<input
									type="text"
									className="form-control"
									name="address2"
									value={this.state.address2}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>City <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="city"
									value={this.state.city}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>State <abbr className="text-danger">*</abbr></label>
								<select
									className="form-control select"
									name="state"
									value={this.state.state}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								>
									{states.map((state, index) => {
										return (
											<option key={index} value={state.value}>{state.state}</option>
										);
									})}
								</select>
							</div>
						</div>

						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Zip Code <abbr className="text-danger">*</abbr></label>
								<input
									type="text"
									className="form-control"
									name="zip"
									value={this.state.zip}
									onChange={this.handleChange}
									disabled={this.state.disableEdit}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="row">
								<div className="col-12 col-md-8">
									<div className="row">
										<div className="col-12 col-md-6">
											<div className="row">
												<div className="col-12">
													<div className="form-group">
														<FormLabel>
															What is your ethnicity? <abbr className="text-danger">*</abbr>
														</FormLabel>
														<select
															required
															type="text"
															name="ethnicity"
															value={this.state.ethnicity}
															onChange={this.handleChange}
															disabled={this.state.disableEdit}
															className="form-control select"
														>
															{ethnicity.map((item) => {
																return (
																	<option
																		key={item.value}
																		value={item.value}
																	>
																		{item.desc}
																	</option>
																);
															})}
														</select>
													</div>
												</div>
												<div className="col-12">
													<div className="form-group" style={{ marginTop: "20px" }}>
														<MenuItem
															onClick={this.togglePregnancy}
															disabled={this.state.disableEdit}
														>
															<Checkbox checked={this.state.is_pregnant} />
															<div style={{ whiteSpace: "break-spaces" }}>Pregnancy</div>
														</MenuItem>
													</div>
												</div>
												{
													this.props.type === "patientPortal"
													&&
													<div className="col-12">
														<div className="form-group">
															<MenuItem
																onClick={this.toggleSampleTrackingNotification}
																disabled={this.state.disableEdit}
															>
																<Checkbox checked={this.state.get_sample_tracking_notification} />
																<div style={{ whiteSpace: "break-spaces" }}>Receive Sample Tracking Notification</div>
															</MenuItem>
														</div>
													</div>
												}
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="row">
												<div className="col-12">
													<div className="form-group">
														<label>Passport</label>
														<input
															type="text"
															className="form-control"
															name="passport"
															value={this.state.passport}
															onChange={this.handleChange}
															disabled={this.state.disableEdit}
														/>
													</div>
												</div>
												{
													this.props.type !== "patientPortal"
													&&
													<div className="col-12">
														<div className="form-group">
															<label>Custom Info</label>
															<input
																type="text"
																className="form-control"
																name="custom_info"
																value={this.state.custom_info}
																onChange={this.handleChange}
																disabled={this.state.disableEdit ? true : false}
															/>
														</div>
													</div>
												}
											</div>
										</div>
										{
											!/facilityuser|facilityadmin/.test(getUserRole().toLowerCase())
											&&
											this.props.type !== "patientPortal"
											&&
											<div className="col-12">
												<div className="form-group">
													<label>Internal Note</label>
													<textarea
														type="text"
														rows="3"
														className="form-control"
														name="internal_note"
														value={this.state.internal_note}
														onChange={this.handleChange}
														disabled={this.state.disableEdit ? true : false}
													/>
												</div>
											</div>
										}
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<FormControl>
											<FormLabel>
												What is your race? <abbr className="text-danger">*</abbr>
											</FormLabel>
											<RadioGroup
												aria-label="Race *"
												name="race"
												value={this.state.race}
												onChange={this.handleChange}
												style={{ flexDirection: "column" }}
											>
												{
													race.map((race) => {
														return (
															<FormControlLabel
																key={race.value}
																value={race.value}
																control={<Radio />}
																label={race.value}
																disabled={this.state.disableEdit}
															/>
														);
													})
												}
											</RadioGroup>
										</FormControl>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div className="row">
					<div
						className="col-12"
						style={{
							paddingTop: "12px",
							borderTop: "1px solid rgba(0,0,0,.2",
						}}
					>
						<Button
							variant="info"
							onClick={this.showDriverLicensePopup}
						>
							Driver License
						</Button>
						{
							!this.state.disableEdit &&
							<Button
								variant="primary"
								style={{ float: "right", marginLeft: "10px" }}
								onClick={this.handlePatientEditChanges}
							>
								Save Changes
							</Button>
						}

						<Button
							variant="secondary"
							style={{ float: "right" }}
							onClick={this.handleClose}
						>
							Close
						</Button>
					</div>
				</div>
				<DriverLicensePopup
					data={{
						_id: this.props.data._id,
						driver_license: this.state.driverLicense,
						driver_license_state: this.state.driverLicenseState,
						driver_license_image: this.state.driverLicenseImage,
						is_incorrect: this.props.data.is_incorrect,
						incorrect_history: this.props.data.incorrect_history,
						incorrect_note: this.props.data.incorrect_note
					}}
					context={this.props.context}
					type={this.props.type}
					show={this.state.showDriverLicensePopup}
					handleClose={() => { this.setState({ showDriverLicensePopup: false }) }}
					handleParentClose={this.handleClose}
					handlePDFfiles={this.handlePDFfiles}
				/>
			</div >
		);
	}
}
