import React, { Component } from "react";
import moment from "moment";
import ResultCellBtnRenderer from "./resultCellBtnRenderer";
import SampleTrackingRenderer from "./sampleTrackingRenderer";

class ResultData extends Component {
    constructor(props) {
        super(props);

        let isDesktop = true;
        if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(window.navigator.userAgent)) {
            isDesktop = false;
        }

        this.state = {
            isDesktopDev: isDesktop,
        };
    }

    render() {
        return (
            <div className="result-data" style={{ overflowY: "auto" }}>
                <table
                    id="result-table"
                    role="table"
                    style={{ width: "100%" }}
                >
                    <thead>
                        <tr role="row">
                            <th role="columnheader">Test Name</th>
                            <th role="columnheader">Testing Facility</th>
                            <th role="columnheader">Physician</th>
                            <th role="columnheader">Requisition ID</th>
                            <th role="columnheader">Sample ID</th>
                            <th role="columnheader">Collection Date</th>
                            <th role="columnheader">Release Date</th>
                            <th role="columnheader">Sample Tracking</th>
                            <th role="columnheader">Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.result.map((resultItem, index) => {
                                return (
                                    <tr role="row" key={index}>
                                        <td role="cell">
                                            {resultItem.test_id.name}
                                        </td>
                                        <td role="cell">
                                            {resultItem.facility_source}
                                        </td>
                                        <td role="cell">
                                            {resultItem.provider.first_name} {resultItem.provider.last_name}
                                        </td>
                                        <td role="cell">
                                            {resultItem.lab_order_id}
                                        </td>
                                        <td role="cell">
                                            {resultItem.test_info.sample}
                                        </td>
                                        <td role="cell">
                                            {moment(resultItem.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}
                                        </td>
                                        <td role="cell">
                                            {resultItem.results && resultItem.results.released ? moment(resultItem.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : ""}
                                        </td>
                                        <td role="cell">
                                            <SampleTrackingRenderer
                                                data={resultItem}
                                            />
                                        </td>
                                        <td>
                                            <ResultCellBtnRenderer
                                                patientData={this.props.patientData}
                                                facilityData={this.props.facilityData}
                                                data={resultItem}
                                                isNotMobile={this.state.isDesktopDev}
                                            />
                                        </td>
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ResultData;