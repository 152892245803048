import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgChartsReact } from "ag-charts-react";
import moment from "moment";
import _ from "lodash";
import { searchOrdersReports } from "../../../../services/clinicPortalServices/orderSearchService";
import {
    getViewSettings,
    saveViewDefault
} from "../../../../services/clinicPortalServices/userViewSettings";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import {
    getUserRole,
    dateComparator,
    getUserDetails
} from "../../../../services/common/util";
import OrderReportsMenu from "./orderReportsMenu";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicOrderReportsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isArchived: false,
            isLoading: false,
            gridData: [],
            companies: [],
            chartInfo: {
                theme: "ag-default",
                autoSize: true,
                series: [
                    {
                        data: [],
                        xKey: "time",
                        yKey: "orders",
                        stroke: "#8bc24a",
                        marker: {
                            fill: "#8bc24a",
                            stroke: "#658d36"
                        }
                    }
                ],
                axes: [
                    {
                        type: "time",
                        position: "bottom"
                    },
                    {
                        type: "number",
                        position: "left"
                    }
                ],
                legend: { enabled: false }
            },
            facilityIds: [],
            user_role: getUserRole(),
            userId: window.localStorage.getItem("USER_ID"),
            searchFilters: {
                facility_id: [],
                from_date:
                    moment().subtract(3, "days").format("YYYY-MM-DD") +
                    "T00:00",
                to_date:
                    moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                dateRange: "custom",
                date_view: "daily",
                filter_by: "received_date"
            },
            columnDefsCompany: [
                {
                    headerName: "Name",
                    minWidth: 300,
                    field: "name",
                    cellRenderer: "agGroupCellRenderer",
                    cellRendererParams: { suppressCount: true }
                },
                {
                    headerName: "Category",
                    minWidth: 200,
                    field: "category",
                    resizable: true
                },
                {
                    headerName: "Date Range",
                    minWidth: 200,
                    field: "dateRange",
                    resizable: true
                },
                {
                    headerName: "Number of Orders",
                    minWidth: 150,
                    field: "orders"
                }
            ],
            groupDefaultExpanded: 0,
            getDataPath: function (data) {
                return data.orgHierarchy;
            },
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            context: { componentParent: this },
            excelStyles: [
                {
                    id: "header",
                    interior: {
                        color: "#aaaaaa",
                        pattern: "Solid"
                    }
                },
                {
                    id: "body",
                    interior: {
                        color: "#dddddd",
                        pattern: "Solid"
                    }
                }
            ],
            facilities: [],
            tests: [],
            testIds: []
        };
        getAllTests({ type: "all" }).then((res) => {
            if (res && res.data) {
                let testIds = res.data.map((test) => {
                    return test._id;
                });
                this.testIds = testIds;
                this.setState({ tests: res.data, testIds: testIds }, () => {
                    this.loadFacilities(this.state.isArchived);
                });
            }
        });
    }

    componentDidUpdate() {
        if (this.state.isLoading === true) {
            this.loadGridData();
        }
    }
    loadFacilities = (isArchived) => {
        let facilityIds = [];
        let filters = this.state.searchFilters;
        let facilities = this.state.facilities;

        let isAllFacilities = false;
        if (
            filters.facility_id.length > 0 &&
            filters.facility_id.length === facilities.length &&
            facilities.length > 0
        ) {
            isAllFacilities = true;
        }

        getCompanyWithFacility(isArchived).then((response) => {
            facilities = response.data;
            if (facilities.length > 0) {
                facilities.map((fac) => {
                    facilityIds.push(fac._id);
                    return null;
                });
                this.facilityIds = facilityIds;
                const facilityIdStorage =
                    window.localStorage.getItem("FACILITY_ID");
                if (isAllFacilities) {
                    filters.facility_id = facilityIds;
                } else if (
                    facilityIdStorage &&
                    facilityIdStorage !== undefined &&
                    Array.isArray(JSON.parse(facilityIdStorage)) &&
                    JSON.parse(facilityIdStorage).length > 0
                ) {
                    filters.facility_id = JSON.parse(facilityIdStorage);
                } else if (facilityIds.length >= 1) {
                    filters.facility_id = facilityIds;
                } else {
                    filters.facility_id = facilities[0]._id;
                }
            } else {
                filters.facility_id = [];
            }
            this.setState({
                searchFilters: filters,
                facilities,
                isLoading: true
            });
        });
    };

    onIsArchivedInformation = (event) => {
        this.setState({ isArchived: event.target.checked });
        this.loadFacilities(event.target.checked);
    };

    saveState = () => {
        let userId = window.localStorage.getItem("USER_ID");
        const columnState = this.gridColumnApi.getColumnState();
        let pageSize = document.getElementById("page-size").value;
        let saveObject = {
            user_id: userId,
            type: settingConstants.CHART,
            page: settingConstants.REPORTS,
            grid_views: [
                {
                    name: this.state.gridName,
                    page_size: pageSize,
                    columns: columnState
                }
            ]
        };
        saveViewDefault(saveObject).then(() => {
            toastr.success("Saved successfully.");
        });
    };

    checkRealFacilities = (searchFilters) => {
        let realFacilities = [];
        searchFilters.facility_id.map((facilityId) => {
            if (
                this.state.facilities.find(
                    (facility) => facility._id === facilityId
                )
            ) {
                realFacilities.push(facilityId);
            }
            return null;
        });
        return realFacilities;
    };

    loadGridData = () => {
        let { chartInfo, searchFilters } = this.state;
        searchFilters.facility_id = this.checkRealFacilities(searchFilters);
        searchFilters.testIds = this.state.testIds;
        chartInfo.series[0].data = [];
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }
        searchOrdersReports(searchFilters).then((response) => {
            this.setState(
                { rowData: response.data.orders, isLoading: false },
                () => {
                    this.setGridData(response.data.orders, searchFilters);
                }
            );
            if (!response.data.orders.length) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        });
    };

    setGridData = (rowData, searchFilters) => {
        let { chartInfo, facilities, isLoading } = this.state;
        let gridCompany = [],
            gridData = [],
            dateTemp = null,
            chartData = [],
            formatDate = "",
            dateRange = "",
            offsetKey = "",
            dateRangeCol = "",
            categoryDate = "",
            dateSearchBy = "",
            dateUpdated = moment(),
            yearUpdated = "",
            weekUpdated = "",
            monthUpdated = "";
        let dateRangeAll =
            moment(searchFilters.from_date).format("MM/DD/YYYY") +
            " - " +
            moment(searchFilters.to_date).format("MM/DD/YYYY");
        if (!isLoading && rowData.length) {
            switch (searchFilters.date_view) {
                case "custom":
                    formatDate = "MM/DD/YYYY";
                    offsetKey = "d";
                    break;
                case "week":
                    formatDate = "WW/YYYY";
                    offsetKey = "w";
                    break;
                case "month":
                    formatDate = "MM/YYYY";
                    offsetKey = "M";
                    break;
                case "year":
                    formatDate = "YYYY";
                    offsetKey = "y";
                    break;
                default:
                    formatDate = "MM/DD/YYYY";
                    offsetKey = "d";
                    break;
            }
            categoryDate =
                searchFilters.date_view === "daily"
                    ? "Number of orders a day"
                    : "Number of orders a " + searchFilters.date_view;
            rowData.forEach(function (val, i) {
                dateSearchBy =
                    searchFilters.filter_by === "received_date"
                        ? val.test_info.received
                        : val.results.released;
                if (searchFilters.date_view === "week") {
                    yearUpdated = moment(dateSearchBy, "YYYYMMDDHHmmss").year();
                    weekUpdated = moment(dateSearchBy, "YYYYMMDDHHmmss").week();
                    monthUpdated = moment(
                        dateSearchBy,
                        "YYYYMMDDHHmmss"
                    ).month();
                    if (monthUpdated === 11 && weekUpdated === 1) {
                        yearUpdated += 1;
                    } else if (monthUpdated === 0 && weekUpdated === 52) {
                        yearUpdated -= 1;
                    }
                    dateUpdated = weekUpdated + "/" + yearUpdated;
                } else {
                    dateUpdated = moment(dateSearchBy, "YYYYMMDDHHmmss").format(
                        formatDate
                    );
                }
                let facilityDetail = facilities.find(
                    (x) => x._id === val.facility_id
                );
                let companyDetail = facilities.find(
                    (x) => x._id === facilityDetail.parent_id
                );
                // --- Add item to chartData
                if (dateTemp !== dateUpdated) {
                    chartData.push({
                        time: Date.parse(
                            moment(dateUpdated, formatDate).format("MM/DD/YYYY")
                        ),
                        orders: 1,
                        timeString: dateUpdated
                    });
                } else {
                    chartData[chartData.length - 1].orders++;
                }
                // --- Add item to girdData
                dateRange =
                    searchFilters.date_view === "daily"
                        ? moment(dateUpdated, formatDate).format("MM/DD/YYYY")
                        : moment(dateUpdated, formatDate).format("MM/DD/YYYY") +
                          " - " +
                          moment(dateUpdated, formatDate)
                              .add(1, offsetKey)
                              .subtract(1, "d")
                              .format("MM/DD/YYYY");
                dateRangeCol = facilityDetail.name + " (" + dateRange + ")";
                let gridCompanyRow = gridCompany.find(
                    (x) => x._id === companyDetail._id
                );
                if (gridCompanyRow) {
                    gridCompanyRow.orders++;
                    let gridFacilityRow = gridCompanyRow.gridFacility.find(
                        (x) =>
                            x._id === facilityDetail._id &&
                            x.parent_id === companyDetail._id
                    );
                    if (gridFacilityRow) {
                        gridFacilityRow.orders++;
                        let gridDateRow = gridFacilityRow.gridDate.find(
                            (x) =>
                                x._id === facilityDetail._id &&
                                x.parent_id === companyDetail._id &&
                                x.dateUpdated === dateUpdated
                        );
                        if (gridDateRow) {
                            gridDateRow.orders++;
                        } else {
                            gridFacilityRow.gridDate.push({
                                _id: facilityDetail._id,
                                parent_id: companyDetail._id,
                                category: categoryDate,
                                name: facilityDetail.name,
                                orgHierarchy: [
                                    companyDetail.name,
                                    facilityDetail.name,
                                    dateRangeCol
                                ],
                                orders: 1,
                                dateUpdated: dateUpdated,
                                dateRange: dateRange
                            });
                        }
                    } else {
                        gridCompanyRow.gridFacility.push({
                            _id: facilityDetail._id,
                            parent_id: companyDetail._id,
                            category: "Facility",
                            name: facilityDetail.name,
                            orgHierarchy: [
                                companyDetail.name,
                                facilityDetail.name
                            ],
                            orders: 1,
                            dateRange: dateRangeAll,
                            gridDate: [
                                {
                                    _id: facilityDetail._id,
                                    parent_id: companyDetail._id,
                                    name: facilityDetail.name,
                                    category: categoryDate,
                                    orgHierarchy: [
                                        companyDetail.name,
                                        facilityDetail.name,
                                        dateRangeCol
                                    ],
                                    orders: 1,
                                    dateUpdated: dateUpdated,
                                    dateRange: dateRange
                                }
                            ]
                        });
                    }
                } else {
                    gridCompany.push({
                        _id: companyDetail._id,
                        name: companyDetail.name,
                        orgHierarchy: [companyDetail.name],
                        category: "Company",
                        orders: 1,
                        dateRange: dateRangeAll,
                        date_updated: dateUpdated,
                        gridFacility: [
                            {
                                _id: facilityDetail._id,
                                parent_id: companyDetail._id,
                                name: facilityDetail.name,
                                orgHierarchy: [
                                    companyDetail.name,
                                    facilityDetail.name
                                ],
                                category: "Facility",
                                orders: 1,
                                dateRange: dateRangeAll,
                                gridDate: [
                                    {
                                        _id: facilityDetail._id,
                                        parent_id: companyDetail._id,
                                        category: categoryDate,
                                        name: facilityDetail.name,
                                        orgHierarchy: [
                                            companyDetail.name,
                                            facilityDetail.name,
                                            dateRangeCol
                                        ],
                                        orders: 1,
                                        dateRange: dateRange,
                                        dateUpdated: dateUpdated
                                    }
                                ]
                            }
                        ]
                    });
                }
                dateTemp = dateUpdated;
            });
            gridCompany.forEach((com) => {
                gridData.push(com);
                com.gridFacility.forEach((fac) => {
                    gridData.push(fac);
                    fac.gridDate.forEach((dat) => {
                        gridData.push(dat);
                    });
                });
            });
        }
        chartInfo.series[0].data = chartData;
        this.setState({ chartInfo, gridData: gridData, isLoading: false });
    };

    loadGridSchema = () => {
        let userId = window.localStorage.getItem("USER_ID");
        let params = {
            user_id: userId,
            type: settingConstants.CHART,
            page: settingConstants.REPORTS
        };
        getViewSettings(params).then((response) => {
            const columnState =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                })?.columns;
            if (columnState) {
                this.gridColumnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true
                });
            } else {
                this.gridColumnApi.resetColumnState();
            }

            const pageSize =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                })?.page_size;
            document.getElementById("page-size").value =
                pageSize && pageSize > 0 ? pageSize : 20;
            this.onPageSizeChanged();
        });
    };

    updateDateRange = (dateRange) => {
        let filters = this.state.searchFilters;
        if (filters.dateRange !== dateRange) {
            filters.dateRange = dateRange;
            switch (dateRange) {
                case "today":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().format("YYYY-MM-DD") + "T00:00";
                    break;
                case "week":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                case "month":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                default:
                    break;
            }
            this.setState({ searchFilters: filters });
            if (dateRange !== "custom") {
                this.loadGridData();
            }
        }
    };

    setDateRange = (searchFilters) => {
        if (
            moment(searchFilters.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "today";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("week").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "week";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("month").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "month";
            } else {
                searchFilters.dateRange = "custom";
            }
        } else {
            searchFilters.dateRange = "custom";
        }
    };

    handleDateFiltersChange = (dateTime, type) => {
        let filters = this.state.searchFilters;
        let filterIsValid = true;
        if (type === "from_date") {
            if (moment(filters.to_date).isAfter(dateTime)) {
                filters.from_date = dateTime;
            } else {
                filterIsValid = false;
            }
        } else {
            if (moment(dateTime).isAfter(filters.from_date)) {
                filters.to_date = dateTime;
            } else {
                filterIsValid = false;
            }
        }
        if (filterIsValid) {
            this.setDateRange(filters);
            this.setState({ searchFilters: filters });
            this.loadGridData();
        }
    };

    handleFiltersChange = (e) => {
        let { facilities, rowData } = this.state;
        let filters = this.state.searchFilters;
        switch (e.target.name) {
            case "date_view": {
                filters.date_view = e.target.value;
                break;
            }
            case "filter_by": {
                filters.filter_by = e.target.value;
                break;
            }
            case "facility_id": {
                let facilityIdChanged = [],
                    isAdded = false,
                    facilitiesIn = [],
                    facilityIdsTemp = [];
                let newFacilityIds = e.target.value;
                if (e.target.value.includes("all")) {
                    if (
                        filters.facility_id.length === this.facilityIds.length
                    ) {
                        filters.facility_id = [];
                    } else {
                        filters.facility_id = _.cloneDeep(this.facilityIds);
                    }
                } else {
                    if (filters.facility_id.length > newFacilityIds.length) {
                        facilityIdChanged = filters.facility_id.find(
                            (val) => !newFacilityIds.includes(val)
                        );
                    } else {
                        facilityIdChanged = newFacilityIds.find(
                            (val) => !filters.facility_id.includes(val)
                        );
                        isAdded = true;
                    }
                    let facilityChanged = facilities.find(
                        (facility) => facility._id === facilityIdChanged
                    );
                    facilityIdsTemp = _.cloneDeep(filters.facility_id);
                    // If company is changed
                    if (facilityChanged.type === "company") {
                        let companyChanged = facilityChanged;
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyChanged._id) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        let index = facilityIdsTemp.indexOf(companyChanged._id);
                        if (facilitiesIn.length > 0) {
                            // If company is selected
                            if (isAdded) {
                                facilityIdsTemp = _.cloneDeep(
                                    filters.facility_id
                                );
                                // Add company Id
                                facilityIdsTemp.push(companyChanged._id);
                                // Add facility Ids
                                facilitiesIn.forEach((facId) => {
                                    if (!facilityIdsTemp.includes(facId)) {
                                        facilityIdsTemp.push(facId);
                                    }
                                });
                            } else {
                                // If company is unselected
                                facilityIdsTemp = _.cloneDeep(
                                    filters.facility_id
                                );
                                // Remove company Id
                                let index = facilityIdsTemp.indexOf(
                                    companyChanged._id
                                );
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                                facilitiesIn.forEach((facId) => {
                                    let index = facilityIdsTemp.indexOf(facId);
                                    if (index > -1) {
                                        facilityIdsTemp.splice(index, 1);
                                    }
                                });
                            }
                        } else {
                            isAdded
                                ? facilityIdsTemp.push(companyChanged._id)
                                : facilityIdsTemp.splice(index, 1);
                        }
                    } else {
                        // If facility is changed
                        let companyId = facilityChanged.parent_id;
                        let facilitiesIn = [];
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyId) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        facilityIdsTemp = _.cloneDeep(filters.facility_id);
                        // If facility Id is selected
                        if (isAdded) {
                            // Add facility Id
                            facilityIdsTemp.push(facilityChanged._id);
                            if (!filters.facility_id.includes(companyId)) {
                                facilityIdsTemp.push(companyId);
                            }
                        } else {
                            // If facility Id is unselected
                            facilityIdsTemp = _.cloneDeep(filters.facility_id);
                            // Remove facility Id
                            let index = facilityIdsTemp.indexOf(
                                facilityChanged._id
                            );
                            if (index > -1) {
                                facilityIdsTemp.splice(index, 1);
                            }
                            // Remove company Id
                            let facilitiesInFilters = [];
                            facilitiesIn.forEach((fac) => {
                                if (facilityIdsTemp.includes(fac)) {
                                    facilitiesInFilters.push(fac);
                                }
                            });
                            if (facilitiesInFilters.length === 0) {
                                index = facilityIdsTemp.indexOf(companyId);
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                            }
                        }
                    }
                    filters.facility_id = _.cloneDeep(facilityIdsTemp);
                }
                window.localStorage.setItem(
                    "FACILITY_ID",
                    JSON.stringify(filters.facility_id)
                );
                break;
            }
            case "testIds": {
                let testIds = this.state.testIds;
                if (e.target.value.includes("all")) {
                    if (testIds.length === this.state.tests.length) {
                        testIds = [];
                    } else {
                        testIds = _.cloneDeep(this.testIds);
                    }
                } else {
                    testIds = e.target.value;
                }
                this.setState({ testIds: testIds }, () => {
                    this.loadGridData();
                });
                break;
            }
            default:
                break;
        }
        this.setState({ searchFilters: filters });
        if (e.target.name === "date_view") {
            this.setGridData(rowData, filters);
        } else {
            if (e.target.name !== "testIds") {
                this.setState({ isLoading: true });
            }
        }
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridSchema();
    };

    sortByColumn = (type, sortType) => {
        if (type === "received_date") {
            type = "test_info.received";
        }
        if (type === "released_date") {
            type = "results.released";
        }
        this.gridColumnApi.applyColumnState({
            state: [
                {
                    colId: type,
                    sort: sortType,
                    comparator: dateComparator
                }
            ],
            defaultState: { sort: null }
        });
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Order_Total_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Orders Total"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.OrderTotalGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.OrderTotalGridExported
        };
        createAudit(auditData);
    };

    onPageSizeChanged = (e) => {
        let value = document.getElementById("page-size").value;
        this.gridApi.paginationSetPageSize(Number(value));
    };

    resetState = () => {
        this.clearFilter();
    };

    clearFilter = () => {
        let filters = this.state.searchFilters;
        filters.date_view = "daily";
        filters.filter_by = "received_date";
        filters.from_date =
            moment().subtract(3, "days").format("YYYY-MM-DD") + "T00:00";
        filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
        filters.dateRange = "custom";
        if (
            this.state.user_role &&
            (this.state.user_role.toLowerCase().trim() === "superadmin" ||
                this.state.user_role.toLowerCase().trim() === "admin")
        ) {
            filters.facility_id = this.facilityIds;
        } else {
            if (this.state.facilities.length > 1) {
                filters.facility_id = this.facilityIds;
            } else if (this.state.facilities.length === 1) {
                filters.facility_id = this.state.facilities[0]._id;
            } else {
                filters.facility_id = [];
            }
        }
        window.localStorage.removeItem("FACILITY_ID");
        this.setState({
            searchFilters: filters,
            isLoading: true,
            testIds: this.testIds
        });
    };

    render() {
        const { facilities, searchFilters } = this.state;
        return (
            <div className="clinic-contain">
                <OrderReportsMenu
                    onIsArchivedInformation={(event) => {
                        this.onIsArchivedInformation(event);
                    }}
                    isArchived={this.state.isArchived}
                    facilities={facilities}
                    facilityId={searchFilters.facility_id}
                    from_date={searchFilters.from_date}
                    to_date={searchFilters.to_date}
                    date_range={searchFilters.dateRange}
                    date_view={searchFilters.date_view}
                    filter_by={searchFilters.filter_by}
                    onFilterTextChange={this.onFilterTextChange}
                    handleFiltersChange={this.handleFiltersChange}
                    handleDateFiltersChange={this.handleDateFiltersChange}
                    updateDateRange={this.updateDateRange}
                    clearFilter={this.clearFilter}
                    onPageSizeChanged={this.onPageSizeChanged}
                    saveState={this.saveState}
                    resetState={this.resetState}
                    onBtnExport={this.onBtnExport}
                    tests={this.state.tests}
                    testIds={this.state.testIds}
                />
                <ThemeContext.Consumer>
                    {({ themeName }) => (
                        <>
                            <div style={{ height: "300px" }}>
                                <AgChartsReact
                                    options={
                                        themeName === "Light"
                                            ? this.state.chartInfo
                                            : {
                                                  ...this.state.chartInfo,
                                                  theme: "ag-default-dark"
                                              }
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    padding: "15px"
                                }}
                            >
                                <div
                                    id="myGrid"
                                    style={{
                                        height: "100%",
                                        width: "100%"
                                    }}
                                    className={
                                        themeName === "Light"
                                            ? "ag-theme-alpine"
                                            : "ag-theme-alpine-dark"
                                    }
                                >
                                    <AgGridReact
                                        rowData={this.state.gridData}
                                        groupDisplayType={"custom"}
                                        rowGroupPanelShow={"never"}
                                        columnDefs={
                                            this.state.columnDefsCompany
                                        }
                                        defaultColDef={this.state.defaultColDef}
                                        treeData={true}
                                        animateRows={true}
                                        groupDefaultExpanded={
                                            this.state.groupDefaultExpanded
                                        }
                                        getDataPath={this.state.getDataPath}
                                        onGridReady={this.onGridReady}
                                        masterDetail={true}
                                        pagination={true}
                                        paginationPageSize={20}
                                        paginationNumberFormatter={
                                            this.state.paginationNumberFormatter
                                        }
                                        excelStyles={this.state.excelStyles}
                                        context={this.state.context}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </ThemeContext.Consumer>
            </div>
        );
    }
}

export default ClinicOrderReportsGrid;
