import React, { Component } from "react";
import { phoneNumberFormatter } from "../../../../services/common/util";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    InputAdornment
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import {
    isValidEmail,
    isValidPhoneNumber
} from "../../../../services/common/util";
import moment from "moment";
import { serviceConstants } from "../../../../services/common/constants";
import {
    getCheckoutSessionIdForVIP,
    checkTime
} from "../../../../services/clinicPortalServices/vipPaymentsServices";

class FormInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe: null,
            date: moment().format("YYYYMMDD"),
            ampm: "am",
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            email: "",
            mobile: "",
            facilityId: "",
            formDisabled: false,
            errors: []
        };
    }

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    componentDidMount() {
        if (window.Stripe) {
            this.setState({
                stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY)
            });
        } else {
            document
                .querySelector("#stripe-js")
                .addEventListener("load", () => {
                    // Create Stripe instance once Stripe.js loads
                    this.setState({
                        stripe: window.Stripe(
                            serviceConstants.STRIPE_API_PUBLIC_KEY
                        )
                    });
                });
        }

        const timeInfo = {
            date: this.state.date,
            ampm: this.state.ampm
        };
        this.checkTimeIsOk(timeInfo);
    }

    checkTimeIsOk = (timeInfo) => {
        checkTime(timeInfo).then((response) => {
            if (!response.isOk) {
                this.setState({ formDisabled: true });
            } else {
                this.setState({ formDisabled: false });
            }
        });
    };

    handleChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        if (key === "mobile") {
            this.setState({
                mobile: phoneNumberFormatter(value)
            });
        } else {
            this.setState({ [key]: value });
        }

        if (key === "date" || key === "ampm") {
            let timeInfo = {};
            if (key === "date") {
                timeInfo.date = value;
                timeInfo.ampm = this.state.ampm;
            } else {
                timeInfo.date = this.state.date;
                timeInfo.ampm = value;
            }
            this.checkTimeIsOk(timeInfo);
        }
    };

    handleDateChange = (dateTime) => {
        this.setState({ dateOfBirth: dateTime });
    };

    reserve = () => {
        let errors = [];
        if (!this.state.firstName) {
            errors.push("firstName");
        }
        if (!this.state.lastName) {
            errors.push("lastName");
        }
        if (!this.state.dateOfBirth) {
            errors.push("dateOfBirth");
        }
        if (
            this.state.dateOfBirth &&
            moment(this.state.dateOfBirth).format("YYYY-MM-DD") ===
                "Invalid date"
        ) {
            errors.push("wrongBirthday");
        }
        if (!this.state.email) {
            errors.push("email");
        }
        if (this.state.email && !isValidEmail(this.state.email)) {
            errors.push("wrongEmail");
        }
        if (!this.state.mobile) {
            errors.push("mobile");
        }
        if (this.state.mobile && !isValidPhoneNumber(this.state.mobile)) {
            errors.push("wrongMobile");
        }
        if (errors.length) {
            this.setState({ errors: errors });
        } else {
            this.setState({ errors: [] });
            let info = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                date_of_birth: moment(this.state.dateOfBirth).format(
                    "YYYY-MM-DD"
                ),
                email: this.state.email,
                mobile: this.state.mobile,
                date: this.state.date,
                ampm: this.state.ampm
            };
            getCheckoutSessionIdForVIP(info).then((response) => {
                if (response.sessionId) {
                    this.state.stripe.redirectToCheckout({
                        sessionId: response.sessionId
                    });
                }
            });
        }
    };

    render() {
        return (
            <div style={{ padding: "50px 20px 40px 20px" }}>
                <div className="vip-reserve-form">
                    <h3>Select Time You Will Go for Testing</h3>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel>Select Date</InputLabel>
                                    <Select
                                        name="date"
                                        value={this.state.date}
                                        onChange={this.handleChange}
                                        label="Select Date"
                                    >
                                        <MenuItem
                                            key="today"
                                            value={moment().format("YYYYMMDD")}
                                        >
                                            Today (
                                            {moment().format("MM/DD/YYYY")})
                                        </MenuItem>
                                        <MenuItem
                                            key="tomorrow"
                                            value={moment()
                                                .add(1, "days")
                                                .format("YYYYMMDD")}
                                        >
                                            Tomorrow (
                                            {moment()
                                                .add(1, "days")
                                                .format("MM/DD/YYYY")}
                                            )
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel>Select AM or PM</InputLabel>
                                    <Select
                                        name="ampm"
                                        value={this.state.ampm}
                                        onChange={this.handleChange}
                                        label="Select AM or PM"
                                    >
                                        <MenuItem key="am" value="am">
                                            AM
                                        </MenuItem>
                                        <MenuItem key="pm" value="pm">
                                            PM
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <br />
                    {this.state.formDisabled && (
                        <div className="text-center text-danger">
                            {moment().format("YYYYMMDD") === this.state.date
                                ? "Today"
                                : "Tomorrow"}{" "}
                            {this.state.ampm.toUpperCase()} VIP spots not longer
                            available
                            <br />
                            <br />
                        </div>
                    )}
                    <h3>Enter Patient Info</h3>
                    <br />
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    value={this.state.firstName}
                                    name="firstName"
                                    onChange={this.handleChange}
                                    disabled={this.state.formDisabled}
                                />
                                <div
                                    className={
                                        this.hasError("firstName")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    value={this.state.lastName}
                                    name="lastName"
                                    onChange={this.handleChange}
                                    disabled={this.state.formDisabled}
                                />
                                <div
                                    className={
                                        this.hasError("lastName")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <MobileDatePicker
                                    value={this.state.dateOfBirth}
                                    onChange={(dateTime) =>
                                        this.handleDateChange(dateTime.$d)
                                    }
                                    label="Date Of Birth"
                                    maxDate={new Date()}
                                    disabled={this.state.formDisabled}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <EventIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                <div
                                    className={
                                        this.hasError("dateOfBirth")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                                <div
                                    className={
                                        this.hasError("wrongBirthday")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is invalid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <TextField
                                    label="Email Address"
                                    variant="outlined"
                                    value={this.state.email}
                                    name="email"
                                    onChange={this.handleChange}
                                    disabled={this.state.formDisabled}
                                />
                                <div
                                    className={
                                        this.hasError("email")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                                <div
                                    className={
                                        this.hasError("wrongEmail")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is invalid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <TextField
                                    label="Cell Phone #"
                                    variant="outlined"
                                    value={this.state.mobile}
                                    name="mobile"
                                    onChange={this.handleChange}
                                    disabled={this.state.formDisabled}
                                />
                                <div
                                    className={
                                        this.hasError("mobile")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                                <div
                                    className={
                                        this.hasError("wrongMobile")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is invalid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-12"
                            style={{ textAlign: "center", marginTop: "40px" }}
                        >
                            <div className="form-group">
                                <button
                                    className="btn btn-primary pay-btn"
                                    disabled={
                                        this.state.formDisabled ? true : false
                                    }
                                    onClick={this.reserve}
                                >
                                    Make Secure $50 Payment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormInfo;
