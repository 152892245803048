import { apiInterfaceForJson } from "../common/apiManager";

export const getAllMaintenanceEvents = () => {
    return apiInterfaceForJson("/maintenanceEvent/v1/", "GET");
};

export const searchMaintenanceEvent = (query) => {
    return apiInterfaceForJson("/maintenanceEvent/v1/search", "POST", query);
}

export const getMaintenanceEventById = (id) => {
    return apiInterfaceForJson("/maintenanceEvent/v1/" + id, "GET");
}

export const createMaintenanceEvent = (info) => {
    return apiInterfaceForJson("/maintenanceEvent/v1/", "POST", info);
}

export const updateMaintenanceEvent = (info) => {
    return apiInterfaceForJson("/maintenanceEvent/v1/", "PUT", info);
}

export const deleteMaintenanceEvent = (info) => {
    return apiInterfaceForJson("/maintenanceEvent/v1/delete", "POST", info);
}