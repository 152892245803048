import React from "react";
import { BLS_LOGO } from "./common/imgLogoes";
import { serviceConstants } from "../services/common/constants";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import classNames from "classnames";

const Footer = (props) => {
    const { t } = useTranslation();

    return (
        <footer
            className={classNames("footer footer-changes", {
                "footer-dark-enabled":
                    window.location.pathname !== "/" &&
                    window.location.pathname !== "/home" &&
                    window.location.pathname.split("/")[1] === "lims"
            })}
        >
            <div className="footer-bottom">
                <div className="container-fluid">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-4 d-flex justify-content-center align-items-center">
                                <div className="terms-agree-text text-center ">
                                    <p className="mb-0">
                                        <Link
                                            to="/terms-and-conditions"
                                            target="_blank"
                                        >
                                            {t("Terms and conditions")}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-logo-div">
                                    <p className="mb-3 mt-3">
                                        Copyright &copy;{" "}
                                        {new Date().getFullYear()} Benchlab,
                                        LLC. ({serviceConstants.VERSION})
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <div className="footer-logo-div">
                                    <img
                                        className="footer-img"
                                        src={BLS_LOGO}
                                        alt=""
                                    />
                                    {/* <a href="#up" className="footer-a" style={{ position: 'absolute', right: '20px' }} onClick={() => { window.scrollTo(0, 0) }}>
										<i className="fas fa-chevron-up"></i>
									</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default withTranslation()(Footer);
