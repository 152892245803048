import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { getRowColumnIndexForQuantPlate, getCellIndexForQuantPlate, getPlateCellCount } from "../../../services/common/util";
import { quantPlateRows, quantPlateColumns } from "../../../services/common/optionsData";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class SelectPlateTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlateTemplate: null
        };
    }

    componentDidMount() {
    }

    handleSelect = (plateTemplate) => {
        this.setState({
            selectedPlateTemplate: plateTemplate
        })
    }

    onHide = () => {
        this.setState({
            selectedPlateTemplate: null
        })
        this.props.onHide();
    }

    createNewPlateWithTemplate = () => {
        this.onHide();
        let selectedPlateTemplate;
        if (this.state.selectedPlateTemplate) {
            selectedPlateTemplate = this.state.selectedPlateTemplate
        } else {
            selectedPlateTemplate = this.props.allPlateTemplates.filter(item => item.is_default)[0];
        }
        this.props.createNewPlateWithTemplate(selectedPlateTemplate);
    }

    render() {
        let btnDisabled = true;
        if (this.state.selectedPlateTemplate || (this.props.allPlateTemplates && this.props.allPlateTemplates.length && this.props.allPlateTemplates.filter(item => item.is_default).length)) {
            btnDisabled = false;
        }
        return <ModalStyled
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.show}
            onHide={this.onHide}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please Select Plate Template
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        {
                            this.props.allPlateTemplates.length > 0
                            &&
                            this.props.allPlateTemplates.map((plateTemplate, key) => {
                                let control_spots = []
                                if (plateTemplate.control_spots && getPlateCellCount(plateTemplate.control_spots)) {
                                    for (const [key, value] of Object.entries(plateTemplate.control_spots)) {
                                        if (value) {
                                            const { row, column } = getRowColumnIndexForQuantPlate(key);
                                            control_spots.push(
                                                quantPlateRows[row] + quantPlateColumns[column] + " (" + value + ")"
                                            );
                                        }
                                    }
                                }
                                let activeClassName = '';
                                if ((!this.state.selectedPlateTemplate && plateTemplate.is_default) || (plateTemplate === this.state.selectedPlateTemplate)) {
                                    activeClassName = 'active';
                                }

                                return <div className="col-md-6" key={key}>
                                    <div className={"mt-2 plate-template-box " + activeClassName} onClick={() => this.handleSelect(plateTemplate)}>
                                        <div className="plate-table d-flex justify-content-center small-cell">
                                            <table>
                                                <tbody>
                                                    {
                                                        quantPlateRows.map((row, index) => {
                                                            return <tr key={index}>
                                                                {
                                                                    quantPlateColumns.map((column, i) => {
                                                                        let cellIndex = null;
                                                                        let className = '';
                                                                        if (i >= 1 && index >= 1) {
                                                                            cellIndex = getCellIndexForQuantPlate(index - 1, i - 1);
                                                                        }
                                                                        if (plateTemplate && plateTemplate.control_spots && plateTemplate.control_spots[cellIndex]) {
                                                                            className = 'assigned-cell'
                                                                        }
                                                                        if (i !== 0 && index !== 0) {
                                                                            className += ' cell'
                                                                        } else {
                                                                            className += ' constant'
                                                                        }
                                                                        return <td className={className} key={i}>
                                                                            {(i === 0 && index !== 0) ? row : ""}
                                                                            {(i !== 0 && index === 0) ? column : ""}
                                                                            {(i !== 0 && index !== 0 && plateTemplate.control_spots[cellIndex]) ? plateTemplate.control_spots[cellIndex] : ""}
                                                                        </td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <h4 className="text-center mt-3">{control_spots.join(', ')}</h4>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={this.onHide}
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={this.createNewPlateWithTemplate}
                    disabled={btnDisabled}
                >
                    Select
                </Button>
            </Modal.Footer>
        </ModalStyled>;
    }
}
