import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PatientDetails from "../../patients/clinicPatientGrid/patientDetails";
import { getUserRole } from "../../../../services/common/util";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class PatientBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
	}

	handleShow = () => {
		if (!(/salesperson|salesadmin|labtech|audit/.test(getUserRole().toLowerCase()))) {
			this.setState({ show: true }, () => {
				let elements = document.getElementsByClassName("removeTabIndexModal");
				if (elements.length) {
					for (let i = 0; i < elements.length; i++) {
						let dialog = elements[i];
						dialog.parentNode.removeAttribute('tabindex');
					}
				}
			});
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				<button
					onClick={this.handleShow}
					style={{ border: "none", backgroundColor: "transparent" }}
				>
					{this.props.data.patient_id.first_name + ' ' + this.props.data.patient_id.last_name}
				</button>

				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Patient Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.props.data.patient_id}
							order={this.props.data}
							context={this.props.context}
							handleClose={this.handleClose}
							type='orderGrid'
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
