import React, { Component, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import AuditSearchMenu from "./auditSearchMenu";
import {
    dateComparator,
    defaultExcelExportParams,
    getUserDetails
} from "../../../../services/common/util";
import {
    getAuditData,
    createAudit
} from "../../../../services/clinicPortalServices/auditService";
import moment from "moment";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicAuditGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchFilters: {
                from_date:
                    moment().startOf("week").format("YYYY-MM-DD") + "T00:00",
                to_date:
                    moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                dateRange: "week"
            },
            columnDefs: [
                {
                    headerName: "Event Type",
                    minWidth: 200,
                    field: "event_type",
                    resizable: true
                },
                {
                    headerName: "Details",
                    minWidth: 600,
                    field: "update_string",
                    resizable: true,
                    valueGetter: (params) => {
                        let str = params.data.update_string
                            ? params.data.update_string.replaceAll(
                                  "__v",
                                  "Updated Count"
                              )
                            : "";
                        return str;
                    },
                    cellRenderer: memo((props) => {
                        let str = props.data.update_string
                            ? props.data.update_string.replaceAll(
                                  "__v",
                                  "Updated Count"
                              )
                            : "";
                        return (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: str ? str : "No field updated"
                                }}
                            ></div>
                        );
                    })
                },
                {
                    headerName: "User Name",
                    field: "user_name",
                    minWidth: 150,
                    resizable: true
                },
                {
                    headerName: "Record ID",
                    field: "record_id",
                    minWidth: 100,
                    resizable: true
                },
                {
                    headerName: "IP Address",
                    minWidth: 100,
                    resizable: true,
                    field: "ip_address"
                },
                {
                    headerName: "Timestamp",
                    field: "createdAt",
                    minWidth: 200,
                    resizable: true,
                    valueGetter: (params) => {
                        return moment
                            .tz(params.data.createdAt, "America/New_York")
                            .format("MM/DD/YYYY hh:mm:ss A");
                    },
                    comparator: dateComparator
                }
            ],

            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: []
        };
    }
    getRowHeight = (params) => {
        if (params.data.update_count && params.data.update_count > 0) {
            return (
                params.api.getSizesForCurrentTheme().rowHeight *
                (params.data.update_count + 1)
            );
        } else {
            return false;
        }
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadAuditGridData();
    };

    loadAuditGridData = () => {
        this.gridApi.showLoadingOverlay();
        const filter = {
            createdAt: {
                $gte: this.state.searchFilters.from_date,
                $lt: this.state.searchFilters.to_date
            }
        };
        getAuditData(filter).then((data) => {
            this.setState({ rowData: data });
            if (!data.length) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        let filters = this.state.searchFilters;
        filters.from_date =
            moment().subtract(7, "days").format("YYYY-MM-DD") + "T00:00";
        filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
        filters.dateRange = "week";
        this.setState({ searchFilters: filters });

        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
        this.loadAuditGridData();
    };

    updateDateRange = (dateRange) => {
        const filters = this.state.searchFilters;
        if (filters.dateRange !== dateRange) {
            filters.dateRange = dateRange;
            switch (dateRange) {
                case "today":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().format("YYYY-MM-DD") + "T00:00";
                    break;
                case "week":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                case "month":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                default:
                    break;
            }
            this.setState({ searchFilters: filters });
            if (dateRange !== "custom") {
                this.loadAuditGridData();
            }
        }
    };

    setDateRange = (searchFilters) => {
        if (
            moment(searchFilters.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "today";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("week").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "week";
            } else if (
                moment(searchFilters.from_date).format("YYYY-MM-DD") ===
                moment().startOf("month").format("YYYY-MM-DD")
            ) {
                searchFilters.dateRange = "month";
            } else {
                searchFilters.dateRange = "custom";
            }
        } else {
            searchFilters.dateRange = "custom";
        }
    };

    handleDateFiltersChange = (dateTime, type) => {
        const filters = this.state.searchFilters;
        let filterIsValid = true;
        if (type === "from_date") {
            if (moment(filters.to_date).isAfter(dateTime)) {
                filters.from_date = dateTime;
            } else {
                filterIsValid = false;
            }
        } else {
            if (moment(dateTime).isAfter(filters.from_date)) {
                filters.to_date = dateTime;
            } else {
                filterIsValid = false;
            }
        }
        if (filterIsValid) {
            this.setDateRange(filters);
            this.setState({ searchFilters: filters });
            this.loadAuditGridData();
        }
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Audit_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Audits"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.AuditGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.AuditGridExported
        };
        createAudit(auditData);
    };

    render() {
        return (
            <div className="clinic-contain">
                <AuditSearchMenu
                    handleDateFiltersChange={this.handleDateFiltersChange}
                    updateDateRange={this.updateDateRange}
                    from_date={this.state.searchFilters.from_date}
                    to_date={this.state.searchFilters.to_date}
                    date_range={this.state.searchFilters.dateRange}
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    getRowHeight={this.getRowHeight}
                                    defaultExcelExportParams={
                                        defaultExcelExportParams
                                    }
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicAuditGrid;
