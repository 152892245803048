import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { fetchPatientQrResponse } from "../../../../services/patientSignupServices/patientSignupFormService";
import SocialShare from "./../../../socialShare";
import { logout } from "../../../../services/patientPortalServices/loginService";
import { easyUpdatePatient } from "../../../../services/clinicPortalServices/patientEditService";
import { MenuItem } from "@mui/material";
import toastr from "toastr";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import PatientDetails from "../../../clinicPortal/patients/clinicPatientGrid/patientDetails";
import InsuranceInfoPopup from "../../../clinicPortal/patients/clinicPatientGrid/insuranceInfoPopup";
import ResetPasswordModal from "../../resetPasswordModal";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";

class DashboardSidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			qrData: '',
			isExpand: false,
			patientData: this.props.patientData,
			getSampleTrackingNotification: this.props.patientData ? this.props.patientData.get_sample_tracking_notification : true,
			showPatientEditPopup: false,
			showInsurancePopup: false,
			showPasswordResetPopup: false,
		};
	}

	componentDidMount() {
		fetchPatientQrResponse(this.props.patientData._id).then((response) => {
			this.setState({ qrData: response.data });
		});
	}

	expandQR = () => {
		this.setState({ isExpand: true });
	}

	handleClose = () => {
		this.setState({ isExpand: false });
	}

	toggleGetSampleTrackingNotification = () => {
		let getSampleTrackingNotification = this.state.getSampleTrackingNotification;
		getSampleTrackingNotification = !getSampleTrackingNotification
		this.setState({ getSampleTrackingNotification: getSampleTrackingNotification });
		if (this.state.patientData) {
			const patientDetails = {
				_id: this.state.patientData._id,
				get_sample_tracking_notification: getSampleTrackingNotification,
			}
			easyUpdatePatient(patientDetails).then(res => {
				toastr.success('Updated successfully!');
			})
		}
	}

	showPatientEditPopup = () => {
		this.setState({ showPatientEditPopup: true })
	}

	handleClosePatientEditPopup = () => {
		this.setState({ showPatientEditPopup: false })
	}

	showInsuranceEditPopup = () => {
		this.setState({ showInsurancePopup: true })
	}

	showPasswordResetPopup = () => {
		this.setState({ showPasswordResetPopup: true })
	}

	handleUpdatePassword = (newPassword) => {
		const patientDetails = {
			_id: this.state.patientData._id,
			password: newPassword,
		}
		easyUpdatePatient(patientDetails).then(res => {
			this.setState({ showPasswordResetPopup: false })
			toastr.success('Password is updated successfully!');
			const auditData = {
				identifier: auditEnums.IDENTIFIERS.PatientRecord,
				event_type: auditEnums.EVENTTYPES.PatientUpdatePassword,
				user_id: this.state.patientData._id,
				user_name: this.state.patientData.first_name + " " + this.state.patientData.last_name + " (Patient, username: " + this.state.patientData.username + ")",
				record_id: this.state.patientData._id,
				update_string: this.state.patientData.first_name + " " + this.state.patientData.last_name + " (" + this.state.patientData.mrn + ") updated password"
			}
			createAudit(auditData);
		})
	}

	render() {
		let insuranceIncorrect = this.props.incorrectItems.find(item => item.type === 'Insurance' && item.is_incorrect);
		return (
			<div className="profile-sidebar">
				<div className="widget-profile pro-widget-content">
					{
						this.props.patientData &&
						<div className="profile-info-widget">
							<div className="profile-det-info">
								<h3 className="patient-sampleId">BlueLot (PID): {this.props.patientData._id}</h3>
								{this.state.qrData &&
									<>
										<img className="qr-Image" src={this.state.qrData} onClick={this.expandQR} alt="QR" />
										<h3 className="text-info qr-expandbtn" onClick={this.expandQR} style={{ cursor: "pointer" }}>CLICK TO EXPAND</h3>
									</>}
								<h3 className="patient-name">{this.props.patientData.fullName}</h3>
								<h5>Username: {this.props.patientData.username}</h5>
								<h6>Medical Record Number: {this.props.patientData.mrn}</h6>
								<div className="patient-details">
									<h5>
										<i className="fas fa-birthday-cake"></i>
										{this.props.patientData.ageDetails}
									</h5>
									<h5 className="mb-0">
										<i className="fas fa-map-marker-alt"></i>
										{this.props.patientData.address.address1}
										<br /> {this.props.patientData.address.address2}
									</h5>
								</div>
							</div>

						</div>
					}
				</div>
				<div style={{ padding: "20px 20px 0px 20px" }}>
					<p>
						<b>Facility Name</b>
						<br />{this.props.facilityData && this.props.facilityData.name}
						<br />
						<b>Facility Phone</b>
						<br />{this.props.facilityData && this.props.facilityData.phone_no}
					</p>
				</div>
				<div className="dashboard-widget">
					<nav className="dashboard-menu" style={{ borderTop: "1px solid #f0f0f0" }}>
						<ul>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
							>
								<div
									onClick={this.showPatientEditPopup}
								>
									<i className="fas fa-user" style={{ padding: "0.8rem" }}></i>
									Edit Your Own Patient Info
								</div>
								{
									this.props.patientData.is_incorrect
									&&
									<i
										className="fas fa-exclamation-triangle"
										style={{ padding: "0.8rem", color: 'orange' }}
										onClick={this.props.showIncorrectPopupAction}
									>
									</i>
								}
							</MenuItem>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
							>
								<div
									onClick={this.showInsuranceEditPopup}
								>
									<i className="fas fa-address-card" style={{ padding: "0.8rem" }}></i>
									Edit Insurance Information
								</div>
								{
									insuranceIncorrect
									&&
									<i
										className="fas fa-exclamation-triangle"
										style={{ padding: "0.8rem", color: 'orange' }}
										onClick={this.props.showIncorrectPopupAction}
									>
									</i>
								}
							</MenuItem>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
								onClick={this.showPasswordResetPopup}
							>
								<i className="fas fa-key" style={{ padding: "0.8rem" }}></i>
								Update Password
							</MenuItem>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
								onClick={this.props.showCommunicator}
							>
								<i className="fas fa-envelope" style={{ padding: "0.8rem" }}></i>
								<span>Contact Us</span>
							</MenuItem>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
								onClick={logout}
							>
								<i className="fas fa-sign-out-alt" style={{ padding: "0.8rem" }}></i>
								<span>Logout</span>
							</MenuItem>
							<li className="dashboardFooter">
								<div
									style={{
										textAlign: "center",
										padding: "50px 20px 0px 20px",
										textTransform: "none",
									}}
								>
									<span style={{ fontWeight: "bold", fontSize: "large" }}>
										PLEASE CONSIDER SHARING
									</span>
									<p style={{ paddingTop: "20px" }}>
										Results to your device in less than 24 hours!
									</p>
									<p>
										If you are satisfied with the ease and speed of getting your
										results,please consider sharing our services.
									</p>
									<b>(NOT YOUR RESULTS)</b>
									<p> Let others know, keep people safe!</p>
								</div>
								<SocialShare />
							</li>
						</ul>
					</nav>
				</div>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.isExpand}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						QR Code Viewer
					</Modal.Header>
					<Modal.Body style={{ textAlign: "center" }}>
						{this.state.qrData && <img src={this.state.qrData} style={{ width: "500px", heigh: "500px", margin: 'auto' }} alt="QR" />}
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showPatientEditPopup}
					onHide={this.handleClosePatientEditPopup}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Your Own Patient Info</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.props.patientData}
							handleClose={this.handleClosePatientEditPopup}
							type="patientPortal"
							refreshData={this.props.refreshData}
						/>
					</Modal.Body>
				</ModalStyled>
				<InsuranceInfoPopup
					data={this.props.patientData}
					show={this.state.showInsurancePopup}
					handleClose={() => { this.setState({ showInsurancePopup: false }) }}
					type="patientPortal"
					refreshData={this.props.refreshData}
				/>
				<ResetPasswordModal
					handleSubmit={this.handleUpdatePassword}
					show={this.state.showPasswordResetPopup}
					handleClose={() => this.setState({ showPasswordResetPopup: false })}
					usedPatient={this.props.patientData}
					type="UPDATE_PASSWORD"
				/>
			</div>
		);
	}
};

export default DashboardSidebar;
