import React, { Component } from "react";
import DashboardSidebar from "./sidebar/sidebar.jsx";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import StickyBox from "react-sticky-box";
import ResultData from "./resultData";
import InsuranceData from "./insuranceData";
import { serviceConstants } from "../../../services/common/constants";
import { fetchDashboardDetails, filterPatients, patientCommunication } from "../../../services/patientPortalServices/dashboardService";
import Swal from "sweetalert2";
import SocialShare from "./../../socialShare";
import { getFacilityDataById } from "../../../services/clinicPortalServices/facilityServices.js";
import { ModalStyled } from "../../../theme/customizedStyleComponents";
import moment from "moment";
import { fetchPatientDataById } from "../../../services/clinicPortalServices/patientSearchService.js";
import IncorrectInfoPopup from "../../clinicPortal/patients/clinicPatientGrid/incorrectInfoPopup.jsx";

class PatientPortalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      usedPatient: null,
      result: null,
      selectedDateId: "",
      orderDates: [],
      value: "",
      loading: false,
      paymentRelatedOrderResults: [],
      showSidebar: false,
      tabValue: 'order',
      isCommunication: false,
      comment: {
        patientId: '',
        name: '',
        email: '',
        mobile: '',
        orderId: [],
        subject: 'Benchlab, LLC. - Patient Support Request',
        message: '',
      },
      commentError: {
        emailError: false,
        messageError: false,
      },
      facilityData: null,
      showIncorrectPopup: false,
      incorrectItems: []
    };
  }

  hideCommunication = () => {
    this.setState({ isCommunication: false });
  }

  showCommunication = () => {
    this.setState({ isCommunication: true });
  }

  onCommunicationTextChange = (e) => {
    let comment = this.state.comment;
    if (e.target.name === "message") {
      comment.message = e.target.value;
    }
    if (e.target.name === "email") {
      comment.email = e.target.value;
    }
    this.setState({ comment: comment });
  }

  componentDidMount = () => {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
      });
    }

    this.refreshData();
  }

  refreshData = () => {
    if (window.localStorage.getItem('MATCHED_PATIENTS_DATA')) {
      const patientData = JSON.parse(window.localStorage.getItem('MATCHED_PATIENTS_DATA'));
      fetchPatientDataById(patientData._id).then(res => {
        if (res && res.data && res.data[0]) {
          const updatedPatientData = res.data[0];
          updatedPatientData.fullName = updatedPatientData.first_name + ' ' + updatedPatientData.last_name;
          updatedPatientData.ageDetails = moment(updatedPatientData.date_of_birth, "YYYY/MM/DD").format("Do MMM YYYY") + ", " + moment().diff(moment(updatedPatientData.date_of_birth, "YYYY/MM/DD"), "years") + " years";
          const comment = {
            patientId: updatedPatientData._id,
            name: updatedPatientData.first_name + ' ' + updatedPatientData.last_name,
            email: updatedPatientData.email,
            mobile: updatedPatientData.mobile,
            orderId: [],
            subject: 'Benchlab, LLC. - Patient Support Request',
            message: '',
          }
          this.setState({ usedPatient: updatedPatientData, comment: comment });
          this.loadDashboardDetail(updatedPatientData._id);
          getFacilityDataById(updatedPatientData.facility_id._id).then(res => {
            if (res.data && res.data[0] && res.data[0]._id) {
              this.setState({ facilityData: res.data[0] })
            }
          })
        }
      })
    } else {
      this.props.history.push('/');
    }
  }

  showCommunicator = () => {
    this.setState({ isCommunication: true });
  }

  onSupportRequest = () => {
    if (this.state.comment.email === "" || this.state.comment.message === "") {
      let error = this.state.commentError;
      if (this.state.comment.email === "") {
        error.emailError = true;
      }
      if (this.state.comment.message === "") {
        error.messageError = true;
      }
      this.setState({ commentError: error });
      return;
    }

    patientCommunication(this.state.comment).then(res => {
      this.hideCommunication();
      Swal.fire({
        customClass: {
          container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
        },
        title: 'We have received your support request',
        html: '<p>A support representative will be reviewing your request and will send you a personal response. (usually within 24 hours).</p><p>Our laboratory customer service hours are from 8am to 8pm, 7 days a week. We will reply to you within those hours.</p><p>Thank you for your patience.</p><p>Sincerely,</p><p>The Benchlab, LLC. Team</p>',
        icon: 'success'
      })
    });
  }

  loadDashboardDetail = (patientID) => {
    const patientInfo = { patient_id: patientID };
    this.setState({ loading: true });
    fetchDashboardDetails(patientInfo).then((data) => {
      this.setState({ loading: false });
      if (data.data != null && data.data.length > 0) {
        let orderResults = [];
        let paymentRelatedOrderResults = [];
        data.data.map(item => {
          if (item.results && item.results.pdf_path) {
            orderResults.push(item);
            if (item.payment && item.payment.due_amount && Number(item.payment.due_amount) > 0) {
              paymentRelatedOrderResults.push(item);
            } else if (item.payment && item.payment.due_amount && item.payment.due_amount === "0") {
              paymentRelatedOrderResults.push(item);
            }
          } else if (item.sample_tracking && item.sample_tracking.length) {
            orderResults.push(item);
          }
          return null;
        })
        orderResults.sort((a, b) => b.test_info.collected - a.test_info.collected);
        paymentRelatedOrderResults.sort((a, b) => {
          if ((a.payment && a.payment.due_amount && a.payment.due_amount === "0") && (b.payment && b.payment.due_amount && Number(b.payment.due_amount) > 0)) {
            return 1;
          } else if ((b.payment && b.payment.due_amount && b.payment.due_amount === "0") && (a.payment && a.payment.due_amount && Number(a.payment.due_amount) > 0)) {
            return -1;
          }
          return 0;
        })
        this.setState({
          result: orderResults,
          paymentRelatedOrderResults: paymentRelatedOrderResults,
          selectedDateId: orderResults[0]._id,
        });

        if (paymentRelatedOrderResults.filter(item => Number(item.payment.due_amount) > 0).length > 0) {
          this.setState({ tabValue: 'insurance' });
        }
      } else {
        this.setState({
          result: null,
          selectedDateId: "",
        });
      }
    }).catch((error) => {
      console.log(error);
    })
    filterPatients({ _id: patientID }).then(res => {
      if (res.data && res.data.length) {
        let usedPatient = Object.assign(this.state.usedPatient, res.data[0]);
        this.setState({ usedPatient: usedPatient, showSidebar: true });

        let incorrectItems = [{
          type: "Patient",
          data: usedPatient,
          is_incorrect: usedPatient.is_incorrect ? usedPatient.is_incorrect : false,
          incorrect_note: usedPatient.incorrect_note ? usedPatient.incorrect_note : "",
          incorrect_history: usedPatient.incorrect_history && usedPatient.incorrect_history.length ? usedPatient.incorrect_history : []
        }];
        if (usedPatient.patient_insurance_ids && usedPatient.patient_insurance_ids.length) {
          usedPatient.patient_insurance_ids.map(insurance => {
            incorrectItems.push({
              type: "Insurance",
              data: insurance,
              is_incorrect: insurance.is_incorrect ? insurance.is_incorrect : false,
              incorrect_note: insurance.incorrect_note ? insurance.incorrect_note : "",
              incorrect_history: insurance.incorrect_history && insurance.incorrect_history.length ? insurance.incorrect_history : []
            })
            return null;
          })
        }
        let insuranceIncorrect = usedPatient.patient_insurance_ids.find(item => item.is_incorrect);
        this.setState({ incorrectItems: incorrectItems, showIncorrectPopup: (insuranceIncorrect || usedPatient.is_incorrect) ? true : false });
      }
    })
  }

  handleCloseIncorrectPopup = () => {
    this.setState({ showIncorrectPopup: false })
  }

  showIncorrectPopupAction = () => {
    this.setState({ showIncorrectPopup: true })
  }

  handleResultsTab = () => {
    this.setState({ tabValue: 'order' });
  }

  handleInsuranceTab = () => {
    this.setState({ tabValue: 'insurance' });
  }

  render() {
    return (
      <div>
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Dashboard</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  {
                    this.state.showSidebar
                    &&
                    <DashboardSidebar
                      patientData={this.state.usedPatient}
                      showCommunicator={this.showCommunicator}
                      refreshData={this.refreshData}
                      incorrectItems={this.state.incorrectItems}
                      facilityData={this.state.facilityData}
                      showIncorrectPopupAction={this.showIncorrectPopupAction}
                    />
                  }
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9 patientDashboard-content">
                <p style={{ fontSize: "20px", marginTop: "40px 0px", padding: "0px 30px", textAlign: "center" }}>
                  Our laboratory only provides test results, we do not offer a diagnosis.  If you have questions please contact the facility listed under "Testing Facility" for the specific test.  The last facility you were at is listed in the sidebar.
                </p>
                <div className="card schedule-widget mb-0">
                  <div className="schedule-header">
                    <div className="schedule-nav">
                      <ul className="nav-tabs">
                        <li className={this.state.tabValue === 'order' ? "nav-item active" : "nav-item"} onClick={this.handleResultsTab}>
                          Orders
                        </li>
                        {
                          this.state.paymentRelatedOrderResults.length > 0
                          &&
                          <li className={this.state.tabValue === 'insurance' ? "nav-item active" : "nav-item"} onClick={this.handleInsuranceTab}>
                            Payments
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-cont">
                    {this.state.result != null &&
                      <div>
                        {
                          this.state.tabValue === 'order'
                          &&
                          <ResultData
                            result={this.state.result}
                            patientData={this.state.usedPatient}
                            facilityData={this.state.facilityData}
                          />
                        }
                        {
                          this.state.tabValue === 'insurance'
                          &&
                          <InsuranceData
                            insurance={this.state.paymentRelatedOrderResults}
                            patientData={this.state.usedPatient}
                            stripe={this.state.stripe}
                          />
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row mobile-patient-footer-section" style={{ display: "none" }}>
              <div
                style={{
                  textAlign: "center",
                  padding: "50px 20px 0px 20px",
                  textTransform: "none",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "large" }}>
                  PLEASE CONSIDER SHARING
                </span>
                <p style={{ paddingTop: "20px" }}>
                  Results to your device in less than 24 hours!
                </p>
                <p>
                  If you are satisfied with the ease and speed of getting your
                  results, please consider sharing our services.
                </p>
                <b>(NOT YOUR RESULTS)</b>
                <p> Let others know, keep people safe!</p>
              </div>
              <SocialShare />
            </div>
          </div>
        </div>
        <ModalStyled
          size="lx"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.isCommunication}
          onHide={this.hideCommunication}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Contact Us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  error={this.state.commentError.emailError}
                  className="form-control"
                  id="reset-form"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="string"
                  margin="dense"
                  value={this.state.comment.email}
                  onChange={this.onCommunicationTextChange}
                />
              </div>
              <div className="col-md-12">
                <TextField
                  label="Message"
                  name="message"
                  variant="outlined"
                  error={this.state.commentError.messageError}
                  className="form-control mt-4"
                  id="reset-form"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={4}
                  type="string"
                  margin="dense"
                  style={{ height: "auto" }}
                  value={this.state.comment.message}
                  onChange={this.onCommunicationTextChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.onSupportRequest}>
              Send
            </Button>
          </Modal.Footer>
        </ModalStyled>
        <IncorrectInfoPopup
          show={this.state.showIncorrectPopup}
          onHide={this.handleCloseIncorrectPopup}
          incorrectItems={this.state.incorrectItems}
          type='patientPortal'
        />
      </div>
    );
  }
}
export default PatientPortalDashboard;
