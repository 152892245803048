import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { states } from "../../services/common/optionsData";
import { isValidEmail, isValidPhoneNumber } from "../../services/common/util";

class CompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
    }

    componentDidMount() {
    }

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    }

    continue = () => {
        let errors = [];
        if (!this.props.companyDetails.name) {
            errors.push('name');
        }
        if (!this.props.companyDetails.contactName) {
            errors.push('contactName');
        }
        if (!this.props.companyDetails.contactEmail) {
            errors.push('contactEmail');
        }
        if (this.props.companyDetails.contactEmail && !isValidEmail(this.props.companyDetails.contactEmail)) {
            errors.push('wrongContactEmail');
        }
        if (!this.props.companyDetails.contactPhone) {
            errors.push('contactPhone');
        }
        if (this.props.companyDetails.contactPhone && !isValidPhoneNumber(this.props.companyDetails.contactPhone)) {
            errors.push('wrongContactPhone');
        }
        if (!this.props.companyDetails.address1) {
            errors.push('address1');
        }
        if (!this.props.companyDetails.city) {
            errors.push('city');
        }
        if (!this.props.companyDetails.zip) {
            errors.push('zip');
        }
        if (!this.props.companyDetails.state) {
            errors.push('state');
        }
        if (errors.length) {
            this.setState({ errors: errors });
        } else {
            this.props.nextStep();
        }
    }

    render() {
        return (
            <div className="row form-row">
                <div className="col-12">
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <h3>Company Information</h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Company Name <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={this.props.companyDetails.name}
                                    onChange={this.props.handleCompanyChange}
                                />
                                <div className={this.hasError("name") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>Name of Invoicing Contact <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="contactName"
                                    value={this.props.companyDetails.contactName}
                                    onChange={this.props.handleCompanyChange}
                                />
                                <div className={this.hasError("contactName") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>Phone of Invoicing Contact <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="contactPhone"
                                    value={this.props.companyDetails.contactPhone}
                                    onChange={this.props.handleCompanyChange}
                                />
                                <div className={this.hasError("contactPhone") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                                <div className={this.hasError("wrongContactPhone") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;Phone is not valid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>Email of Invoicing Contact <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="contactEmail"
                                    value={this.props.companyDetails.contactEmail}
                                    onChange={this.props.handleCompanyChange}
                                />
                                <div className={this.hasError("contactEmail") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                                <div className={this.hasError("wrongContactEmail") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;Email is not valid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <h5>Billing Address</h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Address1 <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    name="address1"
                                    value={this.props.companyDetails.address1}
                                    onChange={this.props.handleCompanyChange}
                                    className="form-control"
                                />
                                <div className={this.hasError("address1") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Address2</label>
                                <input
                                    type="text"
                                    name="address2"
                                    value={this.props.companyDetails.address2}
                                    onChange={this.props.handleCompanyChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>City <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    name="city"
                                    value={this.props.companyDetails.city}
                                    onChange={this.props.handleCompanyChange}
                                    className="form-control"
                                />
                                <div className={this.hasError("city") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>State <abbr className="text-danger">*</abbr></label>
                                <select
                                    className="form-control select"
                                    name="state"
                                    value={this.props.companyDetails.state}
                                    onChange={this.props.handleCompanyChange}
                                >
                                    {
                                        states.map((state, index) => {
                                            return (
                                                <option key={index} value={state.value}>
                                                    {state.state}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                                <div className={this.hasError("state") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>Zip Code <abbr className="text-danger">*</abbr></label>
                                <input
                                    type="text"
                                    name="zip"
                                    value={this.props.companyDetails.zip}
                                    onChange={this.props.handleCompanyChange}
                                    className="form-control"
                                />
                                <div className={this.hasError("zip") ? "inline-errormsg" : "hidden"}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row next-button btn-patientinfo-next">
                        <Button
                            className="btn-pagebreak-next"
                            type="submit"
                            onClick={this.continue}
                            id="btnNext"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyInfo;
