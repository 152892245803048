export const identity = [
    {
        value: "",
        gender: "Please Select"
    },
    {
        value: "M",
        gender: "Male"
    },
    {
        value: "F",
        gender: "Female"
    },
    {
        value: "U",
        gender: "Unknown"
    }
];

export const testTypeValues = {
    "Nasal Swab": "Nasal Swab",
    "Nasopharyngeal Swab": "Nasopharyngeal Swab",
    "Throat Swab": "Throat Swab"
};

export const testMachines = {
    Quant: "Quant Studio Machine",
    Solana: "Solana Machine"
};

export const resultsSearch = [
    {
        code: "all",
        value: "All"
    },
    {
        code: "positive",
        value: "Positive"
    },
    {
        code: "negative",
        value: "Negative"
    },
    {
        code: "inconclusive",
        value: "Inconclusive / Invalid"
    }
];

export const insuranceStateSearchOptions = [
    {
        code: "all",
        value: "All"
    },
    {
        code: "outstanding",
        value: "Outstanding"
    },
    {
        code: "paid",
        value: "Paid"
    },
    {
        code: "no_entry",
        value: "No Entry"
    }
];

export const requisitionOptionsForAccessioningQuant = [
    {
        code: "all",
        value: "All"
    },
    {
        code: "vip_inconclusive",
        value: "VIP Inconclusive"
    },
    {
        code: "vip_invalid",
        value: "VIP Invalid"
    },
    {
        code: "vip",
        value: "VIP"
    },
    {
        code: "regular_inconclusive",
        value: "Regular Inconclusive"
    },
    {
        code: "regular_invalid",
        value: "Regular Invalid"
    },
    {
        code: "regular",
        value: "Regular"
    }
];

export const requisitionOptionsForAccessioningSolana = [
    {
        code: "all",
        value: "All"
    },
    {
        code: "vip_invalid",
        value: "VIP Invalid"
    },
    {
        code: "vip",
        value: "VIP"
    },
    {
        code: "regular_invalid",
        value: "Regular Invalid"
    },
    {
        code: "regular",
        value: "Regular"
    }
];

export const resultsSearchReport = [
    {
        code: "daily",
        value: "Daily"
    },
    {
        code: "week",
        value: "Weekly"
    },
    {
        code: "month",
        value: "Monthly"
    },
    {
        code: "year",
        value: "Yearly"
    }
];

export const stateReportsTypes = [
    {
        code: "ALL",
        value: "All"
    },
    {
        code: "MD",
        value: "Maryland"
    },
    {
        code: "DC",
        value: "DC"
    },
    {
        code: "VA",
        value: "Virginia"
    }
];

export const dailyReportsTypes = [
    {
        code: "all",
        value: "All"
    },
    {
        code: "environmental",
        value: "Environmental"
    },
    {
        code: "patient_samples",
        value: "Patient Samples"
    }
];

export const faxTypes = [
    {
        value: "Please Select",
        testType: "Please Select"
    },
    {
        value: "NoFax",
        faxType: "NoFax"
    },
    {
        value: "FaxAll",
        faxType: "FaxAll"
    },
    {
        value: "FaxPositive",
        faxType: "FaxPositive"
    },
    {
        value: "ManualFax",
        faxType: "ManualFax"
    }
];

export const ethnicity = [
    {
        value: "",
        desc: "Please Select"
    },
    {
        value: "Hispanic or Latino",
        desc: "Hispanic or Latino"
    },
    {
        value: "Not Hispanic or Latino",
        desc: "Not Hispanic or Latino"
    }
];

export const race = [
    {
        id: 1,
        value: "White"
    },
    {
        id: 2,
        value: "Black or African American"
    },
    {
        id: 3,
        value: "American Indian or Alaska Native"
    },
    {
        id: 4,
        value: "Asian"
    },
    {
        id: 5,
        value: "Native Hawaiian or Other Pacific Islander"
    },
    {
        id: 6,
        value: "Other Race"
    }
];

export const symptoms = [
    {
        id: 1,
        value: "None"
    },
    {
        id: 2,
        value: "Contact with and (suspected) exposure"
    },
    {
        id: 3,
        value: "Fever or chills"
    },
    {
        id: 4,
        value: "Cough"
    },
    {
        id: 5,
        value: "Shortness of breath / difficulty breathing"
    },
    {
        id: 6,
        value: "Fatigue"
    },
    {
        id: 7,
        value: "Muscle / body aches"
    },
    {
        id: 8,
        value: "Loss of taste or smell"
    },
    {
        id: 9,
        value: "Sore throat"
    },
    {
        id: 10,
        value: "Congestion or runny nose"
    },
    {
        id: 11,
        value: "Nausea or vomiting"
    }
];

export const relation = [
    {
        value: "",
        desc: "Please Select"
    },
    {
        value: "Self",
        desc: "Self"
    },
    {
        value: "Spouse",
        desc: "Spouse"
    },
    {
        value: "Parent",
        desc: "Parent"
    },
    {
        value: "Child",
        desc: "Child"
    },
    {
        value: "Other Adult",
        desc: "Other Adult"
    },
    {
        value: "Adult",
        desc: "Adult"
    }
];

export const states = [
    {
        value: "",
        state: "Please Select"
    },
    {
        value: "AA",
        state: "Armed Forces Americas"
    },
    {
        value: "AE",
        state: "Armed Forces Europe"
    },
    {
        value: "AK",
        state: "Alaska"
    },
    {
        value: "AL",
        state: "Alabama"
    },
    {
        value: "AP",
        state: "Armed Forces Pacific"
    },
    {
        value: "AR",
        state: "Arkansas"
    },
    {
        value: "AZ",
        state: "Arizona"
    },
    {
        value: "CA",
        state: "California"
    },
    {
        value: "CO",
        state: "Colorado"
    },
    {
        value: "CT",
        state: "Connecticut"
    },
    {
        value: "DE",
        state: "Delaware"
    },
    {
        value: "DC",
        state: "District Of Columbia"
    },
    {
        value: "FL",
        state: "Florida"
    },
    {
        value: "GA",
        state: "Georgia"
    },
    {
        value: "HI",
        state: "Hawaii"
    },
    {
        value: "ID",
        state: "Idaho"
    },
    {
        value: "IL",
        state: "Illinois"
    },
    {
        value: "IN",
        state: "Indiana"
    },
    {
        value: "IA",
        state: "Iowa"
    },
    {
        value: "KS",
        state: "Kansas"
    },
    {
        value: "KY",
        state: "Kentucky"
    },

    {
        value: "LA",
        state: "Louisiana"
    },
    {
        value: "ME",
        state: "Maine"
    },
    {
        value: "MD",
        state: "Maryland"
    },
    {
        value: "MA",
        state: "Massachusetts"
    },
    {
        value: "MI",
        state: "Michigan"
    },
    {
        value: "MN",
        state: "Minnesota"
    },
    {
        value: "MS",
        state: "Mississippi"
    },
    {
        value: "MO",
        state: "Missouri"
    },
    {
        value: "MT",
        state: "Montana"
    },
    {
        value: "NE",
        state: "Nebraska"
    },
    {
        value: "NV",
        state: "Nevada"
    },
    {
        value: "NH",
        state: "New Hampshire"
    },
    {
        value: "NJ",
        state: "New Jersey"
    },
    {
        value: "NM",
        state: "New Mexico"
    },
    {
        value: "NY",
        state: "New York"
    },
    {
        value: "NC",
        state: "North Carolina"
    },

    {
        value: "ND",
        state: "North Dakota"
    },
    {
        value: "OH",
        state: "Ohio"
    },

    {
        value: "OK",
        state: "Oklahoma"
    },
    {
        value: "OR",
        state: "Oregon"
    },
    {
        value: "PA",
        state: "Pennsylvania"
    },
    {
        value: "RI",
        state: "Rhode Island"
    },
    {
        value: "SC",
        state: "South Carolina"
    },
    {
        value: "SD",
        state: "South Dakota"
    },
    {
        value: "TN",
        state: "Tennessee"
    },
    {
        value: "TX",
        state: "Texas"
    },
    {
        value: "UT",
        state: "Utah"
    },
    {
        value: "VT",
        state: "Vermont"
    },
    {
        value: "VA",
        state: "Virginia"
    },

    {
        value: "WA",
        state: "Washington"
    },
    {
        value: "WV",
        state: "West Virginia"
    },
    {
        value: "WI",
        state: "Wisconsin"
    },
    {
        value: "WY",
        state: "Wyoming"
    }
];

export const roles = [
    {
        key: "Audit",
        value: "Audit"
    },
    {
        key: "SuperAdmin",
        value: "SuperAdmin"
    },
    {
        key: "Admin",
        value: "Admin"
    },
    {
        key: "FacilityAdmin",
        value: "FacilityAdmin"
    },
    {
        key: "FacilityUser",
        value: "FacilityUser"
    },
    {
        key: "SalesAdmin",
        value: "SalesAdmin"
    },
    {
        key: "SalesPerson",
        value: "SalesPerson"
    },
    {
        key: "Accountant",
        value: "Accountant"
    },
    {
        key: "LabTech",
        value: "LabTech"
    },
    {
        key: "CustomerServiceUser",
        value: "CustomerServiceUser"
    }
];

export const rolesVideo = [
    {
        key: "BlueLot Admin",
        value: "BlueLot Admin"
    },
    {
        key: "Facility",
        value: "Facility"
    },
    {
        key: "Both",
        value: "Both"
    }
];

export const diagnosisCodeValues = {
    "J12.82": "J12.82 Pneumonia due to coronavirus disease",
    "M35.81": "M35.81 Multisystem inflammatory syndrome (MIS)",
    "M35.89":
        "M35.89 Other specified systemic involvement of connective tissue",
    "Z03.818":
        "Z03.818 Encounter for observation for suspected exposure to other biological agents ruled out.",
    "Z11.52": "Z11.52 Encounter for screening for COVID-19",
    "Z11.59": "Z11.59 Encounter for screening for other viral diseases",
    "Z20.822": "Z20.822 Contact with and (suspected) exposure to COVID-19",
    "Z20.828":
        "Z20.828 Contact with and (suspected) exposure to other viral communicable diseases"
};

export const historyDescriptions = {
    payment: "Payment is added",
    credit: "Credit is added",
    note: "Note is added"
};

export const settingConstants = {
    GRID: "grid",
    DEFAULT_VIEWNAME: "Default",
    CHART: "chart",
    ORDER: "order",
    REPORTS: "reports",
    SELF_CHECKIN_REPORTS: "self_checkin_reports",
    REQUISITIONS: "requisitions",
    REQUISITIONSNEW: "requestNew",
    PATIENTS: "patients",
    PATIENT_DUPLICATES: "patient_duplicates",
    DEFAULT_PAGESIZE: 20,
    INSURANCE: "insurance",
    STATE_REPORTS: "state_reports",
    END_OF_DAY_REPORTS: "end_of_day_reports",
    FAILED_SMS_EMAIL_REPORTS: "failed_sms_email_reports"
};

export const quantPlateRows = ["", "A", "B", "C", "D", "E", "F", "G", "H"];
export const quantPlateColumns = [
    "",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12"
];
export const solanaPlateRows = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12"
];
export const targetNameArray = ["MS2", "N gene", "ORF1ab", "S gene"];
export const reporterArray = ["JUN", "VIC", "FAM", "ABY"];

export const loaderPositionStyle = {
    display: "block",
    top: "50%",
    left: "50%",
    position: "fixed",
    zIndex: "99999999"
};

export const dashboardLinks = {
    Accountant: "/clinic/invoices",
    SuperAdmin: "/clinic/dashboard",
    Admin: "/clinic/dashboard",
    LabTech: "/lims/accessioning"
};

export const specimenTypes = [
    "Blood",
    "Urine",
    "Tissue",
    "Swab",
    "Stool/Feces",
    "Respiratory Samples",
    "Cerebrospinal Fluid (CSF)",
    "Saliva",
    "Other Bodily Fluids"
];

export const sampleTypes = {
    Blood: [
        "Serum",
        "Plasma",
        "Whole Blood",
        "Buffy Coat",
        "Peripheral Blood Mononuclear Cells (PBMCs)"
    ],
    Urine: [
        "Unprocessed Urine (Random, First Morning, Mid-stream Clean-catch)",
        "Centrifuged Urine (Supernatant and Sediment)",
        "24-hour Urine Collection"
    ],
    Tissue: [
        "Tissue Homogenate",
        "Tissue Sections (Fresh, Frozen, or Fixed)",
        "Cell Cultures derived from Tissue",
        "Fine Needle Aspirate (FNA)",
        "Core Needle Biopsy"
    ],
    Swab: [
        "Swab Extract (in Transport Medium or Directly Plated)",
        "Swab placed in Viral Transport Media"
    ],
    "Stool/Feces": [
        "Unprocessed Stool",
        "Stool Culture",
        "Stool for Ova and Parasites (O&P)"
    ],
    "Respiratory Samples": [
        "Sputum",
        "Bronchoalveolar Lavage (BAL) Fluid",
        "Nasopharyngeal Swab/Aspirate",
        "Throat Swab"
    ],
    "Cerebrospinal Fluid (CSF)": [
        "CSF for Cell Count and Differential",
        "CSF for Chemistry and Microbiology Analysis"
    ],
    Saliva: ["Whole Saliva", "Saliva Supernatant"],
    "Other Bodily Fluids": [
        "Synovial Fluid",
        "Amniotic Fluid",
        "Pleural Fluid",
        "Peritoneal Fluid",
        "Pericardial Fluid",
        "Semen",
        "Vaginal/Cervical Secretions",
        "Gastric Aspirate",
        "Bile"
    ]
};
