import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { getOrderDataById } from "../../../../services/clinicPortalServices/orderEditService";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import moment from "moment";
import { saveRequisitionChanges, createRequisitionPDF } from "../../../../services/clinicPortalServices/requisitionService";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class UpgradePanelPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orderDetails: null,
			targetPanelId: "",
			panelTests: [],
			allTests: []
		}
	}

	componentDidMount() {
		if (this.props.data.facility_id.testIds && this.props.data.facility_id.testIds.length && this.props.data.facility_id.testIds.filter(item => item.is_bundle) && this.props.data.facility_id.testIds.filter(item => item.is_bundle).length) {
			this.setState({ panelTests: this.props.data.facility_id.testIds.filter(item => item.is_bundle), allTests: this.props.context.componentParent.state.allSingleTests, targetPanelId: this.props.data.facility_id.testIds.filter(item => item.is_bundle)[0]._id })
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.show && this.props.show !== prevProps.show) {
			getOrderDataById(this.props.data._id).then(res => {
				this.setState({ orderDetails: res.data })
			})
		}
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	handleUpgrade = async () => {
		boundActions.startBlockUILoading();
		let testDetails = this.state.panelTests.find(item => item._id === this.state.targetPanelId);
		let orderIds = [];
		for (let i = 0; i < testDetails.testIds.length; i++) {
			let testId = testDetails.testIds[i];
			let subTestDetails;
			let suffix = "";
			this.state.allTests.map(test => {
				if (test._id === testId) {
					suffix = test.name ? test.name.substring(0, 1).toUpperCase() : "";
					if (suffix === "I") {
						suffix = "F";
					}
					subTestDetails = test;
				}
				return null;
			})
			let sample = this.state.orderDetails.test_info.sample + suffix;
			let reqInfo = this.getParamsForRequisition(this.state.orderDetails.patient_id._id, sample, subTestDetails, "sub_bundle");
			if (this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id._id) {
				reqInfo.patientInsuranceId = this.state.orderDetails.patient_insurance_id._id;
			}
			if (this.state.orderDetails.test_id.is_bundle) {
				let originalRequisition = this.state.orderDetails.orderIds.find(item => item.test_id === testId);
				if (originalRequisition) {
					reqInfo._id = originalRequisition._id;
				}
				reqInfo.bundle_id = this.state.orderDetails._id;
			} else if (this.state.orderDetails.test_id._id === testId) {
				reqInfo._id = this.state.orderDetails._id;
			}
			let createSubBundlesPromise = new Promise((resolve, reject) => {
				saveRequisitionChanges(reqInfo).then((response) => {
					const createPDFData = this.getParamsForPDF(sample, testId);
					createPDFData.lab_order_id = response.data.lab_order_id;
					createPDFData.mrn = this.state.orderDetails.patient_id.mrn;
					let orderId = response.data._id;
					createRequisitionPDF(createPDFData).then((response) => {
						resolve(orderId);
					}).catch((error) => {
						reject(error);
					})
				}).catch((error) => {
					reject(error);
				})
			})
			let orderId = await createSubBundlesPromise;
			orderIds.push(orderId);
		}
		//create bundle
		let reqInfo = this.getParamsForRequisition(this.state.orderDetails.patient_id._id, this.state.orderDetails.test_info.sample, testDetails, "bundle");
		reqInfo.orderIds = orderIds;
		if (this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id._id) {
			reqInfo.patientInsuranceId = this.state.orderDetails.patient_insurance_id._id;
		}
		if (this.state.orderDetails.test_id.is_bundle) {
			reqInfo._id = this.state.orderDetails._id;
		}
		saveRequisitionChanges(reqInfo).then((response) => {
			const createPDFData = this.getParamsForPDF(this.state.orderDetails.test_info.sample, this.state.targetPanelId);
			createPDFData.lab_order_id = response.data.lab_order_id;
			createPDFData.mrn = this.state.orderDetails.patient_id.mrn;
			createRequisitionPDF(createPDFData).then((response) => {
				boundActions.endBlockUILoading();
				this.props.handleClose();
				this.props.context.componentParent.loadGridData();
			})
		})
	}

	getParamsForRequisition = (patientId, sample, testDetails, orderType) => {
		return {
			testId: testDetails._id,
			providerFirstName: this.state.orderDetails.provider.first_name,
			providerLastName: this.state.orderDetails.provider.last_name,
			providerNPI: this.state.orderDetails.provider.npi,
			testInfoCode: testDetails.code,
			testInfoDescription: testDetails.method_name,
			specimenType: this.state.orderDetails.test_info.specimenType,
			sampleType: this.state.orderDetails.test_info.sampleType,
			diagnosisCode: this.state.orderDetails.diagnosis_code,
			sample: sample,
			collectedDate: this.state.orderDetails.test_info.collected,
			collectorName: this.state.orderDetails.test_info.collector_name,
			patientId: patientId,
			facilityId: this.state.orderDetails.facility_id._id,
			orderDate: this.state.orderDetails.order_date,
			facilityOrderId: sample,
			facilitySource: this.state.orderDetails.facility_id.name,
			requistionPDFPath: sample + '-' + this.state.orderDetails.test_info.collected + '.pdf',
			symptoms: this.state.orderDetails.symptoms,
			orderType: orderType
		}
	}

	getParamsForPDF = (sample, testId) => {
		return {
			patientName: this.state.orderDetails.patient_id.first_name + ' ' + this.state.orderDetails.patient_id.last_name,
			address1: this.state.orderDetails.patient_id.address ? this.state.orderDetails.patient_id.address.address1 : "",
			address2: this.state.orderDetails.patient_id.address ? this.state.orderDetails.patient_id.address.address2 : "",
			city: this.state.orderDetails.patient_id.address ? this.state.orderDetails.patient_id.address.city : "",
			state: this.state.orderDetails.patient_id.address ? this.state.orderDetails.patient_id.address.state : "",
			zip: this.state.orderDetails.patient_id.address ? this.state.orderDetails.patient_id.address.zip : "",
			phoneNumber: this.state.phoneNumber,
			email: this.state.orderDetails.patient_id.email,
			dateOfBirth: moment(this.state.orderDetails.patient_id.date_of_birth).format("MM/DD/YYYY"),
			gender: this.state.orderDetails.patient_id.gender,
			sample: sample,
			physicianName: this.state.orderDetails.provider.first_name + ' ' + this.state.orderDetails.provider.last_name,
			facilityName: this.state.orderDetails.facility_id.name,
			providerNPI: this.state.orderDetails.provider.npi,
			facilityAddress1: this.state.orderDetails.facility_id.address && this.state.orderDetails.facility_id.address.address1 ? this.state.orderDetails.facility_id.address.address1 : "",
			facilityAddress2: this.state.orderDetails.facility_id.address && this.state.orderDetails.facility_id.address.address2 ? this.state.orderDetails.facility_id.address.address2 : "",
			facilityCity: this.state.orderDetails.facility_id.address && this.state.orderDetails.facility_id.address.city ? this.state.orderDetails.facility_id.address.city : "",
			facilityState: this.state.orderDetails.facility_id.address && this.state.orderDetails.facility_id.address.state ? this.state.orderDetails.facility_id.address.state : "",
			facilityZip: this.state.orderDetails.facility_id.address && this.state.orderDetails.facility_id.address.zip ? this.state.orderDetails.facility_id.address.zip : "",
			facilityPhone: this.state.orderDetails.facility_id.phone_no,
			specimenType: this.state.orderDetails.test_info.specimenType,
			sampleType: this.state.orderDetails.test_info.sampleType,
			collectedDate: moment(this.state.orderDetails.test_info.collected, "YYYYMMDDHHmmss").format('MM/DD/YYYY'),
			collectedTime: moment(this.state.orderDetails.test_info.collected, "YYYYMMDDHHmmss").format('hh:mm A'),
			signature_image: this.state.orderDetails.provider.signature_image,
			requisition_pdf_path: sample + '-' + this.state.orderDetails.test_info.collected + '.pdf',
			diagnosisCode: this.state.orderDetails.diagnosis_code,
			symptoms: this.state.orderDetails.symptoms,
			insuranceProvider: this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insurance_provider ? this.state.orderDetails.patient_insurance_id.insurance_provider : "",
			insuranceMemberId: this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_member_id ? this.state.orderDetails.patient_insurance_id.insured_member_id : "",
			insuranceGroupNumber: this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_group_number ? this.state.orderDetails.patient_insurance_id.insured_group_number : "",
			insuranceRelationToInsured: this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.relation_to_insured ? this.state.orderDetails.patient_insurance_id.relation_to_insured : "",
			insuranceHolderName: this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_first_name ? (this.state.orderDetails.patient_insurance_id.insured_first_name + " " + this.state.orderDetails.patient_insurance_id.insured_last_name) : "",
			insuranceDriverLicense: this.state.orderDetails.patient_id.driver_license,
			insuranceDriverLicenseState: this.state.orderDetails.patient_id.driver_license_state,
			testId: testId,
		}
	}

	render() {
		return (
			<ModalStyled
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				onHide={this.props.handleClose}
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>Upgrade to Panel</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						this.state.orderDetails
						&&
						<div className="row">
							<div className="col-12 form-group">
								<h5>Selected requisition</h5>
								<label>{this.state.orderDetails.test_info.sample} ({this.state.orderDetails.lab_order_id} - {this.state.orderDetails.test_id.name})</label>
							</div>
							<div className="col-12 form-group">
								<h5>Please select panel type you want to upgrade to</h5>
								<RadioGroup
									aria-label="panel"
									name="targetPanelId"
									value={this.state.targetPanelId}
									onChange={this.handleChange}
								>
									{
										this.state.panelTests.map(panelTest => {
											if (panelTest._id !== this.state.orderDetails.test_id._id) {
												return <FormControlLabel
													key={panelTest._id}
													value={panelTest._id}
													control={<Radio />}
													label={panelTest.name}
												/>
											}
											return null;
										})
									}
								</RadioGroup>
							</div>
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={this.props.handleClose}
					>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={this.handleUpgrade}
					>
						Upgrade
					</Button>
				</Modal.Footer>
			</ModalStyled>
		);
	}
}
