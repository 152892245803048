import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { isValidEmail, isValidPhoneNumber } from "../../services/common/util";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
    }

    componentDidMount() {
    }

    hasError = (index, key) => {
        if (this.state.errors[index]) {
            return this.state.errors[index].indexOf(key) !== -1;
        }
        return false;
    }

    continue = () => {
        let errors = this.props.users.map(user => {
            return [];
        });

        this.props.users.map((user, index) => {
            if (!user.firstName) {
                errors[index].push('firstName');
            }
            if (!user.lastName) {
                errors[index].push('lastName');
            }
            if (!user.facilityNames.length) {
                errors[index].push('facilityNames');
            }
            if (!user.mobile) {
                errors[index].push('mobile');
            }
            if (user.mobile && !isValidPhoneNumber(user.mobile)) {
                errors[index].push('wrongMobile');
            }
            if (!user.email) {
                errors[index].push('email');
            }
            if (user.email && !isValidEmail(user.email)) {
                errors[index].push('wrongEmail');
            }
            return null;
        })

        let errorHappened = false;
        errors.map(error => {
            if (error.length) {
                errorHappened = true;
            }
            return null;
        })
        if (errorHappened) {
            this.setState({ errors: errors });
        } else {
            this.setState({ errors: [] });
            this.props.handleSubmit();
        }
    }

    render() {
        return (
            <div className="row form-row">
                <div className="col-12">
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <h3>User Information <i className="fa fa-plus-circle btn" onClick={() => this.props.addArrayItem("user")}></i></h3>
                            </div>
                        </div>
                        {
                            this.props.users.map((user, index) => {
                                return <div className="col-12" key={index}>
                                    <div className="row form-row input-box">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <h4>User {index + 1} <i className="fa fa-times-circle btn" onClick={() => this.props.removeArrayItem("user", index)}></i></h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>First Name <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    value={user.firstName}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "user", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "firstName") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Last Name <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    value={user.lastName}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "user", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "lastName") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Facility <abbr className="text-danger">*</abbr></label>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{ width: "100%", marginTop: "5px" }}
                                                >
                                                    <InputLabel id="facility-label">Select Facility</InputLabel>
                                                    {
                                                        this.props.facilities
                                                        &&
                                                        this.props.facilities.length
                                                        &&
                                                        <Select
                                                            labelId="facility-label"
                                                            id="facility-select"
                                                            value={user.facilityNames}
                                                            onChange={(e) => this.props.handleArrayItemChange(e, "user", index)}
                                                            label="Select Facility"
                                                            name="facilityNames"
                                                            multiple
                                                            renderValue={(selected) => {
                                                                if (selected.length === this.props.facilities.length) {
                                                                    return 'All Facilities';
                                                                } else {
                                                                    return selected.join(', ');
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem className={this.props.facilities.length === user.facilityNames.length ? "Mui-selected" : ""} key="all" value="all">
                                                                <Checkbox checked={this.props.facilities.length === user.facilityNames.length ? true : false} />
                                                                All Facilities
                                                            </MenuItem>
                                                            {
                                                                this.props.facilities.map((fac, indexForFac) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={indexForFac}
                                                                            value={fac.name}
                                                                        >
                                                                            <Checkbox checked={user.facilityNames.includes(fac.name) ? true : false} />
                                                                            {fac.name}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    }
                                                </FormControl>
                                                <div className={this.hasError(index, "facilityNames") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Mobile # <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    value={user.mobile}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "user", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "mobile") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongMobile") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Mobile is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Email <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={user.email}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "user", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "email") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongEmail") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Email is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="row next-button">
                        <Button
                            className="btn-pagebreak-previous"
                            type="submit"
                            onClick={this.props.previousStep}
                            id="btnBack"
                        >
                            Back
                        </Button>
                        <Button
                            className="btn-pagebreak-submit"
                            type="submit"
                            onClick={this.continue}
                            id="btnNext"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserInfo;
