import { apiInterfaceForJson } from "../common/apiManager";

export const fetchNotificationTypes = () => {
    return apiInterfaceForJson("/notificationType/v1/", "GET");
};

export const getNotificationTypeDataById = (notificationTypeId) => {
    return apiInterfaceForJson("/notificationType/v1/" + notificationTypeId, "GET");
};

export const createNotificationType = (notificationDetails) => {
    return apiInterfaceForJson("/notificationType/v1/", "POST", notificationDetails);
};

export const updateNotificationType = (notificationDetails) => {
    return apiInterfaceForJson("/notificationType/v1/", "PUT", notificationDetails);
};
