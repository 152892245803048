import React, { Component } from "react";
import CommunicationBreadcrumb from "./communicationBreadcrumb"
import RepliesComponent from "./repliesComponent";
import { getCommunication, getAllReplies } from "../../../../services/clinicPortalServices/communicationService";
import ReplyModal from "../clinicFacilityCommunications/replyModal";
import { submitCommunication } from "../../../../services/clinicPortalServices/communicationService"
import toastr from 'toastr';
import { getUserID } from "../../../../services/common/util";
import moment from "moment";
import { Link } from "react-router-dom";

class ClinicCommunicationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communicationId: '',
            commentData: null,
            replyComments: [],
            showReplyBox: false,
            comment: {
                replyToId: '',
                subject: '',
                message: '',
            }
        };
    }

    componentDidMount() {
        const communicationId = this.props.match.params.communicationId;
        this.loadCommunications(communicationId);
    }

    componentDidUpdate() {
        const communicationId = this.props.match.params.communicationId;
        if (this.state.communicationId && this.state.communicationId !== communicationId) {
            this.loadCommunications(communicationId);
        }
    }

    loadCommunications = (communicationId) => {
        this.setState({ communicationId: communicationId });
        getCommunication({ id: communicationId }).then(async (res) => {
            if (res.data) {
                this.setState({ commentData: res.data });
                if (res.data.replyCount > 0) {
                    const replyComments = await getAllReplies({ id: this.state.communicationId });
                    this.setState({ replyComments: replyComments.data })
                }
            }
        })
    }

    loadRplyComments = async () => {
        const replyComments = await getAllReplies({ id: this.state.communicationId });
        this.setState({ replyComments: replyComments.data })
    }

    openReplyBox = (commentData) => {
        const comment = {
            replyToId: commentData._id,
            subject: commentData.subject,
            message: '',
        }
        this.setState({ comment: comment, showReplyBox: true });
    }

    onCommunicateTextChange = (event) => {
        let comment = this.state.comment;
        if (event.target.name === "subject") {
            comment.subject = event.target.value;
        }
        if (event.target.name === "message") {
            comment.message = event.target.value;
        }
        this.setState({ comment: comment });
    }

    hideReplyBox = () => {
        this.setState({ showReplyBox: false });
    }

    handleReplyCommunication = () => {
        let comment = this.state.comment;
        if (comment.subject !== '' && comment.message !== '') {
            submitCommunication(comment).then(res => {
                comment = {
                    replyToId: '',
                    subject: '',
                    message: '',
                }
                this.setState({ showReplyBox: false, comment: comment });
                toastr.success('Communication is replied successfully')
                this.loadRplyComments();
            })
        } else {
            toastr.error('Please fill all fields')
        }
    }


    render() {
        const { replyComments } = this.state
        return (
            <div className="clinic-contain">
                <CommunicationBreadcrumb
                    communicationId={this.state.communicationId}
                />
                {
                    this.state.commentData != null
                    &&
                    <div className="row px-5">
                        <div
                            className="col-md-12"
                            style={{ fontWeight: "500" }}
                        >
                            <div className="row">
                                <div className="col-md-6 text-left py-2">
                                    {
                                        this.state.commentData.replyTo
                                        &&
                                        <Link to={"/clinic/communication/" + this.state.commentData.replyTo} style={{ textDecoration: 'underline' }}>
                                            <span>Original Message</span>
                                        </Link>
                                    }
                                </div>
                                <div className="col-md-6 text-right py-2">
                                    <label className="mr-2">From: </label>
                                    <label>{this.state.commentData.fromUserId.user_name}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="communicate_date">
                                        <span><b>{moment.tz(moment(this.state.commentData.createdAt, "YYYYMMDDHHmmss"), "America/New_York").format("MM/DD/YYYY hh:mm:ss A")}</b></span>
                                    </div>
                                    <label>Subject: </label>
                                    <h4 className="card-title pl-2">{this.state.commentData.subject}</h4>
                                    <label>Message: </label>
                                    <p className="card-text pl-2">
                                        {this.state.commentData.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3 p-3">
                            <div className="row m-0 justify-content-between align-items-center">
                                <h5>Replies</h5>
                                {
                                    getUserID() !== this.state.commentData.fromUserId._id
                                    &&
                                    <button className="btn btn-primary py-0" onClick={() => { this.openReplyBox(this.state.commentData) }}>
                                        <i className="fas fa-reply mr-2"></i>
                                        <span>Reply</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <RepliesComponent
                            replyComments={replyComments}
                            reloadReply={this.loadRplyComments}
                            openReplyBox={(commentData) => { this.openReplyBox(commentData) }}
                        />
                    </div>
                }
                <ReplyModal
                    showReplyBox={this.state.showReplyBox}
                    comment={this.state.comment}
                    hideReplyBox={this.hideReplyBox}
                    handleReplyCommunication={this.handleReplyCommunication}
                    onCommunicateTextChange={(event) => { this.onCommunicateTextChange(event) }}
                />
            </div>
        )
    }
}

export default ClinicCommunicationDetail;