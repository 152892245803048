import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { getCheckoutSessionId } from "../../../../services/patientPortalServices/paymentService";
import MessageQuestions from "../../../Icons/messageQuestions";
import { TextField } from "@mui/material";
import { patientCommunication } from "../../../../services/patientPortalServices/dashboardService";
import Swal from "sweetalert2";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class PayCellButtonRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommunication: false,
            comment: {
                email: this.props.patientData.email,
                orderId: this.props.data.lab_order_id,
                mobile: this.props.patientData.mobile,
                name: this.props.patientData.first_name + ' ' + this.props.patientData.last_name,
                subject: "Benchlab, LLC. - Patient Support Request",
                message: '',
            },
            commentError: {
                emailError: false,
                messageErr: false,
            }
        }
    }

    payNow = (orderInfo) => {
        boundActions.startBlockUILoading();
        getCheckoutSessionId(orderInfo).then(response => {
            if (response && response.sessionId) {
                this.props.stripe.redirectToCheckout({ sessionId: response.sessionId });
            }
        });
    }

    onSupportRequest = () => {
        if (this.state.comment.email === "" || this.state.comment.message === "") {
            let error = this.state.commentError;
            if (this.state.comment.email === "") {
                error.emailError = true;
            }
            if (this.state.comment.message === "") {
                error.messageErr = true;
            }
            this.setState({ commentError: error });
            return;
        }

        let comment = this.state.comment;
        comment.subject = "Benchlab, LLC. - Patient Support Request";
        comment.name = this.props.patientData.first_name + ' ' + this.props.patientData.last_name;
        comment.mobile = this.props.patientData.mobile;
        comment.orderId = this.props.data.lab_order_id;

        patientCommunication(comment).then(res => {
            this.hideCommunication();
            Swal.fire({
                customClass: {
                    container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
                },
                title: 'We have received your support request',
                html: '<p>A support representative will be reviewing your request and will send you a personal response. (usually within 24 hours).</p><p>Our laboratory customer service hours are from 8am to 8pm, 7 days a week. We will reply to you within those hours.</p><p>Thank you for your patience.</p><p>Sincerely,</p><p>The Benchlab, LLC. Team</p>',
                icon: 'success'
            })
        });
        this.setState({ comment: comment });
    }

    onCommunicationTextChange = (e) => {
        let comment = this.state.comment;
        if (e.target.name === 'message') {
            comment.message = e.target.value;
        }
        if (e.target.name === 'email') {
            comment.email = e.target.value;
        }
        this.setState({ comment: comment });
    }

    openNewCommunication = () => {
        this.setState({
            showCommunication: true
        })
    }

    hideCommunication = () => {
        this.setState({
            showCommunication: false,
        })
    }

    render() {
        let paid_time = "";
        if (this.props.value === "0") {
            if (this.props.data.payment.paid_time) {
                paid_time = moment(this.props.data.payment.paid_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY");
            } else if (this.props.data.payment.notes && this.props.data.payment.notes.length) {
                let payment_note = this.props.data.payment.notes.find(note => note.note.includes(" is paid via BlueLot patient portal stripe form"))
                if (payment_note) {
                    paid_time = payment_note.appliedDate;
                }
            }
        }
        return (
            <div className="d-flex" style={{ flexDirection: "column" }}>
                {
                    this.props.value && Number(this.props.value) > 0
                    &&
                    <Button
                        onClick={() => this.payNow(this.props.data)}
                        variant="primary"
                        style={{ padding: "0 10px", lineHeight: "30px" }}
                    >
                        Pay ${this.props.value}
                    </Button>
                }
                {
                    this.props.value && this.props.value === "0"
                    &&
                    <div>Paid ${this.props.data.payment.paid_amount}{paid_time ? (" (" + paid_time + ")") : ""}</div>
                }
                <button
                    onClick={this.openNewCommunication}
                    style={{ border: "none", margin: "3px", backgroundColor: "#78e3ad47", color: "#2fb305", borderRadius: '5px', padding: "0 10px", lineHeight: "30px" }}
                >
                    <MessageQuestions width={30} height={20} color="rgb(47 179 107)" />
                </button>
                <ModalStyled
                    size="lx"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCommunication}
                    onHide={this.hideCommunication}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Request support for Sample {this.props.data.lab_order_id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <TextField
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    error={this.state.commentError.emailError}
                                    className="mb-2"
                                    id="reset-form"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="string"
                                    margin="dense"
                                    disabled={this.props.data.email}
                                    value={this.state.comment.email}
                                    onChange={this.onCommunicationTextChange}
                                />
                            </div>
                            <div className="col-md-12">
                                <TextField
                                    label="Smaple ID"
                                    name="sampleId"
                                    variant="outlined"
                                    className="mb-2"
                                    id="reset-form"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="string"
                                    margin="dense"
                                    disabled
                                    value={this.state.comment.orderId}
                                ></TextField>
                            </div>
                            <div className="col-md-12">
                                <TextField
                                    label="Message"
                                    name="message"
                                    variant="outlined"
                                    error={this.state.commentError.messageErr}
                                    id="reset-form"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={4}
                                    type="string"
                                    margin="dense"
                                    style={{ height: "auto" }}
                                    value={this.state.comment.message}
                                    onChange={this.onCommunicationTextChange}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.onSupportRequest}>
                            Send
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
            </div>
        )
    }
}
