import { alpha, createTheme, darken } from "@mui/material";

export const Light = (primaryColor) => {
  return createTheme({
    direction: "ltr",
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(darken("#000C57", 0.4), 0.2),
            backdropFilter: "blur(2px)",

            "&.MuiBackdrop-invisible": {
              backgroundColor: "transparent",
              backdropFilter: "blur(2px)",
            },
          },
        },
      },
    },
    shape: {
      borderRadius: 8,
    },
    palette: {
      primary: { main: primaryColor },
    },
    typography: {
      fontFamily: '"Poppins", sans-serif',
    },
  });
};
