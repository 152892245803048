import React, { Component } from "react";
import { Modal, Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import { addVipStatus, removeVipStatus } from "../../../../services/clinicPortalServices/vipPaymentsServices";
import toastr from 'toastr';
import { getUserRole } from "../../../../services/common/util";

export default class VipButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			orderId: props.data._id,
			providedSample: '',
			errors: [],
		};
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	}


	handleVIP = () => {
		if (!this.props.data.is_vip_order) {
			this.setState({ show: true })
		} else {
			// removeVIPStatus
			removeVipStatus({ order_id: this.state.orderId }).then(response => {
				if (response.RESULT === 'SUCCESS') {
					this.props.context.componentParent.loadGridData();
				} else {
					toastr.warning("This order is matched with VIP payment, can't remove VIP Status!")
				}
			})
		}
	}

	handleAddVIP = () => {
		const data = {
			provided_sample: this.state.providedSample,
			order_id: this.state.orderId
		}
		addVipStatus(data).then(response => {
			if (response.RESULT === "SUCCESS") {
				if (response.message === 'Matched') {
					toastr.success("Successfully matched VIP Payment Record, Order is VIP status now");
				} else {
					toastr.success("Order is VIP status now");
				}
				this.handleClose();
				this.props.context.componentParent.loadGridData();
			}
		})
	}

	handleClose = () => {
		this.setState({
			show: false
		});
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	renderTooltipVip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{(this.props.data && this.props.data.is_vip_order) ? "Remove VIP Status" : "Add VIP Status"}
		</Tooltip>
	);

	render() {
		return (
			<div>
				{
					["superadmin", "admin"].includes(getUserRole().toLowerCase())
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipVip}
					>
						<button onClick={this.handleVIP} className="edit-order-btn">
							<i className={this.props.data.is_vip_order ? "fas fa-star" : "far fa-star"}></i>
						</button>
					</OverlayTrigger>
				}

				<Modal
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add VIP Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row form-row">
							<div className="col-12">
								<div className="form-group">
									<label>
										If responding to email, please enter provided sample ID.
										<br />
										If just making a VIP to fill plate, just click submit no ID needed
									</label>
									<input
										type="text"
										className="form-control"
										name="providedSample"
										value={this.state.providedSample}
										onChange={this.handleChange}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={this.handleAddVIP}>
							Submit
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}
