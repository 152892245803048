import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class Hl7MessageContentRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <div>
        <button onClick={this.handleShow} className="edit-order-btn">
          <span>{this.props.value}</span>
        </button>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>HL7 Message Content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{this.props.data.message_content}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
