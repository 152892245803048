import React, { Component } from "react";
import {
    FormLabel,
    TextField,
    InputAdornment,
    IconButton
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import BarcodeAndFind from "./BarcodeAndFind.jsx";

class RequisitionsNewSearchbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from_date: this.props.from_date,
            to_date: this.props.to_date,
            pickerOpened: false
        };
    }

    handleChange = (e) => {
        this.props.findSample(e);
    };

    render() {
        return (
            <div>
                <div
                    className="row justify-content-start align-items-center"
                    style={{ padding: "15px" }}
                >
                    <div className="col-md-4 col-sm-12 col-12 grid-buttons">
                        <TextField
                            style={{ width: "100%" }}
                            label="Search New Samples"
                            variant="outlined"
                            id="reset-form"
                            margin="dense"
                            onChange={this.props.onSearchTextChange}
                            value={this.props.searchString}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <FormLabel style={{ marginBottom: "10px" }}>
                                    Date Range
                                </FormLabel>
                            </div>
                            <div className="dateRangeBar mb-3">
                                <button
                                    className={
                                        "btn btn-info dateRangeBtn mx-1 mb-2" +
                                        (this.props.date_range === "today"
                                            ? " active"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.props.updateDateRange("today")
                                    }
                                >
                                    Today
                                </button>
                                <button
                                    className={
                                        "btn btn-info dateRangeBtn mx-1 mb-2" +
                                        (this.props.date_range === "week"
                                            ? " active"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.props.updateDateRange("week")
                                    }
                                >
                                    Week
                                </button>
                                <button
                                    className={
                                        "btn btn-info dateRangeBtn mx-1 mb-2" +
                                        (this.props.date_range === "month"
                                            ? " active"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.props.updateDateRange("month")
                                    }
                                >
                                    Month
                                </button>
                                <button
                                    className={
                                        "btn btn-info dateRangeBtn ml-1 mb-2" +
                                        (this.props.date_range === "custom"
                                            ? " active"
                                            : "")
                                    }
                                >
                                    Custom
                                </button>
                            </div>
                            <div className="dateRangeBar dateRangeBarPickers">
                                <MobileDateTimePicker
                                    value={
                                        this.state.pickerOpened
                                            ? this.state.from_date
                                            : this.props.from_date
                                    }
                                    onChange={(dateTime) =>
                                        this.setState({ from_date: dateTime })
                                    }
                                    onOpen={() =>
                                        this.setState({
                                            from_date: this.props.from_date,
                                            pickerOpened: true
                                        })
                                    }
                                    onClose={() =>
                                        this.setState({ pickerOpened: false })
                                    }
                                    onAccept={(dateTime) =>
                                        this.props.handleDateFiltersChange(
                                            dateTime.$d,
                                            "from_date"
                                        )
                                    }
                                    label="From Date"
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            className="ml-1 mr-2 mt-2"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <EventIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                <MobileDateTimePicker
                                    value={
                                        this.state.pickerOpened
                                            ? this.state.to_date
                                            : this.props.to_date
                                    }
                                    onChange={(dateTime) =>
                                        this.setState({ to_date: dateTime })
                                    }
                                    onOpen={() =>
                                        this.setState({
                                            to_date: this.props.to_date,
                                            pickerOpened: true
                                        })
                                    }
                                    onClose={() =>
                                        this.setState({ pickerOpened: false })
                                    }
                                    onAccept={(dateTime) =>
                                        this.props.handleDateFiltersChange(
                                            dateTime.$d,
                                            "to_date"
                                        )
                                    }
                                    label="To Date"
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            className="mt-2"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <EventIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-12 grid-buttons">
                        <BarcodeAndFind
                            sampleIds={this.props.sampleIds}
                            handleChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default RequisitionsNewSearchbar;
