import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { fetchUnassignedPatientDetails } from "../../../../services/clinicPortalServices/unassignedPatientService";
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { getAllInsuranceProviders } from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class CreateRequisitionFromPatient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patientId:
				this.props && this.props.patientId ? this.props.patientId : "No result",
			patientDetails: {
				patientId: -1,
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				address: "",
				city: "",
				state: "",
				zip: "",
				sex: "U",
				dob: "",
				ethnicity: "",
				race: "",
				insuranceId: "",
				insuranceProv1: "",
				insuranceProv2: "",
				memberId: "",
				groupNumber: "",
				relation: "",
				insuredFirstName: "",
				insuredLastName: "",
				driverLicense: "",
				driverLicenseState: "",
				driverLicFileName: "",
				insuranceFrontPageFileName: "",
				insuranceBackPageFileName: "",
				socialSecurityNumber: "",
				certifyNoInsurance: false
			},
			insurances: [],
			selectedInsuranceId: '',
			errors: [],
			insuranceProviders: []
		};
	}

	componentDidMount() {
		getAllInsuranceProviders().then(res => {
			this.setState({ insuranceProviders: ['Self/Pay', 'Other', ...res.data.filter(item => item.name !== "NO INSURANCE").map(item => { return item.name; })] });
		})
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.patientId && this.props.show !== nextProps.show && nextProps.show) {
			this.fetchPatientDetails(nextProps.patientId);
		}
	}

	fetchPatientDetails = (patientId) => {
		this.setState({ insurances: [], selectedInsuranceId: '', errors: [] })
		if (patientId && patientId.toLowerCase() !== "no result") {
			fetchUnassignedPatientDetails(patientId).then((data) => {
				if (data && Array.isArray(data.data) && data.data[0]) {
					let patientDetails = data.data[0];
					if (patientDetails.insurance && patientDetails.insurance.length) {
						this.setState({ insurances: patientDetails.insurance })
					}
					let insurance = patientDetails.insurance[0];
					let facility = patientDetails.facility[0];
					if (insurance) {
						let index = this.state.insuranceProviders.findIndex(
							(i) =>
								insurance.insurance_provider && i.toLowerCase() ===
								insurance.insurance_provider.toLowerCase()
						);
						if (index > -1) {
							insurance.insuranceProv1 = insurance.insurance_provider;
							insurance.insuranceProv2 = "";
						} else {
							insurance.insuranceProv1 = "Other";
							insurance.insuranceProv2 = insurance.insurance_provider;
						}
					}

					let patientInfo = {
						patientId: patientDetails._id,
						firstName: patientDetails.first_name,
						lastName: patientDetails.last_name,
						email: patientDetails.email,
						phone: patientDetails.mobile,
						address1:
							patientDetails.address.address1,
						address2:
							patientDetails.address.address2,
						address: (patientDetails.address.address1 ? patientDetails.address.address1 : '') + ' ' + (patientDetails.address.address2 ? patientDetails.address.address2 : ''),
						city: patientDetails.address.city,
						state: patientDetails.address.state,
						zip: patientDetails.address.zip,
						sex: patientDetails.gender,
						dob: patientDetails.date_of_birth,
						ethnicity: patientDetails.ethnicity,
						race: patientDetails.race,
						insuranceId: insurance ? insurance._id : "",
						insuranceProv1: insurance ? insurance.insuranceProv1 : "",
						insuranceProv2: insurance ? insurance.insuranceProv2 : "",
						memberId: insurance ? insurance.insured_member_id : "",
						groupNumber: insurance ? insurance.insured_group_number : "",
						relation: insurance ? insurance.relation_to_insured : "",
						insuredFirstName: insurance ? insurance.insured_first_name : "",
						insuredLastName: insurance ? insurance.insured_last_name : "",
						driverLicense: patientDetails && patientDetails.driver_license ? patientDetails.driver_license : "",
						driverLicenseState: patientDetails && patientDetails.driver_license_state ? patientDetails.driver_license_state : "",
						socialSecurityNumber: insurance ? insurance.social_security_number : "",
						certifyNoInsurance: insurance ? insurance.certify_no_insurance : "",

						driverLicFileName:
							patientDetails && patientDetails.driver_license_image
								? patientDetails.driver_license_image
								: "",
						insuranceFrontPageFileName:
							insurance && insurance.images
								? insurance.images.insurance_front
								: "",
						insuranceBackPageFileName:
							insurance && insurance.images
								? insurance.images.insurance_back
								: "",
						mrn: patientDetails.mrn,
						facilityId: facility ? facility._id : "",
						facilityName: facility ? facility.name : ""
					};
					this.setState({ patientDetails: patientInfo });
					this.props.setPatientDetails(patientInfo);
				}
			});
		}
	};

	handleInsuranceChange = (e) => {
		this.setState({ selectedInsuranceId: e.target.value });
	}

	showCreateRequisitionHandler = () => {
		if (!this.state.selectedInsuranceId) {
			this.setState({ errors: ['noneSelected'] });
		} else {
			this.setState({ errors: [] });
			let patientDetails = this.state.patientDetails;
			if (this.state.selectedInsuranceId === 'Self/Pay') {
				patientDetails.insuranceId = "";
				patientDetails.insuranceProv1 = "";
				patientDetails.insuranceProv2 = "";
				patientDetails.memberId = "";
				patientDetails.groupNumber = "";
				patientDetails.relation = "";
				patientDetails.insuredFirstName = "";
				patientDetails.insuredLastName = "";
				patientDetails.socialSecurityNumber = "";
				patientDetails.certifyNoInsurance = false;
				patientDetails.insuranceFrontPageFileName = "";
				patientDetails.insuranceBackPageFileName = "";
			} else {
				this.state.insurances.map(insurance => {
					if (insurance._id === this.state.selectedInsuranceId) {
						let index = this.state.insuranceProviders.findIndex(
							(i) =>
								insurance.insurance_provider && i.toLowerCase() ===
								insurance.insurance_provider.toLowerCase()
						);
						if (index > -1) {
							insurance.insuranceProv1 = insurance.insurance_provider;
							insurance.insuranceProv2 = "";
						} else {
							insurance.insuranceProv1 = "Other";
							insurance.insuranceProv2 = insurance.insurance_provider;
						}
						patientDetails.insuranceId = insurance._id;
						patientDetails.insuranceProv1 = insurance.insuranceProv1;
						patientDetails.insuranceProv2 = insurance.insuranceProv2;
						patientDetails.memberId = insurance.insured_member_id ? insurance.insured_member_id : "";
						patientDetails.groupNumber = insurance.insured_group_number ? insurance.insured_group_number : "";
						patientDetails.relation = insurance.relation_to_insured ? insurance.relation_to_insured : "";
						patientDetails.insuredFirstName = insurance.insured_first_name ? insurance.insured_first_name : "";
						patientDetails.insuredLastName = insurance.insured_last_name ? insurance.insured_last_name : "";
						patientDetails.socialSecurityNumber = insurance.social_security_number ? insurance.social_security_number : "";
						patientDetails.certifyNoInsurance = insurance.certify_no_insurance ? insurance.certify_no_insurance : false;
						patientDetails.insuranceFrontPageFileName = insurance && insurance.images ? insurance.images.insurance_front : "";
						patientDetails.insuranceBackPageFileName = insurance && insurance.images ? insurance.images.insurance_back : "";
					}
					return null;
				})
			}
			this.setState({ patientDetails: patientDetails });
			this.props.setPatientDetails(patientDetails);
			this.props.showCreateRequisitionHandler();
		}
	}

	render() {
		let options = [{ value: "Self/Pay", label: "Self/Pay" }];
		this.state.insurances.map(insurance => {
			if (insurance.is_active && insurance.insurance_provider !== "Self/Pay") {
				let label = insurance.insurance_provider;
				if (insurance.relation_to_insured) {
					label += ' (' + insurance.relation_to_insured + ')';
				}
				options.push(
					{
						value: insurance._id,
						label: label
					}
				)
			}
			return null
		})
		return (
			<div>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.props.show}
					onHide={this.props.hidePatientSignupHandler}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Please select insurance to create requisition
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							this.state.patientDetails.patientId
							&&
							<div>
								<div className="row">
									<div className="col-12">
										<FormControl component="fieldset">
											<FormLabel component="legend">Please select options</FormLabel>
											<RadioGroup
												name="radio-buttons-group"
												onChange={this.handleInsuranceChange}
											>
												{
													options.map((option, index) => {
														return <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} disabled={/Cares\sAct\s\(Uninsured\)|HRSA\sCOVID-19 Uninsured\sProgram/.test(option.label)} />
													})
												}
											</RadioGroup>
										</FormControl>
										<div
											className={
												this.hasError("noneSelected") ? "inline-errormsg" : "hidden"
											}
											style={{ width: "auto", fontSize: "12px" }}
										>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;Please select any option to create requisition
											</i>
										</div>
										{
											options.length === 1
											&&
											<p>There is no active insurance record for this patient.  If Self/Pay, create requisitions now.  If they have insurance, close the box and use the edit insurance action first.</p>
										}
									</div>
								</div>
							</div>
						}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={this.props.hidePatientSignupHandler}
						>
							Close
						</Button>
						<Button
							onClick={this.showCreateRequisitionHandler}
							variant="primary"
						>
							Create Requisition
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}

export default CreateRequisitionFromPatient;
