import React, { Component } from "react";
import { getAllByFacility } from "../../../../services/clinicPortalServices/communicationService";
import { AgGridReact } from "ag-grid-react";
import ReplyBtnCellRenderer from "./replyBtnCellRenderer";
import RepliesCellRenderer from "./repliesCellRenderer";
import DetailCellRenderer from "./detailCellRenderer";
import FacilityCommunicationBreadcrumb from "./facilityCommunicationBreadcrumb";
import moment from "moment";
import { dateComparator } from "../../../../services/common/util";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicFacilityCommunications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 100,
                    maxWidth: 100,
                    cellStyle: { textAlign: "center" },
                    field: "isRead",
                    cellRenderer: "replyBtnCellRenderer"
                },
                {
                    headerName: "Subject",
                    field: "subject",
                    minWidth: 300,
                    maxWidth: 600,
                    resizable: true,
                    cellRenderer: "agGroupCellRenderer"
                },
                {
                    headerName: "Created At",
                    field: "createdAt",
                    minWidth: 200,
                    maxWidth: 300,
                    resizable: true,
                    valueGetter: (params) => {
                        return moment(
                            params.data.createdAt,
                            "YYYYMMDDHHmmss"
                        ).format("MM/DD/YYYY hh:mm:ss A");
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Replies",
                    minWidth: 120,
                    maxWidth: 120,
                    field: "replyCount",
                    cellRenderer: "repliesCellRenderer"
                },
                {
                    headerName: "Sample Id",
                    resizable: true,
                    field: "orderId.test_info.sample"
                },
                {
                    headerName: "Facility",
                    resizable: true,
                    field: "orderId.facility_source",
                    valueGetter: function addColumns(params) {
                        if (params.data.orderId) {
                            return params.data.orderId.facility_source;
                        } else {
                            return params.data.facilityName;
                        }
                    }
                },
                {
                    headerName: "From User",
                    minWidth: 200,
                    valueGetter: function addColumns(params) {
                        if (
                            params.data.fromUserId &&
                            params.data.fromUserId.first_name
                        ) {
                            return (
                                params.data.fromUserId.first_name +
                                " " +
                                params.data.fromUserId.last_name
                            );
                        } else {
                            return "";
                        }
                    }
                },
                {
                    headerName: "User Role",
                    minWidth: 200,
                    field: "fromRole"
                }
            ],
            components: {
                replyBtnCellRenderer: ReplyBtnCellRenderer,
                repliesCellRenderer: RepliesCellRenderer
            },
            detailCellRenderer: DetailCellRenderer,
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            communications: [],
            context: { componentParent: this }
        };
        this.loadCommunications();
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    loadCommunications() {
        getAllByFacility().then((res) => {
            let tmp_communications = [];
            res.data.map((item) => {
                tmp_communications.push(item);
                return null;
            });
            if (this.gridApi) {
                this.gridApi.applyTransaction({ add: tmp_communications });
            }
        });
    }

    render() {
        return (
            <div className="clinic-contain">
                <FacilityCommunicationBreadcrumb />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 140px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    detailCellRenderer={
                                        this.state.detailCellRenderer
                                    }
                                    detailRowHeight={500}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.communications}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    components={this.state.components}
                                    context={this.state.context}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicFacilityCommunications;
