import { apiInterfaceForJson } from "../common/apiManager";

export const getAllByFacility = () => {
	return apiInterfaceForJson("/communication/v1/getAllByFacility", "POST");
}

export const getReplies = (replyIdInfo) => {
	return apiInterfaceForJson("/communication/v1/getReplies", "POST", replyIdInfo);
}

export const getAllReplies = (replyIdInfo) => {
	return apiInterfaceForJson("/communication/v1/getAllReplies", "POST", replyIdInfo);
}

export const getCommunication = (commentIdInfo) => {
	return apiInterfaceForJson("/communication/v1/getCommunication", "POST", commentIdInfo);
}

export const submitCommunication = (communication) => {
	return apiInterfaceForJson("/communication/v1/", "POST", communication);
}

export const unreadCommunications = () => {
	return apiInterfaceForJson("/communication/v1/unreadCommunications/", "POST");
}

export const readCommunications = (communicationIdInfo) => {
	return apiInterfaceForJson("/communication/v1/readCommunication/", "POST", communicationIdInfo);
}

export const checkUnreadCommuniationById = (communicationIdInfo) => {
	return apiInterfaceForJson("/communication/v1/checkUnreadCommuniationById/", "POST", communicationIdInfo);
}