import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import { fetchUsers } from "../../../../services/clinicPortalServices/userService";
import UserSearchMenu from "./userSearchMenu";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import moment from "moment";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicUserGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 100,
                    maxWidth: 100,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Username",
                    minWidth: 150,
                    resizable: true,
                    field: "user_name"
                },
                {
                    headerName: "First Name",
                    minWidth: 150,
                    resizable: true,
                    field: "first_name"
                },
                {
                    headerName: "Last Name",
                    minWidth: 150,
                    resizable: true,
                    field: "last_name"
                },
                {
                    headerName: "Email",
                    minWidth: 200,
                    resizable: true,
                    field: "email"
                },
                {
                    headerName: "Role",
                    minWidth: 150,
                    field: "role",
                    resizable: true
                },
                {
                    headerName: "Facility",
                    minWidth: 200,
                    resizable: true,
                    valueGetter: function (params) {
                        if (
                            params.data.role &&
                            (params.data.role.toLowerCase() === "superadmin" ||
                                params.data.role.toLowerCase() === "admin")
                        ) {
                            return "All Facillities";
                        } else {
                            if (params.data.facilityIds.length) {
                                let facilityNames = [];
                                params.data.facilityIds.map((facility) => {
                                    facilityNames.push(facility.name);
                                    return null;
                                });
                                return facilityNames.join(", ");
                            } else {
                                return "";
                            }
                        }
                    }
                },
                {
                    headerName: "Mobile",
                    minWidth: 200,
                    resizable: true,
                    field: "mobile"
                },
                {
                    headerName: "Is Active",
                    minWidth: 200,
                    resizable: true,
                    field: "is_active",
                    valueGetter: (params) => {
                        if (params.data.is_active) {
                            return "YES";
                        }
                        return "NO";
                    }
                },
                {
                    headerName: "Last logged in",
                    minWidth: 200,
                    resizable: true,
                    field: "last_logged_in",
                    valueGetter: (params) => {
                        if (params.data.last_logged_in) {
                            return moment(
                                params.data.last_logged_in,
                                "YYYYMMDDHHmmss"
                            ).format("MM/DD/YYYY hh:mm A");
                        }
                    }
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer
            },

            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            context: { componentParent: this },
            rowClassRules: {
                "old-facility-row": function (params) {
                    return !params.data.is_active;
                }
            }
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadUserGridData();
    };

    loadUserGridData = () => {
        fetchUsers().then((response) => {
            const users = response.data.filter((item) => item.role !== "Mirth");
            this.setState({ rowData: users });
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "User_Grid_" + moment().format("YYYY_MM_DD_HH_mm_ss") + ".xlsx",
            sheetName: "Users"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.UserGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.UserGridExported
        };
        createAudit(auditData);
    };

    render() {
        let columnDefs = /salesperson|audit|customerserviceuser/.test(
            getUserRole().toLowerCase()
        )
            ? this.state.columnDefs.filter((x) => x.headerName !== "Actions")
            : this.state.columnDefs;
        return (
            <div className="clinic-contain">
                <UserSearchMenu
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                    context={this.state.context}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    rowClassRules={this.state.rowClassRules}
                                    paginationAutoPageSize={true}
                                    components={this.state.components}
                                    context={this.state.context}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicUserGrid;
