import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class HistoryManagedCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false
		}
	}

	handleShow = () => {
		if (this.props.data.visited.length) {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					<span>{this.props.data.visited.length} records</span>
				</button>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.data.visited.length} records</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								this.props.data.visited.length > 0
								&&
								this.props.data.visited.map((history, index) => {
									return <div className="row form-row history-row" key={index}>
										<div className="col-12 col-md-5 history-date">
											{moment(history.visited_date, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A")}
										</div>
										<div className="col-12 col-md-7 history-description">
											{history.first_name + ' ' + history.last_name + '(' + history.email + ')'}
										</div>
									</div>
								})
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
