import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import {
    saveRequisitionChanges,
    generateUniqueKey,
    createRequisitionPDF
} from "../../../../services/clinicPortalServices/requisitionService";
import { createInsurance } from "../../../../services/clinicPortalServices/patientInsuranceService";
import { patientSignup } from "../../../../services/patientSignupServices/patientSignupFormService";
import { fetchPhysiciansUnlocked } from "../../../../services/clinicPortalServices/physicianServices";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import { fetchPatientWithQuery } from "../../../../services/clinicPortalServices/patientSearchService";
import { serviceConstants } from "../../../../services/common/constants";
import moment from "moment";
import {
    identity,
    ethnicity,
    race,
    relation,
    states,
    specimenTypes,
    sampleTypes
} from "../../../../services/common/optionsData";
import {
    getUserAuthToken,
    isValidEmail,
    isValidPhoneNumber
} from "../../../../services/common/util";
import { MobileDateTimePicker, MobileDatePicker } from "@mui/x-date-pickers";
import {
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    TextField,
    Checkbox,
    MenuItem,
    Select,
    InputLabel,
    Autocomplete,
    InputAdornment,
    IconButton
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Barcode from "react-barcode";
import BarcodeScanner from "../../patients/barcodeScanner";
import {
    phoneNumberFormatter,
    checkValidityLicense,
    getUserDetails
} from "../../../../services/common/util";
import { updatePatientData } from "../../../../services/clinicPortalServices/patientEditService";
import Swal from "sweetalert2";
import _ from "lodash";
import { getAllInsuranceProviders } from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class CreateRequisition extends Component {
    constructor(props) {
        super(props);

        let collectorName = "";
        if (getUserDetails()) {
            const userInfo = JSON.parse(getUserDetails());
            collectorName = userInfo.first_name + " " + userInfo.last_name;
        }
        this.state = {
            facilities: [],
            facilityId: "",
            facilityName: "",
            facilityAddress: {},
            facilityPhone: "",
            facilityDetails: {},
            testId: "",
            testDetails: {},
            providers: [],
            selectedProviderId: "",
            providerFirstName: "",
            providerLastName: "",
            providerNPI: "",
            providerSignatureImage: "",
            collectorName: collectorName,
            collectedDate: moment().format("MM/DD/YYYY hh:mm A"),
            specimenType: "",
            sampleType: "",
            diagnosisCode: [],
            testInfoCode: "",
            testInfoDescription: "",
            sample: "",
            step: "beforePatient",
            patients: [],
            patientCase: "existing",
            existingPatient: {},
            checkPatient: false,
            patientFirstName: "",
            patientLastName: "",
            gender: "U",
            phoneNumber: "",
            email: "",
            is_pregnant: false,
            dateOfBirth: moment().format("MM/DD/YYYY"),
            ethnicity: "",
            race: "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip: "",
            passport: "",
            customInfo: "",
            symptoms: [],
            errors: [],
            insurances: [],
            selectedInsurance: "",
            insuranceId: "",
            insuranceProv1: "",
            insuranceProv2: "",
            memberId: "",
            groupNumber: "",
            relation: "",
            insuredFirstName: "",
            insuredLastName: "",
            driverLicense: "",
            driverLicenseState: "",
            socialSecurityNumber: "",
            checkedSign: false,
            saving: false,
            showDatePickerError: false,
            showPrintModal: false,
            insuranceProviders: [],
            allTests: []
        };
        getAllTests({ type: "all" }).then((res) => {
            this.setState({ allTests: res.data });
        });
    }

    componentDidMount() {
        getAllInsuranceProviders().then((res) => {
            this.setState({
                insuranceProviders: [
                    "Self/Pay",
                    "Other",
                    ...res.data
                        .filter((item) => item.name !== "NO INSURANCE")
                        .map((item) => {
                            return item.name;
                        })
                ]
            });
        });
    }

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    loadDataForModal = () => {
        fetchFacilitiesForOrders().then((res) => {
            let facilities;
            if (this.props.testId) {
                facilities = res.data.filter((item) =>
                    item.testIds.find(
                        (test) =>
                            test._id === this.props.testId ||
                            (test.testIds &&
                                test.testIds.length &&
                                test.testIds.find(
                                    (testId) => testId === this.props.testId
                                ))
                    )
                );
                let testDetails = this.state.allTests.find(
                    (item) => item._id === this.props.testId
                );
                this.setState(
                    {
                        testDetails: testDetails,
                        specimenType: testDetails.specimenType,
                        sampleType: testDetails.sampleType
                    },
                    () => {
                        this.setTestInfo();
                    }
                );
            } else {
                facilities = res.data;
            }

            this.setState({
                facilities: facilities,
                facilityId: facilities[0]._id,
                facilityName: facilities[0].name,
                facilityAddress: facilities[0].address,
                facilityPhone: facilities[0].phone_no,
                facilityDetails: facilities[0]
            });
            this.getPhysicians(facilities[0]._id);
        });
    };

    setTestInfo = () => {
        this.setState({
            testId:
                this.state.testDetails && this.state.testDetails._id
                    ? this.state.testDetails._id
                    : "",
            testInfoDescription:
                this.state.testDetails && this.state.testDetails.description
                    ? this.state.testDetails.description
                    : "",
            testInfoCode:
                this.state.testDetails && this.state.testDetails.code
                    ? this.state.testDetails.code
                    : "",
            diagnosisCode: [],
            symptoms: []
        });
    };

    getPhysicians = (facilityId) => {
        fetchPhysiciansUnlocked(facilityId).then((response) => {
            this.setState({
                providers: response.data
            });
            if (response.data.length) {
                this.setState({
                    selectedProviderId: response.data[0]._id,
                    providerFirstName: response.data[0].first_name,
                    providerLastName: response.data[0].last_name,
                    providerNPI: response.data[0].npi,
                    providerSignatureImage: response.data[0].signature_image
                });
            } else {
                this.setState({
                    selectedProviderId: "",
                    providerFirstName: "",
                    providerLastName: "",
                    providerNPI: "",
                    providerSignatureImage: ""
                });
            }
        });
    };

    getPatients = (facilityId, extraFilters = null) => {
        boundActions.startBlockUILoading();
        let patientsQuery = {
            facility_id: facilityId,
            first_name: extraFilters.firstName.trim(),
            last_name: extraFilters.lastName.trim()
        };
        fetchPatientWithQuery(patientsQuery).then((response) => {
            boundActions.endBlockUILoading();
            if (response.data) {
                let patients = response.data;
                let patientCase;
                if (patients.length) {
                    patientCase = "existing";
                } else {
                    patientCase = "new";
                }
                this.setState({
                    patients: patients,
                    patientCase: patientCase,
                    checkPatient: true
                });
            }
        });
    };

    initPatient = (isNewPatientOption = false) => {
        let patientFirstName, patientLastName, checkPatient;
        if (isNewPatientOption) {
            patientFirstName = this.state.patientFirstName;
            patientLastName = this.state.patientLastName;
            checkPatient = true;
        } else {
            patientFirstName = "";
            patientLastName = "";
            checkPatient = false;
        }
        this.setState({
            existingPatient: {},
            patientFirstName: patientFirstName,
            patientLastName: patientLastName,
            gender: "U",
            phoneNumber: "",
            email: "",
            is_pregnant: false,
            dateOfBirth: moment().format("MM/DD/YYYY"),
            ethnicity: "",
            race: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            passport: "",
            customInfo: "",
            symptoms: [],
            errors: [],
            insurances: [],
            selectedInsurance: "",
            insuranceId: "",
            insuranceProv1: "",
            insuranceProv2: "",
            memberId: "",
            groupNumber: "",
            relation: "",
            insuredFirstName: "",
            insuredLastName: "",
            driverLicense: "",
            driverLicenseState: "",
            socialSecurityNumber: "",
            checkedSign: false,
            checkPatient: checkPatient
        });
    };

    handleChange = (e) => {
        if (e.target.name === "phoneNumber") {
            this.setState({
                phoneNumber: phoneNumberFormatter(e.target.value)
            });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
        if (e.target.name === "facilityId") {
            const facility = this.state.facilities.filter(
                (fac) => fac._id === e.target.value
            )[0];
            let testDetails = this.props.testId
                ? facility.testIds.find(
                      (test) => test._id === this.props.testId
                  )
                    ? facility.testIds.find(
                          (test) => test._id === this.props.testId
                      )
                    : facility.testIds.find((test) =>
                          test.testIds.includes(this.props.testId)
                      )
                : this.state.testId
                ? facility.testIds.find(
                      (test) => test._id === this.state.testId
                  )
                : {};
            this.setState(
                {
                    facilityName: facility.name,
                    facilityAddress: facility.address,
                    facilityPhone: facility.phone_no,
                    facilityDetails: facility,
                    testDetails: testDetails
                },
                () => {
                    this.setTestInfo();
                }
            );
            this.getPhysicians(e.target.value);
            this.initPatient();
        }
        if (e.target.name === "specimenType") {
            this.setState({ sampleType: "", testDetails: {} }, () => {
                this.setTestInfo();
            });
        }
        if (e.target.name === "sampleType") {
            this.setState({ testDetails: {} }, () => {
                this.setTestInfo();
            });
        }
        if (e.target.name === "testId") {
            this.setState(
                {
                    testDetails:
                        this.state.facilityDetails.testIds &&
                        this.state.facilityDetails.testIds.length
                            ? this.state.facilityDetails.testIds.find(
                                  (item) => item._id === e.target.value
                              )
                            : {}
                },
                () => {
                    this.setTestInfo();
                }
            );
        }
        if (e.target.name === "selectedProviderId") {
            let index = this.state.providers.findIndex(
                (i) => i._id === e.target.value
            );
            if (index > -1) {
                this.setState({
                    providerFirstName: this.state.providers[index].first_name,
                    providerLastName: this.state.providers[index].last_name,
                    providerNPI: this.state.providers[index].npi,
                    providerSignatureImage:
                        this.state.providers[index].signature_image
                });
            }
        }
        if (e.target.name === "patientCase") {
            this.initPatient(e.target.value === "new" ? true : false);
        }
        if (e.target.name === "symptoms") {
            const value = e.target.value;
            let symptoms = this.state.symptoms;
            if (value === "None") {
                symptoms = ["None"];
            } else {
                if (
                    symptoms.findIndex((element) => element === value) === -1 &&
                    e.target.checked
                ) {
                    symptoms.push(value);
                } else if (
                    symptoms.findIndex((element) => element === value) !== -1 &&
                    !e.target.checked
                ) {
                    symptoms.splice(
                        symptoms.findIndex((element) => element === value),
                        1
                    );
                }
                symptoms = symptoms.filter((symptom) => symptom !== "None");
            }
            this.setState({ symptoms: symptoms });
        }
        if (this.state.errors.includes(e.target.name) && e.target.value) {
            let errors = [];
            if (
                /socialSecurityNumber|driverLicense/.test(e.target.name) &&
                e.target.value
            ) {
                errors = this.state.errors.filter(
                    (error) => !/socialSecurityNumber|driverLicense/.test(error)
                );
            } else {
                errors = this.state.errors.filter(
                    (error) => error !== e.target.name
                );
            }
            this.setState({ errors: errors });
        }
    };

    handleSelectInsurance = (e) => {
        this.setState({
            selectedInsurance: e.target.value,
            errors: this.state.errors.filter((error) => error !== "noInsurance")
        });
        let insuranceDetails = {};
        this.state.insurances.map((insurance) => {
            if (insurance._id === e.target.value) {
                insuranceDetails = _.cloneDeep(insurance);
            }
            return null;
        });
        if (insuranceDetails._id) {
            let flag = true;
            this.state.insuranceProviders.map((item) => {
                if (item.value === insuranceDetails.insurance_provider) {
                    flag = false;
                }
                return null;
            });
            if (flag) {
                insuranceDetails.insurance_provider2 =
                    insuranceDetails.insurance_provider;
                insuranceDetails.insurance_provider = "Other";
            }
            this.setState({
                insuranceId: insuranceDetails._id ? insuranceDetails._id : "",
                insuranceProv1: insuranceDetails.insurance_provider
                    ? insuranceDetails.insurance_provider
                    : "",
                insuranceProv2: insuranceDetails.insurance_provider2
                    ? insuranceDetails.insurance_provider2
                    : "",
                memberId: insuranceDetails.insured_member_id
                    ? insuranceDetails.insured_member_id
                    : "",
                groupNumber: insuranceDetails.insured_group_number
                    ? insuranceDetails.insured_group_number
                    : "",
                relation: insuranceDetails.relation_to_insured
                    ? insuranceDetails.relation_to_insured
                    : "",
                insuredFirstName: insuranceDetails.insured_first_name
                    ? insuranceDetails.insured_first_name
                    : "",
                insuredLastName: insuranceDetails.insured_last_name
                    ? insuranceDetails.insured_last_name
                    : "",
                socialSecurityNumber: insuranceDetails.social_security_number
                    ? insuranceDetails.social_security_number
                    : ""
            });
        } else {
            this.setState({
                insuranceId: "",
                insuranceProv1: "",
                insuranceProv2: "",
                memberId: "",
                groupNumber: "",
                relation: "",
                insuredFirstName: "",
                insuredLastName: "",
                socialSecurityNumber: ""
            });
        }
    };

    togglePregnancy = () => {
        this.setState({ is_pregnant: !this.state.is_pregnant });
    };

    toggleCheckedSign = () => {
        if (!this.state.checkedSign) {
            this.setState({ errors: [] });
        } else {
            this.setState({ errors: ["checkedSign"] });
        }
        this.setState({ checkedSign: !this.state.checkedSign });
    };

    handleCheckPatient = () => {
        let errors = [];
        if (!this.state.patientFirstName) {
            errors.push("patientFirstName");
        }
        if (!this.state.patientLastName) {
            errors.push("patientLastName");
        }
        if (errors.length) {
            this.setState({ errors: errors });
        } else {
            const extraFilters = {
                firstName: this.state.patientFirstName,
                lastName: this.state.patientLastName
            };
            this.getPatients(this.state.facilityId, extraFilters);
        }
    };

    handleClearCheckPatient = () => {
        this.setState({
            checkPatient: false
        });
        this.initPatient();
    };

    autoGenerateKey = () => {
        generateUniqueKey().then((response) => {
            this.setState({
                uniqueKey: response.data,
                sample: response.data,
                showGeneratedBarcode: true
            });
        });
    };

    handleDateFiltersChange = (dateTime, type) => {
        if (type === "collectedDate") {
            this.setState({ collectedDate: dateTime });
        } else if (type === "dateOfBirth") {
            this.setState({ dateOfBirth: dateTime });
            if (
                moment(dateTime).format("YYYYMMDD") !==
                moment().format("YYYYMMDD")
            ) {
                this.setState({ showDatePickerError: false });
            }
        }
    };

    handleClose = () => {
        this.props.handleClose();
        let collectorName = "";
        if (getUserDetails()) {
            const userInfo = JSON.parse(getUserDetails());
            collectorName = userInfo.first_name + " " + userInfo.last_name;
        }
        this.setState({
            facilities: [],
            facilityId: "",
            facilityName: "",
            facilityAddress: {},
            facilityPhone: "",
            providers: [],
            selectedProviderId: "",
            providerFirstName: "",
            providerLastName: "",
            providerNPI: "",
            providerSignatureImage: "",
            collectorName: collectorName,
            collectedDate: moment().format("MM/DD/YYYY hh:mm A"),
            specimenType: "",
            sampleType: "",
            diagnosisCode: [],
            testInfoCode: "",
            testInfoDescription: "",
            sample: "",
            step: "beforePatient",
            errors: [],
            patients: [],
            patientCase: "existing",
            showPrintModal: false,
            checkPatient: false
        });

        this.initPatient();
    };

    handleBack = () => {
        if (this.state.step === "beforePatient") {
            this.handleClose();
        } else if (this.state.step === "selectPatient") {
            this.setState({ step: "beforePatient" });
        } else if (this.state.step === "selectSymptoms") {
            this.setState({ step: "selectPatient" });
        } else if (this.state.step === "afterSymptoms") {
            this.setState({ step: "selectSymptoms" });
        } else {
            this.setState({ step: "afterSymptoms" });
        }
    };

    checkValidSecurityNumber = () => {
        let isValidSecurityNumber = checkValidityLicense(
            this.state.socialSecurityNumber
        );
        if (!isValidSecurityNumber && this.state.socialSecurityNumber) {
            Swal.fire({
                title: "Invalid Social Security Number",
                text:
                    "This looks like an invalid social security number.  It is required for using the " +
                    this.state.insuranceProv1 +
                    ".  Please ensure it is entered correctly.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Try again",
                cancelButtonText: "Allow it",
                customClass: {
                    container:
                        window.localStorage.getItem("appTheme") === "Dark" &&
                        /clinic|lims/.test(
                            window.location.pathname.split("/")[1]
                        ) &&
                        "dark-swal",
                    cancelButton: "order-1",
                    confirmButton: "order-2"
                }
            }).then((result) => {
                if (!result.isConfirmed) {
                    this.setState({ step: "afterInsurance", errors: [] });
                }
            });
        } else {
            this.setState({ step: "afterInsurance", errors: [] });
        }
    };
    checkValidDriverLicense = () => {
        let isValidDriverLicense = checkValidityLicense(
            this.state.driverLicense
        );
        if (!isValidDriverLicense && this.state.driverLicense) {
            Swal.fire({
                title: "Invalid Driver License",
                text: "This looks like an invalid drivers license. A Drivers License is required to accept insurance. Without the drivers license the order may not be able to be processed by the billing company, in which case we will inform you of the error and you can correct it at that time.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Try again",
                cancelButtonText: "Allow it",
                customClass: {
                    container:
                        window.localStorage.getItem("appTheme") === "Dark" &&
                        /clinic|lims/.test(
                            window.location.pathname.split("/")[1]
                        ) &&
                        "dark-swal",
                    cancelButton: "order-1",
                    confirmButton: "order-2"
                }
            }).then((result) => {
                if (!result.isConfirmed) {
                    this.checkValidSecurityNumber();
                }
            });
        } else {
            this.checkValidSecurityNumber();
        }
    };

    handleNext = () => {
        if (this.state.step === "beforePatient") {
            let errors = [];
            if (!this.state.selectedProviderId) {
                errors.push("selectedProviderId");
            }
            if (!this.state.collectorName) {
                errors.push("collectorName");
            }
            if (
                !this.state.collectedDate ||
                (this.state.collectedDate &&
                    moment(this.state.collectedDate).format(
                        "YYYYMMDDHHmmss"
                    ) === "Invalid date")
            ) {
                errors.push("collectedDate");
            }
            if (!this.state.sampleType) {
                errors.push("sampleType");
            }
            if (!this.state.diagnosisCode.length) {
                errors.push("diagnosisCode");
            }
            if (errors.length) {
                this.setState({ errors: errors });
            } else {
                this.setState({ step: "selectPatient", errors: [] });
            }
        } else if (this.state.step === "selectPatient") {
            let errors = [];
            if (this.state.patientCase === "existing") {
                if (!this.state.existingPatient._id) {
                    errors.push("existingPatient");
                }
            }
            if (!this.state.patientFirstName) {
                errors.push("patientFirstName");
            }
            if (!this.state.patientLastName) {
                errors.push("patientLastName");
            }
            if (this.state.email && !isValidEmail(this.state.email)) {
                errors.push("wrongEmail");
            }
            if (
                this.state.phoneNumber &&
                !isValidPhoneNumber(this.state.phoneNumber)
            ) {
                errors.push("wrongPhoneNumber");
            }
            if (!this.state.phoneNumber && !this.state.email) {
                errors.push("phoneNumberOrEmail");
            }
            if (!this.state.address1) {
                errors.push("address1");
            }
            if (!this.state.city) {
                errors.push("city");
            }
            if (!this.state.state) {
                errors.push("state");
            }
            if (!this.state.zip) {
                errors.push("zip");
            }
            if (!this.state.ethnicity) {
                errors.push("ethnicity");
            }
            if (!this.state.race) {
                errors.push("race");
            }
            if (
                moment(this.state.dateOfBirth).format("YYYYMMDD") ===
                "Invalid date"
            ) {
                errors.push("dateOfBirth");
            }

            if (errors.length) {
                this.setState({ errors: errors });
            } else {
                if (
                    moment(this.state.dateOfBirth).format("YYYYMMDD") ===
                    moment().format("YYYYMMDD")
                ) {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        title: "Birthday Error",
                        text: "Birthdate can not be today's date.",
                        icon: "warning",
                        confirmButtonText: "Close"
                    }).then((result) => {
                        this.setState({ showDatePickerError: true });
                        return;
                    });
                    return;
                }
                this.setState({ step: "selectSymptoms", errors: [] });
            }
        } else if (this.state.step === "selectSymptoms") {
            let errors = [];
            if (!this.state.symptoms.length) {
                errors.push("symptoms");
            }
            if (errors.length) {
                this.setState({ errors: errors });
            } else {
                this.setState({ step: "afterSymptoms" });
            }
        } else if (this.state.step === "afterSymptoms") {
            let errors = [];
            if (!this.state.selectedInsurance) {
                errors.push("noInsurance");
            } else {
                if (this.state.selectedInsurance === "new") {
                    if (
                        !this.state.insuranceProv1 &&
                        !this.state.insuranceProv2
                    ) {
                        errors.push("noInsuranceProvider");
                    }
                    if (
                        this.state.insuranceProv1 &&
                        this.state.insuranceProv1.toLowerCase() === "other" &&
                        !this.state.insuranceProv2
                    ) {
                        errors.push("noInsuranceProvider2");
                    }
                    if (this.state.insuranceProv1 !== "Self/Pay") {
                        if (!this.state.memberId) {
                            errors.push("memberId");
                        }
                        if (!this.state.groupNumber) {
                            errors.push("groupNumber");
                        }
                        if (!this.state.relation) {
                            errors.push("relation");
                        }
                        if (
                            this.state.relation !== "Self" &&
                            !this.state.insuredFirstName
                        ) {
                            errors.push("insuredFirstName");
                        }
                        if (
                            this.state.relation !== "Self" &&
                            !this.state.insuredLastName
                        ) {
                            errors.push("insuredLastName");
                        }
                    }
                }
            }
            if (errors.length) {
                this.setState({ errors: errors });
            } else {
                if (this.state.selectedInsurance === "new") {
                    this.checkValidDriverLicense();
                } else {
                    this.setState({ step: "afterInsurance" });
                }
            }
        } else {
            let errors = [];
            if (!this.state.sample) {
                errors.push("sample");
            }
            if (this.state.sample && this.state.sample.length < 6) {
                errors.push("sampleLength");
            }
            if (!this.state.checkedSign) {
                errors.push("checkedSign");
            }
            if (errors.length) {
                this.setState({ errors: errors });
            } else {
                this.setState({ saving: true, errors: [] });
                boundActions.startBlockUILoading();
                if (this.state.patientCase === "existing") {
                    // update patient info
                    const editParams = {
                        _id: this.state.existingPatient._id,
                        firstName: this.state.patientFirstName,
                        lastName: this.state.patientLastName,
                        dob: moment(this.state.dateOfBirth).format(
                            "YYYY-MM-DD"
                        ),
                        gender: this.state.gender,
                        email: this.state.email,
                        mobile: this.state.phoneNumber,
                        address1: this.state.address1,
                        address2: this.state.address2,
                        city: this.state.city,
                        state: this.state.state,
                        zip: this.state.zip,
                        passport: this.state.passport,
                        custom_info: this.state.customInfo,
                        country: "US",
                        ethnicity: this.state.ethnicity,
                        race: this.state.race,
                        is_pregnant: this.state.is_pregnant
                    };
                    if (this.state.driverLicense) {
                        editParams.driverLicense = this.state.driverLicense;
                        editParams.driverLicenseState =
                            this.state.driverLicenseState;
                    }
                    updatePatientData(editParams).then(async (userDetails) => {
                        let insuranceId;
                        // create patient insurance
                        let insuranceDetails = {
                            insuranceId: this.state.insuranceId,
                            insuranceProv1: this.state.insuranceProv1,
                            insuranceProv2: this.state.insuranceProv2,
                            memberId: this.state.memberId,
                            groupNumber: this.state.groupNumber,
                            relation: this.state.relation,
                            insuredFirstName: this.state.insuredFirstName,
                            insuredLastName: this.state.insuredLastName,
                            socialSecurityNumber:
                                this.state.socialSecurityNumber,
                            patientId: this.state.existingPatient._id
                        };
                        if (this.state.insuranceId) {
                            insuranceId = this.state.insuranceId;
                        } else {
                            let createInsurancePromise = new Promise(
                                (resolve, reject) => {
                                    createInsurance(insuranceDetails).then(
                                        (response) => {
                                            resolve(response.data._id);
                                        }
                                    );
                                }
                            );
                            insuranceId = await createInsurancePromise;
                        }
                        // create requisition
                        if (this.state.testDetails.isPanel) {
                            this.createBundle(
                                this.state.existingPatient._id,
                                this.state.existingPatient.mrn,
                                insuranceId
                            );
                        } else {
                            this.createSingleRequisition(
                                this.state.existingPatient._id,
                                this.state.existingPatient.mrn,
                                insuranceId
                            );
                        }
                    });
                } else {
                    // create patient and insurance
                    const patientInfo = {
                        firstName: this.state.patientFirstName,
                        lastName: this.state.patientLastName,
                        email: this.state.email,
                        phone: this.state.phoneNumber,
                        sex: this.state.gender,
                        dob: moment(this.state.dateOfBirth).format(
                            "YYYY-MM-DD"
                        ),
                        ethnicity: this.state.ethnicity,
                        race: this.state.race,
                        address1: this.state.address1,
                        address2: this.state.address2,
                        city: this.state.city,
                        state: this.state.state,
                        zip: this.state.zip,
                        passport: this.state.passport,
                        custom_info: this.state.customInfo,
                        symptoms: this.state.symptoms,
                        facilityId: this.state.facilityId,
                        is_pregnant: this.state.is_pregnant,
                        notify: false
                    };
                    if (this.state.selectedInsurance === "new") {
                        patientInfo.insuranceProv1 = this.state.insuranceProv1;
                        patientInfo.insuranceProv2 = this.state.insuranceProv2;
                        patientInfo.memberId = this.state.memberId;
                        patientInfo.groupNumber = this.state.groupNumber;
                        patientInfo.relation = this.state.relation;
                        patientInfo.insuranceId = this.state.insuranceId;
                        patientInfo.insuredFirstName =
                            this.state.insuredFirstName;
                        patientInfo.insuredLastName =
                            this.state.insuredLastName;
                        patientInfo.driverLicense = this.state.driverLicense;
                        patientInfo.driverLicenseState =
                            this.state.driverLicenseState;
                        patientInfo.socialSecurityNumber =
                            this.state.socialSecurityNumber;
                    }
                    patientSignup(patientInfo).then((response) => {
                        if (response.data && response.data.length) {
                            const patient = response.data[0];
                            let insuranceId = null;
                            if (
                                response.data &&
                                response.data[1] &&
                                response.data[1]._id
                            ) {
                                insuranceId = response.data[1]._id;
                            }
                            // create requisition
                            if (this.state.testDetails.isPanel) {
                                this.createBundle(
                                    patient._id,
                                    patient.mrn,
                                    insuranceId
                                );
                            } else {
                                this.createSingleRequisition(
                                    patient._id,
                                    patient.mrn,
                                    insuranceId
                                );
                            }
                        }
                    });
                }
            }
        }
    };

    createBundle = async (patientId, patientMrn, insuranceId) => {
        let orderIds = [];
        for (let i = 0; i < this.state.testDetails.testIds.length; i++) {
            let testId = this.state.testDetails.testIds[i];
            let suffix = "";
            let testDetails;
            this.state.allTests.map((test) => {
                if (test._id === testId) {
                    suffix = test.name
                        ? test.name.substring(0, 1).toUpperCase()
                        : "";
                    if (suffix === "I") {
                        suffix = "F";
                    }
                    testDetails = test;
                }
                return null;
            });
            let sample = this.state.sample + suffix;
            let reqInfo = this.getParamsForRequisition(
                patientId,
                sample,
                testDetails,
                "sub_bundle"
            );
            if (insuranceId) {
                reqInfo.patientInsuranceId = insuranceId;
            }
            let createSubBundlesPromise = new Promise((resolve, reject) => {
                saveRequisitionChanges(reqInfo)
                    .then((response) => {
                        const createPDFData = this.getParamsForPDF(
                            sample,
                            testId
                        );
                        createPDFData.lab_order_id = response.data.lab_order_id;
                        createPDFData.mrn = patientMrn;
                        let orderId = response.data._id;
                        createRequisitionPDF(createPDFData)
                            .then((response) => {
                                resolve(orderId);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
            let orderId = await createSubBundlesPromise;
            orderIds.push(orderId);
        }
        //create bundle
        let testDetails = {
            _id: this.state.testId,
            code: this.state.testInfoCode,
            description: this.state.testInfoDescription
        };
        let reqInfo = this.getParamsForRequisition(
            patientId,
            this.state.sample,
            testDetails,
            "bundle"
        );
        reqInfo.orderIds = orderIds;
        if (insuranceId) {
            reqInfo.patientInsuranceId = insuranceId;
        }
        saveRequisitionChanges(reqInfo).then((response) => {
            const createPDFData = this.getParamsForPDF(
                this.state.sample,
                this.state.testId
            );
            createPDFData.lab_order_id = response.data.lab_order_id;
            createPDFData.mrn = patientMrn;
            createRequisitionPDF(createPDFData).then((response) => {
                boundActions.endBlockUILoading();
                this.setState({ saving: false, showPrintModal: true });
            });
        });
    };

    createSingleRequisition = async (patientId, patientMrn, insuranceId) => {
        let testDetails = {
            _id: this.state.testId,
            code: this.state.testInfoCode,
            description: this.state.testInfoDescription
        };
        let reqInfo = this.getParamsForRequisition(
            patientId,
            this.state.sample,
            testDetails,
            "single"
        );
        if (insuranceId) {
            reqInfo.patientInsuranceId = insuranceId;
        }
        saveRequisitionChanges(reqInfo).then((response) => {
            const createPDFData = this.getParamsForPDF(
                this.state.sample,
                this.state.testId
            );
            createPDFData.lab_order_id = response.data.lab_order_id;
            createPDFData.mrn = patientMrn;
            createRequisitionPDF(createPDFData).then((response) => {
                boundActions.endBlockUILoading();
                this.setState({ saving: false, showPrintModal: true });
            });
        });
    };

    getParamsForPDF = (sample, testId) => {
        return {
            patientName:
                this.state.patientFirstName + " " + this.state.patientLastName,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            dateOfBirth: moment(this.state.dateOfBirth).format("MM/DD/YYYY"),
            gender: this.state.gender,
            sample: sample,
            physicianName:
                this.state.providerFirstName +
                " " +
                this.state.providerLastName,
            facilityName: this.state.facilityName,
            providerNPI: this.state.providerNPI,
            facilityAddress1:
                this.state.facilityAddress &&
                this.state.facilityAddress.address1
                    ? this.state.facilityAddress.address1
                    : "",
            facilityAddress2:
                this.state.facilityAddress &&
                this.state.facilityAddress.address2
                    ? this.state.facilityAddress.address2
                    : "",
            facilityCity:
                this.state.facilityAddress && this.state.facilityAddress.city
                    ? this.state.facilityAddress.city
                    : "",
            facilityState:
                this.state.facilityAddress && this.state.facilityAddress.state
                    ? this.state.facilityAddress.state
                    : "",
            facilityZip:
                this.state.facilityAddress && this.state.facilityAddress.zip
                    ? this.state.facilityAddress.zip
                    : "",
            facilityPhone: this.state.facilityPhone,
            specimenType: this.state.specimenType,
            sampleType: this.state.sampleType,
            collectedDate: moment(this.state.collectedDate).format(
                "MM/DD/YYYY"
            ),
            collectedTime: moment(this.state.collectedDate).format("hh:mm A"),
            signature_image: this.state.providerSignatureImage,
            requisition_pdf_path:
                sample +
                "-" +
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") +
                ".pdf",
            diagnosisCode: this.state.diagnosisCode,
            symptoms: this.state.symptoms,
            insuranceProvider:
                this.state.insuranceProv1.toLowerCase() === "other"
                    ? this.state.insuranceProv2
                    : this.state.insuranceProv1,
            insuranceMemberId: this.state.memberId ? this.state.memberId : "",
            insuranceGroupNumber: this.state.groupNumber
                ? this.state.groupNumber
                : "",
            insuranceRelationToInsured: this.state.relation
                ? this.state.relation
                : "",
            insuranceHolderName:
                this.state.insuredFirstName + " " + this.state.insuredLastName,
            insuranceDriverLicense: this.state.driverLicense
                ? this.state.driverLicense
                : "",
            insuranceDriverLicenseState: this.state.driverLicenseState
                ? this.state.driverLicenseState
                : "",
            testId: testId
        };
    };

    getParamsForRequisition = (patientId, sample, testDetails, orderType) => {
        return {
            testId: testDetails._id,
            providerFirstName: this.state.providerFirstName,
            providerLastName: this.state.providerLastName,
            providerNPI: this.state.providerNPI,
            testInfoCode: testDetails.code,
            testInfoDescription: testDetails.description,
            specimenType: this.state.specimenType,
            sampleType: this.state.sampleType,
            diagnosisCode: this.state.diagnosisCode,
            sample: sample,
            collectedDate: this.state.collectedDate
                ? moment(this.state.collectedDate, "").format("YYYYMMDDHHmmss")
                : "",
            collectorName: this.state.collectorName,
            patientId: patientId,
            facilityId: this.state.facilityId,
            orderDate: moment().format("YYYYMMDDHHmmss"),
            facilityOrderId: sample,
            facilitySource: this.state.facilityName,
            requistionPDFPath:
                sample +
                "-" +
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") +
                ".pdf",
            symptoms: this.state.symptoms,
            orderType: orderType
        };
    };

    hidePrintModal = () => {
        Swal.fire({
            title: "Did you Print?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ showPrintModal: false });
                this.props.context.componentParent.loadGridData();
                this.handleClose();
            }
        });
    };

    printRequisitionPDF = () => {
        window.open(
            serviceConstants.API_HOST_NAME +
                "/files/requisitionPDF/" +
                this.state.sample +
                "-" +
                moment(this.state.collectedDate).format("YYYYMMDDHHmmss") +
                ".pdf?" +
                new Date().getTime() +
                "&token=" +
                getUserAuthToken()
        );
    };

    render() {
        return (
            <ModalStyled
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.handleClose}
                onEnter={this.loadDataForModal}
                backdrop="static"
                dialogClassName="removeTabIndexModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create Requisition &nbsp;
                        {this.state.step === "beforePatient" &&
                            "(Select Test and Facility)"}
                        {this.state.step === "selectPatient" &&
                            "(Select Patient)"}
                        {this.state.step === "selectSymptoms" &&
                            "(AOE Questions)"}
                        {this.state.step === "afterSymptoms" &&
                            "(Select Insurance)"}
                        {this.state.step === "afterInsurance" && "(Sample)"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {this.state.step === "beforePatient" && (
                            <div className="row form-row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Facility *"
                                            name="facilityId"
                                            value={this.state.facilityId}
                                            onChange={this.handleChange}
                                        >
                                            {this.state.facilities &&
                                                this.state.facilities.map(
                                                    (fac) => {
                                                        return (
                                                            <MenuItem
                                                                value={fac._id}
                                                                key={fac._id}
                                                            >
                                                                {fac.name}{" "}
                                                                {fac.archived ===
                                                                "archived"
                                                                    ? " ( Archived ) "
                                                                    : ""}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                        </TextField>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Physician *"
                                            name="selectedProviderId"
                                            value={
                                                this.state.selectedProviderId
                                            }
                                            onChange={this.handleChange}
                                        >
                                            {this.state.providers &&
                                                this.state.providers.map(
                                                    (prov) => {
                                                        return (
                                                            <MenuItem
                                                                value={prov._id}
                                                                key={prov._id}
                                                            >
                                                                {prov.first_name +
                                                                    " " +
                                                                    prov.last_name}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError(
                                                    "selectedProviderId"
                                                )
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Specimen Type *"
                                            name="specimenType"
                                            value={this.state.specimenType}
                                            onChange={this.handleChange}
                                            disabled={
                                                this.props.testId ? true : false
                                            }
                                        >
                                            {specimenTypes.map(
                                                (specimenType) => {
                                                    return (
                                                        <MenuItem
                                                            value={specimenType}
                                                            key={specimenType}
                                                        >
                                                            {specimenType}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError("specimenType")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Sample Type *"
                                            name="sampleType"
                                            value={this.state.sampleType}
                                            onChange={this.handleChange}
                                            disabled={
                                                this.props.testId ? true : false
                                            }
                                        >
                                            {sampleTypes[
                                                this.state.specimenType
                                            ] &&
                                                sampleTypes[
                                                    this.state.specimenType
                                                ].length > 0 &&
                                                sampleTypes[
                                                    this.state.specimenType
                                                ].map((sampleType) => {
                                                    return (
                                                        <MenuItem
                                                            value={sampleType}
                                                            key={sampleType}
                                                        >
                                                            {sampleType}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </TextField>
                                        <div
                                            className={
                                                this.hasError("sampleType")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <Autocomplete
                                            id="patient-box-demo"
                                            options={[]}
                                            value={this.state.collectorName}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    collectorName: newValue
                                                });
                                            }}
                                            freeSolo
                                            getOptionLabel={(option) =>
                                                option ? option : ""
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Name of Collector *"
                                                />
                                            )}
                                        />
                                        <div
                                            className={
                                                this.hasError("collectorName")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <MobileDateTimePicker
                                            value={this.state.collectedDate}
                                            onChange={(dateTime) =>
                                                this.handleDateFiltersChange(
                                                    dateTime.$d,
                                                    "collectedDate"
                                                )
                                            }
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    className=""
                                                    label="Collection Date *"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <EventIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                        <div
                                            className={
                                                this.hasError("collectedDate")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is incorrect.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TextField
                                            select
                                            label="Test *"
                                            name="testId"
                                            value={this.state.testId}
                                            onChange={this.handleChange}
                                            disabled={
                                                this.props.testId ? true : false
                                            }
                                        >
                                            <MenuItem value="" key="">
                                                Please select test
                                            </MenuItem>
                                            {this.state.facilityDetails &&
                                                this.state.facilityDetails
                                                    .testIds &&
                                                this.state.facilityDetails
                                                    .testIds.length > 0 &&
                                                this.state.facilityDetails.testIds.map(
                                                    (test) => {
                                                        if (
                                                            (this.props
                                                                .testId &&
                                                                (this.props
                                                                    .testId ===
                                                                    test._id ||
                                                                    test.testIds.includes(
                                                                        this
                                                                            .props
                                                                            .testId
                                                                    ))) ||
                                                            (!this.props
                                                                .testId &&
                                                                test.sampleType ===
                                                                    this.state
                                                                        .sampleType)
                                                        ) {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        test._id
                                                                    }
                                                                    key={
                                                                        test._id
                                                                    }
                                                                >
                                                                    {test.name}
                                                                </MenuItem>
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                )}
                                        </TextField>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="Test Description *"
                                            name="testId"
                                            value={
                                                this.state.testInfoDescription
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <FormControl
                                            variant="outlined"
                                            style={{
                                                width: "100%"
                                            }}
                                        >
                                            <InputLabel id="diagnosisCode-label">
                                                Diagnosis Code For Visit *
                                            </InputLabel>
                                            <Select
                                                labelId="diagnosisCode-label"
                                                id="diagnosisCode-select"
                                                value={this.state.diagnosisCode}
                                                onChange={this.handleChange}
                                                label="Diagnosis Code For Visit *"
                                                className={
                                                    this.hasError(
                                                        "diagnosisCode"
                                                    )
                                                        ? "select is-invalid"
                                                        : ""
                                                }
                                                name="diagnosisCode"
                                                multiple
                                                renderValue={(selected) => {
                                                    return selected.join(", ");
                                                }}
                                            >
                                                {this.state.testDetails &&
                                                    this.state.testDetails
                                                        .name &&
                                                    this.state.testDetails.diagnosis_codes.map(
                                                        (code) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        code.code
                                                                    }
                                                                    value={
                                                                        code.code +
                                                                        " " +
                                                                        code.text
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            this.state.diagnosisCode.includes(
                                                                                code.code +
                                                                                    " " +
                                                                                    code.text
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {code.code +
                                                                        " " +
                                                                        code.text}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                            </Select>
                                            <div
                                                className={
                                                    this.hasError(
                                                        "diagnosisCode"
                                                    )
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;This field is
                                                    required.
                                                </i>
                                            </div>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.step === "selectPatient" && (
                            <div className="row form-row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="First Name *"
                                            name="patientFirstName"
                                            value={this.state.patientFirstName}
                                            disabled={this.state.checkPatient}
                                            onChange={this.handleChange}
                                        />
                                        <div
                                            className={
                                                this.hasError(
                                                    "patientFirstName"
                                                )
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <TextField
                                            label="Last Name *"
                                            name="patientLastName"
                                            value={this.state.patientLastName}
                                            disabled={this.state.checkPatient}
                                            onChange={this.handleChange}
                                        />
                                        <div
                                            className={
                                                this.hasError("patientLastName")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div
                                        className="form-group"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}
                                    >
                                        {!this.state.checkPatient && (
                                            <Button
                                                variant="secondary"
                                                onClick={this.handleBack}
                                                style={{ marginRight: "10px" }}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Button
                                            variant="info"
                                            onClick={
                                                this.handleClearCheckPatient
                                            }
                                        >
                                            Clear above info
                                        </Button>
                                        <Button
                                            variant={
                                                this.state.checkPatient
                                                    ? "secondary"
                                                    : "primary"
                                            }
                                            onClick={this.handleCheckPatient}
                                            disabled={
                                                this.state.checkPatient
                                                    ? true
                                                    : false
                                            }
                                            style={{ marginLeft: "10px" }}
                                        >
                                            Check Existing Patient with above
                                            info
                                        </Button>
                                    </div>
                                </div>
                                {this.state.checkPatient && (
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <FormControl component="fieldset">
                                                        <FormLabel
                                                            style={{
                                                                marginBottom:
                                                                    "0px"
                                                            }}
                                                        >
                                                            Patient{" "}
                                                            <abbr className="text-danger">
                                                                *
                                                            </abbr>
                                                        </FormLabel>
                                                        <RadioGroup
                                                            aria-label="Patient *"
                                                            name="patientCase"
                                                            value={
                                                                this.state
                                                                    .patientCase
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            style={{
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "center"
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                key="existing"
                                                                value="existing"
                                                                disabled
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Existing Patient"
                                                            />
                                                            <FormControlLabel
                                                                key="new"
                                                                value="new"
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="New Patient"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    {this.state.patients
                                                        .length > 0 && (
                                                        <p className="text-info">
                                                            Patient Match found,
                                                            please click arrow
                                                            on right to select
                                                            patient in dropdown
                                                        </p>
                                                    )}
                                                    {this.state.patients
                                                        .length === 0 && (
                                                        <p className="text-info">
                                                            No patient found,
                                                            please create new
                                                            patient.
                                                        </p>
                                                    )}
                                                    {this.state.patientCase ===
                                                        "existing" && (
                                                        <Autocomplete
                                                            id="patient-box-demo"
                                                            options={
                                                                this.state
                                                                    .patients
                                                            }
                                                            value={
                                                                this.state
                                                                    .existingPatient
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                if (
                                                                    newValue &&
                                                                    newValue._id
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            existingPatient:
                                                                                newValue,
                                                                            patientFirstName:
                                                                                newValue.first_name,
                                                                            patientLastName:
                                                                                newValue.last_name,
                                                                            gender: newValue.gender,
                                                                            phoneNumber:
                                                                                newValue.mobile,
                                                                            email: newValue.email,
                                                                            is_pregnant:
                                                                                newValue.hasOwnProperty(
                                                                                    "is_pregnant"
                                                                                )
                                                                                    ? newValue.is_pregnant
                                                                                    : false,
                                                                            dateOfBirth:
                                                                                moment(
                                                                                    newValue.date_of_birth
                                                                                ).format(
                                                                                    "MM/DD/YYYY"
                                                                                ),
                                                                            ethnicity:
                                                                                newValue.ethnicity
                                                                                    ? newValue.ethnicity
                                                                                    : "",
                                                                            race: newValue.race
                                                                                ? newValue.race
                                                                                : "",
                                                                            errors: [],
                                                                            address1:
                                                                                newValue.address &&
                                                                                newValue
                                                                                    .address
                                                                                    .address1
                                                                                    ? newValue
                                                                                          .address
                                                                                          .address1
                                                                                    : "",
                                                                            address2:
                                                                                newValue.address &&
                                                                                newValue
                                                                                    .address
                                                                                    .address2
                                                                                    ? newValue
                                                                                          .address
                                                                                          .address2
                                                                                    : "",
                                                                            city:
                                                                                newValue.address &&
                                                                                newValue
                                                                                    .address
                                                                                    .city
                                                                                    ? newValue
                                                                                          .address
                                                                                          .city
                                                                                    : "",
                                                                            state:
                                                                                newValue.address &&
                                                                                newValue
                                                                                    .address
                                                                                    .state
                                                                                    ? newValue
                                                                                          .address
                                                                                          .state
                                                                                    : "",
                                                                            zip:
                                                                                newValue.address &&
                                                                                newValue
                                                                                    .address
                                                                                    .zip
                                                                                    ? newValue
                                                                                          .address
                                                                                          .zip
                                                                                    : "",
                                                                            passport:
                                                                                newValue.passport
                                                                                    ? newValue.passport
                                                                                    : "",
                                                                            customInfo:
                                                                                newValue.custom_info
                                                                                    ? newValue.custom_info
                                                                                    : "",
                                                                            driverLicense:
                                                                                newValue.driver_license,
                                                                            driverLicenseState:
                                                                                newValue.driver_license_state
                                                                        }
                                                                    );

                                                                    if (
                                                                        newValue.patient_insurance_ids &&
                                                                        newValue
                                                                            .patient_insurance_ids
                                                                            .length
                                                                    ) {
                                                                        let insurances =
                                                                            newValue.patient_insurance_ids;
                                                                        this.setState(
                                                                            {
                                                                                insurances:
                                                                                    insurances
                                                                            }
                                                                        );
                                                                    } else {
                                                                        this.setState(
                                                                            {
                                                                                insurances:
                                                                                    []
                                                                            }
                                                                        );
                                                                    }
                                                                } else {
                                                                    const temp =
                                                                        {
                                                                            patientFirstName:
                                                                                this
                                                                                    .state
                                                                                    .patientFirstName,
                                                                            patientLastName:
                                                                                this
                                                                                    .state
                                                                                    .patientLastName,
                                                                            dateOfBirth:
                                                                                this
                                                                                    .state
                                                                                    .dateOfBirth
                                                                        };
                                                                    this.initPatient();
                                                                    this.setState(
                                                                        temp
                                                                    );
                                                                }
                                                            }}
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.first_name
                                                                    ? option.first_name +
                                                                      " " +
                                                                      option.last_name +
                                                                      (option.mrn
                                                                          ? " (mrn: " +
                                                                            option.mrn +
                                                                            ", birthday: " +
                                                                            moment(
                                                                                option.date_of_birth,
                                                                                "YYYY-MM-DD"
                                                                            ).format(
                                                                                "MM/DD/YYYY"
                                                                            ) +
                                                                            ", email: " +
                                                                            option.email +
                                                                            ", mobile: " +
                                                                            option.mobile +
                                                                            ")"
                                                                          : "")
                                                                    : ""
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Existing Patient"
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "existingPatient"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Facility Name *"
                                                        name="facilityName"
                                                        value={
                                                            this.state
                                                                .facilityName
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <MobileDatePicker
                                                        value={
                                                            this.state
                                                                .dateOfBirth
                                                        }
                                                        onChange={(dateTime) =>
                                                            this.handleDateFiltersChange(
                                                                dateTime.$d,
                                                                "dateOfBirth"
                                                            )
                                                        }
                                                        maxDate={new Date()}
                                                        renderInput={(
                                                            props
                                                        ) => (
                                                            <TextField
                                                                {...props}
                                                                label="Date of Birth *"
                                                                InputProps={{
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment position="end">
                                                                                <IconButton edge="end">
                                                                                    <EventIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {this.state
                                                        .showDatePickerError && (
                                                        <div className="inline-errormsg">
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;Please
                                                                select correctly
                                                            </i>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "dateOfBirth"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            incorrect.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <MenuItem
                                                        onClick={
                                                            this.togglePregnancy
                                                        }
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .is_pregnant
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                whiteSpace:
                                                                    "break-spaces"
                                                            }}
                                                        >
                                                            Pregnancy
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        select
                                                        label="Gender *"
                                                        name="gender"
                                                        value={
                                                            this.state.gender
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        {identity.map(
                                                            (
                                                                identity,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            identity.value
                                                                        }
                                                                    >
                                                                        {
                                                                            identity.gender
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </TextField>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Phone Number *"
                                                        name="phoneNumber"
                                                        value={
                                                            this.state
                                                                .phoneNumber
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "phoneNumberOrEmail"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;At least one
                                                            of phone or email is
                                                            required.
                                                        </i>
                                                    </div>
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "wrongPhoneNumber"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This is
                                                            invalid phone
                                                            number.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "phoneNumberOrEmail"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;At least one
                                                            of phone or email is
                                                            required.
                                                        </i>
                                                    </div>
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "wrongEmail"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This is
                                                            invalid email.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Address 1 *"
                                                        name="address1"
                                                        value={
                                                            this.state.address1
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "address1"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Address 2"
                                                        name="address2"
                                                        value={
                                                            this.state.address2
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="City *"
                                                        name="city"
                                                        value={this.state.city}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "city"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        select
                                                        label="State *"
                                                        name="state"
                                                        value={this.state.state}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        {states.map((state) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        state.value
                                                                    }
                                                                    value={
                                                                        state.value
                                                                    }
                                                                >
                                                                    {
                                                                        state.state
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "state"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Zip Code *"
                                                        name="zip"
                                                        value={this.state.zip}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            this.hasError("zip")
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        select
                                                        label="What is your ethnicity? *"
                                                        name="ethnicity"
                                                        value={
                                                            this.state.ethnicity
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        {ethnicity.map(
                                                            (item) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            item.value
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                    >
                                                                        {
                                                                            item.desc
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </TextField>
                                                    <div
                                                        className={
                                                            this.hasError(
                                                                "ethnicity"
                                                            )
                                                                ? "inline-errormsg"
                                                                : "hidden"
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        >
                                                            &nbsp;This field is
                                                            required.
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <FormControl>
                                                        <FormLabel>
                                                            What is your race?{" "}
                                                            <abbr className="text-danger">
                                                                *
                                                            </abbr>
                                                        </FormLabel>
                                                        <div
                                                            className={
                                                                this.hasError(
                                                                    "race"
                                                                )
                                                                    ? "inline-errormsg"
                                                                    : "hidden"
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;This field
                                                                is required.
                                                            </i>
                                                        </div>
                                                        <RadioGroup
                                                            aria-label="Race *"
                                                            name="race"
                                                            value={
                                                                this.state.race
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            style={{
                                                                flexDirection:
                                                                    "column"
                                                            }}
                                                        >
                                                            {race.map(
                                                                (race) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={
                                                                                race.value
                                                                            }
                                                                            value={
                                                                                race.value
                                                                            }
                                                                            control={
                                                                                <Radio />
                                                                            }
                                                                            label={
                                                                                race.value
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Passport"
                                                        name="passport"
                                                        value={
                                                            this.state.passport
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Custom Info"
                                                        name="customInfo"
                                                        value={
                                                            this.state
                                                                .customInfo
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {this.state.step === "selectSymptoms" && (
                            <div className="row form-row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <FormControl>
                                            <FormLabel>
                                                Symptoms{" "}
                                                <abbr className="text-danger">
                                                    *
                                                </abbr>
                                            </FormLabel>
                                            <div
                                                className={
                                                    this.hasError("symptoms")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;This field is
                                                    required.
                                                </i>
                                            </div>
                                            {this.state.testDetails &&
                                                this.state.testDetails
                                                    .symptoms &&
                                                this.state.testDetails.symptoms.map(
                                                    (item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .symptoms &&
                                                                        this.state.symptoms.findIndex(
                                                                            (
                                                                                elem
                                                                            ) =>
                                                                                elem ===
                                                                                item
                                                                        ) !== -1
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    value={item}
                                                                    name="symptoms"
                                                                />
                                                                <span>
                                                                    {item}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.step === "afterSymptoms" && (
                            <div className="row form-row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            Please select options{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <div
                                            className={
                                                this.hasError("noInsurance")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;Please select this field.
                                            </i>
                                        </div>
                                        <RadioGroup
                                            name="radio-buttons-group"
                                            onChange={
                                                this.handleSelectInsurance
                                            }
                                            value={this.state.selectedInsurance}
                                        >
                                            {this.state.insurances.length > 0 &&
                                                this.state.insurances.map(
                                                    (insurance, index) => {
                                                        if (
                                                            insurance.is_active
                                                        ) {
                                                            let label =
                                                                insurance.insurance_provider;
                                                            if (
                                                                insurance.relation_to_insured
                                                            ) {
                                                                label +=
                                                                    " (" +
                                                                    insurance.relation_to_insured +
                                                                    ")";
                                                            }
                                                            return (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    value={
                                                                        insurance._id
                                                                    }
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    label={
                                                                        label
                                                                    }
                                                                    disabled={/Cares\sAct\s\(Uninsured\)|HRSA\sCOVID-19 Uninsured\sProgram/.test(
                                                                        insurance.insurance_provider
                                                                    )}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                )}
                                            <FormControlLabel
                                                value={"new"}
                                                control={<Radio />}
                                                label={"Create New Insurance"}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                                {this.state.selectedInsurance &&
                                    this.state.selectedInsurance === "new" && (
                                        <div className="col-12">
                                            <p>
                                                If possible provide one or the
                                                other for this patient. If we
                                                are unable to collect it is
                                                possible we will invoice you
                                            </p>
                                            <div className="row form-row">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <FormLabel>
                                                            Insurance Provider
                                                            or Self/Pay{" "}
                                                            <abbr className="text-danger">
                                                                *
                                                            </abbr>
                                                        </FormLabel>
                                                        <Autocomplete
                                                            id="insurance-provider-field"
                                                            options={
                                                                this.state
                                                                    .insuranceProviders
                                                            }
                                                            value={
                                                                this.state
                                                                    .insuranceProv1
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                this.handleChange(
                                                                    {
                                                                        target: {
                                                                            value: newValue,
                                                                            name: "insuranceProv1"
                                                                        }
                                                                    }
                                                                );
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                        <div
                                                            className={
                                                                this.hasError(
                                                                    "noInsuranceProvider"
                                                                )
                                                                    ? "inline-errormsg"
                                                                    : "hidden"
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;This field
                                                                is required.
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    {this.state
                                                        .insuranceProv1 ===
                                                        "Other" && (
                                                        <div className="form-group">
                                                            <FormLabel>
                                                                Insurance
                                                                Provider (If
                                                                Other)
                                                            </FormLabel>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .insuranceProv2
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                className="form-control"
                                                                name="insuranceProv2"
                                                            />
                                                            <div
                                                                className={
                                                                    this.hasError(
                                                                        "noInsuranceProvider2"
                                                                    )
                                                                        ? "inline-errormsg"
                                                                        : "hidden"
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                >
                                                                    &nbsp;This
                                                                    field is
                                                                    required.
                                                                </i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row form-row">
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <FormLabel>
                                                            Social Security
                                                            Number
                                                        </FormLabel>
                                                        <input
                                                            type="number"
                                                            value={
                                                                this.state
                                                                    .socialSecurityNumber
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className={
                                                                this.hasError(
                                                                    "socialSecurityNumber"
                                                                )
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="socialSecurityNumber"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <FormLabel>
                                                            Driver's License #
                                                            (required for
                                                            filling an insurance
                                                            claim)
                                                        </FormLabel>
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .driverLicense
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className={
                                                                this.hasError(
                                                                    "driverLicense"
                                                                )
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                            name="driverLicense"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <FormLabel>
                                                            Driver License State
                                                        </FormLabel>
                                                        <select
                                                            value={
                                                                this.state
                                                                    .driverLicenseState
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className={
                                                                "form-control"
                                                            }
                                                            name="driverLicenseState"
                                                        >
                                                            {states.map(
                                                                (state) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                state.value
                                                                            }
                                                                            value={
                                                                                state.value
                                                                            }
                                                                        >
                                                                            {
                                                                                state.state
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.insuranceProv1 !==
                                                "Self/Pay" && (
                                                <div className="row form-row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <FormLabel>
                                                                Insurance Member
                                                                ID
                                                            </FormLabel>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .memberId
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                className="form-control"
                                                                name="memberId"
                                                            />
                                                            <div
                                                                className={
                                                                    this.hasError(
                                                                        "memberId"
                                                                    )
                                                                        ? "inline-errormsg"
                                                                        : "hidden"
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                >
                                                                    &nbsp;This
                                                                    field is
                                                                    required.
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <FormLabel>
                                                                Insurance Group
                                                                Number
                                                            </FormLabel>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .groupNumber
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                className="form-control"
                                                                name="groupNumber"
                                                            />
                                                            <div
                                                                className={
                                                                    this.hasError(
                                                                        "groupNumber"
                                                                    )
                                                                        ? "inline-errormsg"
                                                                        : "hidden"
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                >
                                                                    &nbsp;This
                                                                    field is
                                                                    required.
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <FormLabel>
                                                                Relation to
                                                                Insured
                                                            </FormLabel>
                                                            <select
                                                                value={
                                                                    this.state
                                                                        .relation
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                className="form-control"
                                                                name="relation"
                                                            >
                                                                {relation.map(
                                                                    (item) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    item.value
                                                                                }
                                                                                key={
                                                                                    item.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.desc
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                            <div
                                                                className={
                                                                    this.hasError(
                                                                        "relation"
                                                                    )
                                                                        ? "inline-errormsg"
                                                                        : "hidden"
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                >
                                                                    &nbsp;This
                                                                    field is
                                                                    required.
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.relation !==
                                                        "Self" && (
                                                        <>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group">
                                                                    <FormLabel>
                                                                        Insurance
                                                                        Holder
                                                                        First
                                                                        Name
                                                                    </FormLabel>
                                                                    <input
                                                                        type="text"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .insuredFirstName
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                        className="form-control"
                                                                        name="insuredFirstName"
                                                                    />
                                                                    <div
                                                                        className={
                                                                            this.hasError(
                                                                                "insuredFirstName"
                                                                            )
                                                                                ? "inline-errormsg"
                                                                                : "hidden"
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        >
                                                                            &nbsp;This
                                                                            field
                                                                            is
                                                                            required.
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group">
                                                                    <FormLabel>
                                                                        Insurance
                                                                        Holder
                                                                        Last
                                                                        Name
                                                                    </FormLabel>
                                                                    <input
                                                                        type="text"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .insuredLastName
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                        className="form-control"
                                                                        name="insuredLastName"
                                                                    />
                                                                    <div
                                                                        className={
                                                                            this.hasError(
                                                                                "insuredLastName"
                                                                            )
                                                                                ? "inline-errormsg"
                                                                                : "hidden"
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        >
                                                                            &nbsp;This
                                                                            field
                                                                            is
                                                                            required.
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                            </div>
                        )}
                        {this.state.step === "afterInsurance" && (
                            <div className="row  form-row">
                                <div className="col-12 ">
                                    <div className="form-group">
                                        <TextField
                                            label="Sample *"
                                            name="sample"
                                            value={this.state.sample}
                                            onChange={this.handleChange}
                                        />
                                        <ModalStyled
                                            show={
                                                this.state.showGeneratedBarcode
                                            }
                                            onHide={() =>
                                                this.setState({
                                                    showGeneratedBarcode: false
                                                })
                                            }
                                        >
                                            <Modal.Header closeButton="true">
                                                <Modal.Title>
                                                    Auto Generated BarCode
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="text-center">
                                                    {this.state.patientName}
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex"
                                                    }}
                                                >
                                                    <Barcode
                                                        value={this.state.sample.toString()}
                                                        format="CODE39"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end"
                                                    }}
                                                >
                                                    <button
                                                        className="btn btn-secondary button-info-grid"
                                                        type="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                showGeneratedBarcode: false
                                                            });
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </Modal.Body>
                                        </ModalStyled>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <BarcodeScanner
                                                handleChange={this.handleChange}
                                            />
                                            <button
                                                className="btn btn-primary button-info-grid"
                                                type="button"
                                                onClick={this.autoGenerateKey}
                                            >
                                                Auto Generate
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                this.hasError("sample")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                this.hasError("sampleLength")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;Sample ID must be at least
                                                6 characters.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <MenuItem onClick={this.toggleCheckedSign}>
                                        <Checkbox
                                            checked={this.state.checkedSign}
                                        />
                                        <div
                                            style={{
                                                whiteSpace: "break-spaces"
                                            }}
                                        >
                                            I authorize this requisition and the
                                            use of my electronic signature
                                        </div>
                                    </MenuItem>
                                    <div
                                        className={
                                            this.hasError("checkedSign")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                        style={{
                                            width: "auto",
                                            fontSize: "15px"
                                        }}
                                    >
                                        <i
                                            className="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        >
                                            &nbsp;&nbsp;You must check the
                                            authorize box before creating/saving
                                            a requisition
                                        </i>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                    <ModalStyled
                        show={this.state.showPrintModal}
                        onHide={() => this.hidePrintModal()}
                    >
                        <Modal.Header closeButton="true">
                            <Modal.Title>
                                Please Print Necessary Items
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center mb-3">
                                Thank you, the requisition was created
                                successfully and has been sent to our lab.
                            </h3>
                            <div className="row">
                                <div className="text-center col-12 mb-3">
                                    <button
                                        className="btn btn-info"
                                        type="button"
                                        style={{ width: "250px" }}
                                        onClick={() => {
                                            window.open(
                                                serviceConstants.API_HOST_NAME +
                                                    "/files/barcodeImagePDF/" +
                                                    this.state.sample +
                                                    ".pdf?" +
                                                    new Date().getTime() +
                                                    "&token=" +
                                                    getUserAuthToken()
                                            );
                                        }}
                                    >
                                        <i
                                            className="fa fa-barcode"
                                            aria-hidden="true"
                                        ></i>{" "}
                                        Print Barcode
                                    </button>
                                </div>
                                <div className="text-center col-12 mb-3">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{ width: "250px" }}
                                        onClick={() =>
                                            this.printRequisitionPDF()
                                        }
                                    >
                                        <i
                                            className="fa fa-file-pdf"
                                            aria-hidden="true"
                                        ></i>{" "}
                                        Print Requisition PDF
                                    </button>
                                </div>
                                <div className="text-center col-12">
                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        style={{ width: "250px" }}
                                        onClick={() => this.hidePrintModal()}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </ModalStyled>
                </Modal.Body>
                {(this.state.step !== "selectPatient" ||
                    this.state.checkPatient) && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleBack}>
                            {this.state.step === "beforePatient"
                                ? "Close"
                                : "Back"}
                        </Button>
                        <Button variant="primary" onClick={this.handleNext}>
                            {this.state.saving && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                ></i>
                            )}
                            {this.state.step === "afterInsurance"
                                ? "Create"
                                : "Next"}
                        </Button>
                    </Modal.Footer>
                )}
            </ModalStyled>
        );
    }
}
