import React, { Component } from "react";
import { getUserID } from "../../../../services/common/util";
import moment from "moment";

class RepliesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    replyItemCard = (replyItem) => {
        return (
            <div className="card pb-0" key={replyItem._id}>
                <div className="card-header">
                    <div className="row justify-content-between align-items-center">
                        <h5 className="card-title pl-2">From: {replyItem.fromUserId.user_name}</h5>
                        {
                            replyItem.fromUserId._id !== getUserID()
                            &&
                            <button className="btn btn-primary py-0" onClick={() => { this.props.openReplyBox(replyItem) }}>
                                <i className="fas fa-reply mr-2"></i>
                                <span>Reply</span>
                            </button>
                        }
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="communicate_date_reply">
                        <span><b>{moment.tz(moment(replyItem.createdAt, "YYYYMMDDHHmmss"), "America/New_York").format("MM/DD/YYYY hh:mm:ss A")}</b></span>
                    </div>
                    <h4 className="card-title pl-2">{replyItem.subject}</h4>
                    <p className="card-text pl-2">{replyItem.message}</p>
                </div>
                <div style={{ padding: "1rem 0 0 2rem" }}>
                    {replyItem.replies.length > 0 && replyItem.replies.map(this.replyItemCard)}
                </div>
            </div>
        )

    }

    render() {
        const { replyComments } = this.props
        return (
            <div className="col-md-12">
                {
                    replyComments.length > 0
                    &&
                    replyComments.map(this.replyItemCard)
                }
            </div>
        )
    }
}

export default RepliesComponent;