const actions = {
  startBlockUILoading: (state) => {
    return {
      blockUILoading: true,
    };
  },

  endBlockUILoading: (state) => {
    return {
      blockUILoading: false,
    };
  },

  setBackgroundThemeColor: (state, item) => {
    return {
      backgroundThemeColor: item,
    };
  },
};

export default actions;
