import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class NotificationLogCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			logs: []
		}
	}

	handleShow = () => {
		if (this.props.data.notification_state) {
			switch (this.props.data.notification_state) {
				case "NO_ACTION":
					if (["Positive SARS-CoV-2", "SARS-CoV-2 POSITIVE"].includes(this.props.data.results.value)) {
						this.setState({
							logs: [
								{
									time: moment(this.props.data.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
									state: "Delivered Positive Notification to Physician"
								}
							]
						});
					}
					break;
				case "SENT_TO_PATIENT":
					this.setState({
						logs: [
							{
								time: moment(this.props.data.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Patient - Failed"
							}
						]
					});
					break;
				case "DELIVERED_TO_PATIENT":
					this.setState({
						logs: [
							{
								time: moment(this.props.data.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Patient"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Delivered to Patient - Success"
							}
						]
					});
					break;
				case "SENT_TO_FACILITY":
					this.setState({
						logs: [
							{
								time: moment(this.props.data.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Facility"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Delivered to Facility - Success"
							}
						]
					});
					break;
				case "SENT_TO_PHYSICIAN":
					this.setState({
						logs: [
							{
								time: moment(this.props.data.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Patient"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Physician"
							}
						]
					});
					break;
				case "DELIVERED_TO_PHYSICIAN":
					this.setState({
						logs: [
							{
								time: moment(this.props.data.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Patient"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Physician"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Delivered to Physician"
							}
						]
					});
					break;
				case "SENT_TO_SUPERADMIN":
					this.setState({
						logs: [
							{
								time: moment(this.props.data.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Patient"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").subtract(2, "hours").format("MM/DD/YYYY hh:mm A"),
								state: "Sent to Physician"
							},
							{
								time: moment(this.props.data.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A"),
								state: "Delivered to SuperAdmin"
							}
						]
					});
					break;
				default:
					break;
			}
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false, logs: [] });
	};

	render() {
		let notificationState = this.props.data.notification_state;
		if (notificationState === 'SENT_TO_FACILITY') {
			notificationState = 'DELIVERED_TO_FACILITY';
		}
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					<span>
						{
							notificationState !== 'NO_ACTION'
							&&
							notificationState
						}
						{
							notificationState === 'NO_ACTION'
							&&
							this.props.data.results && ["Positive SARS-CoV-2", "SARS-CoV-2 POSITIVE"].includes(this.props.data.results.value)
							&&
							"DELIVERED_POSITIVE_NOTIFICATION_TO_PHYSICIAN"
						}
					</span>
				</button>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Notification Log</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								this.state.logs.length > 0
								&&
								this.state.logs.map((log, index) => {
									return <div className="row form-row history-row" key={index}>
										<div className="col-12 col-md-5 history-date">
											{log.time}
										</div>
										<div className="col-12 col-md-7 history-description">
											{log.state}
										</div>
									</div>
								})
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
