import React, { Component } from "react";

class ReserveNow extends Component {
	render() {
		return (
			<section className="section popular-section" id="reserve_now">
				<div className="container">
					<div className="section-header text-center">
						<h2>RESERVE YOUR VIP RESULT NOW</h2>
						<p className="sub-title">
							Simply fill out the form and complete the payment and visit your chosen location.
							You will receive an email and sms with simple instructions once you are at your visit.
						</p>
					</div>
				</div>
			</section>
		);
	}
}
export default ReserveNow;
