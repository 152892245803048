import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { notificationTemplateValues } from "../../../../services/common/constants";
import { TextField } from "@mui/material";

export default class TemplateContentEditPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            selectionStart: null,
            selectionEnd: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.setState({
                content: this.props.content,
                selectionStart: this.props.content
                    ? this.props.content.length
                    : 0,
                selectionEnd: this.props.content ? this.props.content.length : 0
            });
        }
    }

    handleClose = () => {
        this.props.handleClose();
    };

    handleSave = () => {
        this.handleClose();
        this.props.handleSave(this.props.type, this.state.content);
    };

    render() {
        return (
            <div>
                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Template Content</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-md-8">
                                    <TextField
                                        rows={20}
                                        value={this.state.content}
                                        onChange={(e) =>
                                            this.setState({
                                                content: e.target.value,
                                                selectionStart:
                                                    e.target.selectionStart,
                                                selectionEnd:
                                                    e.target.selectionEnd
                                            })
                                        }
                                        onClick={(e) => {
                                            this.setState({
                                                selectionStart:
                                                    e.target.selectionStart,
                                                selectionEnd:
                                                    e.target.selectionEnd
                                            });
                                        }}
                                        multiline
                                    />
                                </div>
                                <div className="col-md-4">
                                    {notificationTemplateValues.map(
                                        (item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        className="btn btn-info w-100 m-1"
                                                        onClick={() => {
                                                            let originalContent =
                                                                this.state
                                                                    .content;
                                                            let newContent =
                                                                originalContent?.substring(
                                                                    0,
                                                                    this.state
                                                                        .selectionStart
                                                                ) +
                                                                "{{ " +
                                                                item.label +
                                                                " }}" +
                                                                originalContent?.substring(
                                                                    this.state
                                                                        .selectionEnd
                                                                );
                                                            this.setState({
                                                                content:
                                                                    newContent
                                                            });
                                                        }}
                                                    >
                                                        {item.label}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleSave}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
            </div>
        );
    }
}
