import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { states, faxTypes } from "../../services/common/optionsData";
import { Checkbox, FormControl, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { isValidEmail, isValidPhoneNumber } from "../../services/common/util";

class FacilityInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
    }

    componentDidMount() {
    }

    hasError = (index, key) => {
        if (this.state.errors[index]) {
            return this.state.errors[index].indexOf(key) !== -1;
        }
        return false;
    }

    continue = () => {
        let errors = this.props.facilities.map(facility => {
            return [];
        });

        this.props.facilities.map((facility, index) => {
            if (!facility.name) {
                errors[index].push('name');
            }
            if (!facility.contactName) {
                errors[index].push('contactName');
            }
            if (!facility.contactEmail) {
                errors[index].push('contactEmail');
            }
            if (facility.contactEmail && !isValidEmail(facility.contactEmail)) {
                errors[index].push('wrongContactEmail');
            }
            if (!facility.contactPhone) {
                errors[index].push('contactPhone');
            }
            if (facility.contactPhone && !isValidPhoneNumber(facility.contactPhone)) {
                errors[index].push('wrongContactPhone');
            }
            if (!facility.contactMobile) {
                errors[index].push('contactMobile');
            }
            if (facility.contactMobile && !isValidPhoneNumber(facility.contactMobile)) {
                errors[index].push('wrongContactMobile');
            }
            if (!facility.fax) {
                errors[index].push('fax');
            }
            if (facility.fax && !isValidPhoneNumber(facility.fax)) {
                errors[index].push('wrongFax');
            }
            if (!facility.faxType) {
                errors[index].push('faxType');
            }
            if (!facility.address1) {
                errors[index].push('address1');
            }
            if (!facility.city) {
                errors[index].push('city');
            }
            if (!facility.state) {
                errors[index].push('state');
            }
            if (!facility.zip) {
                errors[index].push('zip');
            }
            return null;
        })

        let errorHappened = false;
        errors.map(error => {
            if (error.length) {
                errorHappened = true;
            }
            return null;
        })
        if (errorHappened) {
            console.log(errors);
            this.setState({ errors: errors });
        } else {
            this.setState({ errors: [] });
            this.props.nextStep();
        }
    }

    render() {
        return (
            <div className="row form-row">
                <div className="col-12">
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <h3>Facility Information <i className="fa fa-plus-circle btn" onClick={() => this.props.addArrayItem("facility")}></i></h3>
                            </div>
                        </div>
                        {
                            this.props.facilities.map((facility, index) => {
                                return <div className="col-12" key={index}>
                                    <div className="row form-row input-box">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <h4>Facility {index + 1} <i className="fa fa-times-circle btn" onClick={() => this.props.removeArrayItem("facility", index)}></i></h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Facility Name <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={facility.name}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "name") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label></label>
                                                <MenuItem onClick={() => this.props.toggleItem('isEnvironmental', index)}>
                                                    <Checkbox checked={facility.isEnvironmental} ></Checkbox>
                                                    Environmental Monitoring
                                                </MenuItem>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Contact Name <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="contactName"
                                                    value={facility.contactName}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "contactName") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Contact Email <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="contactEmail"
                                                    value={facility.contactEmail}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "contactEmail") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongContactEmail") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Email is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Contact Mobile <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="contactMobile"
                                                    placeholder="(XXX) XXX-XXXX"
                                                    value={facility.contactMobile}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "contactMobile") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongContactMobile") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Mobile is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Contact Phone <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="contactPhone"
                                                    placeholder="(XXX) XXX-XXXX"
                                                    value={facility.contactPhone}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "contactPhone") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongContactPhone") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Phone is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Fax # <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="fax"
                                                    value={facility.fax}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "fax") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                                <div className={this.hasError(index, "wrongFax") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;Fax # is not valid.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Fax Results <abbr className="text-danger">*</abbr></label>
                                                <select
                                                    name="faxType"
                                                    value={facility.faxType}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                >
                                                    {
                                                        faxTypes.filter(item => item.value !== "ManualFax").map((fax, indexForFaxItem) => {
                                                            return <option key={indexForFaxItem} value={fax.value}>{fax.value}</option>;
                                                        })
                                                    }
                                                </select>
                                                <div className={this.hasError(index, "faxType") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <MenuItem onClick={() => this.props.toggleItem('emailNotification', index)}>
                                                    <Checkbox checked={facility.emailNotification} ></Checkbox>
                                                    Email Notification
                                                </MenuItem>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <FormControl component="fieldset">
                                                    <FormLabel style={{ marginBottom: "0px" }}>
                                                        Inconclusive Release Handling
                                                    </FormLabel>
                                                    <RadioGroup
                                                        name="firstInconclusiveRelease"
                                                        value={facility.firstInconclusiveRelease}
                                                        onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                        style={{ flexDirection: "row", justifyContent: "center" }}
                                                    >
                                                        <FormControlLabel
                                                            key={true}
                                                            value={true}
                                                            control={<Radio />}
                                                            label="First Release"
                                                        />
                                                        <FormControlLabel
                                                            key={false}
                                                            value={false}
                                                            control={<Radio />}
                                                            label="Second Release"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <h5>Facility Address</h5>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Address1 <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="address1"
                                                    value={facility.address1}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "address1") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Address2</label>
                                                <input
                                                    type="text"
                                                    name="address2"
                                                    value={facility.address2}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>City <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={facility.city}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "city") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>State <abbr className="text-danger">*</abbr></label>
                                                <select
                                                    className="form-control select"
                                                    name="state"
                                                    value={facility.state}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                >
                                                    {
                                                        states.map((state, indexForState) => {
                                                            return (
                                                                <option key={indexForState} value={state.value}>
                                                                    {state.state}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className={this.hasError(index, "state") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Zip Code <abbr className="text-danger">*</abbr></label>
                                                <input
                                                    type="text"
                                                    name="zip"
                                                    value={facility.zip}
                                                    onChange={(e) => this.props.handleArrayItemChange(e, "facility", index)}
                                                    className="form-control"
                                                />
                                                <div className={this.hasError(index, "zip") ? "inline-errormsg" : "hidden"}>
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                        &nbsp;This field is required.
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="row next-button">
                        <Button
                            className="btn-pagebreak-previous"
                            type="submit"
                            onClick={this.props.previousStep}
                            id="btnBack"
                        >
                            Back
                        </Button>
                        <Button
                            className="btn-pagebreak-next"
                            type="submit"
                            onClick={this.continue}
                            id="btnNext"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FacilityInfo;
