import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { TextField, IconButton, InputAdornment } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import moment from 'moment';
import toastr from 'toastr';
import { orderEasyUpdate } from "../../../../services/clinicPortalServices/orderEditService";
import { sendEmailNotificationToPatientForSelfPayment, sendSMSNotificationToPatientForSelfPayment } from "../../../../services/clinicPortalServices/patientInsuranceService";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { getCheckoutSessionId } from "../../../../services/patientPortalServices/paymentService";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showBillingPopup: false,
			notes: [],
			errors: []
		};
	}

	componentDidMount() {
		this.initBillingPopup();
	}

	initBillingPopup = () => {
		const paymentDetails = this.props.data.payment;
		this.setState({
			patientName: this.props.data.patient_id.first_name + " " + this.props.data.patient_id.last_name,
			orderDate: moment(this.props.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY"),
			statement_date: paymentDetails && paymentDetails.statement_date ? paymentDetails.statement_date : moment().format("MM/DD/YYYY"),
			due_date: paymentDetails && paymentDetails.due_date ? paymentDetails.due_date : moment().add(5, "days").format("MM/DD/YYYY"),
			chart_number: paymentDetails && paymentDetails.chart_number ? paymentDetails.chart_number : '',
			total_amount: paymentDetails && paymentDetails.total_amount ? paymentDetails.total_amount : 0,
			paid_amount: paymentDetails && paymentDetails.paid_amount ? paymentDetails.paid_amount : 0,
			adjustment_amount: paymentDetails && paymentDetails.adjustment_amount ? paymentDetails.adjustment_amount : 0,
			due_amount: paymentDetails && paymentDetails.due_amount ? paymentDetails.due_amount : 0,
			insurance_payments: paymentDetails && paymentDetails.insurance_payments ? paymentDetails.insurance_payments : 0,
			status: paymentDetails && paymentDetails.due_amount ? (paymentDetails.due_amount > 0 ? 'Outstanding' : 'Paid') : '',
			visit_number: paymentDetails && paymentDetails.visit_number ? paymentDetails.visit_number : '',
			patient_insurance_id: paymentDetails && paymentDetails.patient_insurance_id ? paymentDetails.patient_insurance_id : '',
			referring_provider_name: paymentDetails && paymentDetails.referring_provider_name ? paymentDetails.referring_provider_name : '',
			referring_facility: paymentDetails && paymentDetails.referring_facility ? paymentDetails.referring_facility : '',
			insurance_balance: paymentDetails && paymentDetails.insurance_balance ? paymentDetails.insurance_balance : 0,
			notes: paymentDetails ? paymentDetails.notes : [],
			errors: []
		});
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	addNote = () => {
		let notes = this.state.notes;
		notes.push({
			note: "",
			appliedDate: moment().format("MM/DD/YYYY"),
		})
		this.setState({ notes: notes });
	}

	removeNote = (index) => {
		let notes = this.state.notes;
		notes.splice(index, 1);
		this.setState({ notes: notes });
	}

	handleDateChange = (dateTime, type, index) => {
		if (type === 'statement_date') {
			this.setState({ statement_date: dateTime })
		} else if (type === 'due_date') {
			this.setState({ due_date: dateTime })
		} else {
			let notes = this.state.notes;
			notes.map((note, i) => {
				if (i === index) {
					note.appliedDate = dateTime;
				}
				return null;
			})
			this.setState({ notes: notes });
		}
	}

	handleNoteChange = (e, index) => {
		const name = e.target.name;
		let value = e.target.value;
		let notes = this.state.notes;
		notes.map((notes, i) => {
			if (index === i) {
				notes[name] = value;
			}
			return null;
		})
		this.setState({ notes: notes });
	}

	handleSubmit = (sendNotification) => {
		let errors = [];
		if (!this.state.statement_date) {
			errors.push('statement_date');
		}
		if (!this.state.due_date) {
			errors.push('due_date');
		}
		if (!this.state.total_amount) {
			errors.push('total_amount');
		}
		this.state.notes.map((note => {
			if (!note.note) {
				errors.push('note');
			}
			return null;
		}))

		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			this.setState({ errors: [] });
			let params = {
				_id: this.props.data._id,
				payment: {
					statement_date: this.state.statement_date,
					due_date: this.state.due_date,
					total_amount: this.state.total_amount,
					insurance_payments: this.state.insurance_payments,
					paid_amount: this.state.paid_amount,
					adjustment_amount: this.state.adjustment_amount,
					due_amount: Number(this.state.due_amount) ? this.state.due_amount : "0",
					chart_number: this.state.chart_number,
					visit_number: this.state.visit_number,
					patient_insurance_id: this.state.patient_insurance_id,
					referring_provider_name: this.state.referring_provider_name,
					referring_facility: this.state.referring_facility,
					insurance_balance: this.state.insurance_balance,
					notes: this.state.notes
				}
			}
			orderEasyUpdate(params).then(response => {
				if (sendNotification) {
					const patientName = this.props.data.patient_id.first_name + " " + this.props.data.patient_id.last_name;
					if (this.props.data.patient_id.email) {
						sendEmailNotificationToPatientForSelfPayment(this.props.data.patient_id.email, this.props.data.facility_id.name, patientName, Number(this.state.due_amount), this.props.data.test_id.name).then(res => {
						})
					}
					if (this.props.data.patient_id.mobile) {
						sendSMSNotificationToPatientForSelfPayment(this.props.data.patient_id.mobile, this.props.data.facility_id.name, patientName, Number(this.state.due_amount), this.props.data.test_id.name).then(res => {
						})
					}
					toastr.success("Sent Notification Successfully");
				}
				this.handleClose();
				this.props.context.componentParent.loadGridData();
			})
		}
	}

	handleShowBillingPopup = () => {
		this.initBillingPopup();
		this.setState({
			showBillingPopup: true
		}, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	}

	handleNotification = () => {
		const patientName = this.props.data.patient_id.first_name + " " + this.props.data.patient_id.last_name;
		if (this.props.data.patient_id.email) {
			sendEmailNotificationToPatientForSelfPayment(this.props.data.patient_id.email, this.props.data.facility_id.name, patientName, Number(this.props.data.payment.due_amount), this.props.data.test_id.name).then(res => {
			})
		}
		if (this.props.data.patient_id.mobile) {
			sendSMSNotificationToPatientForSelfPayment(this.props.data.patient_id.mobile, this.props.data.facility_id.name, patientName, Number(this.props.data.payment.due_amount), this.props.data.test_id.name).then(res => {
			})
		}
		toastr.success("Sent Notification Successfully");
	}

	handleClose = () => {
		this.setState({ showBillingPopup: false });
	};

	handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;
		if (name === 'total_amount' && Number(value) >= Number(this.state.insurance_payments) + Number(this.state.adjustment_amount)) {
			let due_amount = (Math.round((value - this.state.insurance_payments - this.state.adjustment_amount) * 100) / 100).toFixed(2);
			this.setState({ due_amount: due_amount, total_amount: value })
			if (Number(due_amount) <= 0) {
				this.setState({ status: 'Paid' });
			} else {
				this.setState({ status: 'Outstanding' });
			}
		} else if (name === 'insurance_payments' && Number(this.state.total_amount) >= Number(value) + Number(this.state.adjustment_amount)) {
			let due_amount = (Math.round((this.state.total_amount - value - this.state.adjustment_amount) * 100) / 100).toFixed(2);
			this.setState({ due_amount: due_amount, insurance_payments: value })
			if (Number(due_amount) <= 0) {
				this.setState({ status: 'Paid' });
			} else {
				this.setState({ status: 'Outstanding' });
			}
		} else if (name === 'adjustment_amount' && Number(this.state.total_amount) >= Number(value) + Number(this.state.insurance_payments)) {
			let due_amount = (Math.round((this.state.total_amount - value - this.state.insurance_payments) * 100) / 100).toFixed(2);
			this.setState({ due_amount: due_amount, adjustment_amount: value })
			if (Number(due_amount) <= 0) {
				this.setState({ status: 'Paid' });
			} else {
				this.setState({ status: 'Outstanding' });
			}
		} else if (!(name.includes('amount') || name === "insurance_payments")) {
			this.setState({ [name]: value });
		}
	};

	processPayment = () => {
		boundActions.startBlockUILoading();
		let orderInfo = this.props.data;
		orderInfo.isFromSelfPaymentPage = true;
		getCheckoutSessionId(orderInfo).then(response => {
			if (response && response.sessionId) {
				this.props.context.componentParent.state.stripe.redirectToCheckout({ sessionId: response.sessionId });
			}
		});
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Billing
		</Tooltip>
	);

	renderTooltipNotification = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Send Notification
		</Tooltip>
	)

	renderTooltipProcessPayment = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Process Payment
		</Tooltip>
	)

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShowBillingPopup} className="edit-order-btn">
						<i className="fas fa-dollar-sign"></i>
					</button>
				</OverlayTrigger>
				{
					(this.props.data.payment && this.props.data.payment.due_amount > 0) && (this.props.data.patient_id.email || this.props.data.patient_id.mobile)
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipNotification}
					>
						<button onClick={this.handleNotification} className="edit-order-btn">
							<i className="fas fa-bell"></i>
						</button>
					</OverlayTrigger>
				}
				{
					(this.props.data.payment && this.props.data.payment.due_amount > 0)
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipProcessPayment}
					>
						<button onClick={this.processPayment} className="edit-order-btn">
							<i className="fas fa-credit-card"></i>
						</button>
					</OverlayTrigger>
				}

				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showBillingPopup}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Self/Pay Payment Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label>Patient Name</label>
									<input
										type="text"
										disabled
										className="form-control"
										name="patientName"
										value={this.state.patientName}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Order Date</label>
									<input
										type="text"
										disabled
										className="form-control"
										name="orderDate"
										value={this.state.orderDate}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Statement Date</label>
									<MobileDatePicker
										value={this.state.statement_date}
										onChange={(dateTime) => this.handleDateChange(dateTime.$d, 'statement_date')}
										renderInput={props => <TextField
											{...props}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton edge="end">
															<EventIcon />
														</IconButton>
													</InputAdornment>
												)
											}}
											margine="none"
										/>}
									/>
									<div className={this.hasError("statement_date") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Due Date</label>
									<MobileDatePicker
										value={this.state.due_date}
										onChange={(dateTime) => this.handleDateChange(dateTime.$d, 'due_date')}
										renderInput={props => <TextField
											{...props}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton edge="end">
															<EventIcon />
														</IconButton>
													</InputAdornment>
												)
											}}
											margine="none"
										/>}
									/>
									<div className={this.hasError("due_date") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>Total Amount</label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<input
											type="number"
											min={0}
											style={{ textAlign: 'right' }}
											placeholder="Total Amount"
											value={this.state.total_amount}
											name="total_amount"
											onChange={this.handleChange}
											className="form-control"
											disabled={this.state.status === "Paid" ? true : false}
										/>
										<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
									</div>
									<div className={this.hasError("total_amount") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>Adjustment Amount</label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<input
											type="number"
											min={0}
											style={{ textAlign: 'right' }}
											placeholder="Adjustment Amount"
											value={this.state.adjustment_amount}
											name="adjustment_amount"
											onChange={this.handleChange}
											className="form-control"
											disabled={this.state.status === "Paid" ? true : false}
										/>
										<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>Insurance Payments</label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<input
											type="number"
											min={0}
											style={{ textAlign: 'right' }}
											placeholder="Insurance Payments"
											value={this.state.insurance_payments}
											name="insurance_payments"
											onChange={this.handleChange}
											className="form-control"
											disabled={this.state.status === "Paid" ? true : false}
										/>
										<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								{
									this.state.status === "Paid"
									&&
									<div className="form-group">
										<label>Paid Amount</label>
										<div style={{ display: "flex", alignItems: "center" }}>
											<input
												type="number"
												min={0}
												style={{ textAlign: 'right' }}
												placeholder="Paid Amount"
												value={this.state.paid_amount}
												name="paid_amount"
												onChange={this.handleChange}
												className="form-control"
												disabled
											/>
											<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
										</div>
									</div>
								}
								{
									this.state.status !== "Paid"
									&&
									<div className="form-group">
										<label>Due Amount</label>
										<div style={{ display: "flex", alignItems: "center" }}>
											<input
												type="number"
												min={0}
												style={{ textAlign: 'right' }}
												placeholder="Due Amount"
												value={this.state.due_amount}
												name="due_amount"
												onChange={this.handleChange}
												className="form-control"
												disabled
											/>
											<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
										</div>
									</div>
								}
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Referring Facility</label>
									<input
										type="text"
										placeholder="Referring Facility"
										value={this.state.referring_facility}
										name="referring_facility"
										onChange={this.handleChange}
										className="form-control"
									/>
									<div className={this.hasError("referring_facility") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Physician Name</label>
									<input
										type="text"
										placeholder="Physician Name"
										value={this.state.referring_provider_name}
										name="referring_provider_name"
										onChange={this.handleChange}
										className="form-control"
									/>
									<div className={this.hasError("referring_provider_name") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Status</label>
									<input
										type="text"
										disabled
										value={this.state.status}
										className="form-control"
										style={this.state.status ? { color: this.state.status === 'Paid' ? 'rgb(18, 222, 18)' : 'red' } : null}
									/>
								</div>
							</div>
							<div className="col-12">
								<label>
									Notes
									&nbsp;&nbsp;
									<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => this.addNote()}></i>
								</label>
							</div>
							<div className="col-12">
								{
									this.state.notes.length > 0
									&&
									this.state.notes.map((note, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-md-8">
													<div className="form-group">
														<input
															type="text"
															placeholder="Note"
															value={note.note}
															name="note"
															onChange={(e) => this.handleNoteChange(e, index)}
															className="form-control"
														/>
														<div
															className={!note.note ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
													</div>
												</div>
												<div className="col-10 col-md-3">
													<div className="form-group">
														<MobileDatePicker
															value={note.appliedDate}
															onChange={(dateTime) => this.handleDateChange(dateTime.$d, 'note', index)}
															label="Applied Date"
															renderInput={props => <TextField
																{...props}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton edge="end">
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																margine="none"
															/>}
														/>
													</div>
												</div>
												<div className="col-2 col-md-1" style={{ marginTop: "-20px", display: "flex", alignItems: "center" }}>
													<div>
														<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => this.removeNote(index)}></i>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "12px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									variant="primary"
									style={{ float: "right", marginLeft: "10px" }}
									onClick={() => this.handleSubmit(true)}
								>
									Save and Send Notification
								</Button>
								<Button
									variant="info"
									style={{ float: "right", marginLeft: "10px" }}
									onClick={() => this.handleSubmit(false)}
								>
									Save
								</Button>
								<Button
									variant="secondary"
									style={{ float: "right" }}
									onClick={this.handleClose}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
