import React, { Component } from 'react';
import moment from 'moment';
import { BLUELOT_ICON } from '../../../common/imgLogoes';
import { getInvoiceById } from "../../../../services/clinicPortalServices/invoiceServices";
import { serviceConstants } from "../../../../services/common/constants";

class InvoiceView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			invoiceId: this.props.invoiceId,
			company_name: "",
			company_address: "",
			invoice_date: "",
			due_date: "",
			terms: "Due on receipt",
			amount: "",
			init_amount: "",
			payments: [],
			credits: [],
			notes: [],
			services: [],
			fileName: ""
		}
	}

	componentDidMount() {
		getInvoiceById(this.state.invoiceId)
			.then((response) => {
				if (response.data && response.data.length) {
					const invoiceData = response.data[0];
					this.setState({
						invoice_id: invoiceData.invoice_id,
						company_name: invoiceData.company_id.name,
						company_address: invoiceData.company_id.address,
						invoice_date: invoiceData.invoice_date ? invoiceData.invoice_date : "-",
						due_date: invoiceData.due_date ? invoiceData.due_date : "-",
						amount: invoiceData.amount,
						init_amount: invoiceData.init_amount,
						payments: invoiceData.payments,
						credits: invoiceData.credits,
						notes: invoiceData.notes,
						services: invoiceData.services,
						state: invoiceData.state,
						fileName: invoiceData.fileName
					})
				}
			});
	}

	print() {
		window.print();
	}

	render() {
		return (
			<div>
				<div className="content">
					<div className="container-fluid">

						<div className="row" style={{ fontFamily: "sans-serif" }}>
							<div className="col-lg-8 offset-lg-2">
								<div className="invoice-content">
									<div className="invoice-item">
										<div className="row">
											<div className="col-md-6">
												<div className="invoice-logo" style={{ fontSize: "18px" }}>
													<strong>Benchlab, LLC. International Inc.</strong>
													<br />
													9713 Key West Ave. 5th Floor
													<br />
													MD US
													<br />
													(301) 337-2100
													<br />
													info@benchlabit.com
													<br />
													www.benchlabit.com
												</div>
											</div>
											<div className="col-md-6">
												<p className="invoice-details">
													<img src={BLUELOT_ICON} alt="logo" style={{ maxHeight: "160px" }} />
												</p>
											</div>
										</div>
									</div>

									<div className="invoice-item">
										<div className="row">
											<div className="col-12 text-right init_invoice_label">
												Initial Invoice: ${this.state.init_amount}
											</div>
										</div>
									</div>

									<div className="invoice-item">
										<div className="row">
											<div className="col-12 col-md-6 invoice-label">
												INVOICE
											</div>
											{
												this.state.state === 'OUTSTANDING'
												&&
												<div className="col-12 col-md-6 text-right invoice-label invoice-state-label" style={{ color: "red" }}>
													Remaining Balance Due {"$" + this.state.amount}
												</div>
											}
											{
												this.state.state === 'PAID'
												&&
												<div className="col-12 col-md-6 text-right invoice-label invoice-state-label" style={{ color: "#12de12" }}>
													PAID
												</div>
											}
										</div>
									</div>

									<div className="invoice-item">
										<div className="row">
											<div className="col-md-4">
												<div className="invoice-info">
													<strong className="customer-text">BILL TO</strong>
													<p className="invoice-details invoice-details-two">
														{this.state.company_name}
														<br />
														{this.state.company_address.address1 + ' ' + this.state.company_address.address2}
														<br />
														{this.state.company_address.city + ', ' + this.state.company_address.state + ' ' + this.state.company_address.zip}
													</p>
												</div>
											</div>
											<div className="col-md-4">
												<div className="invoice-info">
													<strong className="customer-text">SHIP TO</strong>
													<p className="invoice-details invoice-details-two">
														{this.state.company_name}
														<br />
														{this.state.company_address.address1 + ' ' + this.state.company_address.address2}
														<br />
														{this.state.company_address.city + ', ' + this.state.company_address.state + ' ' + this.state.company_address.zip}
													</p>
												</div>
											</div>
											<div className="col-md-4">
												<div className="invoice-info invoice-info2">
													<div className="row">
														<div className="col-6">
															<strong className="customer-text text-right">INVOICE #</strong>
															<strong className="customer-text text-right">DATE</strong>
															<strong className="customer-text text-right">DUE DATE</strong>
															<strong className="customer-text text-right">TERMS</strong>
														</div>
														<div className="col-6">
															<strong className="customer-text text-left"><abbr className="invoice-details" style={{ fontSize: "15px" }}>{this.state.invoice_id}</abbr></strong>
															<strong className="customer-text text-left"><abbr className="invoice-details" style={{ fontSize: "15px" }}>{this.state.invoice_date}</abbr></strong>
															<strong className="customer-text text-left"><abbr className="invoice-details" style={{ fontSize: "15px" }}>{this.state.due_date}</abbr></strong>
															<strong className="customer-text text-left"><abbr className="invoice-details" style={{ fontSize: "15px" }}>{this.state.terms}</abbr></strong>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="invoice-item invoice-table-wrap">
										<div className="row">
											<div className="col-md-12">
												<div className="table-responsive">
													<table className="invoice-table table table-bordered">
														<thead>
															<tr>
																<th>Description</th>
																<th className="text-center">QTY</th>
																<th className="text-center">RATE</th>
																<th className="text-right">AMOUNT</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.services.length > 0
																&&
																this.state.services.map((service, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				<b>{service.service_name}</b>
																				<br />
																				Service period {moment(service.service_start_date).format('MM/DD/YYYY')} to {moment(service.service_end_date).format('MM/DD/YYYY')}
																				<br />
																				<a style={{ fontWeight: "bold" }} href={serviceConstants.HOST_NAME + "/public_files/invoices/" + this.state.fileName} target="_blank" rel="noreferrer">Download orders for this invoice</a>
																			</td>
																			<td className="text-center">{service.quantity}</td>
																			<td className="text-center">${service.rate}.00</td>
																			<td className="text-right">${service.quantity * service.rate}.00</td>
																		</tr>
																	)
																})
															}
															{
																this.state.credits.length > 0
																&&
																this.state.credits.map((credit, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				<b>Credit applied</b>
																				<br />
																				{moment(credit.appliedDate).format('MM/DD/YYYY')}
																			</td>
																			<td className="text-center"></td>
																			<td className="text-center"></td>
																			<td className="text-right">${credit.amount}.00</td>
																		</tr>
																	)
																})
															}
															{
																this.state.payments.length > 0
																&&
																this.state.payments.map((payment, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				<b>Payment received by {payment.paymentMethod}</b>
																				<br />
																				{moment(payment.appliedDate).format('MM/DD/YYYY')}
																			</td>
																			<td className="text-center"></td>
																			<td className="text-center"></td>
																			<td className="text-right">${payment.amount}.00</td>
																		</tr>
																	)
																})
															}
														</tbody>
													</table>
												</div>
											</div>
											<div className="col-md-6 col-xl-4 ml-auto">
												<div className="table-responsive">
													<table className="invoice-table-two table">
														<tbody>
															<tr>
																<th>BALANCE DUE</th>
																<td className="text-right">${this.state.amount}.00</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>


									<div className="other-info">
										<h4>Remit to:</h4>
										<p className="text-muted mb-0">
											Benchlab, LLC. International Inc.
											<br />
											9713 Key West Avenue 5th Floor
											<br />
											Rockville, MD 20850,
											<br />
											Thank you for your business.
										</p>
									</div>

									{/* <div className="other-info">
										<div className="text-right">
											<button className="btn btn-primary" onClick={this.print}>
												<i className="fas fa-print"></i>
												&nbsp;Print
											</button>
										</div>
									</div> */}


								</div>
							</div>
						</div>

					</div>

				</div>

			</div>
		);
	}
}
export default InvoiceView;
