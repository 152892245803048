import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class DescriptionCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		}
	}

	handleShow = () => {
		this.setState({ show: true });
	}

	handleClose = () => {
		this.setState({ show: false });
	}

	render() {
		return (
			<div>
				<div onClick={this.handleShow} style={{ cursor: "pointer" }}>{this.props.data.description}</div>

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Description</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12">
									{this.props.data.description}
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		)
	}
}