import moment from "moment";
import "moment-timezone";
import cookies from "js-cookies";
import Swal from "sweetalert2";
import { patientUploadImages } from "../patientSignupServices/patientSignupFormService";

window.apiCallController = new AbortController();

export const isUserLoggedIn = () => {
    const token = window.localStorage.getItem("AUTH-TOKEN");
    return token != null && token.length > 0 ? true : false;
};

export const setCookie = () => {
    const token =
        window.localStorage.getItem("AUTH-TOKEN") ||
        window.localStorage.getItem("PATIENT-AUTH-TOKEN");
    if (token != null && token.length > 0) {
        const secure = window.location.protocol === "https";
        cookies.setItem("cookie", token, undefined, "/", undefined, secure);
    }
};

export const getUserRole = () => {
    return window.localStorage.getItem("USER_ROLE")
        ? window.localStorage.getItem("USER_ROLE")
        : "";
};

export const isSuperAdminLoggedIn = () => {
    return window.localStorage.getItem("USER_ROLE");
};

export const isAllowedReducedGridView = (type) => {
    if (type === "order") {
        return /superadmin|^admin|facilityadmin|facilityuser|customerserviceuser/.test(
            getUserRole().toLowerCase()
        );
    } else {
        return /superadmin|^admin|facilityadmin|facilityuser|customerserviceuser|labtech/.test(
            getUserRole().toLowerCase()
        );
    }
};
export const getUserAuthToken = () => {
    return window.localStorage.getItem("AUTH-TOKEN")
        ? window.localStorage.getItem("AUTH-TOKEN")
        : "";
};

export const getUserID = () => {
    return window.localStorage.getItem("USER_ID");
};

export const getUserDetails = () => {
    return window.localStorage.getItem("USER_DETAILS");
};

export const getUserFullName = () => {
    const userDetails = JSON.parse(getUserDetails());
    return userDetails.first_name + " " + userDetails.last_name;
};

export const getPatientAuthToken = () => {
    return window.localStorage.getItem("PATIENT-AUTH-TOKEN");
};

export const isToken = () => {
    return window.localStorage.getItem("PATIENT-AUTH-TOKEN") ||
        window.localStorage.getItem("AUTH-TOKEN")
        ? true
        : false;
};

export const phoneNumberFormatter = (value) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
    )}-${currentValue.slice(6, 10)}`;
};

export const parseDate = (dateString) => {
    //expected input format yyyyMMdd or yyyyMMddhhmmss
    //ouput - Mon dd yyyy
    let year = dateString.substring(0, 4);
    let month = dateString.substring(4, 6);
    let day = dateString.substring(6, 8);

    let d = new Date(year + "/" + month + "/" + day);
    return d.toDateString().substring(3);
};

// convert time to EST timezone ("America/New_York")
export const convertTimeToEST = function (timeFromBrowser) {
    let browserDetectedTimezone = moment.tz.guess(true);

    let timeComingFromBrowser = moment.tz(
        timeFromBrowser,
        browserDetectedTimezone
    );
    let callUTCTime = moment.utc(timeComingFromBrowser);
    return moment.tz(callUTCTime, "America/New_York").format("YYYYMMDDHHmmss");
};

// convert time to local timezone
export const convertTimeToLocal = function (timeFromAPI) {
    timeFromAPI = moment(timeFromAPI, "YYYYMMDDHHmmss").format(
        "YYYY-MM-DDTHH:mm:ss"
    );
    let browserDetectedTimezone = moment.tz.guess(true);

    let timeComingFromAPI = moment.tz(timeFromAPI, "America/New_York");
    let callUTCTime = moment.utc(timeComingFromAPI);
    return moment
        .tz(callUTCTime, browserDetectedTimezone)
        .format("YYYYMMDDHHmmss");
};

// date comparator function
export const dateComparator = (date1, date2) => {
    if (!date1 && !date2) {
        return 0;
    }
    if (!date1) {
        return -1;
    }
    if (!date2) {
        return 1;
    }
    const date1Number = moment(date1, "MM/DD/YYYY hh:mm A").format(
        "YYYYMMDDHHmmss"
    );
    const date2Number = moment(date2, "MM/DD/YYYY hh:mm A").format(
        "YYYYMMDDHHmmss"
    );
    return date1Number - date2Number;
};

export const numberComparator = (number1, number2) => {
    if (!number1 && !number2) {
        return 0;
    }
    if (!number1) {
        return -1;
    }
    if (!number2) {
        return 1;
    }
    return number1 - number2;
};

export const isValidEmail = (email) => {
    let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

export const isValidPhoneNumber = (number) => {
    number = number
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");
    let regex = /^([2-9][0-9]{2})([0-9]{3})([0-9]{4})$/;
    return regex.test(number);
};

export const getFullAddress = (addressObject) => {
    let fullAddress =
        addressObject.address1 +
        " " +
        addressObject.address2 +
        " " +
        addressObject.city +
        ", " +
        addressObject.state +
        " " +
        addressObject.zip +
        (addressObject.zipPlus4 ? "-" + addressObject.zipPlus4 : "");
    fullAddress = fullAddress.replace(/  +/g, " ");
    return fullAddress;
};

export const checkValidityLicense = (licenseInfo) => {
    if (licenseInfo) {
        let patt = /[0-9]/g;
        let licenseNumber = licenseInfo.match(patt);
        return licenseNumber && licenseNumber.length >= 9 ? true : false;
    }
    return false;
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    let R = 6371; // Radius of the earth in km
    let dLat = (lat2 - lat1) * (Math.PI / 180);
    let dLon = (lon2 - lon1) * (Math.PI / 180);
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d;
};

export const getRowColumnIndexForQuantPlate = (cellIndex) => {
    return { row: (cellIndex % 8) + 1, column: Math.floor(cellIndex / 8) + 1 };
};

export const getCellIndexForQuantPlate = (row, column) => {
    return column * 8 + row;
};

export const abortApiCalls = () => {
    window.apiCallController.abort();
};

export const releaseApiCalls = () => {
    window.apiCallController = new AbortController();
};

export const processCellForClipboard = (params) => {
    if (
        ["received_date", "collected_date", "released_date"].includes(
            params.column.colId
        ) &&
        params.value
    ) {
        return moment(params.value, "YYYYMMDDHHmmss").format(
            "MM/DD/YYYY hh:mm A"
        );
    }
    if (params.column.colId === "date_of_birth" && params.value) {
        return moment(params.value, "YYYY-MM-DD").format("MM/DD/YYYY");
    }
    if (
        ["created_at", "updated_at"].includes(params.column.colId) &&
        params.value
    ) {
        return moment
            .tz(params.value, "America/New_York")
            .format("MM/DD/YYYY hh:mm A");
    }
    return params.value;
};

export const defaultExcelExportParams = {
    processCellCallback: (params) => {
        if (
            ["received_date", "collected_date", "released_date"].includes(
                params.column.colId
            ) &&
            params.value
        ) {
            return moment(params.value, "YYYYMMDDHHmmss").format(
                "MM/DD/YYYY hh:mm A"
            );
        }
        if (params.column.colId === "date_of_birth" && params.value) {
            return moment(params.value, "YYYY-MM-DD").format("MM/DD/YYYY");
        }
        if (
            ["created_at", "updated_at"].includes(params.column.colId) &&
            params.value
        ) {
            return moment
                .tz(params.value, "America/New_York")
                .format("MM/DD/YYYY hh:mm A");
        }
        if (["update_string"].includes(params.column.colId) && params.value) {
            return params.value
                .replaceAll("<b>", "")
                .replaceAll("</b>", "")
                .replaceAll("<br>", "\n");
        }
        return params.value;
    }
};

export const defaultExcelExportParamsToUpperCase = {
    processCellCallback: (params) => {
        if (
            ["received_date", "collected_date", "released_date"].includes(
                params.column.colId
            ) &&
            params.value
        ) {
            return moment(params.value, "YYYYMMDDHHmmss").format(
                "MM/DD/YYYY hh:mm A"
            );
        }
        if (params.column.colId === "date_of_birth" && params.value) {
            return moment(params.value, "YYYY-MM-DD").format("MM/DD/YYYY");
        }
        if (
            ["created_at", "updated_at"].includes(params.column.colId) &&
            params.value
        ) {
            return moment
                .tz(params.value, "America/New_York")
                .format("MM/DD/YYYY hh:mm A");
        }
        if (params.value) {
            params.value = params.value.toString().toUpperCase();
        }
        return params.value;
    }
};

export const popupCenter = (url, title, w, h) => {
    // Fixes dual-screen position                         Most browsers      Firefox
    let dualScreenLeft =
        window.screenLeft !== undefined
            ? window.screenLeft
            : window.screen.left;
    let dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screen.top;

    let width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
    let height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;

    let left = width / 2 - w / 2 + dualScreenLeft;
    let top = height / 2 - h / 2 + dualScreenTop;
    let newWindow = window.open(
        url,
        title,
        "scrollbars=yes, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            top +
            ", left=" +
            left
    );

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
};

export const getPlateCellCount = (cells) => {
    let count = 0;
    if (cells) {
        for (const [value] of Object.entries(cells)) {
            if (value) {
                count++;
            }
        }
    }
    return count;
};

export const createFilesFormData = (fileInfo) => {
    const {
        driverLicFile,
        driverLicFileName,
        insuranceFrontPageFile,
        insuranceFrontPageFileName,
        insuranceBackPageFile,
        insuranceBackPageFileName
    } = fileInfo;
    const formData = new FormData();
    if (driverLicFile && driverLicFileName) {
        formData.append("images", driverLicFile, driverLicFileName);
    }
    if (insuranceFrontPageFile && insuranceFrontPageFileName) {
        formData.append(
            "images",
            insuranceFrontPageFile,
            insuranceFrontPageFileName
        );
    }
    if (insuranceBackPageFile && insuranceBackPageFileName) {
        formData.append(
            "images",
            insuranceBackPageFile,
            insuranceBackPageFileName
        );
    }
    return formData;
};

export const uploadFiles = async (fileInfo, isMulti = false) => {
    let statesFailed = [];
    const {
        driverLicFile,
        driverLicFileName,
        insuranceFrontPageFile,
        insuranceFrontPageFileName,
        insuranceBackPageFile,
        insuranceBackPageFileName
    } = fileInfo;
    if (driverLicFile && driverLicFileName) {
        const formData = new FormData();
        formData.append("images", driverLicFile, driverLicFileName);
        const handleDriverLicensePromise = new Promise((resolve, reject) => {
            patientUploadImages(formData).then((res) => {
                if (res?.status !== 200) {
                    statesFailed = [
                        ...statesFailed,
                        "driverLicFile",
                        "driverLicFileName"
                    ];
                }
                resolve(true);
            });
        });
        await handleDriverLicensePromise;
    }
    if (insuranceFrontPageFile && insuranceFrontPageFileName) {
        const formData = new FormData();
        formData.append(
            "images",
            insuranceFrontPageFile,
            insuranceFrontPageFileName
        );
        const handleInsuranceFrontPageFilePromise = new Promise(
            (resolve, reject) => {
                patientUploadImages(formData).then((res) => {
                    if (res?.status !== 200) {
                        statesFailed = [
                            ...statesFailed,
                            "insuranceFrontPageFile",
                            "insuranceFrontPageFileName"
                        ];
                    }
                    resolve(true);
                });
            }
        );
        await handleInsuranceFrontPageFilePromise;
    }
    if (insuranceBackPageFile && insuranceBackPageFileName) {
        const formData = new FormData();
        formData.append(
            "images",
            insuranceBackPageFile,
            insuranceBackPageFileName
        );
        const handleInsuranceBackPageFilePromise = new Promise(
            (resolve, reject) => {
                patientUploadImages(formData).then((res) => {
                    if (res?.status !== 200) {
                        statesFailed = [
                            ...statesFailed,
                            "insuranceBackPageFile",
                            "insuranceBackPageFileName"
                        ];
                    }
                    resolve(true);
                });
            }
        );
        await handleInsuranceBackPageFilePromise;
    }
    if (statesFailed.length > 0 && !isMulti) {
        Swal.fire({
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal"
            },
            title: "Failed Uploading!",
            text: "Some of your images did not upload correctly, please try again.",
            icon: "error"
        });
    }
    return { statesFailed };
};

export const removeDuplicates = (arr) => {
    let unique = [];
    arr.forEach((element) => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    return unique;
};

export const getRepeatInfoString = (details) => {
    let repeat_info_string = "";
    switch (details.repeat_unit) {
        case "every_day":
            repeat_info_string += "Daily";
            break;
        case "end_of_month":
            repeat_info_string += "Every End of Month";
            break;
        case "day":
            if (details.repeat_interval > 1) {
                repeat_info_string +=
                    "Every " + details.repeat_interval + " days";
            } else {
                repeat_info_string += "Daily";
            }
            break;
        case "week":
            if (details.repeat_interval > 1) {
                repeat_info_string +=
                    "Every " + details.repeat_interval + " weeks on ";
            } else {
                repeat_info_string += "Weekly on ";
            }
            let weekdayArray = {
                0: "Sunday",
                1: "Monday",
                2: "Tuesday",
                3: "Wednesday",
                4: "Thursday",
                5: "Friday",
                6: "Saturday"
            };
            if (details.repeat_week_info && details.repeat_week_info.length) {
                details.repeat_week_info.map((item, index) => {
                    repeat_info_string +=
                        weekdayArray[item] +
                        (index === details.repeat_week_info.length - 1
                            ? ""
                            : ", ");
                    return null;
                });
            } else {
                return null;
            }
            break;
        case "month":
            if (details.repeat_interval > 1) {
                repeat_info_string +=
                    "Every " + details.repeat_interval + " months on ";
            } else {
                repeat_info_string += "Monthly on ";
            }
            if (details.repeat_month_info === "by_date") {
                repeat_info_string +=
                    "day " + moment(details.start_time).format("DD");
            } else {
                repeat_info_string +=
                    "the " +
                    details.repeat_month_info_week_number +
                    " " +
                    moment(details.start_time).format("dddd");
            }
            break;
        case "year":
            if (details.repeat_interval > 1) {
                repeat_info_string +=
                    "Every " +
                    details.repeat_interval +
                    " years on " +
                    moment(details.start_time).format("MMM DD");
            } else {
                repeat_info_string +=
                    "Annually on " +
                    moment(details.start_time).format("MMM DD");
            }
            break;
        case "specific_dates":
            if (details.repeat_interval > 1) {
                repeat_info_string +=
                    "Every " + details.repeat_interval + " months on ";
            } else {
                repeat_info_string += "Monthly on ";
            }
            details.specific_dates.map((item, index) => {
                repeat_info_string +=
                    item +
                    (index + 1 === details.specific_dates.length ? "" : ", ");
                return null;
            });
            break;
        default:
            break;
    }
    if (details.end_option === "end_option_count") {
        repeat_info_string += ", " + details.end_option_count + " times";
    } else if (details.end_option === "end_option_date") {
        repeat_info_string +=
            ", until " + moment(details.end_option_date).format("MMM DD, YYYY");
    }
    return repeat_info_string;
};
