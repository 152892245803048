import React, { Component } from "react";
import Swal from "sweetalert2";
import { BLUELOT_LOGO, BLUELOT_ICON } from "../imgLogoes.jsx";
import HeaderWrapper from "./headerWrapper";
import {
  isUserLoggedIn,
  getUserRole,
  getPatientAuthToken,
} from "../../../services/common/util";
import HeaderMenuDropdown from "./headerMenuDropdown";
import { logout } from "../../../services/clinicPortalServices/loginService";
import { logoutPatient } from "../../../services/patientPortalServices/loginService";
import { Navbar } from "react-bootstrap";
import { getUnCheckedOrder } from "../../../services/clinicPortalServices/orderSearchService";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { ThemeContext } from "../../../theme/ThemeProvider.jsx";
import classNames from "classnames";
import OrderOverview from "./orderOverview.jsx";
import ColorPickerPopup from "./colorPickerPopup.jsx";

class PatientPortalHeader extends Component {
  constructor(props) {
    super(props);
    const mainUrl = props.location.pathname.split("/")[1];
    this.state = {
      showClinicMenu: mainUrl.trim().toLowerCase() === "clinic" ? true : false,
      showLIMSMenu: mainUrl.trim().toLowerCase() === "lims" ? true : false,
      theme: localStorage.getItem("appTheme") || "Light",
    };
  }

  hasError = (key) => {
    return this.state.supportErrors.indexOf(key) !== -1;
  };

  componentDidMount() {
    if (isUserLoggedIn()) {
      if (
        getUserRole() &&
        (getUserRole().toLowerCase() === "facilityuser" ||
          getUserRole().toLowerCase() === "facilityadmin")
      ) {
        const faciltiesArr = JSON.parse(
          window.localStorage.getItem("FACILITY_IDS")
        );
        if (window.localStorage.getItem("popUpShows") !== "yes") {
          getUnCheckedOrder({ facilities: faciltiesArr }).then((res) => {
            window.localStorage.setItem("popUpShows", "yes");
            if (res.data.length > 0) {
              Swal.fire({
                customClass: {
                  container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                      window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                },
                title: "Unchecked Orders Alert",
                text:
                  "There are " +
                  res.data.length +
                  " unchecked orders for the last 12 hours. Please check them on Orders page",
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
              }).then((result) => {});
            }
          });
        }
      }
    }
  }

  onHandleMobileMenu = () => {
    let root = document.getElementsByTagName("html")[0];
    if (root.classList.contains("menu-opened")) {
      root.classList.remove("menu-opened");
    } else {
      root.classList.add("menu-opened");
    }
  };

  onhandleCloseMenu = () => {
    let root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  onHandleExpandMenu = () => {
    let root = document.getElementsByTagName("html")[0];
    if (root.classList.contains("miniside_menu")) {
      root.classList.remove("miniside_menu");
    } else {
      root.classList.add("miniside_menu");
    }
    window.dispatchEvent(new Event("resize"));
  };

  logout = () => {
    if (this.state.showLIMSMenu) {
      logout("/lims");
    } else {
      logout();
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeTheme(theme) {
    this.setState({ theme });
  }

  render() {
    let userRole = getUserRole();
    let role =
      userRole &&
      /superadmin|^admin|audit|customerserviceuser|salesperson|salesadmin|labtech/.test(
        userRole.toLowerCase().trim()
      );
    return (
      <div
        className={classNames("header", {
          clinicHeader: isUserLoggedIn() && this.state.showClinicMenu,
          limsHeader: isUserLoggedIn() && this.state.showLIMSMenu,
        })}
      >
        <Navbar bg="light">
          <Navbar expand="true" className="header-left">
            <i
              id="mobile_btn"
              className="fa fa-bars"
              onClick={() => this.onHandleMobileMenu()}
            />
            <Link to="/" className="navbar-brand logo logo-big">
              <img
                src={BLUELOT_LOGO}
                width={
                  isUserLoggedIn() && this.state.showClinicMenu ? "250" : "350"
                }
                object-fit="cover"
                className="d-inline-block navbar-logo"
                alt=""
              />
            </Link>
            {this.state.showClinicMenu && isUserLoggedIn() && (
              <i
                id="expand_btn"
                className="fas fa-th-list expand-btn"
                onClick={() => this.onHandleExpandMenu()}
              ></i>
            )}
            <Link to="/home" className="navbar-brand logo-small">
              <img
                src={BLUELOT_ICON}
                width="60"
                object-fit="cover"
                className="d-inline-block navbar-logo"
                alt=""
              />
            </Link>
            {this.state.showLIMSMenu && (
              <HeaderWrapper
                onhandleCloseMenu={this.onhandleCloseMenu}
                role={role}
                roleName={userRole}
                isUserLoggedIn={isUserLoggedIn()}
              />
            )}
          </Navbar>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-between">
            <div className="d-flex align-items-center">
              {(this.state.showClinicMenu || this.state.showLIMSMenu) &&
                isUserLoggedIn() && <ColorPickerPopup />}
              {(this.state.showClinicMenu || this.state.showLIMSMenu) &&
                isUserLoggedIn() && (
                  <ThemeContext.Consumer>
                    {({ setThemeName, themeName }) => (
                      <IconButton
                        onClick={setThemeName}
                        sx={{
                          mr: 0,
                          height: "fit-content",
                        }}
                      >
                        {themeName === "Light" ? (
                          <Brightness7Icon
                            sx={{
                              color: "#272b41",
                            }}
                          />
                        ) : (
                          <Brightness4Icon />
                        )}
                      </IconButton>
                    )}
                  </ThemeContext.Consumer>
                )}

              <div className="universal-desktop">
                <OrderOverview userRole={userRole} />
              </div>
            </div>
            <div>
              {isUserLoggedIn() &&
                (this.state.showClinicMenu || this.state.showLIMSMenu) && (
                  <HeaderMenuDropdown
                    logout={this.logout}
                    showClinicMenu={this.state.showClinicMenu}
                    {...this.props}
                  />
                )}
            </div>
          </Navbar.Collapse>
          {getPatientAuthToken() && (
            <ul className="nav">
              <li className="nav-item">
                <div className="top-nav-button" onClick={logoutPatient}>
                  <span>
                    <i className="fas fa-sign-out-alt"></i> Logout
                  </span>
                </div>
              </li>
            </ul>
          )}
        </Navbar>
      </div>
    );
  }
}

export default PatientPortalHeader;
