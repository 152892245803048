import React, { Component } from "react";
import { checkUnreadCommuniationById } from "../../../../services/clinicPortalServices/communicationService";
import Bell from "../../../Icons/bell";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class RepliesCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isread: false,
            unReadCnt: 0,
        };
    }

    socketConnect = () => {
        if (window.socket) {
            window.socket.on('updateReadCommunication', (responseData) => {
                this.checkUnreadAlert();
            });
        }
    };
    componentDidMount() {
        this.checkUnreadAlert();
        setTimeout(() => {
            this.socketConnect();
        }, 500)
    }
    checkUnreadAlert = () => {
        checkUnreadCommuniationById({ id: this.props.data._id }).then((res) => {
            if (res.data > 0) {
                this.setState({ isread: true, unReadCnt: res.data });
            } else {
                this.setState({ isread: false, unReadCnt: 0 });
            }
        })
    }
    componentWillUnmount() {

    }

    renderTooltipUnread = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {this.state.unReadCnt} Unread Replies
        </Tooltip>
    );

    render() {
        return (
            <div>
                {
                    this.state.isread
                    &&
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 100 }}
                        overlay={this.renderTooltipUnread}
                    >
                        <button className="unReplyBtn" >
                            <Bell width={20} height={20} />
                        </button>
                    </OverlayTrigger>
                }
                <span>{this.props.data.replyCount}</span>
            </div>
        )
    }
}

export default RepliesCellRenderer;