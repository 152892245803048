import { apiInterfaceForJson } from "../common/apiManager";

export const getPlateTemplateAllData = () => {
    return apiInterfaceForJson("/plateTemplate/v1", "GET");
};

export const searchPlateTemplate = (query) => {
    return apiInterfaceForJson("/plateTemplate/v1/search", "POST", query);
}

export const createPlateTemplate = (plateTemplateDetails) => {
    return apiInterfaceForJson("/plateTemplate/v1/", "POST", plateTemplateDetails);
};

export const updatePlateTemplate = (plateTemplateDetails) => {
    return apiInterfaceForJson("/plateTemplate/v1/", "PUT", plateTemplateDetails);
};

export const setDefault = (plateTemplateId) => {
    return apiInterfaceForJson("/plateTemplate/v1/setDefault", "POST", { id: plateTemplateId });
};

export const deletePlateTemplate = (plateTemplateDetails) => {
    let plateTemplateInfo = {
        '_id': plateTemplateDetails.id
    };
    return apiInterfaceForJson("/plateTemplate/v1/delete", "POST", plateTemplateInfo);
};