import { apiInterfaceForJson } from "../common/apiManager";

export const getAllHl7Messages = () => {
  return apiInterfaceForJson("/hl7Message/v1", "GET");
};

export const getHl7MessageDataById = (hl7MessageId) => {
  return apiInterfaceForJson("/hl7Message/v1/" + hl7MessageId, "GET");
};

export const createHl7Message = (hl7MessageDetails) => {
  return apiInterfaceForJson("/hl7Message/v1/", "POST", hl7MessageDetails);
};

export const searchHl7Messages = (query) => {
  return apiInterfaceForJson("/hl7Message/v1/search", "POST", query);
};

export const updateHl7Message = (hl7MessageDetails) => {
  return apiInterfaceForJson("/hl7Message/v1/", "PUT", hl7MessageDetails);
};

export const deleteHl7Message = (hl7MessageDetails) => {
  return apiInterfaceForJson(
    "/hl7Message/v1/delete",
    "POST",
    hl7MessageDetails
  );
};
