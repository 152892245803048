import React, { Component } from "react";
import { TextField } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import ReplyBtnCellRenderer from "./replyBtnCellRenderer";
import RepliesCellRenderer from "./repliesCellRenderer"
import { getReplies, readCommunications } from "../../../../services/clinicPortalServices/communicationService";
import { getUserID } from "../../../../services/common/util";
import moment from "moment";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class MessageCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {
        if (!this.props.data.isRead.includes(getUserID()) && this.props.data.fromUserId._id !== getUserID()) {
            readCommunications({ id: this.props.data._id }).then((response) => {
                this.props.node.parent.setData(response.data);
            });
        }
    }
    componentWillUnmount = () => {

    }
    render() {
        return (
            <div style={{ padding: '10px 2rem' }}>
                <div className="col-12">
                    <label style={{ fontWeight: "600", width: "100%" }}>Message: </label>
                </div>
                <div className="col-12">
                    <span> {this.props.data.message}</span>
                </div>
            </div>
        )
    }
}

export default class DetailCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comlumnDefs: [
                {
                    headerName: 'Reply',
                    minWidth: 100,
                    maxWidth: 100,
                    cellRenderer: "replyBtnCellRenderer"
                },
                {
                    headerName: "Subject",
                    minWidth: 600,
                    maxWidth: 600,
                    field: 'subject',
                    cellStyle: { textAlign: 'center' },
                    cellRenderer: "agGroupCellRenderer"
                },
                {
                    headerName: "Created At",
                    field: 'createdAt',
                    minWidth: 200,
                    maxWidth: 300,
                    resizable: true,
                    valueGetter: params => {
                        return moment(params.data.createdAt, "YYYYMMDDHHmmss").format(
                            "MM/DD/YYYY hh:mm:ss A"
                        );
                    }
                },
                {
                    headerName: "Replies",
                    minWidth: 100,
                    maxWidth: 100,
                    field: 'replyCount',
                    cellRenderer: "repliesCellRenderer"
                },
                {
                    headerName: 'From',
                    minWidth: 250,
                    maxWidth: 200,
                    field: 'fromRole'
                },
                {
                    headerName: 'Role',
                    minWidth: 250,
                    maxWidth: 250,
                    field: 'fromRole'
                },
            ],
            components: {
                replyBtnCellRenderer: ReplyBtnCellRenderer,
                repliesCellRenderer: RepliesCellRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: false,
            },
            replyRows: [],
            messageCellRenderer: MessageCellRenderer,
        };
        this.loadReplyMessage();
    }

    loadReplyMessage = () => {
        getReplies({ id: this.props.data._id }).then(replies => {
            let replyRows = [];
            if (replies.data) {
                replies.data.map(rowItem => {
                    replyRows.push(rowItem);
                    return null;
                });
                this.setState({ replyRows: replyRows });
            }
        })
    }
    componentDidMount = () => {
        if (!this.props.data.isRead.includes(getUserID()) && this.props.data.fromUserId && this.props.data.fromUserId._id && this.props.data.fromUserId._id !== getUserID()) {
            readCommunications({ id: this.props.data._id }).then((response) => {
                this.props.node.parent.setData(response.data);
                this.props.node.setData(response.data);
            });
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 px-5">
                        <TextField
                            label="Message"
                            variant="outlined"
                            className="communiationDetailText"
                            id="message"
                            name="message"
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ height: "80px" }}
                            type="string"
                            margin="dense"
                            disabled={true}
                            value={this.props.data.message}
                            onChange={this.onCommunicateTextChange}
                        />
                    </div>
                    <div className="col-12 px-5 mt-4">
                        <div
                            style={{ width: "100%" }}
                        >
                            <label>
                                Replies
                            </label>
                        </div>
                        <ThemeContext.Consumer>
                            {({ themeName }) => (
                                <div
                                    id="myGrid"
                                    style={{ height: '360px' }}
                                    className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
                                >
                                    <AgGridReact
                                        columnDefs={this.state.comlumnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        masterDetail={true}
                                        detailCellRenderer={this.state.messageCellRenderer}
                                        detailRowHeight={60}
                                        groupDefaultExpanded={1}
                                        onGridReady={this.onGridReady}
                                        rowData={this.state.replyRows}
                                        components={this.state.components}
                                        pagination={true}
                                        paginationPageSize={3}
                                    />
                                </div>
                            )}
                        </ThemeContext.Consumer>
                    </div>
                </div>
            </div>
        )
    }
}