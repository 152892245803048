import { apiInterfaceForJson } from "../common/apiManager";

export const fetchLabs = () => {
    return apiInterfaceForJson("/lab/v1/", "GET");
};

export const getLabDataById = (labId) => {
    return apiInterfaceForJson("/lab/v1/" + labId, "GET");
};

export const createLab = (labDetails) => {
    labDetails.address = {
        address1: labDetails.address1,
        address2: labDetails.address2,
        city: labDetails.city,
        state: labDetails.state,
        zip: labDetails.zip
    };
    return apiInterfaceForJson("/lab/v1/", "POST", labDetails);
};

export const updateLab = (labDetails) => {
    labDetails.address = {
        address1: labDetails.address1,
        address2: labDetails.address2,
        city: labDetails.city,
        state: labDetails.state,
        zip: labDetails.zip
    };
    return apiInterfaceForJson("/lab/v1/", "PUT", labDetails);
};

export const deleteLab = (labDetails) => {
    return apiInterfaceForJson("/lab/v1/delete", "POST", labDetails);
};
