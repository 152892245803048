import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { verifyCode, sendVerificationCode } from '../../../services/patientPortalServices/loginService';
import { ModalStyled } from "../../../theme/customizedStyleComponents";
import Swal from 'sweetalert2';
import { checkPatientUsername } from '../../../services/clinicPortalServices/patientSearchService';
import { auditEnums } from '../../../services/common/constants';
import { createAudit } from '../../../services/clinicPortalServices/auditService';

class ResetPasswordModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isVerified: false,
			sentVerificationCode: false,
			verificationCode: "",
			username: "",
			uniqueUsername: false,
			setPassword: "",
			setPasswordConfirmation: "",
			tryingCount: 0,
			showPasswordCharacters: false,
			passwordRequirements: {
				length: false,
				camelCase: false,
				containNumber: false,
				specialCharacterInclude: false,
				specialCharacterExclude: false,
				containSpace: false
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.show && this.props.show !== prevProps.show) {
			this.setState({
				isVerified: false,
				sentVerificationCode: false,
				verificationCode: "",
				username: this.props.usedPatient && this.props.usedPatient.username ? this.props.usedPatient.username : '',
				uniqueUsername: this.props.usedPatient && this.props.usedPatient.username ? true : false,
				password: "",
				setPassword: "",
				setPasswordConfirmation: "",
				tryingCount: 0,
				showPasswordCharacters: false
			})
		}
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value }, () => {
			if (e.target.name === 'setPassword') {
				this.checkPasswordRequirements();
			}
		});
	}

	sendVerificationCode = (type) => {
		sendVerificationCode({ patientId: this.props.usedPatient._id, type: type }).then(res => {
			if (res && res.data === "OK") {
				this.setState({
					tryingCount: this.state.tryingCount + 1,
					sentVerificationCode: true
				})
			}
		})
	}

	verifyCode = () => {
		verifyCode({ patientId: this.props.usedPatient._id, code: this.state.verificationCode }).then(res => {
			if (res && res.data === "OK") {
				this.setState({
					isVerified: true,
				})
				const auditData = {
					identifier: auditEnums.IDENTIFIERS.PatientRecord,
					event_type: auditEnums.EVENTTYPES.PatientVerificationPass,
					user_id: this.props.usedPatient._id,
					user_name: this.props.usedPatient.first_name + " " + this.props.usedPatient.last_name + " (Patient)",
					record_id: this.props.usedPatient._id,
					update_string: this.props.usedPatient.first_name + " " + this.props.usedPatient.last_name + " (" + this.props.usedPatient.mrn + ") passed verification step to " + (this.props.type === 'FORGOT_PASSWORD' ? 'reset password' : 'set username and password')
				}
				createAudit(auditData);
			} else {
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: "Wrong Code",
					text: `Verification code is not matching`,
					icon: 'warning',
					showCloseButton: true,
				});
			}
		})
	}

	checkPasswordRequirements = () => {
		let passwordRequirements = {
			length: false,
			camelCase: false,
			containNumber: false,
			specialCharacterInclude: false,
			specialCharacterExclude: false,
			containSpace: false
		};
		if (this.state.setPassword.length >= 8 && this.state.setPassword.length <= 20) {
			passwordRequirements.length = true;
		}
		if (/[A-Z]/.test(this.state.setPassword) && /[a-z]/.test(this.state.setPassword)) {
			passwordRequirements.camelCase = true;
		}
		if (/[0-9]/.test(this.state.setPassword)) {
			passwordRequirements.containNumber = true;
		}
		if (/[!@$*+-]/.test(this.state.setPassword)) {
			passwordRequirements.specialCharacterInclude = true;
		}
		if (/[^A-Za-z0-9!@$*+-]/.test(this.state.setPassword)) {
			passwordRequirements.specialCharacterExclude = true;
		}
		if (this.state.setPassword.includes(" ")) {
			passwordRequirements.containSpace = true;
		}
		this.setState({ passwordRequirements: passwordRequirements });
	}

	resetPassword = () => {
		if (this.props.type === "UPDATE_PASSWORD") {
			this.props.handleSubmit(this.state.setPassword)
		} else {
			this.props.handleSubmit('SetPassword', { verificationCode: this.state.verificationCode, username: this.state.username, setPassword: this.state.setPassword })
		}
	}

	checkUsername = () => {
		if (this.state.username) {
			checkPatientUsername(this.state.username.toLowerCase().trim()).then(res => {
				if (res && res.status === 200) {
					if (res.data) {
						this.setState({ uniqueUsername: false })
					} else {
						this.setState({ uniqueUsername: true })
					}
				} else {
					this.setState({ uniqueUsername: false })
				}
			})
		} else {
			this.setState({ uniqueUsername: false })
		}
	}

	render() {
		return (
			<ModalStyled
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				backdrop="static"
			>
				<Modal.Header>
					<Modal.Title>
						{this.state.isVerified ? "Create your Username and Password" : (this.props.type === "NEW_PASSWORD" ? "Action Required: Verify Your Account" : (this.props.type === "UPDATE_PASSWORD" ? "Update Password" : "Action Required - Reset Password"))}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						className="row"
						style={{ padding: "10px" }}
					>
						{
							!this.state.isVerified && this.props.type !== "UPDATE_PASSWORD"
							&&
							<div>
								{
									this.props.type === "NEW_PASSWORD"
									&&
									<div
										className="col-12 mb-4"
									>
										To secure your account and complete registration, choose an option below. We'll send a unique verification code to your registered email and/or mobile. Check your inbox (including spam) or phone for the code. Return to our website, enter the code in the provided field to verify your account. This step ensures contact details validation and account integrity. If no code received within a few minutes, retry up to three times. Thank you for securing your account.
									</div>
								}
								{
									this.props.type === "FORGOT_PASSWORD"
									&&
									<div
										className="col-12 mb-2"
									>
										To reset your password and secure your account, please select from the options below. We'll send a unique code to your registered email or phone. Check your inbox and spam, or your phone messages for this code.
										<br />
										Enter the code you just received on our website to verify your account and complete the reset process. It's important for validating your details and securing your account.
										<br />
										If you don't receive the code shortly, please attempt to resend it (up to three times).
										<br />
										Thank you for ensuring the security of your account.
									</div>
								}
								{
									this.props.usedPatient && this.props.usedPatient.email
									&&
									<div
										className="col-12 mb-2"
									>
										<Button variant="info" onClick={() => this.sendVerificationCode("email")} disabled={this.state.tryingCount > 2}>
											Send verification code to {this.props.usedPatient.email}
										</Button>
									</div>
								}
								{
									this.props.usedPatient && this.props.usedPatient.mobile
									&&
									<div
										className="col-12 mb-2"
									>
										<Button variant="info" onClick={() => this.sendVerificationCode("mobile")} disabled={this.state.tryingCount > 2}>
											Send verification code to {this.props.usedPatient.mobile}
										</Button>
									</div>
								}
								{
									this.state.tryingCount > 2
									&&
									<div
										className="col-12 mb-2"
									>
										You tried 3 times to get verification code, so it is disabled for now, please try again later.
									</div>
								}
								{
									this.state.sentVerificationCode
									&&
									<div className="col-12 mt-3 d-flex align-items-center">
										<div
											className="mb-2"
										>
											<input className="form-control" name="verificationCode" value={this.state.verificationCode} onChange={this.handleChange} />
										</div>
										<div
											className="mx-2"
										>
											<Button variant="primary" onClick={this.verifyCode} disabled={!this.state.verificationCode}>
												Verify Code
											</Button>
										</div>
									</div>
								}
							</div>
						}
						{
							(this.state.isVerified || this.props.type === "UPDATE_PASSWORD")
							&&
							<div>
								{
									this.props.type !== "UPDATE_PASSWORD"
									&&
									<div
										className="col-12 mb-3"
									>
										Congratulations!
										Your account verification is completed. You're just one step away from accessing your results and portal.
										Now, let's set up a secure password for your account. Your password is key to keeping your account safe, so please choose a strong, unique password that you haven't used on other sites.
									</div>
								}
								{
									this.props.type !== "UPDATE_PASSWORD"
									&&
									<div
										className="col-12 mb-2"
									>
										<label className="control-label">Username</label>
										<input className="form-control" type="text" name="username" value={this.state.username} onChange={this.handleChange} onBlur={this.checkUsername} disabled={this.props.type === "FORGOT_PASSWORD" ? true : false} />
									</div>
								}
								<div
									className="col-12 mb-2"
								>
									<label className="control-label">Password</label>
									<input className="form-control" type={this.state.showPasswordCharacters ? "text" : "password"} name="setPassword" value={this.state.setPassword} onChange={this.handleChange} />
									{
										!this.state.showPasswordCharacters
										&&
										<div className="password-eye-icon cursorPointer" onClick={() => this.setState({ showPasswordCharacters: true })}><i className="fa fa-eye"></i></div>
									}
									{
										this.state.showPasswordCharacters
										&&
										<div className="password-eye-icon cursorPointer" onClick={() => this.setState({ showPasswordCharacters: false })}><i className="fa fa-eye-slash"></i></div>
									}
								</div>
								<div
									className="col-12 mb-2"
								>
									<label className="control-label">Password Confirmation</label>
									<input className="form-control" type={this.state.showPasswordCharacters ? "text" : "password"} name="setPasswordConfirmation" value={this.state.setPasswordConfirmation} onChange={this.handleChange} />
									{
										!this.state.showPasswordCharacters
										&&
										<div className="password-eye-icon cursorPointer" onClick={() => this.setState({ showPasswordCharacters: true })}><i className="fa fa-eye"></i></div>
									}
									{
										this.state.showPasswordCharacters
										&&
										<div className="password-eye-icon cursorPointer" onClick={() => this.setState({ showPasswordCharacters: false })}><i className="fa fa-eye-slash"></i></div>
									}
								</div>
								<div className="password-requirements m-2 mt-4">
									{
										this.props.type !== "UPDATE_PASSWORD"
										&&
										<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.uniqueUsername ? "green" : "red" }}></i>Username must be provided as unique one and you will be unable to update later. {this.state.username && !this.state.uniqueUsername ? "(Current username exists already)" : ""}</div>
									}
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.passwordRequirements.length ? "green" : "red" }}></i>Must be between 8 and 20 characters</div>
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.passwordRequirements.camelCase ? "green" : "red" }}></i>Must contain both upper and lower case letters</div>
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.passwordRequirements.containNumber ? "green" : "red" }}></i>Must contain at least 1 number</div>
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.passwordRequirements.specialCharacterInclude ? "green" : "red" }}></i>Must contain at least one of these special characters: ! @ $ * + -</div>
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.passwordRequirements.specialCharacterExclude ? "red" : "green" }}></i>Cannot contain any other special characters beside those listed above</div>
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: this.state.passwordRequirements.containSpace ? "red" : "green" }}></i>Cannot contain spaces</div>
									<div className="m-2"><i className="fa fa-check-circle mr-2" style={{ color: (this.state.setPassword && this.state.setPassword === this.state.setPasswordConfirmation) ? "green" : "red" }}></i>Passwords must match</div>
								</div>
							</div>
						}
					</div>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => this.props.handleClose()}>
						Close
					</Button>
					{
						(this.state.isVerified || this.props.type === "UPDATE_PASSWORD")
						&&
						<Button variant="primary" onClick={this.resetPassword} disabled={!(this.state.passwordRequirements.length && this.state.passwordRequirements.camelCase && this.state.passwordRequirements.containNumber && this.state.passwordRequirements.specialCharacterInclude && !this.state.passwordRequirements.specialCharacterExclude && !this.state.passwordRequirements.containSpace && this.state.setPassword === this.state.setPasswordConfirmation && this.state.uniqueUsername)}>
							{this.props.type === "UPDATE_PASSWORD" ? "Update" : "Set and login"}
						</Button>
					}
				</Modal.Footer>
			</ModalStyled>
		);
	}
}
export default ResetPasswordModal;
