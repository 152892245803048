import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { getUserRole } from "../../../../services/common/util";
import { Checkbox } from "@mui/material";
import moment from 'moment';

class IncorrectInfoPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHistoryPopup: false,
            incorrect_history: []
        }
    }

    render() {
        return (
            <ModalStyled
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.props.onHide}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{getUserRole() && ["SuperAdmin", "Admin"].includes(getUserRole()) ? "Please select incorrect items and add notes" : "Incorrect Items Info"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {
                                this.props.incorrectItems.map((incorrectItem, index) => {
                                    if (getUserRole() && ["SuperAdmin", "Admin"].includes(getUserRole()) && this.props.type === 'patientPage') {
                                        return <div className="row" key={index}>
                                            <div className="col-12 form-group">
                                                <Checkbox
                                                    checked={incorrectItem.is_incorrect}
                                                    name="is_incorrect"
                                                    onClick={(e) => this.props.handleChange(e, index)}
                                                /> {incorrectItem.type === 'Patient' ? ("Patient - " + incorrectItem.data.first_name + " " + incorrectItem.data.last_name) : ("Insurance - " + incorrectItem.data.insurance_provider + (incorrectItem.data.relation_to_insured ? (" (" + incorrectItem.data.relation_to_insured + ")") : ""))} {incorrectItem.incorrect_history && incorrectItem.incorrect_history.length ? <i className="ml-2 fas fa-history cursorPointer p-1" onClick={() => this.setState({ showHistoryPopup: true, incorrect_history: incorrectItem.incorrect_history })}></i> : ''}
                                            </div>
                                            {
                                                incorrectItem.is_incorrect
                                                &&
                                                <div className="col-12 form-group">
                                                    <textarea
                                                        className="form-control"
                                                        value={incorrectItem.incorrect_note}
                                                        name="incorrect_note"
                                                        onChange={(e) => this.props.handleChange(e, index)}
                                                        placeholder="Note"
                                                    >
                                                    </textarea>
                                                    {
                                                        this.props.errors.includes(index)
                                                        &&
                                                        <div className="inline-errormsg">
                                                            <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                                &nbsp;Note should be provided if it is selected as incorrect item.
                                                            </i>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    } else if (getUserRole()) {
                                        return <div className="row" key={index}>
                                            <div className="col-12 form-group">
                                                <div>{incorrectItem.type === 'Patient' ? ("Patient - " + incorrectItem.data.first_name + " " + incorrectItem.data.last_name) : ("Insurance - " + incorrectItem.data.insurance_provider + (incorrectItem.data.relation_to_insured ? (" (" + incorrectItem.data.relation_to_insured + ")") : ""))} {incorrectItem.incorrect_history && incorrectItem.incorrect_history.length ? <i className="ml-2 fas fa-history cursorPointer p-1" onClick={() => this.setState({ showHistoryPopup: true, incorrect_history: incorrectItem.incorrect_history })}></i> : ''}</div>
                                                <div><b>Note:</b> {incorrectItem.incorrect_note}</div>
                                            </div>
                                        </div>
                                    } else if (incorrectItem.is_incorrect) {
                                        return <div className="row" key={index}>
                                            <div className="col-12 form-group">
                                                <div>{incorrectItem.type === 'Patient' ? ("Patient - " + incorrectItem.data.first_name + " " + incorrectItem.data.last_name) : ("Insurance - " + incorrectItem.data.insurance_provider + (incorrectItem.data.relation_to_insured ? (" (" + incorrectItem.data.relation_to_insured + ")") : ""))}</div>
                                                <div><b>Note:</b> {incorrectItem.incorrect_note}</div>
                                            </div>
                                        </div>
                                    }
                                    return null;
                                })
                            }
                        </div>
                    </div>
                    {
                        this.props.type === 'patientPortal'
                        &&
                        <div className="pt-2">
                            The information is editable in the left sidebar, indicated by
                            <i
                                className="fas fa-exclamation-triangle"
                                style={{ padding: "0.8rem", color: 'orange' }}
                            >
                            </i>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.props.onHide}
                    >
                        Close
                    </Button>
                    {
                        getUserRole() && ["SuperAdmin", "Admin"].includes(getUserRole())
                        &&
                        <Button
                            variant="primary"
                            onClick={this.props.handleIncorrectItems}
                        >
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
                <ModalStyled
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showHistoryPopup}
                    onHide={() => this.setState({ showHistoryPopup: false })}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>History Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                this.state.incorrect_history && this.state.incorrect_history.length > 0
                                &&
                                this.state.incorrect_history.sort((a, b) => b.time - a.time).map((historyItem, index) => {
                                    return <div className="col-12 mb-2" key={index}>
                                        <div className="row">
                                            <div className="col-4">{moment(historyItem.time, 'YYYYMMDDHHmmss').format("MM/DD/YYYY hh:mm A")}</div>
                                            <div className="col-8">
                                                Status: <span style={{ color: historyItem.status.includes('INCORRECT') ? 'red' : 'green' }}>{historyItem.status === 'CORRECT' ? 'Corrected' : (historyItem.status === 'INIT_CORRECT' ? 'Was set as corrected (unknown time)' : 'Marked as incorrect')} {!historyItem.status.includes('INCORRECT') ? ('by ' + (historyItem.fixed_by === 'FACILITY' ? 'facility user' : 'patient')) : ''}</span>
                                                <br />
                                                Note: {historyItem.note}
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                            {
                                !(this.state.incorrect_history && this.state.incorrect_history.length > 0)
                                &&
                                <div className="col-12">
                                    No history
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => this.setState({ showHistoryPopup: false })}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
            </ModalStyled>
        );
    }
}
export default IncorrectInfoPopup;

