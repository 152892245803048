import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import BreadCrumb from "./breadcrumb";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import MaintenanceModal from "./maintenanceModal";
import {
    getAllMaintenanceEvents,
    deleteMaintenanceEvent
} from "../../../../services/clinicPortalServices/maintenanceEventService";
import { getAllMaintenanceSlots } from "../../../../services/clinicPortalServices/maintenanceSlotService";
import moment from "moment";
import Swal from "sweetalert2";
import {
    dateComparator,
    getUserDetails,
    getUserRole
} from "../../../../services/common/util";
import {
    FormLabel,
    TextField,
    InputAdornment,
    IconButton,
    Radio,
    FormControlLabel,
    RadioGroup,
    FormControl
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { auditEnums } from "../../../../services/common/constants";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import TextCellRenderer from "./textCellRenderer";

class ClinicMaintenanceGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 100,
                    maxWidth: 100,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Item",
                    minWidth: 100,
                    field: "maintenanceEvent_id.item_name",
                    resizable: true
                },
                {
                    headerName: "Serial",
                    minWidth: 100,
                    resizable: true,
                    field: "maintenanceEvent_id.serial_number"
                },
                {
                    headerName: "Location",
                    minWidth: 100,
                    resizable: true,
                    field: "maintenanceEvent_id.location"
                },
                {
                    headerName: "Description",
                    minWidth: 100,
                    resizable: true,
                    field: "maintenanceEvent_id.description",
                    cellRenderer: "textCellRenderer"
                },
                {
                    headerName: "Start Time",
                    minWidth: 100,
                    resizable: true,
                    field: "start_time",
                    valueGetter: function (params) {
                        return moment(
                            params.data.start_time,
                            "YYYYMMDDHHmm"
                        ).format("MM/DD/YYYY hh:mm A");
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Completed Time",
                    minWidth: 100,
                    resizable: true,
                    field: "completed_time",
                    valueGetter: function (params) {
                        if (params.data.completed_time) {
                            return moment(
                                params.data.completed_time,
                                "YYYYMMDDHHmm"
                            ).format("MM/DD/YYYY hh:mm A");
                        }
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Staff",
                    minWidth: 100,
                    resizable: true,
                    field: "staff"
                },
                {
                    headerName: "Note",
                    minWidth: 100,
                    resizable: true,
                    field: "slot_note",
                    cellRenderer: "textCellRenderer"
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer,
                textCellRenderer: TextCellRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                resizable: true
            },
            rowData: [],
            maintenanceSlots: [],
            maintenanceEvents: [],
            context: { componentParent: this },
            isCalendar:
                window.localStorage.getItem("isCalendar") === "n"
                    ? false
                    : true,
            showMaintenanceEventModal: false,
            maintenanceEventId: null,
            maintenanceSlotDetails: null,
            modalType: null,
            from_date: moment().startOf("day"),
            temp_from_date: moment().startOf("day"),
            to_date: moment().add(1, "weeks").endOf("day"),
            temp_to_date: moment().add(1, "weeks").endOf("day"),
            filter_option: "incompleted",
            date_range: "custom",
            pickerOpened: false
        };
        this.loadGridData();
        this.calendarRef = React.createRef();
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    loadGridData = () => {
        getAllMaintenanceEvents().then((res) => {
            this.setState({
                maintenanceEvents: res.data
            });
        });
        getAllMaintenanceSlots().then((res) => {
            this.setState(
                {
                    maintenanceSlots: res.data
                },
                () => {
                    if (this.state.isCalendar) {
                        this.calendarRef.current._calendarApi
                            .getEvents()
                            .map((event) => {
                                event.remove();
                                return null;
                            });
                        this.state.maintenanceSlots.map((item) => {
                            let backgroundColor = "#20c0f3";
                            if (
                                moment().format("YYYYMMDDHHmm") >=
                                item.start_time
                            ) {
                                if (item.completed_time) {
                                    backgroundColor = "#28c76f";
                                } else {
                                    backgroundColor = "#ee344e";
                                }
                            }
                            const slot = {
                                id:
                                    item.maintenanceEvent_id._id +
                                    "|||" +
                                    item._id,
                                title:
                                    item.maintenanceEvent_id.item_name +
                                    " - " +
                                    item.maintenanceEvent_id.serial_number,
                                start: new Date(
                                    moment(
                                        item.start_time,
                                        "YYYYMMDDHHmm"
                                    ).format("YYYY-MM-DDTHH:mm")
                                ).getTime(),
                                end: new Date(
                                    moment(
                                        item.start_time,
                                        "YYYYMMDDHHmm"
                                    ).format("YYYY-MM-DDTHH:mm")
                                ).getTime(),
                                backgroundColor: backgroundColor,
                                textColor: "white",
                                display: "block"
                            };
                            this.calendarRef.current._calendarApi.addEvent(
                                slot
                            );
                            return null;
                        });
                    }
                    this.updateGridData();
                }
            );
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    onChangeView = () => {
        this.setState({ isCalendar: !this.state.isCalendar }, () => {
            if (this.state.isCalendar) {
                window.localStorage.setItem("isCalendar", "y");
            } else {
                window.localStorage.setItem("isCalendar", "n");
            }
            this.loadGridData();
        });
    };

    addNewMaintenanceEvent = () => {
        this.setState({
            showMaintenanceEventModal: true,
            maintenanceEventDetails: null,
            modalType: "event"
        });
    };

    handleEditMaintenanceEvent = (item) => {
        this.setState({
            showMaintenanceEventModal: true,
            maintenanceEventDetails: item,
            maintenanceSlotDetails: null,
            modalType: "event"
        });
    };

    handleDeleteMaintenanceEvent = (item) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this maintenance event and slots of them!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMaintenanceEvent({ _id: item._id }).then((res) => {
                    this.loadGridData();
                });
            }
        });
    };

    handleEventClick = (clickInfo) => {
        let maintenanceEventId = clickInfo.event.id.split("|||")[0];
        let maintenanceSlotId = clickInfo.event.id.split("|||")[1];
        let maintenanceEventDetails = this.state.maintenanceEvents.find(
            (item) => item._id === maintenanceEventId
        );
        let maintenanceSlotDetails = this.state.maintenanceSlots.find(
            (item) => item._id === maintenanceSlotId
        );
        this.setState({
            showMaintenanceEventModal: true,
            maintenanceEventDetails: maintenanceEventDetails,
            maintenanceSlotDetails: maintenanceSlotDetails,
            modalType: "slot"
        });
    };

    handleFiltersChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.setDateRange();
            this.updateGridData();
        });
    };

    updateGridData = () => {
        let maintenanceSlots = this.state.maintenanceSlots;
        let rowData = [];
        rowData = maintenanceSlots.filter((item) => {
            let fromDate = moment(this.state.from_date).format("YYYYMMDDHHmm");
            let toDate = moment(this.state.to_date).format("YYYYMMDDHHmm");
            if (
                item.start_time > fromDate &&
                item.start_time < toDate &&
                ((item.completed_time &&
                    this.state.filter_option === "completed") ||
                    (!item.completed_time &&
                        this.state.filter_option === "incompleted") ||
                    this.state.filter_option === "all")
            ) {
                return true;
            }
            return false;
        });
        this.setState({
            rowData: rowData
        });
    };

    setDateRange = () => {
        let dateRange;
        if (
            moment(this.state.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(this.state.from_date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            ) {
                dateRange = "today";
            } else if (
                moment(this.state.from_date).format("YYYY-MM-DD") ===
                moment().startOf("week").format("YYYY-MM-DD")
            ) {
                dateRange = "week";
            } else if (
                moment(this.state.from_date).format("YYYY-MM-DD") ===
                moment().startOf("month").format("YYYY-MM-DD")
            ) {
                dateRange = "month";
            } else {
                dateRange = "custom";
            }
        } else {
            dateRange = "custom";
        }
        this.setState({ dateRange: dateRange });
    };

    updateDateRange = (dateRange) => {
        let from_date, to_date;
        if (this.state.dateRange !== dateRange) {
            switch (dateRange) {
                case "today":
                    to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    from_date = moment().format("YYYY-MM-DD") + "T00:00";
                    break;
                case "week":
                    to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                case "month":
                    to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    break;
                default:
                    break;
            }
            this.setState(
                {
                    from_date: from_date,
                    temp_from_date: from_date,
                    to_date: to_date,
                    temp_to_date: to_date,
                    dateRange: dateRange
                },
                () => {
                    if (dateRange !== "custom") {
                        this.updateGridData();
                    }
                }
            );
        }
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Maintenance_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Maintenances"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.MaintenanceGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.MaintenanceGridExported
        };
        createAudit(auditData);
    };

    render() {
        return (
            <div className="clinic-contain">
                <BreadCrumb
                    onChangeView={this.onChangeView}
                    isCalendar={this.state.isCalendar}
                    onBtnExport={this.onBtnExport}
                />
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3">
                            <div
                                className="card"
                                style={{ marginLeft: "10px", height: "100%" }}
                            >
                                <div className="card-header">
                                    <div
                                        className="card-title mb-0"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <h4 style={{ marginBottom: "0px" }}>
                                            Maintenance List
                                        </h4>
                                        {getUserRole() &&
                                            getUserRole() !== "LabTech" && (
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        this.addNewMaintenanceEvent()
                                                    }
                                                >
                                                    <i className="fas fa-plus"></i>{" "}
                                                    Add New
                                                </button>
                                            )}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="calendar-events" className="mb-3">
                                        {this.state.maintenanceEvents &&
                                            this.state.maintenanceEvents
                                                .filter(
                                                    (item) => !item.is_deleted
                                                )
                                                .map((event, index) => {
                                                    return (
                                                        <div
                                                            className="calendar-events py-1 px-1 d-flex align-items-center"
                                                            key={index}
                                                        >
                                                            {getUserRole() &&
                                                                getUserRole() !==
                                                                    "LabTech" && (
                                                                    <div className="d-flex">
                                                                        <button
                                                                            onClick={() =>
                                                                                this.handleEditMaintenanceEvent(
                                                                                    event
                                                                                )
                                                                            }
                                                                            className="edit-order-btn"
                                                                        >
                                                                            <i className="fas fa-pen mr-0"></i>
                                                                        </button>
                                                                        <button
                                                                            onClick={() =>
                                                                                this.handleDeleteMaintenanceEvent(
                                                                                    event
                                                                                )
                                                                            }
                                                                            className="edit-order-btn"
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            <div>
                                                                {
                                                                    event.item_name
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    event.serial_number
                                                                }{" "}
                                                                -{" "}
                                                                {event.location}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.state.isCalendar && (
                                                <FullCalendar
                                                    ref={this.calendarRef}
                                                    plugins={[
                                                        dayGridPlugin,
                                                        timeGridPlugin,
                                                        interactionPlugin
                                                    ]}
                                                    headerToolbar={{
                                                        left: "prev today next",
                                                        center: "title",
                                                        right: "dayGridMonth,dayGridWeek"
                                                    }}
                                                    initialView="dayGridMonth"
                                                    editable={false}
                                                    selectable={true}
                                                    dayMaxEvents={true}
                                                    weekends={true}
                                                    select={
                                                        this.handleDateSelect
                                                    }
                                                    eventClick={(clickInfo) =>
                                                        this.handleEventClick(
                                                            clickInfo
                                                        )
                                                    }
                                                    displayEventTime={false}
                                                />
                                            )}
                                            {!this.state.isCalendar && (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "calc(100vh - 210px)"
                                                    }}
                                                >
                                                    <ThemeContext.Consumer>
                                                        {({ themeName }) => (
                                                            <div
                                                                className="d-flex flex-column"
                                                                style={{
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <FormControl component="fieldset">
                                                                            <FormLabel component="legend">
                                                                                Filter
                                                                                Options
                                                                            </FormLabel>
                                                                            <RadioGroup
                                                                                aria-label="result"
                                                                                name="filter_option"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .filter_option
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    this.handleFiltersChange(
                                                                                        e
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    flexDirection:
                                                                                        "row",
                                                                                    justifyContent:
                                                                                        "center"
                                                                                }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    key={
                                                                                        "all"
                                                                                    }
                                                                                    value={
                                                                                        "all"
                                                                                    }
                                                                                    control={
                                                                                        <Radio />
                                                                                    }
                                                                                    label={
                                                                                        "All Tasks"
                                                                                    }
                                                                                />
                                                                                <FormControlLabel
                                                                                    key={
                                                                                        "completed"
                                                                                    }
                                                                                    value={
                                                                                        "completed"
                                                                                    }
                                                                                    control={
                                                                                        <Radio />
                                                                                    }
                                                                                    label={
                                                                                        "Completed Tasks"
                                                                                    }
                                                                                />
                                                                                <FormControlLabel
                                                                                    key={
                                                                                        "incompleted"
                                                                                    }
                                                                                    value={
                                                                                        "incompleted"
                                                                                    }
                                                                                    control={
                                                                                        <Radio />
                                                                                    }
                                                                                    label={
                                                                                        "Incompleted Tasks"
                                                                                    }
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <FormLabel
                                                                                    style={{
                                                                                        marginBottom:
                                                                                            "10px"
                                                                                    }}
                                                                                >
                                                                                    Date
                                                                                    Range
                                                                                </FormLabel>
                                                                            </div>
                                                                            <div className="dateRangeBar mb-3">
                                                                                <button
                                                                                    className={
                                                                                        "btn btn-info dateRangeBtn mx-1 mb-2" +
                                                                                        (this
                                                                                            .state
                                                                                            .date_range ===
                                                                                        "today"
                                                                                            ? " active"
                                                                                            : "")
                                                                                    }
                                                                                    onClick={() =>
                                                                                        this.updateDateRange(
                                                                                            "today"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Today
                                                                                </button>
                                                                                <button
                                                                                    className={
                                                                                        "btn btn-info dateRangeBtn mx-1 mb-2" +
                                                                                        (this
                                                                                            .state
                                                                                            .date_range ===
                                                                                        "week"
                                                                                            ? " active"
                                                                                            : "")
                                                                                    }
                                                                                    onClick={() =>
                                                                                        this.updateDateRange(
                                                                                            "week"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Week
                                                                                </button>
                                                                                <button
                                                                                    className={
                                                                                        "btn btn-info dateRangeBtn mx-1 mb-2" +
                                                                                        (this
                                                                                            .state
                                                                                            .date_range ===
                                                                                        "month"
                                                                                            ? " active"
                                                                                            : "")
                                                                                    }
                                                                                    onClick={() =>
                                                                                        this.updateDateRange(
                                                                                            "month"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Month
                                                                                </button>
                                                                                <button
                                                                                    className={
                                                                                        "btn btn-info dateRangeBtn ml-1 mb-2" +
                                                                                        (this
                                                                                            .state
                                                                                            .date_range ===
                                                                                        "custom"
                                                                                            ? " active"
                                                                                            : "")
                                                                                    }
                                                                                >
                                                                                    Custom
                                                                                </button>
                                                                            </div>
                                                                            <div className="dateRangeBar dateRangeBarPickers">
                                                                                <MobileDateTimePicker
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .pickerOpened
                                                                                            ? this
                                                                                                  .state
                                                                                                  .temp_from_date
                                                                                            : this
                                                                                                  .state
                                                                                                  .from_date
                                                                                    }
                                                                                    onChange={(
                                                                                        dateTime
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                temp_from_date:
                                                                                                    dateTime
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    onOpen={() =>
                                                                                        this.setState(
                                                                                            {
                                                                                                temp_from_date:
                                                                                                    this
                                                                                                        .state
                                                                                                        .from_date,
                                                                                                pickerOpened: true
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    onClose={() =>
                                                                                        this.setState(
                                                                                            {
                                                                                                pickerOpened: false
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    onAccept={(
                                                                                        dateTime
                                                                                    ) =>
                                                                                        this.handleFiltersChange(
                                                                                            {
                                                                                                target: {
                                                                                                    value: dateTime.$d,
                                                                                                    name: "from_date"
                                                                                                }
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    label="From Date"
                                                                                    renderInput={(
                                                                                        props
                                                                                    ) => (
                                                                                        <TextField
                                                                                            {...props}
                                                                                            className="ml-1 mr-2 mt-2"
                                                                                            InputProps={{
                                                                                                endAdornment:
                                                                                                    (
                                                                                                        <InputAdornment position="end">
                                                                                                            <IconButton edge="end">
                                                                                                                <EventIcon />
                                                                                                            </IconButton>
                                                                                                        </InputAdornment>
                                                                                                    )
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <MobileDateTimePicker
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .pickerOpened
                                                                                            ? this
                                                                                                  .state
                                                                                                  .temp_to_date
                                                                                            : this
                                                                                                  .state
                                                                                                  .to_date
                                                                                    }
                                                                                    onChange={(
                                                                                        dateTime
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                temp_to_date:
                                                                                                    dateTime
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    onOpen={() =>
                                                                                        this.setState(
                                                                                            {
                                                                                                temp_to_date:
                                                                                                    this
                                                                                                        .state
                                                                                                        .to_date,
                                                                                                pickerOpened: true
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    onClose={() =>
                                                                                        this.setState(
                                                                                            {
                                                                                                pickerOpened: false
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    onAccept={(
                                                                                        dateTime
                                                                                    ) =>
                                                                                        this.handleFiltersChange(
                                                                                            {
                                                                                                target: {
                                                                                                    value: dateTime.$d,
                                                                                                    name: "to_date"
                                                                                                }
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    label="To Date"
                                                                                    renderInput={(
                                                                                        props
                                                                                    ) => (
                                                                                        <TextField
                                                                                            {...props}
                                                                                            className="mt-2"
                                                                                            InputProps={{
                                                                                                endAdornment:
                                                                                                    (
                                                                                                        <InputAdornment position="end">
                                                                                                            <IconButton edge="end">
                                                                                                                <EventIcon />
                                                                                                            </IconButton>
                                                                                                        </InputAdornment>
                                                                                                    )
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    id="myGrid"
                                                                    style={{
                                                                        height: "100%",
                                                                        width: "100%",
                                                                        marginTop:
                                                                            "20px"
                                                                    }}
                                                                    className={
                                                                        themeName ===
                                                                        "Light"
                                                                            ? "ag-theme-alpine"
                                                                            : "ag-theme-alpine-dark"
                                                                    }
                                                                >
                                                                    <AgGridReact
                                                                        columnDefs={
                                                                            this
                                                                                .state
                                                                                .columnDefs
                                                                        }
                                                                        defaultColDef={
                                                                            this
                                                                                .state
                                                                                .defaultColDef
                                                                        }
                                                                        masterDetail={
                                                                            true
                                                                        }
                                                                        onGridReady={
                                                                            this
                                                                                .onGridReady
                                                                        }
                                                                        rowData={
                                                                            this
                                                                                .state
                                                                                .rowData
                                                                        }
                                                                        pagination={
                                                                            true
                                                                        }
                                                                        paginationAutoPageSize={
                                                                            true
                                                                        }
                                                                        components={
                                                                            this
                                                                                .state
                                                                                .components
                                                                        }
                                                                        context={
                                                                            this
                                                                                .state
                                                                                .context
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </ThemeContext.Consumer>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MaintenanceModal
                        context={this.state.context}
                        show={this.state.showMaintenanceEventModal}
                        handleClose={() =>
                            this.setState({ showMaintenanceEventModal: false })
                        }
                        maintenanceEventDetails={
                            this.state.maintenanceEventDetails
                        }
                        maintenanceSlotDetails={
                            this.state.maintenanceSlotDetails
                        }
                        type={this.state.modalType}
                    />
                </div>
            </div>
        );
    }
}

export default ClinicMaintenanceGrid;
