import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import { sendReportNewSample } from "../../../../services/clinicPortalServices/requisitionService";

export default class ActionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			facilityId: '',
			facilitySource: '',
			facilityDetails: null,
			reasonList: [
				'Sample Not Received',
				'Incorrect specimen collection container',
				'Insufficient specimen quantity',
				'Transported incorrectly',
				'Incorrect media',
				'Specimen stability compromised (i.e. age of specimen, temperature stored)',
				'Leaking specimens',
				'Other',
			],
			selectedReason: 'Sample Not Received',
			otherReason: '',
			reportReason: '',
			facilities: []
		}
	}

	handleShow = () => {
		this.setState({ show: true });
	}

	handleClose = () => {
		this.setState({ show: false });
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === 'facilityId') {
			const facility = this.state.facilities.filter(fac => fac._id === e.target.value)[0];
			this.setState({ facilitySource: facility.name, facilityDetails: facility })
		}
	}

	loadDataForModal = () => {
		fetchFacilitiesForOrders().then(res => {
			this.setState({ facilities: res.data })
			const facility = res.data.filter(fac => fac._id === this.props.data.facility_id._id)[0];
			this.setState({ facilitySource: facility.name, facilityDetails: facility })
			this.setState({ facilityId: this.props.data.facility_id._id })
		})
	};

	handleReport = () => {
		let reportReasontxt = this.state.selectedReason;
		this.setState({ reportReason: this.state.selectedReason });
		if (this.state.selectedReason === 'Other') {
			this.setState({ reportReason: this.state.otherReason });
			reportReasontxt = this.state.otherReason;
		}
		const reportSampleData = {
			_id: this.props.data._id,
			facilities: this.state.facilityDetails,
			patient: this.props.data.patient_id.first_name + " " + this.props.data.patient_id.last_name,
			patient_phone: this.props.data.patient_id.mobile,
			requisition: this.props.data.lab_order_id,
			sampleId: this.props.data.test_info.sample,
			reportReason: reportReasontxt
		}
		sendReportNewSample(reportSampleData).then((respond) => {
			this.setState({ show: false });
			this.props.context.componentParent.loadGridData();
		})
	};

	renderTooltipEmail = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Email For Requisition
		</Tooltip>
	);

	render() {
		let description = "";
		if (this.props.data.order_type === 'sub_bundle' && this.props.data.bundle_id && this.props.data.bundle_id.orderIds) {
			let somethingAccessioned = false;
			this.props.data.bundle_id.orderIds.map(subBundle => {
				if (subBundle.is_assigned_to_plate || (subBundle.results && subBundle.results.value)) {
					somethingAccessioned = true;
				}
				return null;
			})
			if (somethingAccessioned) {
				description = "Cancel and request a new sample, this action will cancel this individual test and the panel result will show it is cancelled as other tests have either already been accessioned or released."
			} else {
				description = "Cancel and request a new sample, this action will result in the whole panel being cancelled as nothing was accessioned for it yet."
			}
		}
		return (
			<div className="assign-actions text-center">
				{
					this.props.colDef.cellRenderer === "ActionComponent"
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 100, hide: 400 }}
						overlay={this.renderTooltipEmail}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fas fa-envelope"></i>
						</button>
					</OverlayTrigger>
				}
				{
					this.props.colDef.cellRenderer === "RejectActionRenderer"
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 100, hide: 400 }}
						overlay={this.renderTooltipEmail}
					>
						<button className="btn btn-primary" onClick={this.handleShow}>
							Reject
						</button>
					</OverlayTrigger>
				}

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					onEnter={this.loadDataForModal}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Cancel / Request Sample{this.props.data.order_type === 'bundle' ? " (Panel)" : (this.props.data.order_type === 'sub_bundle' ? " (Part of Panel)" : "")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12">
									{
										description
										&&
										<div className="form-group">
											<label>{description}</label>
										</div>
									}
									<div className="form-group">
										<label>Facility</label>
										<input
											type="text"
											className="form-control"
											name="reportReason"
											value={this.state.facilitySource}
											disabled
										/>
									</div>
									<div className="form-group">
										<label>Choose reason for reporting bad sample</label>
										<select
											name="selectedReason"
											type="text"
											value={this.state.selectedReason}
											className="form-control"
											onChange={this.handleChange}
										>
											{
												this.state.reasonList
												&&
												this.state.reasonList.map((fac) => {
													return (
														<option value={fac} key={fac}>
															{fac}
														</option>
													)
												})
											}
										</select>
									</div>
									{this.state.selectedReason === 'Other'
										&&
										<div className="form-group">
											<label>Please input other reason</label>
											<input
												type="text"
												className="form-control"
												name="otherReason"
												value={this.state.otherReason}
												onChange={this.handleChange}
											/>
										</div>
									}

								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={this.handleReport}>
							Email & Cancel
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		)
	}
}