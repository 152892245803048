import React, { Component } from "react";
import { BLUELOT_LOGO } from "../imgLogoes.jsx";
import $ from "jquery";
import { getUserDetails, getUserRole } from "../../../services/common/util";
import { Link } from "react-router-dom";

class HeaderWrapper extends Component {
	constructor(props) {
		super(props);
		let userName = '';
		if (getUserDetails()) {
			const userDetails = JSON.parse(getUserDetails());
			userName = userDetails.user_name;
		}
		this.state = {
			userName: userName
		};
	}

	componentDidMount() {
		if (this.props.role) {
			$(".main-nav a").on("click", function (e) {
				if ($(this).parent().hasClass("has-submenu")) {
					e.preventDefault();
				}
				if (window.innerWidth <= 991) {
					if (!$(this).hasClass("submenu")) {
						$("ul", $(this).parents("ul:first")).slideUp(350);
						$("a", $(this).parents("ul:first")).removeClass("submenu");
						$(this).next("ul").slideDown(350);
						$(this).addClass("submenu");
					} else if ($(this).hasClass("submenu")) {
						$(this).removeClass("submenu");
						$(this).next("ul").slideUp(350);
					}
				}
			});
		}
	}

	render() {
		return (
			<div className="main-menu-wrapper">
				<div className="menu-header">
					<Link to="/home" className="menu-logo">
						<img src={BLUELOT_LOGO} object-fit="cover" alt="" />
					</Link>
					<a
						href="#menu_close"
						id="menu_close"
						className="menu-close"
						onClick={() => this.props.onhandleCloseMenu()}
					>
						<i className="fas fa-times"></i>
					</a>
				</div>

				<ul className="main-nav">
					<>
						{
							getUserRole() && /^superadmin|^admin|^labtech/.test(getUserRole().toLowerCase())
							&&
							<li className="has-submenu login-link">
								<a href="#accessioning_menu" style={{ cursor: "pointer" }} className="top-nav-button">
									Accessioning Menu
								</a>
								<ul className="submenu">
									<li className="login-link">
										<Link to="/lims/accessioning">Accessioning</Link>
									</li>
									<li className="login-link">
										<Link to="/lims/exportPlate">Quant Plate Export</Link>
									</li>
									<li className="login-link">
										<Link to="/lims/importResults">Result Import</Link>
									</li>
								</ul>
							</li>
						}
						{
							getUserRole() && /^superadmin|^admin|^labtech/.test(getUserRole().toLowerCase())
							&&
							<li className="has-submenu login-link">
								<a href="#management" style={{ cursor: "pointer" }} className="top-nav-button">
									Management
								</a>
								<ul className="submenu">
									<li className="login-link">
										<Link to="/lims/plateTemplates">Quant Plate Templates</Link>
									</li>
									<li className="login-link">
										<Link to="/lims/plateArchive">Plate Map Archive</Link>
									</li>
								</ul>
							</li>
						}
						{
							getUserRole() && /^superadmin|^admin|^labtech/.test(getUserRole().toLowerCase())
							&&
							<li className="login-link">
								<Link style={{ cursor: "pointer" }} className="top-nav-button" to="/clinic/requestSampleCancel">
									Request & Cancel Samples
								</Link>
							</li>
						}
						{
							getUserRole() && /^superadmin|^admin|^labtech/.test(getUserRole().toLowerCase())
							&&
							<li className="login-link">
								<Link style={{ cursor: "pointer" }} className="top-nav-button" to="/clinic/orders">
									Back to Clinic
								</Link>
							</li>
						}
					</>
				</ul>
			</div >
		);
	}
}

export default HeaderWrapper;
