import React, { Component } from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Radio,
    RadioGroup,
    TextField,
    InputAdornment,
    IconButton
} from "@mui/material";
import OrderBreadcrumb from "./orderBreadcrumb";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import {
    Nav,
    Button,
    Popover,
    OverlayTrigger,
    ListGroup
} from "react-bootstrap";
// import TestResultFilter from "./testResultFilter";

class OrderSearchMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewName: "Default",
            from_date: this.props.from_date,
            to_date: this.props.to_date,
            pickerOpened: false
        };
    }

    onBtnExport = () => {
        this.props.onBtnExport();
    };

    render() {
        const { gridViews, activeGridView } = this.props;
        const popoverSaveView = (
            <Popover
                id="popover-positioned-bottom"
                title="Popover bottom"
                className="p-2"
            >
                <h4>Save view</h4>
                <p>You can save filters, page size, and column to this view</p>
                <div className="mb-2">
                    <Button
                        onClick={() => this.props.updateView(activeGridView)}
                        className="mr-2"
                        disabled={gridViews.length === 0 ? true : ""}
                    >
                        Save
                    </Button>
                    <Button onClick={() => this.props.openCreateView(false)}>
                        Save as new
                    </Button>
                    <br />
                </div>
            </Popover>
        );
        const popoverEditView = (
            <Popover id="popover-positioned-bottom" title="Popover bottom">
                <ListGroup>
                    <ListGroup.Item
                        action
                        onClick={() => this.props.renameActiveView()}
                    >
                        Rename
                    </ListGroup.Item>
                    <ListGroup.Item
                        action
                        onClick={() => this.props.deleteActiveView()}
                    >
                        Delete
                    </ListGroup.Item>
                </ListGroup>
            </Popover>
        );
        return (
            <div>
                <OrderBreadcrumb
                    onBtnExport={() => {
                        this.props.onBtnExport();
                    }}
                    onBtnExportZipForRequisitions={() => {
                        this.props.onBtnExportZipForRequisitions();
                    }}
                    onBtnExportZipForResults={() => {
                        this.props.onBtnExportZipForResults();
                    }}
                    onIsArchivedInformation={(event) => {
                        this.props.onIsArchivedInformation(event);
                    }}
                    isArchived={this.props.isArchived}
                />
                <div className="row">
                    <div className="grid-buttons absolute-buttons">
                        <div className="text-center">
                            <TextField
                                sx={{ width: "100px" }}
                                label="Page Size"
                                id="page-size"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="number"
                                size="small"
                                value={this.props.page_size}
                                onChange={this.props.onPageSizeChanged}
                                margin="none"
                            />
                            <Button
                                onClick={() => this.props.openCreateView(true)}
                                className="btn btn-primary ml-2"
                            >
                                + Add view
                            </Button>
                            <OverlayTrigger
                                trigger="focus"
                                placement="bottom"
                                overlay={popoverSaveView}
                            >
                                <Button
                                    className="btn btn-primary ml-2"
                                    disabled={
                                        activeGridView.name === "Default"
                                            ? true
                                            : ""
                                    }
                                >
                                    <i className="far fa-save"></i> Save view
                                </Button>
                            </OverlayTrigger>
                            <Button
                                onClick={() =>
                                    this.props.openAllGridViews(true)
                                }
                                className="btn btn-primary ml-2"
                                disabled={gridViews.length === 0 ? true : ""}
                            >
                                All views
                            </Button>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => this.props.resetState()}
                            >
                                {" "}
                                <i className="fa fa-repeat"></i> Default
                            </button>
                        </div>
                    </div>
                </div>
                {gridViews.length > 0 && (
                    <Nav
                        className="mt-3 mb-2 px-2 table-nav"
                        variant="tabs"
                        activeKey={activeGridView._id}
                        onSelect={(selectedKey) =>
                            this.props.handleTabChange(selectedKey)
                        }
                    >
                        {gridViews.map((view) => (
                            <Nav.Item key={view._id}>
                                <Nav.Link eventKey={view._id}>
                                    {view.name}
                                    {activeGridView._id === view._id &&
                                    view.name !== "Default" ? (
                                        <OverlayTrigger
                                            trigger="focus"
                                            placement="bottom"
                                            overlay={popoverEditView}
                                        >
                                            <Button
                                                variant="link"
                                                className="p-0 text"
                                            >
                                                <i className="fa fa-pen pl-2 text-primary"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    ) : (
                                        <div style={{ padding: "3px 0" }}></div>
                                    )}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                        <Nav.Item key="add_btn">
                            <Button
                                variant="link"
                                onClick={() => this.props.openCreateView(true)}
                            >
                                <i className="fa fa-plus text-primary"></i>{" "}
                            </Button>
                        </Nav.Item>
                    </Nav>
                )}

                <div className="row" style={{ padding: "12px" }}>
                    <div className="col-md-12">
                        <div className="row mr-3">
                            <div className="col-md-3 col-12 mb-3">
                                <FormControl
                                    variant="outlined"
                                    className="filter-select-bar"
                                >
                                    <InputLabel id="facility-label">
                                        Select Facility
                                    </InputLabel>
                                    {this.props.facilities &&
                                        this.props.facilities.length <= 1 && (
                                            <Select
                                                labelId="facility-label"
                                                id="facility-select"
                                                value={
                                                    this.props.facilityId &&
                                                    this.props.facilityId.length
                                                        ? this.props.facilityId
                                                        : ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleFiltersChange
                                                }
                                                name="facility_id"
                                            >
                                                {this.props.facilities.map(
                                                    (fac) => {
                                                        return (
                                                            <MenuItem
                                                                key={fac._id}
                                                                value={fac._id}
                                                            >
                                                                <span className="mr-2">
                                                                    {fac.name}
                                                                </span>
                                                                {fac.archived ===
                                                                    "archived" && (
                                                                    <b>
                                                                        {
                                                                            " ( Archived )"
                                                                        }
                                                                    </b>
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    {this.props.facilities &&
                                        this.props.facilities.length > 1 && (
                                            <Select
                                                labelId="facility-label"
                                                id="facility-select"
                                                value={this.props.facilityId}
                                                onChange={
                                                    this.props
                                                        .handleFiltersChange
                                                }
                                                label="Select Facility"
                                                name="facility_id"
                                                multiple
                                                renderValue={(selected) => {
                                                    if (
                                                        selected.length ===
                                                        this.props.facilities
                                                            .length
                                                    ) {
                                                        return "All Facilities";
                                                    } else {
                                                        let displayStrings = [];
                                                        this.props.facilities.map(
                                                            (fac) => {
                                                                if (
                                                                    selected.includes(
                                                                        fac._id
                                                                    )
                                                                ) {
                                                                    displayStrings.push(
                                                                        fac.name
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        );
                                                        return displayStrings.join(
                                                            ", "
                                                        );
                                                    }
                                                }}
                                            >
                                                <MenuItem
                                                    className={
                                                        this.props.facilityId &&
                                                        this.props.facilityId
                                                            .length ===
                                                            this.props
                                                                .facilities
                                                                .length
                                                            ? "Mui-selected"
                                                            : ""
                                                    }
                                                    key="all"
                                                    value="all"
                                                >
                                                    <Checkbox
                                                        checked={
                                                            this.props
                                                                .facilityId &&
                                                            this.props
                                                                .facilityId
                                                                .length ===
                                                                this.props
                                                                    .facilities
                                                                    .length
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    All Facilities
                                                </MenuItem>
                                                {this.props.facilities.map(
                                                    (fac) => {
                                                        return (
                                                            <MenuItem
                                                                key={fac._id}
                                                                value={fac._id}
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .props
                                                                            .facilityId &&
                                                                        this.props.facilityId.includes(
                                                                            fac._id
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    className={
                                                                        fac.type ===
                                                                        "facility"
                                                                            ? "ml-5"
                                                                            : ""
                                                                    }
                                                                />
                                                                <span className="mr-2">
                                                                    {fac.name}
                                                                </span>
                                                                {fac.archived ===
                                                                    "archived" && (
                                                                    <b>
                                                                        {
                                                                            " ( Archived )"
                                                                        }
                                                                    </b>
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        )}
                                </FormControl>
                                {/* <TestResultFilter
									tests={this.props.tests}
									resultValues={this.props.resultValues}
									context={this.props.context}
									updateResultValues={this.props.updateResultValues}
									updateBundleIds={this.props.updateBundleIds}
									bundleIds={this.props.bundleIds}
									type="orderGrid"
								/> */}
                                <FormControl
                                    variant="outlined"
                                    className="filter-select-bar"
                                >
                                    <InputLabel id="test-label">
                                        Select Test
                                    </InputLabel>
                                    <Select
                                        labelId="test-label"
                                        id="test-select"
                                        value={this.props.testIds}
                                        onChange={
                                            this.props.handleFiltersChange
                                        }
                                        label="Select Test"
                                        name="testIds"
                                        multiple
                                        renderValue={(selected) => {
                                            if (
                                                selected.length ===
                                                this.props.tests.length
                                            ) {
                                                return "All Tests";
                                            } else {
                                                let displayStrings = [];
                                                this.props.tests.map((test) => {
                                                    if (
                                                        selected.includes(
                                                            test._id
                                                        )
                                                    ) {
                                                        displayStrings.push(
                                                            test.name
                                                        );
                                                    }
                                                    return null;
                                                });
                                                return displayStrings.join(
                                                    ", "
                                                );
                                            }
                                        }}
                                    >
                                        <MenuItem
                                            className={
                                                this.props.testIds &&
                                                this.props.testIds.length ===
                                                    this.props.tests.length
                                                    ? "Mui-selected"
                                                    : ""
                                            }
                                            key="all"
                                            value="all"
                                        >
                                            <Checkbox
                                                checked={
                                                    this.props.testIds &&
                                                    this.props.testIds
                                                        .length ===
                                                        this.props.tests.length
                                                        ? true
                                                        : false
                                                }
                                            />
                                            All Tests
                                        </MenuItem>
                                        {this.props.tests.map((test) => {
                                            return (
                                                <MenuItem
                                                    key={test._id}
                                                    value={test._id}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            this.props
                                                                .testIds &&
                                                            this.props.testIds.includes(
                                                                test._id
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {test.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div
                                className="col-md-3 col-12"
                                style={{ maxWidth: "830px" }}
                            >
                                <TextField
                                    label="Current Grid Search"
                                    variant="outlined"
                                    className="filter-search-bar"
                                    id="reset-form"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type="string"
                                    margin="dense"
                                    onChange={this.props.onFilterTextChange}
                                />
                                <br />
                                <FormControl
                                    component="fieldset"
                                    style={{ marginTop: "8px" }}
                                >
                                    <FormLabel style={{ marginBottom: "0px" }}>
                                        Search By
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="filter_by"
                                        name="filter_by"
                                        value={this.props.filter_by}
                                        onChange={
                                            this.props.handleFiltersChange
                                        }
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <FormControlLabel
                                            className="mx-0"
                                            key="received_date"
                                            value="received_date"
                                            control={<Radio />}
                                            label="Received Date"
                                        />
                                        <FormControlLabel
                                            className="mx-0"
                                            key="released_date"
                                            value="released_date"
                                            control={<Radio />}
                                            label="Released Date"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div
                                className="col-md-4 col-12"
                                style={{ marginTop: "-15px" }}
                            >
                                <div
                                    className="row"
                                    style={{ paddingLeft: "10px" }}
                                >
                                    <div className="col-12">
                                        <FormLabel
                                            style={{ marginBottom: "10px" }}
                                        >
                                            Date Range
                                        </FormLabel>
                                    </div>
                                    <div className="dateRangeBar mb-3">
                                        <button
                                            className={
                                                "btn btn-info dateRangeBtn mx-1 mb-2" +
                                                (this.props.date_range ===
                                                "today"
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                this.props.updateDateRange(
                                                    "today"
                                                )
                                            }
                                        >
                                            Today
                                        </button>
                                        <button
                                            className={
                                                "btn btn-info dateRangeBtn mx-1 mb-2" +
                                                (this.props.date_range ===
                                                "week"
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                this.props.updateDateRange(
                                                    "week"
                                                )
                                            }
                                        >
                                            Week
                                        </button>
                                        <button
                                            className={
                                                "btn btn-info dateRangeBtn mx-1 mb-2" +
                                                (this.props.date_range ===
                                                "month"
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                this.props.updateDateRange(
                                                    "month"
                                                )
                                            }
                                        >
                                            Month
                                        </button>
                                        <button
                                            className={
                                                "btn btn-info dateRangeBtn ml-1 mb-2" +
                                                (this.props.date_range ===
                                                "custom"
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            Custom
                                        </button>
                                    </div>
                                    <div className="dateRangeBar dateRangeBarPickers">
                                        <MobileDateTimePicker
                                            value={
                                                this.state.pickerOpened
                                                    ? this.state.from_date
                                                    : this.props.from_date
                                            }
                                            onChange={(dateTime) =>
                                                this.setState({
                                                    from_date: dateTime
                                                })
                                            }
                                            onOpen={() =>
                                                this.setState({
                                                    from_date:
                                                        this.props.from_date,
                                                    pickerOpened: true
                                                })
                                            }
                                            onClose={() =>
                                                this.setState({
                                                    pickerOpened: false
                                                })
                                            }
                                            onAccept={(dateTime) =>
                                                this.props.handleDateFiltersChange(
                                                    dateTime.$d,
                                                    "from_date"
                                                )
                                            }
                                            label="From Date"
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    className="ml-1 mr-2 mt-2"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <EventIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                        <MobileDateTimePicker
                                            value={
                                                this.state.pickerOpened
                                                    ? this.state.to_date
                                                    : this.props.to_date
                                            }
                                            onChange={(dateTime) =>
                                                this.setState({
                                                    to_date: dateTime
                                                })
                                            }
                                            onOpen={() =>
                                                this.setState({
                                                    to_date: this.props.to_date,
                                                    pickerOpened: true
                                                })
                                            }
                                            onClose={() =>
                                                this.setState({
                                                    pickerOpened: false
                                                })
                                            }
                                            onAccept={(dateTime) =>
                                                this.props.handleDateFiltersChange(
                                                    dateTime.$d,
                                                    "to_date"
                                                )
                                            }
                                            label="To Date"
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    className="mt-2"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <EventIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-md-2 col-12 text-right"
                                style={{
                                    marginTop: "5px",
                                    marginLeft: "auto",
                                    marginRight: "0px",
                                    padding: "0px"
                                }}
                            >
                                <span>
                                    <button
                                        className="btn btn-primary button-info-grid"
                                        onClick={() => this.props.clearFilter()}
                                    >
                                        <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                        ></i>{" "}
                                        Clear Filters
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderSearchMenu;
