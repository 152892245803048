import { templateIds } from "../common/constants";
import { apiInterfaceForJson } from "../common/apiManager";

export const updateInsurance = (insuranceInfo) => {
	let insuranceDetails = {
		_id: insuranceInfo.insuranceId,
		insured_first_name: insuranceInfo.insuredFirstName,
		insured_last_name: insuranceInfo.insuredLastName,
		insured_member_id: insuranceInfo.memberId,
		insured_group_number: insuranceInfo.groupNumber,
		insurance_provider: insuranceInfo.insuranceProv1.toLowerCase() === "other" ? insuranceInfo.insuranceProv2 : insuranceInfo.insuranceProv1,
		relation_to_insured: insuranceInfo.relation,
		patient: insuranceInfo.patientId,
		social_security_number: insuranceInfo.socialSecurityNumber,
		certify_no_insurance: insuranceInfo.certifyNoInsurance,
	}
	if (insuranceInfo.hasOwnProperty('isActive')) {
		insuranceDetails.is_active = insuranceInfo.isActive;
		insuranceDetails.reason_inactive = insuranceInfo.reasonInactive;
	} else {
		insuranceDetails.is_active = true;
	}
	if (insuranceInfo.images) {
		insuranceDetails.images = {
			insurance_front: insuranceInfo.images.frontImage,
			insurance_back: insuranceInfo.images.backImage
		}
	}
	if (insuranceInfo.hasOwnProperty('note')) {
		insuranceDetails.note = insuranceInfo.note
	}
	if (insuranceInfo.hasOwnProperty('is_incorrect')) {
		insuranceDetails.is_incorrect = insuranceInfo.is_incorrect
	}
	if (insuranceInfo.hasOwnProperty('incorrect_history')) {
		insuranceDetails.incorrect_history = insuranceInfo.incorrect_history
	}
	if (insuranceInfo.hasOwnProperty('incorrect_note')) {
		insuranceDetails.incorrect_note = insuranceInfo.incorrect_note
	}
	return apiInterfaceForJson("/patientinsurance/v1/", "PUT", insuranceDetails);
};

export const easyUpdateInsurance = (insuranceInfo) => {
	return apiInterfaceForJson("/patientinsurance/v1/", "PUT", insuranceInfo);
};

export const createInsurance = (insuranceInfo) => {
	let insuranceDetails = {
		insured_first_name: insuranceInfo.insuredFirstName,
		insured_last_name: insuranceInfo.insuredLastName,
		insured_member_id: insuranceInfo.memberId,
		insured_group_number: insuranceInfo.groupNumber,
		insurance_provider: insuranceInfo.insuranceProv1.toLowerCase() === "other" ? insuranceInfo.insuranceProv2 : insuranceInfo.insuranceProv1,
		relation_to_insured: insuranceInfo.relation,
		patient: insuranceInfo.patientId,
		social_security_number: insuranceInfo.socialSecurityNumber,
		certify_no_insurance: insuranceInfo.certifyNoInsurance,
		reason_inactive: insuranceInfo.reasonInactive
	}
	if (insuranceInfo.hasOwnProperty('isActive')) {
		insuranceDetails.is_active = insuranceInfo.isActive;
		insuranceDetails.reason_inactive = insuranceInfo.reasonInactive;
	} else {
		insuranceDetails.is_active = true;
	}
	if (insuranceInfo.images) {
		insuranceDetails.images = {
			insurance_front: insuranceInfo.images.frontImage,
			insurance_back: insuranceInfo.images.backImage
		}
	}
	if (insuranceInfo.hasOwnProperty('note')) {
		insuranceDetails.note = insuranceInfo.note
	}
	return apiInterfaceForJson("/patientinsurance/v1/", "POST", insuranceDetails);
};

export const mergeInsuranceData = (mergeDetails) => {
	return apiInterfaceForJson("/patientinsurance/v1/merge", "POST", mergeDetails);
}

export const getInsuranceDataById = (insuranceId) => {
	return apiInterfaceForJson("/patientInsurance/v1/" + insuranceId, "GET");
};

export const deleteInsurance = (insuranceId) => {
	return apiInterfaceForJson("/patientinsurance/v1/delete", "POST", { insuranceId: insuranceId });
}

export const fetchPatientMasterDataForInsurance = (facilityId) => {
	const data = {
		facility_id: facilityId,
		isForInsurance: true
	}
	return apiInterfaceForJson("/patient/v1/search", "POST", data, window.apiCallController.signal);
};

export const fetchOrdersByInsuranceId = (insuranceId) => {
	return apiInterfaceForJson("/order/v1/search", "POST", { patient_insurance_id: insuranceId, order_type: { $ne: "sub_bundle" } });
}

export const importSelfPayDocument = (rows) => {
	return apiInterfaceForJson("/order/v1/importSelfPayDocument", "POST", { rows: rows });
}

export const sendEmailNotificationToPatientForSelfPayment = (email, facilityName, patientName, amount, testName) => {
	// Send email with template
	let bodyTemplate = {
		subject: "Benchlab, LLC. Covid19 Test (Payment Required)",
		emailTxt: "Hi " + patientName + ", <br/><br/>You have an outstanding balance for a " + testName + " test that you need to reconcile on Benchlab, LLC..<br/><br/>You had your sample taken at " + facilityName + ", the amount you owe is $" + amount + ".<br/><br/>Please visit https://benchlabit.com/patientportal to view and pay your outstanding balance. As a reminder your payment is due 5 days after this message.<br/><br/>If you have any questions please use the support link on the specific order in the portal.<br/><br/>Thanks,<br/>The Benchlab, LLC. Team"
	}
	const emailDataTemplate = {
		toEmail: email,
		subject: "Benchlab, LLC. Covid19 Test (Payment Required)",
		body: bodyTemplate,
		templateId: templateIds.htmlEmailWithLogo
	}
	return apiInterfaceForJson("/notifications/v1/emailTemplate", "POST", emailDataTemplate);
}

export const sendSMSNotificationToPatientForSelfPayment = (mobile, facilityName, patientName, amount, testName) => {
	let messageData = {};
	messageData.toMobile = mobile;
	messageData.body = "Hi " + patientName + ", \n\nYou have an outstanding balance for a " + testName + " test that you need to reconcile on Benchlab, LLC..\n\nYou had your sample taken at " + facilityName + ", the amount you owe is $" + amount + ".\n\nPlease visit https://benchlabit.com/patientportal to view and pay your outstanding balance. As a reminder your payment is due 5 days after this message.\n\nIf you have any questions please use the support link on the specific order in the portal.\n\nThanks,\nThe Benchlab, LLC. Team";
	return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
}