import React, { Component } from "react";
import { resetPassowrd } from "../../../services/clinicPortalServices/loginService";
import { BLUELOT_LOGO } from "../../common/imgLogoes";

class UserPortalResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            password: "",
            confirmPassword: "",
            isError: false,
            isSuccess: false,
            isEqual: false
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            isEqual: false,
            loading: false
        });
    };

    handleLogin = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ isEqual: true, loading: false });
            return;
        }
        const userId = this.props.match.params.userId;
        const forgetPasswordkey = this.props.match.params.forgetPasswordKey;
        resetPassowrd(userId, forgetPasswordkey, this.state.password).then(
            (res) => {
                this.setState({
                    loading: false,
                    isSuccess: true
                });
                this.props.history.push("/clinic");
            }
        );
    };

    render() {
        return (
            <div className="content login-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-12 col-lg-6 my-4 d-flex justify-content-center">
                                        <img src={BLUELOT_LOGO} alt="login" />
                                    </div>
                                    <div className="col-md-12 col-lg-6 login-right">
                                        <form onSubmit={this.handleLogin}>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    Create a new Password{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    Confirm Password Again{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="confirmPassword"
                                                    value={
                                                        this.state
                                                            .confirmPassword
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                            {this.state.isEqual && (
                                                <div style={{ color: "red" }}>
                                                    Password Does Not Matched
                                                </div>
                                            )}
                                            <button
                                                className="btn btn-primary btn-block btn-lg login-btn"
                                                type="submit"
                                                disabled={this.state.loading}
                                            >
                                                {this.state.loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{
                                                            marginRight: "5px"
                                                        }}
                                                    ></i>
                                                )}
                                                {this.state.loading && (
                                                    <span>
                                                        Reseting Password ...
                                                    </span>
                                                )}
                                                {!this.state.loading &&
                                                    !this.state.isSuccess && (
                                                        <span>
                                                            Reset Password
                                                        </span>
                                                    )}
                                                {this.state.isSuccess &&
                                                    !this.state.loading && (
                                                        <span>
                                                            Password Reset
                                                            Successfully
                                                        </span>
                                                    )}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserPortalResetPassword;
