import { apiInterfaceForJson } from "../common/apiManager";

export const fetchAnalytes = () => {
    return apiInterfaceForJson("/analyte/v1/", "GET");
};

export const getAnalyteDataById = (analyteId) => {
    return apiInterfaceForJson("/analyte/v1/" + analyteId, "GET");
};

export const createAnalyte = (analyteDetails) => {
    return apiInterfaceForJson("/analyte/v1/", "POST", analyteDetails);
};

export const updateAnalyte = (analyteDetails) => {
    return apiInterfaceForJson("/analyte/v1/", "PUT", analyteDetails);
};

export const deleteAnalyte = (analyteDetails) => {
    return apiInterfaceForJson("/analyte/v1/delete", "POST", analyteDetails);
};
