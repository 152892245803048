import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { faxTypes, states } from "../../../../services/common/optionsData";
import {
    phoneNumberFormatter,
    getUserRole
} from "../../../../services/common/util";
import {
    createFacility,
    updateFacility,
    getFacilityDataById
} from "../../../../services/clinicPortalServices/facilityServices";
import { getCompanyData } from "../../../../services/clinicPortalServices/companyServices";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import {
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Select,
    InputLabel
} from "@mui/material";

export default class FacilityDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            id: "",
            name: "",
            code: "",
            contactName: "",
            contactMobile: "",
            phoneNum: "",
            contactEmail: "",
            insuranceContactEmail: "",
            faxNum: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            emailNotification: false,
            environmentalMonitoring: false,
            faxType: "",
            isActive: "",
            company_id: null,
            facilityId:
                this.props && this.props.facilityId
                    ? this.props.facilityId
                    : "",
            errors: [],
            companies: [],
            firstInconclusiveRelease: false,
            invoiceProcessing: false,
            testIds: [],
            tests: []
        };
    }

    componentDidMount() {
        if (this.state.facilityId !== "") {
            this.loadFacilityDetails();
        }

        getCompanyData()
            .then((response) => {
                this.setState({ companies: response.data });
            })
            .catch((error) => {
                console.log(error);
            });

        getAllTests({ type: "all" }).then((res) => {
            this.setState({ tests: res.data });
        });
    }

    loadFacilityDetails = () => {
        getFacilityDataById(this.state.facilityId).then((response) => {
            let facilityDetails = response.data[0];
            this.setState({
                id: facilityDetails ? facilityDetails._id : "",
                name: facilityDetails ? facilityDetails.name : "",
                code: facilityDetails ? facilityDetails.code : "",
                contactName: facilityDetails
                    ? facilityDetails.contact_name
                    : "",
                contactMobile: facilityDetails
                    ? facilityDetails.contact_mobile
                    : "",
                phoneNum: facilityDetails ? facilityDetails.phone_no : "",
                contactEmail: facilityDetails
                    ? facilityDetails.contact_email
                    : "",
                insuranceContactEmail: facilityDetails
                    ? facilityDetails.insurance_contact_email
                    : "",
                faxNum: facilityDetails ? facilityDetails.fax_no : "",
                address1:
                    facilityDetails && facilityDetails.address
                        ? facilityDetails.address.address1
                        : "",
                address2:
                    facilityDetails && facilityDetails.address
                        ? facilityDetails.address.address2
                        : "",
                city:
                    facilityDetails && facilityDetails.address
                        ? facilityDetails.address.city
                        : "",
                state:
                    facilityDetails && facilityDetails.address
                        ? facilityDetails.address.state
                        : "",
                zip:
                    facilityDetails && facilityDetails.address
                        ? facilityDetails.address.zip
                        : "",
                country:
                    facilityDetails && facilityDetails.address
                        ? facilityDetails.address.country
                        : "",
                emailNotification: facilityDetails
                    ? facilityDetails.email_notifications_enabled
                    : "",
                environmentalMonitoring: facilityDetails
                    ? facilityDetails.environmental_monitoring_enabled
                    : "",
                faxType: facilityDetails ? facilityDetails.fax_type : "",
                isActive: facilityDetails ? facilityDetails.isActive : "",
                company_id:
                    facilityDetails &&
                    facilityDetails.company_id &&
                    facilityDetails.company_id._id
                        ? facilityDetails.company_id._id
                        : "",
                firstInconclusiveRelease:
                    facilityDetails &&
                    facilityDetails.first_inconclusive_release
                        ? facilityDetails.first_inconclusive_release
                        : false,
                invoiceProcessing:
                    facilityDetails && facilityDetails.invoice_processing
                        ? facilityDetails.invoice_processing
                        : false,
                testIds:
                    facilityDetails &&
                    facilityDetails.testIds &&
                    facilityDetails.testIds.length
                        ? facilityDetails.testIds
                        : []
            });
        });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    handleChange = (e) => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (name === "phoneNum") {
            this.setState({
                phoneNum: phoneNumberFormatter(value)
            });
        } else if (name === "contactMobile") {
            this.setState({
                contactMobile: phoneNumberFormatter(value)
            });
        } else if (name === "firstInconclusiveRelease") {
            this.setState({
                [name]: value === "true" ? true : false
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    };

    toggleState = (type) => {
        if (type === "environmentalMonitoring") {
            this.setState({
                environmentalMonitoring: !this.state.environmentalMonitoring
            });
        } else if (type === "emailNotification") {
            this.setState({ emailNotification: !this.state.emailNotification });
        } else if (type === "invoiceProcessing") {
            this.setState({ invoiceProcessing: !this.state.invoiceProcessing });
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    updateAndCreateFacility = (e) => {
        e.preventDefault();
        let errors = [];

        if (!this.state.name) {
            errors.push("name");
        }
        if (!this.state.code) {
            errors.push("code");
        }
        if (!this.state.address1) {
            errors.push("address1");
        }
        if (!this.state.city) {
            errors.push("city");
        }
        if (!this.state.state) {
            errors.push("state");
        }
        if (!this.state.zip) {
            errors.push("zip");
        }
        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let facilityInfo = {
            id: this.state.id,
            name: this.state.name,
            code: this.state.code,
            contactName: this.state.contactName,
            contactMobile: this.state.contactMobile,
            phoneNum: this.state.phoneNum,
            contactEmail: this.state.contactEmail,
            insuranceContactEmail: this.state.insuranceContactEmail,
            faxNum: this.state.faxNum,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            country: this.state.country,
            emailNotification: this.state.emailNotification,
            environmentalMonitoring: this.state.environmentalMonitoring,
            faxType: this.state.faxType,
            isActive: this.state.isActive,
            company_id: this.state.company_id,
            first_inconclusive_release: this.state.firstInconclusiveRelease,
            invoice_processing: this.state.invoiceProcessing,
            testIds: this.state.testIds
        };
        if (this.state.facilityId !== "") {
            updateFacility(facilityInfo).then((response) => {
                this.props.context.componentParent.loadGridData();
                this.props.handleClose();
            });
        } else {
            createFacility(facilityInfo).then((response) => {
                this.props.context.componentParent.loadGridData();
                this.props.handleClose();
            });
        }
    };

    render() {
        return (
            <div>
                <form>
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="row from-row">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>
                                            Name{" "}
                                            <span className="text-danger">
                                                {" "}
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            required
                                            disabled={/customerserviceuser|^admin/.test(
                                                getUserRole().toLowerCase()
                                            )}
                                            className={
                                                this.hasError("name")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                        />
                                        <div
                                            className={
                                                this.hasError("name")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>
                                            Code{" "}
                                            <span className="text-danger">
                                                {" "}
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="code"
                                            value={this.state.code}
                                            onChange={this.handleChange}
                                            required
                                            disabled={/customerserviceuser|^admin/.test(
                                                getUserRole().toLowerCase()
                                            )}
                                            className={
                                                this.hasError("code")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                        />
                                        <div
                                            className={
                                                this.hasError("code")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Company</label>
                                        <select
                                            name="company_id"
                                            value={this.state.company_id}
                                            onChange={this.handleChange}
                                            disabled={/customerserviceuser|^admin/.test(
                                                getUserRole().toLowerCase()
                                            )}
                                            className="form-control select "
                                        >
                                            <option
                                                key="0"
                                                value="null"
                                            ></option>
                                            {this.state.companies.map(
                                                (company) => {
                                                    return (
                                                        <option
                                                            key={company._id}
                                                            value={company._id}
                                                        >
                                                            {company.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label>Contact Name</label>
                                        <input
                                            type="text"
                                            name="contactName"
                                            value={this.state.contactName}
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label>Contact Email</label>
                                        <input
                                            type="email"
                                            name="contactEmail"
                                            value={this.state.contactEmail}
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label>Insurance Contact Email</label>
                                        <input
                                            type="email"
                                            name="insuranceContactEmail"
                                            value={
                                                this.state.insuranceContactEmail
                                            }
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label>Contact Mobile</label>
                                        <input
                                            type="tel"
                                            name="contactMobile"
                                            value={this.state.contactMobile}
                                            onChange={this.handleChange}
                                            placeholder="(XXX) XXX-XXXX"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Fax Type</label>
                                        <select
                                            name="faxType"
                                            value={this.state.faxType}
                                            onChange={this.handleChange}
                                            className="form-control select "
                                        >
                                            {faxTypes.map((fax) => {
                                                return (
                                                    <option
                                                        key={fax.value}
                                                        value={fax.value}
                                                    >
                                                        {fax.value}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Fax #</label>
                                        <input
                                            type="text"
                                            name="faxNum"
                                            value={this.state.faxNum}
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input
                                            type="tel"
                                            name="phoneNum"
                                            value={this.state.phoneNum}
                                            onChange={this.handleChange}
                                            placeholder="(XXX) XXX-XXXX"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>
                                            Address1{" "}
                                            <span className="text-danger">
                                                {" "}
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="address1"
                                            value={this.state.address1}
                                            onChange={this.handleChange}
                                            className={
                                                this.hasError("address1")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                        />
                                        <div
                                            className={
                                                this.hasError("address1")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Address2</label>
                                        <input
                                            type="text"
                                            name="address2"
                                            value={this.state.address2}
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>
                                            City{" "}
                                            <span className="text-danger">
                                                {" "}
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="city"
                                            value={this.state.city}
                                            onChange={this.handleChange}
                                            className={
                                                this.hasError("city")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                        />
                                        <div
                                            className={
                                                this.hasError("city")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>
                                            State{" "}
                                            <span className="text-danger">
                                                {" "}
                                                *
                                            </span>{" "}
                                        </label>
                                        <select
                                            name="state"
                                            value={this.state.state}
                                            onChange={this.handleChange}
                                            className={
                                                this.hasError("city")
                                                    ? "form-control is-invalid select "
                                                    : "form-control "
                                            }
                                        >
                                            {states.map((state) => {
                                                return (
                                                    <option
                                                        key={state.value}
                                                        value={state.value}
                                                    >
                                                        {state.state}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <div
                                            className={
                                                this.hasError("state")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>
                                            Zip Code{" "}
                                            <span className="text-danger">
                                                {" "}
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="zip"
                                            value={this.state.zip}
                                            onChange={this.handleChange}
                                            className={
                                                this.hasError("zip")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                        />
                                        <div
                                            className={
                                                this.hasError("zip")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="form-group">
                                        <FormControl
                                            variant="outlined"
                                            style={{
                                                width: "100%",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <InputLabel id="testIds-label">
                                                Test Availability
                                            </InputLabel>
                                            <Select
                                                labelId="testIds-label"
                                                id="testIds-select"
                                                value={this.state.testIds}
                                                onChange={this.handleChange}
                                                label="Test Availability"
                                                className={
                                                    this.hasError("testIds")
                                                        ? "select is-invalid"
                                                        : ""
                                                }
                                                name="testIds"
                                                disabled={/customerserviceuser/.test(
                                                    getUserRole().toLowerCase()
                                                )}
                                                multiple
                                                renderValue={(selected) => {
                                                    let displayStrings = [];
                                                    this.state.tests.map(
                                                        (test) => {
                                                            if (
                                                                selected.includes(
                                                                    test._id
                                                                )
                                                            ) {
                                                                displayStrings.push(
                                                                    test.name
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    );
                                                    return displayStrings.join(
                                                        ", "
                                                    );
                                                }}
                                            >
                                                {this.state.tests.map(
                                                    (test, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={test._id}
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        this.state.testIds.includes(
                                                                            test._id
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {test.name}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                style={{ marginBottom: "0px" }}
                                            >
                                                Inconclusive / Invalid Release
                                                Handling
                                            </FormLabel>
                                            <RadioGroup
                                                name="firstInconclusiveRelease"
                                                value={
                                                    this.state
                                                        .firstInconclusiveRelease
                                                }
                                                onChange={this.handleChange}
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <FormControlLabel
                                                    key={true}
                                                    value={true}
                                                    control={<Radio />}
                                                    label="First Release"
                                                />
                                                <FormControlLabel
                                                    key={false}
                                                    value={false}
                                                    control={<Radio />}
                                                    label="Second Release"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <MenuItem
                                            onClick={() =>
                                                this.toggleState(
                                                    "invoiceProcessing"
                                                )
                                            }
                                            disabled={/customerserviceuser/.test(
                                                getUserRole().toLowerCase()
                                            )}
                                        >
                                            <Checkbox
                                                checked={
                                                    this.state.invoiceProcessing
                                                }
                                            />
                                            <div
                                                style={{
                                                    whiteSpace: "break-spaces"
                                                }}
                                            >
                                                Treat orders as Bulk Invoicing
                                            </div>
                                        </MenuItem>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <MenuItem
                                            onClick={() =>
                                                this.toggleState(
                                                    "emailNotification"
                                                )
                                            }
                                            disabled={/customerserviceuser/.test(
                                                getUserRole().toLowerCase()
                                            )}
                                        >
                                            <Checkbox
                                                checked={
                                                    this.state.emailNotification
                                                }
                                            />
                                            <div
                                                style={{
                                                    whiteSpace: "break-spaces"
                                                }}
                                            >
                                                Email Notification
                                            </div>
                                        </MenuItem>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <MenuItem
                                            onClick={() =>
                                                this.toggleState(
                                                    "environmentalMonitoring"
                                                )
                                            }
                                            disabled={/customerserviceuser/.test(
                                                getUserRole().toLowerCase()
                                            )}
                                        >
                                            <Checkbox
                                                checked={
                                                    this.state
                                                        .environmentalMonitoring
                                                }
                                            />
                                            <div
                                                style={{
                                                    whiteSpace: "break-spaces"
                                                }}
                                            >
                                                Environmental Monitoring
                                            </div>
                                        </MenuItem>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 modal-button-col">
                            <Button
                                style={{ float: "right", marginLeft: "10px" }}
                                variant="primary"
                                onClick={this.updateAndCreateFacility}
                            >
                                Save Changes
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                variant="secondary"
                                onClick={this.props.handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
