import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { states } from "../../../../services/common/optionsData";
import {
    updateInsuranceProvider,
    createInsuranceProvider,
    getInsuranceProviderDataById
} from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import { phoneNumberFormatter } from "../../../../services/common/util";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import moment from "moment";

export default class InsuranceProviderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insuranceProviderId:
                this.props && this.props.insuranceProviderId
                    ? this.props.insuranceProviderId
                    : "",
            name: "",
            code: "",
            phone: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            zipPlus4: "",
            modified_date: moment(),
            errors: []
        };
    }

    componentDidMount() {
        if (this.state.insuranceProviderId) {
            this.loadInsuranceProviderDetails();
        }
    }

    loadInsuranceProviderDetails = () => {
        getInsuranceProviderDataById(this.state.insuranceProviderId).then(
            (response) => {
                let insuranceProviderDetails = response.data;
                this.setState({
                    name: insuranceProviderDetails.name
                        ? insuranceProviderDetails.name
                        : "",
                    code: insuranceProviderDetails.code
                        ? insuranceProviderDetails.code
                        : "",
                    phone: insuranceProviderDetails.phone
                        ? insuranceProviderDetails.phone
                        : "",
                    address1:
                        insuranceProviderDetails &&
                        insuranceProviderDetails.address &&
                        insuranceProviderDetails.address.address1
                            ? insuranceProviderDetails.address.address1
                            : "",
                    address2:
                        insuranceProviderDetails &&
                        insuranceProviderDetails.address &&
                        insuranceProviderDetails.address.address2
                            ? insuranceProviderDetails.address.address2
                            : "",
                    city:
                        insuranceProviderDetails &&
                        insuranceProviderDetails.address &&
                        insuranceProviderDetails.address.city
                            ? insuranceProviderDetails.address.city
                            : "",
                    state:
                        insuranceProviderDetails &&
                        insuranceProviderDetails.address &&
                        insuranceProviderDetails.address.state
                            ? insuranceProviderDetails.address.state
                            : "",
                    zip:
                        insuranceProviderDetails &&
                        insuranceProviderDetails.address &&
                        insuranceProviderDetails.address.zip
                            ? insuranceProviderDetails.address.zip
                            : "",
                    zipPlus4:
                        insuranceProviderDetails &&
                        insuranceProviderDetails.address &&
                        insuranceProviderDetails.address.zipPlus4
                            ? insuranceProviderDetails.address.zipPlus4
                            : "",
                    modified_date: insuranceProviderDetails.modified_date
                        ? moment(
                              insuranceProviderDetails.modified_date,
                              "YYYYMMDDHHmmss"
                          ).format("MM/DD/YYYY hh:mm A")
                        : ""
                });
            }
        );
    };

    handleChange = (e) => {
        if (e.target.name === "phone") {
            this.setState({
                phone: phoneNumberFormatter(e.target.value)
            });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    updateAndCreateInsuranceProvider = () => {
        let errors = [];

        if (this.state.name === "") {
            errors.push("name");
        }
        if (this.state.code === "") {
            errors.push("code");
        }

        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let insuranceProviderInfo = {
            _id: this.state.insuranceProviderId,
            name: this.state.name,
            code: this.state.code,
            phone: this.state.phone,
            address: {
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                zipPlus4: this.state.zipPlus4
            },
            modified_date: moment(this.state.modified_date).format(
                "YYYYMMDDHHmmss"
            )
        };

        if (this.state.insuranceProviderId !== "") {
            updateInsuranceProvider(insuranceProviderInfo).then((response) => {
                this.props.context.componentParent.loadGridData();
                this.props.handleClose();
            });
        } else {
            createInsuranceProvider(insuranceProviderInfo).then((response) => {
                this.props.context.componentParent.loadGridData();
                this.props.handleClose();
            });
        }
    };

    render() {
        return (
            <div>
                <form>
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Name <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("name")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("name")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>
                                    Code <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="code"
                                    value={this.state.code}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("code")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("code")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Phone</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    placeholder="(XXX) XXX-XXXX"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Address1</label>
                                <input
                                    type="text"
                                    name="address1"
                                    value={this.state.address1}
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Address2</label>
                                <input
                                    type="text"
                                    name="address2"
                                    value={this.state.address2}
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={this.state.city}
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label>State</label>
                                <select
                                    className="form-control select"
                                    name="state"
                                    value={this.state.state}
                                    onChange={this.handleChange}
                                >
                                    {states.map((state) => {
                                        return (
                                            <option
                                                key={state.value}
                                                value={state.value}
                                            >
                                                {state.state}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label>Zip Code</label>
                                <input
                                    type="text"
                                    name="zip"
                                    value={this.state.zip}
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label>Zip Plus 4</label>
                                <input
                                    type="text"
                                    name="zipPlus4"
                                    value={this.state.zipPlus4}
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <MobileDateTimePicker
                                    value={this.state.modified_date}
                                    onChange={(dateTime) =>
                                        this.handleChange({
                                            target: {
                                                value: dateTime.$d,
                                                name: "modified_date"
                                            }
                                        })
                                    }
                                    label="Modified Date"
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            className="ml-1 mr-2 mt-2"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <EventIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12"
                            style={{
                                paddingTop: "10px",
                                borderTop: "1px solid rgba(0,0,0,.2"
                            }}
                        >
                            <Button
                                style={{ float: "right", marginLeft: "10px" }}
                                variant="primary"
                                onClick={this.updateAndCreateInsuranceProvider}
                            >
                                Save Changes
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                variant="secondary"
                                onClick={this.props.handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
