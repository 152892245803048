import React, { Component } from "react";
import { FormControlLabel, Switch } from "@mui/material";

class PatientBreadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav
                                aria-label="breadcrumb"
                                className="page-breadcrumb"
                            >
                                <ol className="breadcrumb">
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Patient Menu
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        Possible Duplicate Patients
                                    </li>
                                </ol>
                            </nav>
                            <div style={{ display: "flex" }}>
                                <h2 className="breadcrumb-title">
                                    Possible Duplicate Patients
                                </h2>
                                <FormControlLabel
                                    value="includeArchived"
                                    control={
                                        <Switch
                                            checked={this.props.isArchived}
                                            onChange={
                                                this.props
                                                    .onIsArchivedInformation
                                            }
                                        />
                                    }
                                    label={
                                        this.props.isArchived
                                            ? "Hide Archives"
                                            : "Show Archives"
                                    }
                                    labelPlacement="end"
                                    className="m-0 show-archives-control breadcrumb-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PatientBreadcrumb;
