import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Autocomplete, Checkbox, TextField, IconButton, InputAdornment, Radio } from "@mui/material";
import { MobileDateTimePicker, MobileDatePicker } from "@mui/x-date-pickers";
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { createMaintenanceEvent, updateMaintenanceEvent } from "../../../../services/clinicPortalServices/maintenanceEventService";
import { deleteMaintenanceSlot, updateMaintenanceSlot } from "../../../../services/clinicPortalServices/maintenanceSlotService";
import Swal from "sweetalert2";
import { getUserDetails, removeDuplicates, getUserRole, getRepeatInfoString } from "../../../../services/common/util";

const prefixes = {
	1: "first",
	2: "second",
	3: "third",
	4: "fourth",
	5: "last"
}

export default class MaintenanceDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			maintenanceEventId: "",
			maintenanceSlotId: "",
			item_name: "",
			serial_number: "",
			location: "",
			staff_role: "LabTech",
			description: "",
			is_repeat: false,
			pickerOpened: false,
			start_time: moment(),
			temp_start_time: moment(),
			completed_time: null,
			temp_completed_time: null,
			errors: [],
			showCustomModal: false,
			repeat_interval: 1,
			repeat_unit: "week",
			repeat_week_info: [],
			repeat_month_info: "by_date",
			repeat_month_info_week_number: prefixes[Math.ceil(moment().date() / 7)],
			end_option: "never",
			end_option_count: 13,
			end_option_date: moment().add(3, "months"),
			specific_dates: [moment().format("D")],
			repeat_info: {},
			repeat_info_string: "",
			staff: "",
			slot_note: ""
		};
	}

	componentDidMount() {
		this.loadDetails();
	}

	loadDetails = () => {
		if (this.props.type === "slot") {
			let userInfo = JSON.parse(getUserDetails());
			this.setState({
				maintenanceEventId: this.props.maintenanceEventDetails._id,
				item_name: this.props.maintenanceEventDetails.item_name,
				serial_number: this.props.maintenanceEventDetails.serial_number,
				location: this.props.maintenanceEventDetails.location,
				staff_role: this.props.maintenanceEventDetails.staff_role,
				description: this.props.maintenanceEventDetails.description,
				start_time: moment(this.props.maintenanceSlotDetails.start_time, "YYYYMMDDHHmm"),
				temp_start_time: moment(this.props.maintenanceSlotDetails.start_time, "YYYYMMDDHHmm"),
				is_repeat: this.props.maintenanceEventDetails.is_repeat,
				repeat_info: this.props.maintenanceEventDetails.repeat_info,
				repeat_interval: this.props.maintenanceEventDetails.repeat_info.repeat_interval ? this.props.maintenanceEventDetails.repeat_info.repeat_interval : 1,
				repeat_unit: this.props.maintenanceEventDetails.repeat_info.repeat_unit ? this.props.maintenanceEventDetails.repeat_info.repeat_unit : "week",
				repeat_week_info: this.props.maintenanceEventDetails.repeat_info.repeat_week_info ? this.props.maintenanceEventDetails.repeat_info.repeat_week_info : [],
				repeat_month_info: this.props.maintenanceEventDetails.repeat_info.repeat_month_info ? this.props.maintenanceEventDetails.repeat_info.repeat_month_info : "by_date",
				repeat_month_info_week_number: this.props.maintenanceEventDetails.repeat_info.repeat_month_info_week_number ? this.props.maintenanceEventDetails.repeat_info.repeat_month_info_week_number : prefixes[Math.ceil(moment(this.props.maintenanceSlotDetails.start_time, "YYYYMMDDHHmm").date() / 7)],
				end_option: this.props.maintenanceEventDetails.repeat_info.end_option ? this.props.maintenanceEventDetails.repeat_info.end_option : "never",
				end_option_count: this.props.maintenanceEventDetails.repeat_info.end_option_count ? this.props.maintenanceEventDetails.repeat_info.end_option_count : 13,
				end_option_date: this.props.maintenanceEventDetails.repeat_info.end_option_date ? this.props.maintenanceEventDetails.repeat_info.end_option_date : moment(this.props.maintenanceEventDetails.start_time, "YYYYMMDDHHmm").add(3, "months"),
				specific_dates: this.props.maintenanceEventDetails.repeat_info.specific_dates,
				maintenanceSlotId: this.props.maintenanceSlotDetails._id,
				staff: this.props.maintenanceSlotDetails.staff ? this.props.maintenanceSlotDetails.staff : userInfo.first_name + " " + userInfo.last_name,
				slot_note: this.props.maintenanceSlotDetails.slot_note,
				completed_time: this.props.maintenanceSlotDetails.completed_time ? moment(this.props.maintenanceSlotDetails.completed_time, "YYYYMMDDHHmm") : moment(),
				temp_completed_time: this.props.maintenanceSlotDetails.completed_time ? moment(this.props.maintenanceSlotDetails.completed_time, "YYYYMMDDHHmm") : moment()
			}, () => {
				this.setState({ repeat_info_string: getRepeatInfoString(this.state) })
			})
		} else {
			if (this.props.maintenanceEventDetails && this.props.maintenanceEventDetails._id) {
				this.setState({
					maintenanceEventId: this.props.maintenanceEventDetails._id,
					item_name: this.props.maintenanceEventDetails.item_name,
					serial_number: this.props.maintenanceEventDetails.serial_number,
					location: this.props.maintenanceEventDetails.location,
					staff_role: this.props.maintenanceEventDetails.staff_role,
					description: this.props.maintenanceEventDetails.description,
					start_time: moment(this.props.maintenanceEventDetails.start_time, "YYYYMMDDHHmm"),
					temp_start_time: moment(this.props.maintenanceEventDetails.start_time, "YYYYMMDDHHmm"),
					is_repeat: this.props.maintenanceEventDetails.is_repeat,
					repeat_info: this.props.maintenanceEventDetails.repeat_info,
					repeat_interval: this.props.maintenanceEventDetails.repeat_info.repeat_interval ? this.props.maintenanceEventDetails.repeat_info.repeat_interval : 1,
					repeat_unit: this.props.maintenanceEventDetails.repeat_info.repeat_unit ? this.props.maintenanceEventDetails.repeat_info.repeat_unit : "week",
					repeat_week_info: this.props.maintenanceEventDetails.repeat_info.repeat_week_info ? this.props.maintenanceEventDetails.repeat_info.repeat_week_info : [],
					repeat_month_info: this.props.maintenanceEventDetails.repeat_info.repeat_month_info ? this.props.maintenanceEventDetails.repeat_info.repeat_month_info : "by_date",
					repeat_month_info_week_number: this.props.maintenanceEventDetails.repeat_info.repeat_month_info_week_number ? this.props.maintenanceEventDetails.repeat_info.repeat_month_info_week_number : prefixes[Math.ceil(moment(this.props.maintenanceEventDetails.start_time, "YYYYMMDDHHmm").date() / 7)],
					end_option: this.props.maintenanceEventDetails.repeat_info.end_option ? this.props.maintenanceEventDetails.repeat_info.end_option : "never",
					end_option_count: this.props.maintenanceEventDetails.repeat_info.end_option_count ? this.props.maintenanceEventDetails.repeat_info.end_option_count : 13,
					end_option_date: this.props.maintenanceEventDetails.repeat_info.end_option_date ? this.props.maintenanceEventDetails.repeat_info.end_option_date : moment(this.props.maintenanceEventDetails.start_time, "YYYYMMDDHHmm").add(3, "months"),
					specific_dates: this.props.maintenanceEventDetails.repeat_info.specific_dates
				}, () => {
					this.setState({ repeat_info_string: getRepeatInfoString(this.state) })
				})
			}
		}
	};

	handleChange = (e) => {
		if (e.target.name === 'repeat_interval' && (e.target.value < 1 || e.target.value > 366)) {
			return;
		}
		this.setState({ [e.target.name]: e.target.value }, () => {
			if (e.target.name === 'repeat_unit') {
				switch (this.state.repeat_unit) {
					case "day":
						this.setState({
							end_option_count: 30,
							end_option_date: moment(this.state.start_time).add(1, "months")
						});
						break;
					case "week":
						this.setState({
							end_option_count: 13,
							end_option_date: moment(this.state.start_time).add(3, "months"),
							repeat_week_info: [moment(this.state.start_time).weekday().toString()]
						});
						break;
					case "month":
						this.setState({
							end_option_count: 12,
							end_option_date: moment(this.state.start_time).add(12, "months"),
						});
						break;
					case "year":
						this.setState({
							end_option_count: 5,
							end_option_date: moment(this.state.start_time).add(5, "years"),
						});
						break;
					case "specific_dates":
						this.setState({
							end_option_count: 24,
							end_option_date: moment(this.state.start_time).add(12, "months"),
						});
						break;
					default:
						break;
				}
			}
		});
	};

	updateWeekInfo = (weekdayNumber) => {
		let repeat_week_info = this.state.repeat_week_info;
		if (repeat_week_info.includes(weekdayNumber)) {
			repeat_week_info = repeat_week_info.filter(item => item !== weekdayNumber);
		} else {
			repeat_week_info.push(weekdayNumber);
			repeat_week_info.sort((a, b) => Number(a) - Number(b));
		}
		this.setState({ repeat_week_info: repeat_week_info });
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	saveChanges = () => {
		let errors = [];
		if (this.props.type === "slot") {
		} else {
			if (!this.state.item_name) {
				errors.push("item_name");
			}
			if (!this.state.serial_number) {
				errors.push("serial_number");
			}
			if (!this.state.location) {
				errors.push("location");
			}
			if (!this.state.staff_role) {
				errors.push("staff_role");
			}
			if (!this.state.description) {
				errors.push("description");
			}
			if (this.state.is_repeat && !(this.state.repeat_info && this.state.repeat_info.repeat_unit)) {
				errors.push("repeat_info");
			}
		}
		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		} else {
			if (this.props.type === "slot") {
				let maintenanceSlot = {
					_id: this.state.maintenanceSlotId,
					staff: this.state.staff,
					slot_note: this.state.slot_note,
					start_time: moment(this.state.start_time).format("YYYYMMDDHHmm")
				}
				Swal.fire({
					title: 'Do you complete this maintenance task?',
					icon: 'question',
					width: 600,
					showCancelButton: true,
					showDenyButton: true,
					confirmButtonText: "Yes, complete it",
					confirmButtonColor: '#dc3741',
					denyButtonText: 'No, save without completion',
					denyButtonColor: '#7066e0',
					cancelButtonText: "Cancel",
					allowOutsideClick: false,
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
						cancelButton: 'order-1',
						denyButton: 'order-2 btn_info',
						confirmButton: 'order-3 btn_primary',
					}
				}).then((result) => {
					if (result.isConfirmed) {
						maintenanceSlot.completed_time = this.state.completed_time ? moment(this.state.completed_time).format("YYYYMMDDHHmm") : null;
						updateMaintenanceSlot(maintenanceSlot).then(res => {
							this.props.handleClose();
							this.props.context.componentParent.loadGridData();
						})
					} else if (result.isDenied) {
						updateMaintenanceSlot(maintenanceSlot).then(res => {
							this.props.handleClose();
							this.props.context.componentParent.loadGridData();
						})
					} else {
						return;
					}
				})
			} else {
				let repeat_info = {};
				switch (this.state.repeat_info.repeat_unit) {
					case "day":
						repeat_info = {
							repeat_interval: this.state.repeat_info.repeat_interval,
							repeat_unit: this.state.repeat_info.repeat_unit,
							end_option: this.state.repeat_info.end_option
						}
						break;
					case "week":
						repeat_info = {
							repeat_interval: this.state.repeat_info.repeat_interval,
							repeat_unit: this.state.repeat_info.repeat_unit,
							repeat_week_info: this.state.repeat_info.repeat_week_info,
							end_option: this.state.repeat_info.end_option
						}
						break;
					case "month":
						repeat_info = {
							repeat_interval: this.state.repeat_info.repeat_interval,
							repeat_unit: this.state.repeat_info.repeat_unit,
							repeat_month_info: this.state.repeat_info.repeat_month_info,
							repeat_month_info_week_number: this.state.repeat_info.repeat_month_info_week_number,
							end_option: this.state.repeat_info.end_option
						}
						break;
					case "year":
						repeat_info = {
							repeat_interval: this.state.repeat_info.repeat_interval,
							repeat_unit: this.state.repeat_info.repeat_unit,
							end_option: this.state.repeat_info.end_option
						}
						break;
					case "specific_dates":
						repeat_info = {
							repeat_interval: this.state.repeat_info.repeat_interval,
							repeat_unit: this.state.repeat_info.repeat_unit,
							specific_dates: this.state.specific_dates,
							end_option: this.state.repeat_info.end_option
						}
						break;
					default:
						break;
				}
				if (this.state.repeat_info.end_option === "end_option_count") {
					repeat_info.end_option_count = this.state.repeat_info.end_option_count;
				} else if (this.state.repeat_info.end_option === "end_option_date") {
					repeat_info.end_option_date = moment(this.state.repeat_info.end_option_date).format("YYYYMMDD");
				}
				let maintenanceEvent = {
					item_name: this.state.item_name,
					serial_number: this.state.serial_number,
					location: this.state.location,
					staff_role: this.state.staff_role,
					description: this.state.description,
					start_time: moment(this.state.start_time).format("YYYYMMDDHHmm"),
					is_repeat: this.state.is_repeat,
					repeat_info: repeat_info,
				}
				if (this.state.maintenanceEventId) {
					maintenanceEvent._id = this.state.maintenanceEventId;
					updateMaintenanceEvent(maintenanceEvent).then(res => {
						this.props.handleClose();
						this.props.context.componentParent.loadGridData();
					})
				} else {
					createMaintenanceEvent(maintenanceEvent).then(res => {
						this.props.handleClose();
						this.props.context.componentParent.loadGridData();
					})
				}
			}
		}
	};

	handleCustomRecurrenceModal = (flag) => {
		this.setState({
			showCustomModal: flag
		});
		if (!this.state.repeat_info_string) {
			this.setState({
				repeat_interval: 1,
				repeat_unit: "week",
				end_option_count: 13,
				end_option_date: moment(this.state.start_time).add(3, "months"),
				repeat_week_info: [moment(this.state.start_time).weekday().toString()]
			})
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		}
	}

	updateRepeatInfo = () => {
		if (this.state.repeat_unit === "specific_dates") {
			let specific_dates = this.state.specific_dates;
			specific_dates = removeDuplicates(specific_dates);
			specific_dates.sort((a, b) => a - b);
			if (!specific_dates.length) {
				return;
			}
			this.setState({ specific_dates: specific_dates }, () => {
				let repeat_info_string = getRepeatInfoString(this.state);
				this.setState({
					showCustomModal: false,
					repeat_info: {
						repeat_interval: this.state.repeat_interval,
						repeat_unit: this.state.repeat_unit,
						repeat_week_info: this.state.repeat_week_info,
						repeat_month_info: this.state.repeat_month_info,
						repeat_month_info_week_number: this.state.repeat_month_info_week_number,
						end_option: this.state.end_option,
						end_option_count: this.state.end_option_count,
						end_option_date: this.state.end_option_date,
						specific_dates: this.state.specific_dates
					},
					repeat_info_string: repeat_info_string
				})
			})
		} else {
			let repeat_info_string = getRepeatInfoString(this.state);
			this.setState({
				showCustomModal: false,
				repeat_info: {
					repeat_interval: this.state.repeat_interval,
					repeat_unit: this.state.repeat_unit,
					repeat_week_info: this.state.repeat_week_info,
					repeat_month_info: this.state.repeat_month_info,
					repeat_month_info_week_number: this.state.repeat_month_info_week_number,
					end_option: this.state.end_option,
					end_option_count: this.state.end_option_count,
					end_option_date: this.state.end_option_date,
					specific_dates: this.state.specific_dates
				},
				repeat_info_string: repeat_info_string
			})
		}
	}

	handleChangeTime = (value, type) => {
		if (type === "start_time") {
			this.setState({ start_time: value, repeat_info: {}, pickerOpened: false });
			if (this.props.type !== "slot") {
				this.setState({
					repeat_info_string: ""
				})
			}
			this.setState({
				repeat_month_info_week_number: prefixes[Math.ceil(moment(value).date() / 7)],
				specific_dates: removeDuplicates([moment(value).format("D"), ...this.state.specific_dates]),
			})
		} else if (type === "completed_time") {
			this.setState({ completed_time: value, pickerOpened: false });
		}
	}

	deleteSlot = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this maintenance slot!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				deleteMaintenanceSlot({ _id: this.state.maintenanceSlotId }).then(res => {
					this.props.handleClose();
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	}

	render() {
		let weekday = moment(this.state.start_time).format("dddd");
		return (
			<div>
				<div className="row form-row">
					<div className="col-md-6">
						<div className="form-group">
							<TextField
								label="Name of machine / item"
								name="item_name"
								value={this.state.item_name}
								onChange={this.handleChange}
								disabled={this.props.type === 'slot' ? true : false}
							/>
							<div className={this.hasError("item_name") ? "inline-errormsg" : "hidden"}>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<TextField
								label="Serial or unique number"
								name="serial_number"
								value={this.state.serial_number}
								onChange={this.handleChange}
								disabled={this.props.type === 'slot' ? true : false}
							/>
							<div className={this.hasError("serial_number") ? "inline-errormsg" : "hidden"}>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<TextField
								label="Location within lab"
								name="location"
								value={this.state.location}
								onChange={this.handleChange}
								disabled={this.props.type === 'slot' ? true : false}
							/>
							<div className={this.hasError("location") ? "inline-errormsg" : "hidden"}>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<Autocomplete
								options={["Admin", "LabTech"]}
								value={this.state.staff_role}
								onChange={(event, newValue) => {
									this.setState({ staff_role: newValue });
								}}
								renderInput={(params) => <TextField {...params} label="Which role should be assigned" variant="outlined" style={{ background: "white" }} />}
								getOptionLabel={(option) => option ? option : ""}
								disabled={this.props.type === 'slot' ? true : false}
							/>
							<div className={this.hasError("staff_role") ? "inline-errormsg" : "hidden"}>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="form-group">
							<TextField
								label="Description"
								multiline
								rows={3}
								name="description"
								value={this.state.description}
								onChange={this.handleChange}
								disabled={this.props.type === 'slot' ? true : false}
							/>
							<div className={this.hasError("description") ? "inline-errormsg" : "hidden"}>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-md-6 form-group">
						<MobileDateTimePicker
							value={this.state.pickerOpened ? this.state.temp_start_time : this.state.start_time}
							onChange={(dateTime) => this.setState({ temp_start_time: dateTime.$d })}
							onClose={() => this.setState({ pickerOpened: false })}
							onOpen={() => this.setState({ temp_start_time: this.state.start_time, pickerOpened: true })}
							onAccept={(dateTime) => this.handleChangeTime(dateTime.$d, "start_time")}
							disabled={(this.props.type === "slot" && this.props.maintenanceSlotDetails.completed_time) ? true : false}
							label="Start Time"
							renderInput={props => <TextField
								{...props}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<EventIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>}
						/>
						<div className={this.hasError("time") ? "inline-errormsg" : "hidden"}>
							<i className="fa fa-exclamation-circle" aria-hidden="true">
								&nbsp;Please provide correctly.
							</i>
						</div>
					</div>
					<div className="col-md-6 form-group">
						<div className="d-flex align-items-center">
							<div style={{ cursor: "pointer", marginLeft: "10px", marginTop: "10px" }} onClick={() => {
								if (this.props.type !== "slot") {
									this.setState({ is_repeat: !this.state.is_repeat })
								}
							}}>
								<Checkbox checked={this.state.is_repeat} disabled={this.props.type === 'slot' ? true : false} />It will be repeated?
							</div>
						</div>
					</div>
					{
						this.state.is_repeat
						&&
						<div className="col-md-12 form-group">
							<div className="btn btn-info" onClick={() => {
								if (this.props.type !== "slot") {
									this.handleCustomRecurrenceModal(true)
								}
							}}>{this.state.repeat_info_string ? this.state.repeat_info_string : "Recurrence Setting"}</div>
							<div className={this.hasError("repeat_info") ? "inline-errormsg" : "hidden"}>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This is required.
								</i>
							</div>
						</div>
					}
					{
						this.props.type === 'slot'
						&&
						<div className="col-md-12">
							<hr style={{ marginBottom: "30px" }} />
						</div>
					}
					{
						this.props.type === 'slot'
						&&
						<div className="col-md-6">
							<div className="form-group">
								<TextField
									label="Staff"
									name="staff"
									value={this.state.staff}
									onChange={this.handleChange}
									disabled={(this.props.type === "slot" && this.props.maintenanceSlotDetails.completed_time) ? true : false}
								/>
								<div className={this.hasError("staff") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
					}
					{
						this.props.type === 'slot'
						&&
						<div className="col-md-6">
							<div className="form-group">
								<MobileDateTimePicker
									value={this.state.pickerOpened ? this.state.temp_completed_time : this.state.completed_time}
									onChange={(dateTime) => {
										if (dateTime) {
											this.setState({ temp_completed_time: dateTime.$d })
										}
									}}
									onClose={() => this.setState({ pickerOpened: false })}
									onOpen={() => this.setState({ temp_completed_time: this.state.completed_time, pickerOpened: true })}
									onAccept={(dateTime) => this.handleChangeTime(dateTime.$d, "completed_time")}
									label="Completed Time"
									disabled={this.props.maintenanceSlotDetails.completed_time ? true : false}
									renderInput={props => <TextField
										{...props}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
													{
														this.state.completed_time && !this.props.maintenanceSlotDetails.completed_time
														&&
														<IconButton edge="end" onClick={(event) => { this.setState({ completed_time: null }); event.stopPropagation(); }}>
															<CloseIcon />
														</IconButton>
													}
												</InputAdornment>
											)
										}}
									/>}
								/>
							</div>
						</div>
					}
					{
						this.props.type === 'slot'
						&&
						<div className="col-md-12">
							<div className="form-group">
								<TextField
									label="Note"
									name="slot_note"
									value={this.state.slot_note}
									onChange={this.handleChange}
								/>
								<div className={this.hasError("slot_note") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
					}
				</div>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showCustomModal}
					onHide={() => this.handleCustomRecurrenceModal(false)}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Custom Recurrence</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="form-group col-sm-12 d-flex align-items-center">
								<div style={{ whiteSpace: "nowrap" }}>Repeat every</div>
								<input className="form-control ml-4" type="number" style={{ width: 70 }} name="repeat_interval" value={this.state.repeat_interval} onChange={this.handleChange} min={1} />
								<select className="form-control ml-2" name="repeat_unit" value={this.state.repeat_unit} onChange={this.handleChange} >
									<option value="day">day{Number(this.state.repeat_interval) > 1 ? "s" : ""}</option>
									<option value="week">week{Number(this.state.repeat_interval) > 1 ? "s" : ""}</option>
									<option value="month">month{Number(this.state.repeat_interval) > 1 ? "s" : ""}</option>
									<option value="year">year{Number(this.state.repeat_interval) > 1 ? "s" : ""}</option>
									<option value="specific_dates">month{Number(this.state.repeat_interval) > 1 ? "s" : ""} in following specific dates</option>
								</select>
							</div>
							{
								this.state.repeat_unit === "week"
								&&
								<div className="form-group col-sm-12">
									<label>
										Repeat on
									</label>
									<div className="d-flex align-items-center">
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("0")} >
											<Checkbox checked={this.state.repeat_week_info.includes("0") ? true : false} /> Su
										</div>
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("1")}>
											<Checkbox checked={this.state.repeat_week_info.includes("1") ? true : false} className="ml-2" /> Mo
										</div>
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("2")}>
											<Checkbox checked={this.state.repeat_week_info.includes("2") ? true : false} className="ml-2" /> Tu
										</div>
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("3")}>
											<Checkbox checked={this.state.repeat_week_info.includes("3") ? true : false} className="ml-2" /> We
										</div>
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("4")}>
											<Checkbox checked={this.state.repeat_week_info.includes("4") ? true : false} className="ml-2" /> Th
										</div>
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("5")}>
											<Checkbox checked={this.state.repeat_week_info.includes("5") ? true : false} className="ml-2" /> Fr
										</div>
										<div className="cursorPointer" onClick={() => this.updateWeekInfo("6")}>
											<Checkbox checked={this.state.repeat_week_info.includes("6") ? true : false} className="ml-2" /> Sa
										</div>
									</div>
								</div>
							}
							{
								this.state.repeat_unit === "month"
								&&
								<div className="form-group col-sm-12">
									<select className="form-control" name='repeat_month_info' value={this.state.repeat_month_info} onChange={this.handleChange}>
										<option value="by_date">Monthly on day {moment(this.state.start_time).format("DD")}</option>
										<option value="by_weekday">Monthly on the {this.state.repeat_month_info_week_number} {weekday}</option>
									</select>
								</div>
							}
							{
								this.state.repeat_unit === "specific_dates"
								&&
								<div className="form-group col-sm-12">
									<div>
										<label>Specific Dates&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => { let specific_dates = this.state.specific_dates; specific_dates.push("1"); this.setState({ specific_dates: specific_dates }) }}></i></label>
									</div>
									<div className="row">
										{
											this.state.specific_dates.map((item, index) => {
												let optionsDom = [];
												for (let i = 1; i < 29; i++) {
													optionsDom.push(<option key={i} value={i}>{i}</option>)
												}
												return <div key={index} className="col-sm-6 form-group d-flex align-items-center justify-content-between">
													<select
														onChange={(e) => {
															let specific_dates = this.state.specific_dates;
															specific_dates = specific_dates.map((date, i) => {
																if (i === index) {
																	return e.target.value
																}
																return date;
															});
															this.setState({ specific_dates: specific_dates })
														}}
														className="form-control"
														value={item}
														style={{ width: "calc(100% - 30px)" }}
													>
														{optionsDom}
													</select>
													<div>
														<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => {
															let specific_dates = this.state.specific_dates;
															specific_dates.splice(index, 1);
															this.setState({ specific_dates: specific_dates })
														}}></i>
													</div>
												</div>
											})
										}
									</div>
								</div>
							}
							<div className="form-group col-sm-12">
								<label>
									Ends
								</label>
								<div className="cursorPointer" onClick={() => this.handleChange({ target: { value: "never", name: "end_option" } })}>
									<Radio checked={this.state.end_option === "never" ? true : false} /> Never
								</div>
								<div className="d-flex align-items-center mb-3">
									<div className="cursorPointer" onClick={() => this.handleChange({ target: { value: "end_option_date", name: "end_option" } })}>
										<Radio name="end_option" value="end_option_date" checked={this.state.end_option === "end_option_date" ? true : false} onChange={this.handleChange} />
										On
									</div>
									<div style={{ width: 200, marginLeft: 38 }}>
										<MobileDatePicker
											value={this.state.end_option_date}
											onChange={(dateTime) => {
												this.setState({ end_option_date: dateTime.$d })
											}}
											disabled={this.state.end_option !== "end_option_date" ? true : false}
											renderInput={props => <TextField
												{...props}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
									</div>
								</div>
								<div className="d-flex align-items-center">
									<div className="cursorPointer" onClick={() => this.handleChange({ target: { value: "end_option_count", name: "end_option" } })}>
										<Radio name="end_option" value="end_option_count" checked={this.state.end_option === "end_option_count" ? true : false} onChange={this.handleChange} />
										After
									</div>
									<input className="form-control ml-4" type="number" style={{ width: 70, marginRight: 10 }} name="end_option_count" value={this.state.end_option_count} onChange={this.handleChange} min={1} disabled={this.state.end_option !== "end_option_count" ? true : false} />
									occurrences
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div>
							<div className="btn btn-secondary" onClick={() => this.handleCustomRecurrenceModal(false)}>Cancel</div>
							<div className="btn btn-primary ml-2" onClick={() => this.updateRepeatInfo()}>Done</div>
						</div>
					</Modal.Footer>
				</ModalStyled>
				<div className="row">
					<div
						className="col-12"
						style={{
							paddingTop: "10px",
							borderTop: "1px solid rgba(0,0,0,.2",
						}}
					>
						<Button
							style={{ float: "right", marginLeft: "10px" }}
							variant="primary"
							onClick={this.saveChanges}
						>
							Save Changes
						</Button>
						{
							this.props.type === "slot" && (getUserRole() && getUserRole() !== "LabTech") && !this.props.maintenanceSlotDetails.completed_time
							&&
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="info"
								onClick={this.deleteSlot}
							>
								Delete Slot
							</Button>
						}
						<Button
							style={{ float: "right" }}
							variant="secondary"
							onClick={this.props.handleClose}
						>
							Close
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
