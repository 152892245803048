import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class TestResultRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			results: props.data.results,
			errors: [],
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				{
					this.props.data.results && this.props.data.results.length > 0
					&&
					<div onClick={this.handleShow} style={{ cursor: "pointer" }}>
						{this.props.data.results.length + " Item" + (this.props.data.results.length > 1 ? "s" : "")}
					</div>
				}
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Result Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								<ul>
									{
										this.props.data.results && this.props.data.results.map((result, index) => {
											return <li key={index}>{result}</li>
										})
									}
								</ul>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div >
		);
	}
}
