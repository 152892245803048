import React, { useEffect } from "react";
import $ from "jquery";
import { BLUELOT_LOGO } from "./common/imgLogoes";
import { isUserLoggedIn, getPatientAuthToken, isToken, getUserRole } from "../services/common/util";
import { dashboardLinks } from "../services/common/optionsData";
import { logout } from "../services/clinicPortalServices/loginService";
import { logoutPatient } from "../services/patientPortalServices/loginService";
import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Header = (props) => {
	const { t } = useTranslation();
	const onHandleMobileMenu = () => {
		let root = document.getElementsByTagName("html")[0];
		root.classList.add("menu-opened");
	};

	const onhandleCloseMenu = () => {
		let root = document.getElementsByTagName("html")[0];
		root.classList.remove("menu-opened");
	};

	useEffect(() => {
		$(".main-nav a").on("click", function (e) {
			if ($(this).parent().hasClass("has-submenu")) {
				e.preventDefault();
			}
			if (!$(this).hasClass("submenu")) {
				$("ul", $(this).parents("ul:first")).slideUp(350);
				$("a", $(this).parents("ul:first")).removeClass("submenu");
				$(this).next("ul").slideDown(350);
				$(this).addClass("submenu");
			} else if ($(this).hasClass("submenu")) {
				$(this).removeClass("submenu");
				$(this).next("ul").slideUp(350);
			}
		});
	}, []);

	return (
		<header className="header">
			<nav className="navbar navbar-expand-lg header-nav">
				<div className="navbar-header">
					<i id="mobile_btn" className="fa fa-bars" onClick={() => onHandleMobileMenu()} />
					<Link to="/" className="navbar-brand logo">
						<img src={BLUELOT_LOGO} object-fit="cover" alt="" />
					</Link>
				</div>
				<div className="main-menu-wrapper">
					<div className="menu-header">
						<Link to="/home" className="menu-logo">
							<img src={BLUELOT_LOGO} object-fit="cover" alt="" />
						</Link>
						<div
							id="menu_close"
							className="menu-close"
							onClick={() => onhandleCloseMenu()}
						>
							<i className="fas fa-times"></i>
						</div>
					</div>

					{
						isUserLoggedIn()
						&&
						<ul className="main-nav">
							<li className="firstpage-link">
								<Link to={dashboardLinks[getUserRole()] ? dashboardLinks[getUserRole()] : "/clinic/orders"} className="top-nav-button">
									{" "}{t('Clinic Dashboard')}{" "}
								</Link>
							</li>
							<li className="firstpage-link">
								<a href="#logout" className="top-nav-button" onClick={logout}>
									<span><i className="fas fa-sign-out-alt"></i> {t('Logout')}</span>
								</a>
							</li>
						</ul>
					}
					{
						getPatientAuthToken()
						&&
						<ul className="main-nav">
							<li className="firstpage-link">
								<Link to="/patientportal/dashboard" className="top-nav-button">
									{" "}{t('View My results')}{" "}
								</Link>
							</li>
							<li className="firstpage-link">
								<a href="#logout" className="top-nav-button" onClick={logoutPatient}>
									<span><i className="fas fa-sign-out-alt"></i> {t('Logout')}</span>
								</a>
							</li>
						</ul>
					}
					{
						!isToken()
						&&
						<ul className="main-nav">
							<li className="firstpage-link">
								<Link to="/patientportal" className="top-nav-button">
									{" "}
									{t('View My Results')}{" "}
								</Link>
							</li>{" "}
							<li className="firstpage-link">
								<Link to="/lims" className="top-nav-button">
									{" "}
									{t('Lab Login')}
								</Link>
							</li>{" "}
							<li className="firstpage-link">
								<Link to="/clinic" className="top-nav-button">
									{" "}
									{t('Clinic Login')}
								</Link>
							</li>{" "}
						</ul>
					}
				</div>
				{
					isUserLoggedIn()
					&&
					<ul className="nav header-navbar-rht">
						<li className="nav-item">
							<Link to={dashboardLinks[getUserRole()] ? dashboardLinks[getUserRole()] : "/clinic/orders"} className="top-nav-button">
								{" "}{t('Clinic Dashboard')}{" "}
							</Link>
						</li>
						<li className="nav-item">
							<a href="#logout" className="top-nav-button" onClick={logout}>
								<i className="fas fa-sign-out-alt"></i>
								<span>{" "}{t('Logout')}</span>
							</a>
						</li>
					</ul>
				}
				{
					getPatientAuthToken()
					&&
					<ul className="nav header-navbar-rht">
						<li className="nav-item">
							<Link to="/patientportal/dashboard" className="top-nav-button">
								{" "}{t('View My results')}{" "}
							</Link>
						</li>
						<li className="nav-item">
							<a href="#logout" className="top-nav-button" onClick={logoutPatient}>
								<i className="fas fa-sign-out-alt"></i>
								<span>{" "}{t('Logout')}</span>
							</a>
						</li>
					</ul>
				}
				{
					!isToken()
					&&
					<ul className="nav header-navbar-rht">
						<li className="nav-item">
							<Link to="/patientportal" className="top-nav-button">
								{" "}
								{t('View My results')}{" "}
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/lims" className="top-nav-button">
								{" "}{t('Lab Login')}{" "}
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/clinic" className="top-nav-button">
								{" "}{t('Clinic Login')}{" "}
							</Link>
						</li>
					</ul>
				}
			</nav>
		</header>
	);
};

export default withTranslation()(Header);
