import React, { Component } from "react";
import { Tooltip, OverlayTrigger, Modal, Button } from "react-bootstrap";
import { Checkbox } from "@mui/material";
import { searchTests } from "../../../../services/clinicPortalServices/testService";
import { easyUpdateFacility } from "../../../../services/clinicPortalServices/facilityServices";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			facilityId: props.data._id,
			tests: [],
			patient_test_result_management_info: props.data.patient_test_result_management_info ? props.data.patient_test_result_management_info : {}
		};
		let testIds = props.data.testIds.map(test => {
			return test._id;
		})
		searchTests({ _id: testIds }).then(res => {
			this.setState({ tests: res.data });
		})
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit
		</Tooltip>
	);

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleCheck = (type, testId, subTestId = null, is_bundle = null) => {
		let patient_test_result_management_info = this.state.patient_test_result_management_info;
		if (type === 'test') {
			if (patient_test_result_management_info && patient_test_result_management_info[testId]) {
				delete patient_test_result_management_info[testId];
			} else {
				patient_test_result_management_info[testId] = is_bundle ? [] : "single"
			}
		} else {
			if (patient_test_result_management_info && patient_test_result_management_info[testId] && patient_test_result_management_info[testId].includes(subTestId)) {
				patient_test_result_management_info[testId] = patient_test_result_management_info[testId].filter(item => item !== subTestId);
			} else {
				patient_test_result_management_info[testId].push(subTestId);
			}
		}
		this.setState({ patient_test_result_management_info: patient_test_result_management_info })
	}

	saveChanges = () => {
		easyUpdateFacility({ _id: this.state.facilityId, patient_test_result_management_info: this.state.patient_test_result_management_info }).then(res => {
			this.handleClose();
			this.props.context.componentParent.loadGridData();
		})
	}

	render() {
		return (
			<div style={{ textAlign: "left" }}>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Facility Test Result Management</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="row form-row">
								<div className="col-12 form-group">
									<label>Name</label>
									<input className="form-control" value={this.props.data.name} disabled />
								</div>
								<div className="col-12 form-group">
									By selecting any of the following, for those tests the patient will need to contact you directly for the result and it will not be available to them in the BlueLot patient portal.
									<br />
									<br />
									<label>Don't allow patients to see results for:</label>
									<br />
									{
										this.state.tests.map((test, testIndex) => {
											let testChecked = this.state.patient_test_result_management_info && this.state.patient_test_result_management_info[test._id] ? true : false;
											let subTestDomForBundle = [];
											if (test.is_bundle && test.testIds && test.testIds.length && testChecked) {
												subTestDomForBundle.push(<div style={{ padding: "0px 20px" }} key="question">If not showing full result PDF of bundle to the patient in the portal as you selected above, do you want to show them any of the individual test results from within that bundle?</div>)
												test.testIds.map(subTest => {
													let subTestChecked = this.state.patient_test_result_management_info && this.state.patient_test_result_management_info[test._id] && this.state.patient_test_result_management_info[test._id].includes(subTest._id) ? true : false;
													subTestDomForBundle.push(<div style={{ padding: "0px 10px" }} key={subTest._id}><Checkbox checked={subTestChecked} onClick={() => this.handleCheck("sub_test", test._id, subTest._id)} /> {subTest.name}</div>);
													return null;
												})
											}
											return <div key={testIndex}>
												<Checkbox checked={testChecked} onClick={() => this.handleCheck("test", test._id, null, test.is_bundle)} /> {test.name}
												<br />
												{subTestDomForBundle}
												<hr style={{ margin: "0px", borderTop: "2px solid rgba(0,0,0,.1)" }} />
											</div>
										})
									}
								</div>
							</div>
							<div className="row">
								<div
									className="col-12 modal-button-col"
								>
									<Button
										style={{ float: "right", marginLeft: "10px" }}
										variant="primary"
										onClick={this.saveChanges}
									>
										Save Changes
									</Button>
									<Button
										style={{ float: "right" }}
										variant="secondary"
										onClick={this.handleClose}
									>
										Close
									</Button>
								</div>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
