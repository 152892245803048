import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import VendorInfoCellRenderer from "./vendorInfoCellRenderer";
import InventorySearchMenu from "./inventorySearchMenu";
import {
    getUserRole,
    getUserDetails,
    getRepeatInfoString
} from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { fetchLabInventories } from "../../../../services/clinicPortalServices/labInventoryService";
import moment from "moment";

class ClinicInventoryGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 150,
                    maxWidth: 150,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Name",
                    minWidth: 150,
                    resizable: true,
                    field: "name"
                },
                {
                    headerName: "Current Count",
                    minWidth: 150,
                    resizable: true,
                    field: "current_amount"
                },
                {
                    headerName: "Measurement",
                    minWidth: 150,
                    resizable: true,
                    field: "measurement"
                },
                {
                    headerName: "Minimum Thresold",
                    minWidth: 150,
                    resizable: true,
                    field: "minimum_threshold"
                },
                {
                    headerName: "How Depleted",
                    minWidth: 150,
                    resizable: true,
                    field: "inventory_type",
                    valueGetter: (params) => {
                        if (params.data.inventory_type === "automatic_entry") {
                            return params.data.automatic_deplete_type;
                        } else {
                            return "Manually";
                        }
                    }
                },
                {
                    headerName: "Associated Test",
                    minWidth: 150,
                    resizable: true,
                    field: "associated_tests",
                    valueGetter: (params) => {
                        if (
                            params.data.associated_tests &&
                            params.data.associated_tests.length > 0
                        ) {
                            let displayStrings = [];
                            params.data.associated_tests.map((item) => {
                                displayStrings.push(item.name);
                                return null;
                            });
                            return displayStrings.join(", ");
                        }
                    }
                },
                {
                    headerName: "Associated Maintenance",
                    minWidth: 150,
                    resizable: true,
                    field: "associated_maintenances",
                    valueGetter: (params) => {
                        if (
                            params.data.associated_maintenances &&
                            params.data.associated_maintenances.length > 0
                        ) {
                            let displayStrings = [];
                            params.data.associated_maintenances.map((item) => {
                                displayStrings.push(item.item_name);
                                return null;
                            });
                            return displayStrings.join(", ");
                        }
                    }
                },
                {
                    headerName: "Automatic Deplete Amount",
                    minWidth: 150,
                    resizable: true,
                    field: "automatic_depelte_amount"
                },
                {
                    headerName: "Inventory Count Day",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: (params) => {
                        if (
                            params.data.inventory_count_day_repeat_info &&
                            params.data.inventory_count_day_repeat_info
                                .repeat_interval
                        ) {
                            return getRepeatInfoString(
                                params.data.inventory_count_day_repeat_info
                            );
                        }
                    }
                },
                {
                    headerName: "Location",
                    minWidth: 150,
                    resizable: true,
                    field: "location"
                },
                {
                    headerName: "Vendor Info",
                    minWidth: 150,
                    resizable: true,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "vendorInfoCellRenderer"
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer,
                vendorInfoCellRenderer: VendorInfoCellRenderer
            },

            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            context: { componentParent: this },
            facilities: [],
            rowClassRules: {
                "red-inventory-row": function (params) {
                    return (
                        Number(params.data.current_amount) <
                            Number(params.data.minimum_threshold) &&
                        !(
                            params.data.ordered_date &&
                            params.data.ordered_amount
                        )
                    );
                },
                "green-inventory-row": function (params) {
                    return (
                        Number(params.data.current_amount) >
                            Number(params.data.minimum_threshold) &&
                        params.data.ordered_date &&
                        params.data.ordered_amount
                    );
                },
                "yellow-inventory-row": function (params) {
                    return (
                        Number(params.data.current_amount) <
                            Number(params.data.minimum_threshold) &&
                        params.data.ordered_date &&
                        params.data.ordered_amount
                    );
                }
            }
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridData();
    };

    loadGridData = () => {
        fetchLabInventories().then((res) => {
            this.setState({ rowData: res.data });
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "LabInventory_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Lab Inventories"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.LabInventoryGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.LabInventoryGridExported
        };
        createAudit(auditData);
    };

    render() {
        let columnDefs = /salesperson|audit|salesadmin/.test(
            getUserRole().toLowerCase()
        )
            ? this.state.columnDefs.filter((x) => x.headerName !== "Actions")
            : this.state.columnDefs;
        return (
            <div className="clinic-contain">
                <InventorySearchMenu
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                    context={this.state.context}
                    facilities={this.state.facilities}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    components={this.state.components}
                                    context={this.state.context}
                                    rowClassRules={this.state.rowClassRules}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicInventoryGrid;
