import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import MaintenanceDetails from "./maintenanceDetails";

export default class MaintenanceModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<ModalStyled
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				onHide={this.props.handleClose}
				dialogClassName="removeTabIndexModal"
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.maintenanceEventDetails ? (this.props.maintenanceSlotDetails ? (this.props.maintenanceSlotDetails.completed_time ? "View Maintenance" : "Complete Maintenance") : "Edit Maintenance Event") : "Add New Maintenance Event"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<MaintenanceDetails
						context={this.props.context}
						handleClose={this.props.handleClose}
						maintenanceEventDetails={this.props.maintenanceEventDetails}
						maintenanceSlotDetails={this.props.maintenanceSlotDetails}
						type={this.props.type}
					/>
				</Modal.Body>
			</ModalStyled>
		);
	}
}
