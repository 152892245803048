import React, { Component, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

import MasterBtnCellRenderer from "./masterBtnCellRenderer";
import PatientSearchMenu from "./patientSearchMenu";

import {
    fetchPatientDuplicatedData,
    dismissPatientDuplicatedData
} from "../../../../services/clinicPortalServices/patientSearchService";
import {
    getViewSettings,
    saveViewDefault
} from "../../../../services/clinicPortalServices/userViewSettings";
import {
    getUserRole,
    dateComparator,
    getUserDetails
} from "../../../../services/common/util";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from "lodash";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicPatientDuplicatedGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isArchived: false,
            user_role: getUserRole(),
            searchFilters: {
                facility_id: []
            },
            showCreateRequisition: false,
            scannedPatientId: "", //ToDo: remove later
            patientDetails: {},
            gridName: "Default",
            columnDefs: [
                {
                    headerName: "First Name",
                    field: "first_name",
                    minWidth: 100,
                    maxWidth: 200,
                    resizable: true
                },
                {
                    headerName: "Last Name",
                    field: "last_name",
                    minWidth: 100,
                    maxWidth: 200,
                    resizable: true
                },
                {
                    headerName: "Date Of Birth",
                    field: "date_of_birth",
                    minWidth: 150,
                    maxWidth: 150,
                    sortable: true,
                    valueGetter: function (params) {
                        return params.data.date_of_birth
                            ? moment(
                                  params.data.date_of_birth,
                                  "YYYY-MM-DD"
                              ).format("MM/DD/YYYY")
                            : "";
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Gender",
                    field: "gender",
                    minWidth: 100,
                    maxWidth: 100
                },
                {
                    headerName: "Email",
                    field: "email",
                    minWidth: 150,
                    maxWidth: 300,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        return (
                            <span>
                                <i className="fas fa-envelope"></i>{" "}
                                {params.data.email ? params.data.email : ""}
                            </span>
                        );
                    })
                },
                {
                    headerName: "Phone",
                    field: "mobile",
                    minWidth: 170,
                    maxWidth: 170,
                    cellRenderer: memo((params) => {
                        return (
                            <span>
                                <i className="fas fa-phone-alt"></i>{" "}
                                {params.data.mobile ? params.data.mobile : ""}
                            </span>
                        );
                    })
                },
                {
                    headerName: "Possible # of Duplicates",
                    field: "times",
                    minWidth: 200,
                    resizable: true,
                    valueGetter: function (params) {
                        return (
                            "Possible " + params.data.times + " of Duplicates"
                        );
                    }
                },
                {
                    headerName: "Actions",
                    minWidth: 200,
                    maxWidth: 200,
                    cellStyle: {
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        borderColor: "transparent"
                    },
                    cellRenderer: "masterBtnCellRenderer"
                }
            ],
            components: {
                masterBtnCellRenderer: MasterBtnCellRenderer
            },
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true
            },
            sideBar: { toolPanels: ["columns"] },
            rowGroupPanelShow: "always",
            pivotPanelShow: "always",
            excelStyles: [
                {
                    id: "header",
                    interior: {
                        color: "#aaaaaa",
                        pattern: "Solid"
                    }
                },
                {
                    id: "body",
                    interior: {
                        color: "#dddddd",
                        pattern: "Solid"
                    }
                }
            ],
            rowData: [],
            expandableRowData: [],
            facilities: [],
            context: { componentParent: this }
        };
    }

    loadFacilities = (isArchived) => {
        let filters = this.state.searchFilters;
        let facilities = this.state.facilities;

        let isAllFacilities = false;
        if (
            filters.facility_id.length > 0 &&
            filters.facility_id.length === facilities.length &&
            facilities.length > 0
        ) {
            isAllFacilities = true;
        }

        getCompanyWithFacility(isArchived).then((response) => {
            let facilityIds = [];

            facilities = response.data;
            if (facilities.length > 0) {
                facilities.map((fac) => {
                    facilityIds.push(fac._id);
                    return null;
                });
                filters.facility_id = facilityIds;
                this.facilityIds = facilityIds;
                if (
                    [
                        "superadmin",
                        "admin",
                        "accountant",
                        "audit",
                        "customerserviceuser"
                    ].includes(getUserRole().toLowerCase())
                ) {
                    filters.facility_id = []; // if superadmin, set default for Benchlab, LLC. facility
                }
            } else {
                filters.facility_id = facilities[0]._id;
            }
            if (
                !isAllFacilities &&
                window.localStorage.getItem("FACILITY_ID") &&
                JSON.parse(window.localStorage.getItem("FACILITY_ID")).length
            ) {
                filters.facility_id = JSON.parse(
                    window.localStorage.getItem("FACILITY_ID")
                );
            }
            this.setState({ searchFilters: filters, facilities }, () => {
                this.loadGridData();
            });
        });
    };

    onIsArchivedInformation = (event) => {
        this.setState({ isArchived: event.target.checked });
        this.loadFacilities(event.target.checked);
    };

    handleDismiss = (patientIds) => {
        dismissPatientDuplicatedData(patientIds).then((data) => {
            if (data.status === 200) {
                toastr.success("Patient dismissed");
                this.loadGridData();
            } else {
                toastr.error("Dismiss failed");
            }
        });
    };

    handleFiltersChange = (e) => {
        let filters = this.state.searchFilters;
        let { facilities } = this.state;
        let facilityIdChanged = [],
            isAdded = false,
            facilitiesIn = [],
            facilityIdsTemp = [];
        let newFacilityIds = e.target.value;

        if (e.target.value.includes("all")) {
            if (filters.facility_id.length === facilities.length) {
                filters.facility_id = [];
            } else {
                filters.facility_id = _.cloneDeep(this.facilityIds);
            }
        } else {
            if (filters.facility_id.length > newFacilityIds.length) {
                facilityIdChanged = filters.facility_id.find(
                    (val) => !newFacilityIds.includes(val)
                );
            } else {
                facilityIdChanged = newFacilityIds.find(
                    (val) => !filters.facility_id.includes(val)
                );
                isAdded = true;
            }
            let facilityChanged = facilities.find(
                (facility) => facility._id === facilityIdChanged
            );
            facilityIdsTemp = _.cloneDeep(filters.facility_id);
            // If company is changed
            if (facilityChanged.type === "company") {
                let companyChanged = facilityChanged;
                facilities.forEach((fac) => {
                    if (fac.parent_id === companyChanged._id) {
                        facilitiesIn.push(fac._id);
                    }
                });
                let index = facilityIdsTemp.indexOf(companyChanged._id);
                if (facilitiesIn.length > 0) {
                    // If company is selected
                    if (isAdded) {
                        // Add company Id
                        facilityIdsTemp.push(companyChanged._id);
                        // Add facility Ids
                        facilitiesIn.forEach((facId) => {
                            if (!facilityIdsTemp.includes(facId)) {
                                facilityIdsTemp.push(facId);
                            }
                        });
                    } else {
                        // If company is unselected
                        // Remove company Id
                        if (index > -1) {
                            facilityIdsTemp.splice(index, 1);
                        }
                        facilitiesIn.forEach((facId) => {
                            let index = facilityIdsTemp.indexOf(facId);
                            if (index > -1) {
                                facilityIdsTemp.splice(index, 1);
                            }
                        });
                    }
                } else {
                    isAdded
                        ? facilityIdsTemp.push(companyChanged._id)
                        : facilityIdsTemp.splice(index, 1);
                }
            } else {
                // If facility is changed
                let companyId = facilityChanged.parent_id;
                let facilitiesIn = [];
                facilities.forEach((fac) => {
                    if (fac.parent_id === companyId) facilitiesIn.push(fac._id);
                });
                // If facility Id is selected
                if (isAdded) {
                    // Add facility Id
                    facilityIdsTemp.push(facilityChanged._id);
                    if (!filters.facility_id.includes(companyId)) {
                        facilityIdsTemp.push(companyId);
                    }
                } else {
                    // If facility Id is unselected
                    // Remove facility Id
                    let index = facilityIdsTemp.indexOf(facilityChanged._id);
                    if (index > -1) {
                        facilityIdsTemp.splice(index, 1);
                    }
                    // Remove company Id
                    let facilitiesInFilters = [];
                    facilitiesIn.forEach((fac) => {
                        if (facilityIdsTemp.includes(fac)) {
                            facilitiesInFilters.push(fac);
                        }
                    });
                    if (facilitiesInFilters.length === 0) {
                        index = facilityIdsTemp.indexOf(companyId);
                        if (index > -1) {
                            facilityIdsTemp.splice(index, 1);
                        }
                    }
                }
            }
            filters.facility_id = _.cloneDeep(facilityIdsTemp);
        }
        window.localStorage.setItem(
            "FACILITY_ID",
            JSON.stringify(filters.facility_id)
        );
        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridSchema();
        this.loadFacilities(this.state.isArchived);
    };

    checkRealFacilities = (searchFilters) => {
        let realFacilities = [];
        searchFilters.facility_id.map((facilityId) => {
            if (
                this.state.facilities.find(
                    (facility) => facility._id === facilityId
                )
            ) {
                realFacilities.push(facilityId);
            }
            return null;
        });
        return realFacilities;
    };

    loadGridData = () => {
        this.gridApi.showLoadingOverlay();
        let facilityId = this.checkRealFacilities(this.state.searchFilters);
        fetchPatientDuplicatedData(facilityId).then((data) => {
            this.setState({ rowData: data.data });
            if (!data.data.length) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Duplicated_Patient_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Duplicated Patients"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.PatientDuplicateGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.PatientDuplicateGridExported
        };
        createAudit(auditData);
    };

    onPageSizeChanged = () => {
        let value = document.getElementById("page-size").value;
        this.gridApi.paginationSetPageSize(Number(value));
    };

    loadGridSchema = () => {
        let userId = window.localStorage.getItem("USER_ID");
        let params = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.PATIENT_DUPLICATES
        };
        getViewSettings(params).then((response) => {
            const columnState =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === settingConstants.DEFAULT_VIEWNAME;
                }).columns;
            if (columnState) {
                this.gridColumnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true
                });
            } else {
                this.gridColumnApi.resetColumnState();
            }

            const pageSize =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === settingConstants.DEFAULT_VIEWNAME;
                }).page_size;
            document.getElementById("page-size").value =
                pageSize && pageSize > 0 ? pageSize : 20;
            this.onPageSizeChanged();
        });
    };

    saveState = () => {
        let userId = window.localStorage.getItem("USER_ID");
        const columnState = this.gridColumnApi.getColumnState();
        let pageSize = document.getElementById("page-size").value;
        let saveObject = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.PATIENTS,
            grid_views: [
                {
                    name: settingConstants.DEFAULT_VIEWNAME,
                    page_size: pageSize,
                    columns: columnState
                }
            ]
        };
        saveViewDefault(saveObject).then(() => {
            toastr.success("Saved successfully.");
        });
    };

    resetState = () => {
        this.gridColumnApi.resetColumnState();
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
        const filters = this.state.searchFilters;
        if (
            getUserRole().toLowerCase() === "superadmin" ||
            getUserRole().toLowerCase() === "admin"
        ) {
            filters.facility_id = [];
        } else {
            if (this.state.facilities.length > 1) {
                filters.facility_id = this.facilityIds;
            } else {
                filters.facility_id = this.state.facilities[0]._id;
            }
        }
        window.localStorage.removeItem("FACILITY_ID");
        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    render() {
        let columnDefs = /audit/.test(getUserRole().toLowerCase())
            ? this.state.columnDefs.filter((x) => x.headerName !== "Actions")
            : this.state.columnDefs;

        return (
            <div className="clinic-contain">
                <PatientSearchMenu
                    onIsArchivedInformation={(event) => {
                        this.onIsArchivedInformation(event);
                    }}
                    isArchived={this.state.isArchived}
                    facilityId={this.state.searchFilters.facility_id}
                    handleFiltersChange={this.handleFiltersChange}
                    facilities={this.state.facilities}
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onPageSizeChanged={this.onPageSizeChanged}
                    saveState={this.saveState}
                    resetState={this.resetState}
                    onBtnExport={this.onBtnExport}
                    user_role={this.state.user_role}
                />

                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    components={this.state.components}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationNumberFormatter={
                                        this.state.paginationNumberFormatter
                                    }
                                    excelStyles={this.state.excelStyles}
                                    context={this.state.context}
                                    overlayNoRowsTemplate={
                                        this.state.searchFilters.facility_id
                                            .length === 0
                                            ? "No facility has been selected, please choose a facility"
                                            : "No Rows To Show"
                                    }
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicPatientDuplicatedGrid;
