import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { roles } from "../../../../services/common/optionsData";
import {
    updateUser,
    createUser,
    getUserDataById
} from "../../../../services/clinicPortalServices/userService";
import { getUserRole, getUserID } from "../../../../services/common/util";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import { getCompanyData } from "../../../../services/clinicPortalServices/companyServices";
import { phoneNumberFormatter } from "../../../../services/common/util";
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {
    isValidEmail,
    isValidPhoneNumber
} from "../../../../services/common/util";
import Swal from "sweetalert2";
import toastr from "toastr";

export default class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showMessage: false,
            message: "",
            userId: this.props && this.props.userId ? this.props.userId : "",
            userName: "",
            role: "",
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            password: "",
            facilityIds: [],
            companyIds: [],
            facilityIdsOrigin: [],
            facilityIdsFA: [],
            allFacilityIds: [],
            errors: [],
            require2FA: false,
            facilities: [],
            companies: []
        };
    }

    componentDidMount() {
        if (this.state.userId !== "") {
            this.loadUserDetails();
        }

        fetchFacilitiesForOrders().then((response) => {
            this.setState({ facilities: response.data });
            let allFacilityIds = [];
            response.data.map((item) => {
                allFacilityIds.push(item._id);
                return null;
            });
            this.setState({ allFacilityIds: allFacilityIds });
        });

        this.loadCompanies();
    }

    loadCompanies = () => {
        getCompanyData().then((response) => {
            let companies = response.data;
            let allCompanyIds = [];
            response.data.map((item) => {
                allCompanyIds.push(item._id);
                return null;
            });
            this.setState({ allCompanyIds, companies });
        });
    };

    loadUserDetails = () => {
        getUserDataById(this.state.userId).then((response) => {
            let userDetails = response.data[0];
            this.setState({
                userName: userDetails ? userDetails.user_name : "",
                role: userDetails ? userDetails.role : "",
                firstName:
                    userDetails && userDetails.first_name
                        ? userDetails.first_name
                        : "",
                lastName:
                    userDetails && userDetails.last_name
                        ? userDetails.last_name
                        : "",
                email:
                    userDetails && userDetails.email ? userDetails.email : "",
                mobile:
                    userDetails && userDetails.mobile ? userDetails.mobile : "",
                require2FA:
                    userDetails && userDetails.require2FA
                        ? userDetails.require2FA
                        : false
            });
            this.setFacilityIds(userDetails);
        });
    };

    setFacilityIds = (dataDetails) => {
        let facilityIdsOrigin = dataDetails.facilityIds
            ? dataDetails.facilityIds
            : [];
        if (
            getUserRole() === "FacilityAdmin" &&
            getUserID() !== this.state.userId
        ) {
            const facilityUserId = getUserID();
            getUserDataById(facilityUserId).then((response) => {
                let userDetails = response.data[0];
                let facilityIdsFA =
                    userDetails && userDetails.facilityIds
                        ? userDetails.facilityIds
                        : [];
                let facilityIds = facilityIdsOrigin.filter((n) =>
                    facilityIdsFA.includes(n)
                );
                this.setState({
                    facilityIds: facilityIds,
                    facilityIdsFA: facilityIdsFA,
                    facilityIdsOrigin: facilityIdsOrigin
                });
            });
        } else {
            this.setState({
                facilityIds: facilityIdsOrigin,
                companyIds: dataDetails.companyIds ? dataDetails.companyIds : []
            });
        }
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    handleChange = (e) => {
        if (e.target.name === "mobile") {
            this.setState({
                mobile: phoneNumberFormatter(e.target.value)
            });
        } else {
            if (e.target.name === "facilityIds") {
                if (e.target.value.includes("all")) {
                    if (
                        this.state.allFacilityIds.length ===
                        this.state.facilityIds.length
                    ) {
                        this.setState({ facilityIds: [] });
                    } else {
                        this.setState({
                            facilityIds: this.state.allFacilityIds
                        });
                    }
                } else {
                    this.setState({ facilityIds: e.target.value });
                }
            } else if (e.target.name === "companyIds") {
                let selectedValue = e.target.value;
                let companyIds = [],
                    facilityIds = [];
                if (e.target.value.includes("all")) {
                    companyIds =
                        this.state.allCompanyIds.length ===
                        this.state.companyIds.length
                            ? []
                            : this.state.allCompanyIds;
                } else {
                    companyIds = selectedValue;
                }
                if (companyIds.length > 0) {
                    this.state.companies.forEach((com) => {
                        if (
                            selectedValue.includes(com._id) &&
                            com.facilityIds &&
                            com.facilityIds.length > 0
                        ) {
                            let facilityIdsTemp = com.facilityIds.map(
                                (fac) => fac._id
                            );
                            facilityIds.push.apply(
                                facilityIds,
                                facilityIdsTemp
                            );
                        }
                    });
                }
                this.setState({ facilityIds, companyIds });
            } else {
                this.setState({ [e.target.name]: e.target.value });
            }
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    toggleState = (type) => {
        if (type === "require2FA") {
            this.setState({ require2FA: !this.state.require2FA });
        }
    };

    saveChanges = () => {
        let errors = [];

        if (this.state.userName === "") {
            errors.push("userName");
        }
        if (!this.state.userId && !this.state.password) {
            errors.push("password");
        }
        if (this.state.firstName === "") {
            errors.push("firstName");
        }
        if (this.state.lastName === "") {
            errors.push("lastName");
        }
        if (!this.state.role) {
            errors.push("role");
        }

        if (!this.state.email) {
            errors.push("email");
        }

        if (this.state.email && !isValidEmail(this.state.email)) {
            errors.push("wrongEmail");
        }

        if (this.state.mobile && !isValidPhoneNumber(this.state.mobile)) {
            errors.push("wrongMobile");
        }

        if (
            ["FacilityUser", "FacilityAdmin"].includes(this.state.role) &&
            !this.state.facilityIds.length
        ) {
            errors.push("facilityIds");
        }

        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let userInfo = {
            id: this.state.userId,
            userName: this.state.userName,
            role: this.state.role,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            email: this.state.email,
            mobile: this.state.mobile,
            facilityIds: this.state.facilityIds,
            companyIds: this.state.companyIds,
            require2FA: this.state.require2FA
        };
        if (
            getUserRole() === "FacilityAdmin" &&
            getUserID() !== this.state.userId
        ) {
            let facilityIdsTemp = this.state.facilityIdsOrigin.filter(
                (n) => !this.state.facilityIdsFA.includes(n)
            );
            this.state.facilityIds.forEach((n) => {
                facilityIdsTemp.push(n);
            });
            userInfo.facilityIds = facilityIdsTemp;
        }
        if (this.state.userId !== "") {
            updateUser(userInfo).then((response) => {
                if (response.data.RESULT === "ERROR") {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        text: response.data.message,
                        icon: "error",
                        confirmButtonText: "Ok"
                    });
                } else {
                    this.props.context.componentParent.loadUserGridData();
                    this.props.handleClose();
                    toastr.success(response.data.message);
                }
            });
        } else {
            createUser(userInfo).then((response) => {
                if (response.data.RESULT === "ERROR") {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        text: response.data.message,
                        icon: "error",
                        confirmButtonText: "Ok"
                    });
                } else {
                    this.props.context.componentParent.loadUserGridData();
                    this.props.handleClose();
                    toastr.success(response.data.message);
                }
            });
        }
    };

    render() {
        return (
            <div>
                <form>
                    <div className="row form-row">
                        {this.state.userId && (
                            <div className="col-12">
                                <div className="form-group">
                                    <label>
                                        User Name{" "}
                                        <span className="text-danger"> *</span>{" "}
                                    </label>
                                    <input
                                        type="text"
                                        name="userName"
                                        value={this.state.userName}
                                        onChange={this.handleChange}
                                        required
                                        className={
                                            this.hasError("userName")
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                    />
                                    <div
                                        className={
                                            this.hasError("userName")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                    >
                                        <i
                                            className="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        >
                                            &nbsp;This field is required.
                                        </i>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!this.state.userId && (
                            <div className="col-12">
                                <div className="row form-row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>
                                                User Name{" "}
                                                <span className="text-danger">
                                                    {" "}
                                                    *
                                                </span>{" "}
                                            </label>
                                            <input
                                                type="text"
                                                name="userName"
                                                value={this.state.userName}
                                                onChange={this.handleChange}
                                                required
                                                className={
                                                    this.hasError("userName")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                            />
                                            <div
                                                className={
                                                    this.hasError("userName")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;This field is
                                                    required.
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Password{" "}
                                                <span className="text-danger">
                                                    {" "}
                                                    *
                                                </span>{" "}
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                required
                                                className={
                                                    this.hasError("password")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                            />
                                            <div
                                                className={
                                                    this.hasError("password")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;This field is
                                                    required.
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>
                                    First Name{" "}
                                    <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("firstName")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("firstName")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>
                                    Last Name{" "}
                                    <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("lastName")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("lastName")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Role</label>
                                <select
                                    name="role"
                                    value={this.state.role}
                                    onChange={this.handleChange}
                                    className={
                                        this.hasError("role")
                                            ? "form-control is-invalid select"
                                            : "form-control select"
                                    }
                                >
                                    <option key="" value="">
                                        Please Select
                                    </option>
                                    {getUserRole() === "SalesAdmin" &&
                                        roles.map((role) => {
                                            if (role.key === "SalesPerson") {
                                                return (
                                                    <option
                                                        key={role.key}
                                                        value={role.value}
                                                    >
                                                        {role.value}
                                                    </option>
                                                );
                                            }
                                            return null;
                                        })}
                                    {getUserRole() === "FacilityAdmin" &&
                                        roles.map((role) => {
                                            if (role.key === "FacilityUser") {
                                                return (
                                                    <option
                                                        key={role.key}
                                                        value={role.value}
                                                    >
                                                        {role.value}
                                                    </option>
                                                );
                                            }
                                            return null;
                                        })}
                                    {getUserRole() !== "FacilityAdmin" &&
                                        getUserRole() !== "SalesAdmin" &&
                                        roles.map((role) => {
                                            return (
                                                <option
                                                    key={role.key}
                                                    value={role.value}
                                                >
                                                    {role.value}
                                                </option>
                                            );
                                        })}
                                </select>
                                <div
                                    className={
                                        this.hasError("role")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        {!/SuperAdmin|^Admin|CustomerServiceUser|Audit|SalesAdmin|SalesPerson|LabTech|Accountant/.test(
                            this.state.role
                        ) && (
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label>Facility</label>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                    >
                                        <InputLabel id="facility-label">
                                            Select Facility
                                        </InputLabel>
                                        {this.state.facilities &&
                                            this.state.facilities.length && (
                                                <Select
                                                    labelId="facility-label"
                                                    id="facility-select"
                                                    value={
                                                        this.state.facilityIds
                                                    }
                                                    onChange={this.handleChange}
                                                    label="Select Facility"
                                                    name="facilityIds"
                                                    multiple
                                                    renderValue={(selected) => {
                                                        if (
                                                            selected.length ===
                                                            this.state
                                                                .facilities
                                                                .length
                                                        ) {
                                                            return "All Facilities";
                                                        } else {
                                                            let displayStrings =
                                                                [];
                                                            this.state.facilities.map(
                                                                (fac) => {
                                                                    if (
                                                                        selected.includes(
                                                                            fac._id
                                                                        )
                                                                    ) {
                                                                        displayStrings.push(
                                                                            fac.name
                                                                        );
                                                                    }
                                                                    return null;
                                                                }
                                                            );
                                                            return displayStrings.join(
                                                                ", "
                                                            );
                                                        }
                                                    }}
                                                    sx={{ height: 45 }}
                                                >
                                                    <MenuItem
                                                        className={
                                                            this.state
                                                                .facilityIds
                                                                .length ===
                                                            this.state
                                                                .facilities
                                                                .length
                                                                ? "Mui-selected"
                                                                : ""
                                                        }
                                                        value="all"
                                                        key="all"
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .facilityIds
                                                                    .length ===
                                                                this.state
                                                                    .facilities
                                                                    .length
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        All Facilities
                                                    </MenuItem>
                                                    {this.state.facilities.map(
                                                        (fac) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        fac._id
                                                                    }
                                                                    value={
                                                                        fac._id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            this.state.facilityIds.includes(
                                                                                fac._id
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {fac.name}
                                                                    &nbsp;
                                                                    {fac.archived ===
                                                                        "archived" && (
                                                                        <b>
                                                                            {
                                                                                " ( Archived )"
                                                                            }
                                                                        </b>
                                                                    )}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            )}
                                    </FormControl>
                                    <div
                                        className={
                                            this.hasError("facilityIds")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                    >
                                        <i
                                            className="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        >
                                            &nbsp;This field is required.
                                        </i>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* {
							this.state.role == 'SalesPerson'
							&& 
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Company</label>
									<FormControl
										variant="outlined"
										style={{ width: "100%", marginTop: "5px" }}
									>
										<InputLabel id="company-label">Select Company</InputLabel>
										{
											this.state.facilities
											&&
											this.state.facilities.length
											&&
											<Select
												labelId="company-label"
												id="company-select"
												value={this.state.companyIds}
												onChange={this.handleChange}
												label="Select Company"
												name="companyIds"
												multiple
												renderValue={(selected) => {
													if (selected.length == this.state.companies.length) {
														return 'All Companies';
													} else {
														let displayStrings = [];
														this.state.companies.map(com => {
															if (selected.includes(com._id)) {
																displayStrings.push(com.name)
															}
														})
														return displayStrings.join(', ');
													}
												}}
											>
												<MenuItem className={this.state.companyIds.length === this.state.companies.length ? "Mui-selected" : ""} value="all" key="all">
													<Checkbox checked={this.state.companyIds.length === this.state.companies.length ? true : false} />
													All Companies	
												</MenuItem>
												{
													this.state.companies.map((com) => {
														return (
															<MenuItem
																key={com._id}
																value={com._id}
															>
																<Checkbox checked={this.state.companyIds.includes(com._id) ? true : false} />
																{com.name}
															</MenuItem>
														);
													})
												}
											</Select>
										}
									</FormControl>
								</div>
							</div>
						} */}
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>
                                    Email
                                    <span className="text-danger"> *</span>
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    className={
                                        this.hasError("wrongEmail")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("email")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;Email is required.
                                    </i>
                                </div>
                                <div
                                    className={
                                        this.hasError("wrongEmail")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;Email is invalid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                    type="tel"
                                    name="mobile"
                                    value={this.state.mobile}
                                    onChange={this.handleChange}
                                    placeholder="(XXX) XXX-XXXX"
                                    className={
                                        this.hasError("wrongMobile")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("wrongMobile")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;Mobile is invalid.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mat-checkbox-custom">
                            <div className="form-group">
                                <MenuItem
                                    onClick={() =>
                                        this.toggleState("require2FA")
                                    }
                                >
                                    <Checkbox
                                        checked={
                                            this.state.require2FA ? true : false
                                        }
                                    />
                                    <div style={{ whiteSpace: "break-spaces" }}>
                                        User does require 2FA
                                    </div>
                                </MenuItem>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12"
                            style={{
                                paddingTop: "10px",
                                borderTop: "1px solid rgba(0,0,0,.2"
                            }}
                        >
                            <Button
                                style={{ float: "right", marginLeft: "10px" }}
                                variant="primary"
                                onClick={this.saveChanges}
                            >
                                Save Changes
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                variant="secondary"
                                onClick={this.props.handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
