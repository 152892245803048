import React, { Component } from "react";
import { BLUELOT_LOGO } from "../../common/imgLogoes";
class ComingSoon extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        console.log("coming soon page here");
    }

    componentDidMount() {}

    render() {
        return (
            <div
                style={{
                    position: "fixed",
                    height: "100vh",
                    width: "100vw",
                    top: 0,
                    left: 0,
                    zIndex: 10000,
                    background: "white",
                    paddingTop: "100px"
                }}
            >
                <div className="row text-center">
                    <div className="col-12 ">
                        <img
                            src={BLUELOT_LOGO}
                            width="350"
                            object-fit="cover"
                            alt=""
                        />
                    </div>
                    <div className="col-12 py-4">
                        <h3>We'll be right back.</h3>
                        <p className="py-2">
                            We're busy updating Benchlab, LLC. to add some more
                            amazing features! We will be back very soon.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComingSoon;
