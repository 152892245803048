import React, { Component, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import MasterBtnCellRenderer from "./masterBtnCellRenderer";
import NoteBtnCellRenderer from "./noteBtnCellRenderer.jsx";
import OrderEditBtnCellRenderer from "../../orders/clinicOrderGrid/editBtnCellRenderer";
import RequisitionEditBtnCellRenderer from "../../requisitions/clinicRequisitionGrid/editBtnCellRenderer";
import { Modal } from "react-bootstrap";
import InsuranceDuplicatedDetails from "./insuranceDuplicatedDetails";
import {
    fetchPatientMasterDataForInsurance,
    fetchOrdersByInsuranceId
} from "../../../../services/clinicPortalServices/patientInsuranceService";
import {
    getViewSettings,
    saveViewDefault
} from "../../../../services/clinicPortalServices/userViewSettings";
import { auditEnums } from "../../../../services/common/constants";
import {
    getUserRole,
    dateComparator,
    abortApiCalls,
    releaseApiCalls,
    getUserDetails
} from "../../../../services/common/util";
import InsuranceSearchMenu from "./insuranceSearchMenu";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import PdfResultRenderer from "../../orders/clinicOrderGrid/pdfResultRenderer";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from "lodash";
import PatientBtnCellRenderer from "./patientBtnCellRenderer";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import ImageBtnCellRenderer from "../../../common/imageBtnCellRenderer";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class ClinicInsuranceGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isArchived: false,
            isBulkEdit: false,
            bulkCheckedIds: [],
            insurancesDuplicated: [],
            showDuplicatedDetails: false,
            user_role: getUserRole(),
            gridApi: null,
            searchFilters: {
                facility_id: []
            },
            gridName: "Insurance",
            pageSize: "",
            columnDefs: [
                {
                    headerName: "Insurance ID",
                    minWidth: 200,
                    resizable: true,
                    field: "_id",
                    cellRenderer: "agGroupCellRenderer"
                },
                {
                    headerName: "Insurance Status",
                    field: "status_value",
                    minWidth: 160,
                    sortable: true,
                    resizable: true,
                    valueGetter: function (params) {
                        return params.data && params.data.is_active
                            ? "Active"
                            : "Inactive";
                    }
                },
                {
                    headerName: "Inactive Reason",
                    minWidth: 160,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        if (params.data && params.data.reason_inactive) {
                            return (
                                <NoteBtnCellRenderer
                                    data={params.data}
                                    type="reason_inactive"
                                />
                            );
                        }
                        return <div></div>;
                    })
                },
                {
                    headerName: "Note",
                    minWidth: 160,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        if (params.data && params.data.note) {
                            return (
                                <NoteBtnCellRenderer
                                    data={params.data}
                                    type="note"
                                />
                            );
                        }
                        return <div></div>;
                    })
                },
                {
                    headerName: "Patient Name",
                    minWidth: 200,
                    resizable: true,
                    valueGetter: function addColumns(params) {
                        if (params.data && params.data.patient) {
                            return (
                                params.data.patient.first_name +
                                " " +
                                params.data.patient.last_name
                            );
                        } else {
                            return " ";
                        }
                    },
                    cellRenderer: "patientBtnCellRenderer"
                },
                {
                    headerName: "Facility Source",
                    minWidth: 150,
                    resizable: true,
                    field: "patient.facility_id.name"
                },
                {
                    headerName: "Insurance Provider",
                    field: "insurance_provider",
                    minWidth: 150,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Insurance Member ID",
                    field: "insured_member_id",
                    minWidth: 200,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Insurance Group Number",
                    field: "insured_group_number",
                    minWidth: 200,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Relation to insured",
                    field: "relation_to_insured",
                    minWidth: 200,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Insurance Holder Name",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    valueGetter: function (params) {
                        return params.data && params.data.insured_first_name
                            ? params.data.insured_first_name +
                                  " " +
                                  params.data.insured_last_name
                            : "";
                    }
                },
                {
                    headerName: "Social Security Number",
                    field: "social_security_number",
                    minWidth: 200,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Insurance Front Image",
                    field: "images.insurance_front",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        if (
                            params.data &&
                            params.data.images &&
                            params.data.images.insurance_front
                        ) {
                            return (
                                <ImageBtnCellRenderer
                                    insurance={params.data}
                                    patient={params.data.patient}
                                    type="insurance_front"
                                />
                            );
                        }
                        return <div></div>;
                    })
                },
                {
                    headerName: "Insurance Back Image",
                    field: "images.insurance_back",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        if (
                            params.data &&
                            params.data.images &&
                            params.data.images.insurance_back
                        ) {
                            return (
                                <ImageBtnCellRenderer
                                    insurance={params.data}
                                    patient={params.data.patient}
                                    type="insurance_back"
                                />
                            );
                        }
                        return <div></div>;
                    })
                },
                {
                    headerName: "Driver License",
                    field: "patient.driver_license",
                    minWidth: 200,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Driver License State",
                    field: "patient.driver_license_state",
                    minWidth: 200,
                    sortable: true,
                    resizable: true
                },
                {
                    headerName: "Driver License Image",
                    field: "patient.driver_license_image",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    cellRenderer: memo((params) => {
                        if (
                            params.data &&
                            params.data.patient &&
                            params.data.patient.driver_license_image
                        ) {
                            return (
                                <ImageBtnCellRenderer
                                    patient={params.data.patient}
                                    insurance={{}}
                                    type="driver_license"
                                />
                            );
                        }
                        return <div></div>;
                    })
                }
            ],
            components: {
                masterBtnCellRenderer: MasterBtnCellRenderer,
                patientBtnCellRenderer: PatientBtnCellRenderer,
                noteBtnCellRenderer: NoteBtnCellRenderer
            },
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            detailCellRendererParams: {
                refreshStrategy: "rows",
                detailGridOptions: {
                    immutableData: false,
                    getRowNodeId: function (data) {
                        return data._id;
                    },
                    columnDefs: [
                        {
                            headerName: "Actions",
                            minWidth: 100,
                            resizable: true,
                            cellStyle: { textAlign: "center" },
                            cellRenderer: memo((params) => {
                                if (
                                    params.data.results &&
                                    (params.data.results.value ||
                                        (params.data.results.values &&
                                            params.data.results.values.length >
                                                0))
                                ) {
                                    return (
                                        <OrderEditBtnCellRenderer
                                            params={params}
                                            data={params.data}
                                            context={{ componentParent: this }}
                                            type={"insuranceGrid"}
                                        />
                                    );
                                } else {
                                    return (
                                        <RequisitionEditBtnCellRenderer
                                            params={params}
                                            data={params.data}
                                            context={{ componentParent: this }}
                                            type={"insuranceGrid"}
                                        />
                                    );
                                }
                            })
                        },
                        {
                            headerName: "Test",
                            field: "test_id.name",
                            resizable: true
                        },
                        {
                            headerName: "Specimen Type",
                            field: "test_info.specimenType",
                            resizable: true
                        },
                        {
                            headerName: "Sample Type",
                            field: "test_info.sampleType",
                            resizable: true
                        },
                        {
                            headerName: "Sample",
                            resizable: true,
                            field: "test_info.sample"
                        },
                        {
                            headerName: "Result",
                            resizable: true,
                            field: "results.value",
                            cellRenderer: "pdfResultRenderer"
                        },
                        {
                            headerName: "Specimen Collected Date",
                            field: "test_info.collected",
                            minWidth: 200,
                            resizable: true,
                            valueGetter: function (params) {
                                return params.data &&
                                    params.data.test_info &&
                                    params.data.test_info.collected
                                    ? moment(
                                          params.data.test_info.collected,
                                          "YYYYMMDDHHmmss"
                                      ).format("MM/DD/YYYY hh:mm A")
                                    : "";
                            },
                            comparator: dateComparator
                        },
                        {
                            headerName: "Physician",
                            minWidth: 150,
                            resizable: true,
                            valueGetter: function addColumns(params) {
                                if (params.data && params.data.provider) {
                                    return (
                                        params.data.provider.first_name +
                                        " " +
                                        params.data.provider.last_name
                                    );
                                } else {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Received Date",
                            field: "test_info.received",
                            minWidth: 200,
                            resizable: true,
                            valueGetter: function (params) {
                                return params.data &&
                                    params.data.test_info &&
                                    params.data.test_info.received
                                    ? moment(
                                          params.data.test_info.received,
                                          "YYYYMMDDHHmmss"
                                      ).format("MM/DD/YYYY hh:mm A")
                                    : "";
                            },
                            comparator: dateComparator
                        },
                        {
                            headerName: "Requisition",
                            field: "lab_order_id",
                            resizable: true
                        }
                    ],
                    components: {
                        pdfResultRenderer: PdfResultRenderer,
                        editBtnCellRenderer: OrderEditBtnCellRenderer,
                        requisitionEditBtnCellRenderer:
                            RequisitionEditBtnCellRenderer
                    },
                    defaultColDef: { flex: 1, filter: true }
                },
                getDetailRowData: function (params) {
                    fetchOrdersByInsuranceId(params.data._id).then(
                        (response) => {
                            response.data.map((row) => {
                                row.context = params.context;
                                return null;
                            });
                            params.successCallback(response.data);
                        }
                    );
                }
            },
            gridLoading: false,
            context: { componentParent: this },
            excelStyles: [
                {
                    id: "header",
                    interior: {
                        color: "#aaaaaa",
                        pattern: "Solid"
                    }
                },
                {
                    id: "body",
                    interior: {
                        color: "#dddddd",
                        pattern: "Solid"
                    }
                }
            ],
            facilities: []
        };
        this.loadFacilities(this.state.isArchived);
    }

    collapseAll = () => {
        this.gridApi.forEachNode(function (node) {
            node.expanded = false;
        });
        this.gridApi.onGroupExpandedOrCollapsed();
    };

    componentDidMount() {
        if (
            [
                "superadmin",
                "admin",
                "facilityadmin",
                "facilityuser",
                "customerserviceuser"
            ].includes(getUserRole().toLowerCase())
        ) {
            let columnDefs = [
                {
                    headerName: "Actions",
                    maxWidth: 100,
                    minWidth: 100,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "masterBtnCellRenderer"
                },
                ...this.state.columnDefs
            ];
            this.setState({ columnDefs: columnDefs });
        }
    }

    loadFacilities = (isArchived) => {
        const filters = this.state.searchFilters;
        let facilities = this.state.facilities;
        const facilityIdStorage = window.localStorage.getItem("FACILITY_ID");

        let isAllFacilities = false;
        if (
            filters.facility_id.length > 0 &&
            filters.facility_id.length === facilities.length &&
            facilities.length > 0
        ) {
            isAllFacilities = true;
        }

        getCompanyWithFacility(isArchived).then((response) => {
            let facilityIds = [];

            facilities = response.data;
            if (facilities.length > 0) {
                facilities.map((fac) => {
                    facilityIds.push(fac._id);
                    return null;
                });
                this.facilityIds = facilityIds;
                filters.facility_id = facilityIds;
                if (getUserRole().toLowerCase() === "superadmin") {
                    filters.facility_id = []; // if superadmin, set default for Benchlab, LLC. facility
                }
            } else {
                filters.facility_id = facilities[0]._id;
            }

            if (
                !isAllFacilities &&
                facilityIdStorage &&
                facilityIdStorage !== undefined &&
                Array.isArray(JSON.parse(facilityIdStorage)) &&
                facilityIdStorage.length
            ) {
                filters.facility_id = JSON.parse(facilityIdStorage);
            }

            if (window.localStorage.getItem("FROM_DATE_RANGE")) {
                filters.from_date =
                    window.localStorage.getItem("FROM_DATE_RANGE");
            }
            if (window.localStorage.getItem("TO_DATE_RANGE")) {
                filters.to_date = window.localStorage.getItem("TO_DATE_RANGE");
            }
            this.setState({ searchFilters: filters, facilities }, () => {
                this.loadGridData();
            });
        });
    };

    onIsArchivedInformation = (event) => {
        this.setState({ isArchived: event.target.checked });
        this.loadFacilities(event.target.checked);
    };

    handleFiltersChange = (e) => {
        let filters = this.state.searchFilters;
        switch (e.target.name) {
            case "facility_id": {
                let { facilities } = this.state;
                let facilityIdChanged = [],
                    isAdded = false,
                    facilitiesIn = [],
                    facilityIdsTemp = [];
                let newFacilityIds = e.target.value;
                if (e.target.value.includes("all")) {
                    if (filters.facility_id.length === facilities.length) {
                        filters.facility_id = [];
                    } else {
                        filters.facility_id = _.cloneDeep(this.facilityIds);
                    }
                } else {
                    if (filters.facility_id.length > newFacilityIds.length) {
                        facilityIdChanged = filters.facility_id.find(
                            (val) => !newFacilityIds.includes(val)
                        );
                    } else {
                        facilityIdChanged = newFacilityIds.find(
                            (val) => !filters.facility_id.includes(val)
                        );
                        isAdded = true;
                    }
                    let facilityChanged = facilities.find(
                        (facility) => facility._id === facilityIdChanged
                    );
                    facilityIdsTemp = _.cloneDeep(filters.facility_id);
                    // If company is changed
                    if (facilityChanged.type === "company") {
                        let companyChanged = facilityChanged;
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyChanged._id) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        let index = facilityIdsTemp.indexOf(companyChanged._id);
                        if (facilitiesIn.length > 0) {
                            // If company is selected
                            if (isAdded) {
                                // Add company Id
                                facilityIdsTemp.push(companyChanged._id);
                                // Add facility Ids
                                facilitiesIn.forEach((facId) => {
                                    if (!facilityIdsTemp.includes(facId)) {
                                        facilityIdsTemp.push(facId);
                                    }
                                });
                            } else {
                                // If company is unselected
                                // Remove company Id
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                                facilitiesIn.forEach((facId) => {
                                    let index = facilityIdsTemp.indexOf(facId);
                                    if (index > -1) {
                                        facilityIdsTemp.splice(index, 1);
                                    }
                                });
                            }
                        } else {
                            isAdded
                                ? facilityIdsTemp.push(companyChanged._id)
                                : facilityIdsTemp.splice(index, 1);
                        }
                    }
                    // If facility is changed
                    else {
                        let companyId = facilityChanged.parent_id;
                        let facilitiesIn = [];
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyId) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        // If facility Id is selected
                        if (isAdded) {
                            // Add facility Id
                            facilityIdsTemp.push(facilityChanged._id);
                            if (!filters.facility_id.includes(companyId)) {
                                facilityIdsTemp.push(companyId);
                            }
                        } else {
                            // If facility Id is unselected
                            // Remove facility Id
                            let index = facilityIdsTemp.indexOf(
                                facilityChanged._id
                            );
                            if (index > -1) {
                                facilityIdsTemp.splice(index, 1);
                            }
                            // Remove company Id
                            let facilitiesInFilters = [];
                            facilitiesIn.forEach((fac) => {
                                if (facilityIdsTemp.includes(fac)) {
                                    facilitiesInFilters.push(fac);
                                }
                            });
                            if (facilitiesInFilters.length === 0) {
                                index = facilityIdsTemp.indexOf(companyId);
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                            }
                        }
                    }
                    filters.facility_id = _.cloneDeep(facilityIdsTemp);
                }
                window.localStorage.setItem(
                    "FACILITY_ID",
                    JSON.stringify(filters.facility_id)
                );
                break;
            }
            default:
                break;
        }

        this.setState({ searchFilters: filters });
    };

    handleBulkChecked = (id) => {
        let showReviewButton = false;
        let { bulkCheckedIds } = this.state;
        if (bulkCheckedIds.includes(id)) {
            bulkCheckedIds = bulkCheckedIds.filter((x) => x !== id);
        } else {
            bulkCheckedIds.push(id);
        }
        let patientIds = [];
        this.gridApi.forEachNode((rowNode, index) => {
            if (
                bulkCheckedIds.includes(rowNode.data._id) &&
                !patientIds.includes(rowNode.data.patient._id)
            ) {
                patientIds.push(rowNode.data.patient._id);
            }
        });
        if (patientIds.length === 1 && bulkCheckedIds.length > 1) {
            showReviewButton = true;
        }
        if (!showReviewButton && bulkCheckedIds.length > 1) {
            toastr.warning(
                "You must choose same patient to update their insurance, please unselect and try again."
            );
        }
        this.setState({
            bulkCheckedIds: bulkCheckedIds,
            showReviewButton: showReviewButton
        });
        this.gridApi.refreshCells();
    };
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.setState({ gridApi: this.gridApi });
        this.gridColumnApi = params.columnApi;
        this.loadGridSchema();
    };

    updateGridRow = (insuranceId, insuranceData) => {
        let updatedRowNode = this.gridApi.getRowNode(insuranceId);
        insuranceData.patient = updatedRowNode.data.patient;
        let data = Object.assign({}, updatedRowNode.data, insuranceData);
        updatedRowNode.setData(data);
    };

    getFacilityIds = () => {
        const filteredFacilities = [];
        this.state.facilities.map((fac) => {
            if (
                fac.type === "facility" &&
                this.state.searchFilters.facility_id.includes(fac._id)
            ) {
                filteredFacilities.push(fac._id);
            }
            return null;
        });
        return filteredFacilities;
    };

    loadGridData = async () => {
        abortApiCalls();
        releaseApiCalls();
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }
        const facilityId = this.getFacilityIds();
        let rowData = [];
        fetchPatientMasterDataForInsurance(facilityId)
            .then(async (response) => {
                if (!response) {
                    this.setState({ gridLoading: false });
                    return;
                }
                const patients = response.data ? response.data : [];
                patients.map((patient) => {
                    if (
                        patient.patient_insurance_ids &&
                        patient.patient_insurance_ids.length
                    ) {
                        patient.patient_insurance_ids.map((insurance) => {
                            insurance.patient = patient;
                            rowData.push(insurance);
                            return null;
                        });
                    }
                    return null;
                });
                this.setState({ rowData });
                if (!rowData.length) {
                    this.gridApi.showNoRowsOverlay();
                } else {
                    this.gridApi.hideOverlay();
                }
                this.collapseAll();
            })
            .catch((err) => {
                console.log(err);
                this.setState({ gridLoading: false });
                this.gridApi.hideOverlay();
            });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Insurance_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx",
            sheetName: "Insurances"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.PatientInsuranceGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.PatientInsuranceGridExported
        };
        createAudit(auditData);
    };

    onBtnBulkEdit = () => {
        if (this.state.isBulkEdit) {
            this.setState({ bulkCheckedIds: [], showReviewButton: false });
        }
        this.setState({ isBulkEdit: !this.state.isBulkEdit });
        this.gridApi.refreshCells();
    };

    handleCloseDuplicatedDetails = () => {
        this.setState({ showDuplicatedDetails: false });
    };

    onBtnReviewSelection = () => {
        let insurances = [];
        this.gridApi.forEachNode((rowNode, index) => {
            if (this.state.bulkCheckedIds.includes(rowNode.data._id)) {
                insurances.push(rowNode.data);
            }
        });
        this.setState({
            showDuplicatedDetails: true,
            insurancesDuplicated: insurances
        });
    };

    onPageSizeChanged = () => {
        let value = document.getElementById("page-size").value;
        this.gridApi.paginationSetPageSize(Number(value));
    };

    loadGridSchema = () => {
        let userId = window.localStorage.getItem("USER_ID");
        let params = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.INSURANCE
        };
        getViewSettings(params).then((response) => {
            const columnState =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                }).columns;
            if (columnState) {
                this.gridColumnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true
                });
            } else {
                this.gridColumnApi.resetColumnState();
            }

            const pageSize =
                response.data &&
                response.data.length > 0 &&
                response.data[0].grid_views.find((item) => {
                    return item.name === this.state.gridName;
                }).page_size;
            document.getElementById("page-size").value =
                pageSize && pageSize > 0 ? pageSize : 20;
            this.onPageSizeChanged();
        });
    };

    saveState = () => {
        let userId = window.localStorage.getItem("USER_ID");
        const columnState = this.gridColumnApi.getColumnState();
        let pageSize = document.getElementById("page-size").value;
        let saveObject = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.INSURANCE,
            grid_views: [
                {
                    name: this.state.gridName,
                    page_size: pageSize,
                    columns: columnState
                }
            ]
        };
        saveViewDefault(saveObject).then(() => {
            toastr.success("Saved successfully.");
        });
    };

    resetState = () => {
        this.gridColumnApi.resetColumnState();
    };

    clearFilter = () => {
        abortApiCalls();
        this.setState({ gridLoading: false });
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";

        const filters = this.state.searchFilters;
        if (getUserRole().toLowerCase() === "superadmin") {
            filters.facility_id = [];
        } else {
            if (this.state.facilities.length > 1) {
                filters.facility_id = this.facilityIds;
            } else {
                filters.facility_id = this.state.facilities[0]._id;
            }
        }
        window.localStorage.removeItem("FACILITY_ID");
        this.setState({ searchFilters: filters });
        this.loadGridData();
    };

    emptyInsurancesDuplicated = () => {
        this.setState({ bulkCheckedIds: [], showReviewButton: false });
        this.gridApi.refreshCells();
    };

    render() {
        let detailCellRendererParams = this.state.detailCellRendererParams;
        detailCellRendererParams.detailGridOptions.columnDefs = /audit/.test(
            getUserRole().toLowerCase()
        )
            ? this.state.detailCellRendererParams.detailGridOptions.columnDefs.filter(
                  (x) => x.headerName !== "Actions"
              )
            : this.state.detailCellRendererParams.detailGridOptions.columnDefs;
        return (
            <div className="clinic-contain">
                <InsuranceSearchMenu
                    onIsArchivedInformation={(event) => {
                        this.onIsArchivedInformation(event);
                    }}
                    isArchived={this.state.isArchived}
                    facilityId={this.state.searchFilters.facility_id}
                    handleFiltersChange={this.handleFiltersChange}
                    handelGridLoading={this.loadGridData}
                    facilities={this.state.facilities}
                    onFilterTextChange={this.onFilterTextChange}
                    gridLoading={this.state.gridLoading}
                    clearFilter={this.clearFilter}
                    onPageSizeChanged={this.onPageSizeChanged}
                    saveState={this.saveState}
                    resetState={this.resetState}
                    onBtnExport={this.onBtnExport}
                    onBtnBulkEdit={this.onBtnBulkEdit}
                    onBtnReviewSelection={this.onBtnReviewSelection}
                    isBulkEdit={this.state.isBulkEdit}
                    user_role={this.state.user_role}
                    showReviewButton={this.state.showReviewButton}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 230px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    detailCellRendererParams={
                                        detailCellRendererParams
                                    }
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    components={this.state.components}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationNumberFormatter={
                                        this.state.paginationNumberFormatter
                                    }
                                    excelStyles={this.state.excelStyles}
                                    context={this.state.context}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showDuplicatedDetails}
                    onHide={this.handleCloseDuplicatedDetails}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Review Possible Duplicate Insurances
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InsuranceDuplicatedDetails
                            insurancesDuplicated={
                                this.state.insurancesDuplicated
                            }
                            bulkCheckedIds={this.state.bulkCheckedIds}
                            handleCloseDuplicatedDetails={
                                this.handleCloseDuplicatedDetails
                            }
                            loadGridData={this.loadGridData}
                            emptyInsurancesDuplicated={
                                this.emptyInsurancesDuplicated
                            }
                        />
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}

export default ClinicInsuranceGrid;
